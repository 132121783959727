import { Skeleton } from "primereact/skeleton";

export function CardSkeletonLoader() {
  return (
    <>
      <Skeleton />
      <div className="p-mt-1">
        <Skeleton />
      </div>
      <Skeleton />
      <div className="p-my-5">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
      <Skeleton />
      <div className="p-mt-1">
        <Skeleton />
      </div>
      <Skeleton />
    </>
  );
}
