import { useEffect, useState } from "react";
import { BACK_OFFICE_API } from "../api/routes/back-office/index";
// import { API_SERVICE } from "../api/service";
import { API_SERVICE } from "../api/service";

export function useSingleAppConfigurationValue(configKey) {
  const [configValue, setConfigValue] = useState(null);
  useEffect(() => {
    function getConfigValue() {
      const url = BACK_OFFICE_API.CONFIGURATION.CONFIGURATIONS;
      API_SERVICE.MAKE_GET_REQUEST(`${url}/${configKey}`)
        .then((response) => {
          const value = response?.result?.configValue;
          setConfigValue(value);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getConfigValue();
  }, [configKey]);
  return configValue;
}
