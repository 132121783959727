export const monitoringNavItems = [
  {
    text: "Transaction Processing",
    route: "/monitor/transaction-processing",
    icon: "dashboard",
    primeIcon: "pi-chart-line",
    authority: "all",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "mo",
    id: "transaction-processing",
    children: [],
  },
  {
    text: "Banks APIs",
    route: "/monitor/banks-apis",
    icon: "dashboard",
    primeIcon: "pi-external-link",
    authority: "all",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "mo",
    id: "banks-apis",
    children: [],
  },

  {
    text: "Service Monitoring",
    route: "/monitor/dcir-services",
    icon: "service-monitoring",
    authority: "all",
    roles: ["DCIR_MERCHANT_SUPER_ADMIN"],
    type: "bo",
    id: "dcir-services",
    children: [],
  },
  {
    text: "Server Health",
    route: "/monitor/server-health",
    icon: "server-health",
    authority: "all",
    roles: ["DCIR_MERCHANT_SUPER_ADMIN"],
    type: "bo",
    id: "server-health",
    children: [],
  },
  {
    text: "Merchant's Last Transaction Times",
    route: "/monitor/last-transaction-times",
    icon: "dashboard",
    authority: "all",
    roles: ["DCIR_MERCHANT_SUPER_ADMIN"],
    type: "bo",
    id: "last-transaction-time",
    children: [],
  },
  {
    text: "Pending Transactions",
    route: "/monitor/pending-transactions",
    icon: "dashboard",
    primeIcon: "pi-sliders-v",
    authority: "all",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "mo",
    id: "pending-transactions",
    children: [],
  },
  {
    text: "Alert Configurations",
    route: "/monitor/alert-configurations",
    icon: "dashboard",
    primeIcon: "pi-bell",
    authority: "all",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "mo",
    id: "alert-configurations",
    children: [],
  },
];
