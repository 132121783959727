const GET_PENDING_SETTLEMENT_PAYMENTS =
  "/backoffice/manual-settlement-payment/pending";
const DOWNLOAD_SETTLEMENT_PAYMENTS =
  "/backoffice/manual-settlement-payment/download";
const PROCESS_SETTLEMENT_PAYMENT = "/backoffice/manual-settlement-payment";
const CHECK_MANUAL_SETTLEMENTS_DOWNLOAD_STATUS =
  "/backoffice/manual-settlement-payment/download-info";
const GET_MANUAL_SETTLEMENT_BULK_UPLOADS =
  "/backoffice/manual-settlement-payment/batches";
const UPLOAD_MANUAL_SETTLEMENT_BULK =
  "/backoffice/manual-settlement-payment/upload";
const GET_ITEMS_IN_MANUAL_SETTLEMENT_BULK =
  "/backoffice/manual-settlement-payment/batch-items";
const GET_MANUAL_SETTLEMENT_BULK_SUMMARY =
  "/backoffice/manual-settlement-payment/transactions/summary";

export const MANUAL_SETTLEMENT_PAYMENT_ROUTES = {
  GET_PENDING_SETTLEMENT_PAYMENTS,
  DOWNLOAD_SETTLEMENT_PAYMENTS,
  PROCESS_SETTLEMENT_PAYMENT,
  CHECK_MANUAL_SETTLEMENTS_DOWNLOAD_STATUS,
  GET_MANUAL_SETTLEMENT_BULK_SUMMARY,
  GET_MANUAL_SETTLEMENT_BULK_UPLOADS,
  UPLOAD_MANUAL_SETTLEMENT_BULK,
  GET_ITEMS_IN_MANUAL_SETTLEMENT_BULK,
};
