import { CustomCard } from "../../../components/elements/custom-card/custom-card";

export function FepAverageResponseTimeChart() {
  return (
    <CustomCard background="#7bd54f">
      <div
        className="p-text-center"
        style={{ background: "#7bd54f", color: "#ffffff", padding: "1rem 0" }}
      >
        <p>Average Response Time for the last 5 minutes</p>
        <p style={{ fontSize: "2rem" }}>20 secs</p>
      </div>
    </CustomCard>
  );
}
