import "./dashboard.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { Icon } from "components/elements/icons/icon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export function AnalyticsChart(props) {
  // const chartValueType = useRef("count");
  const [chartValueType, setChartValueType] = useState("count");
  const barChartOptions = useRef(null);
  const [chartData, setChartData] = useState(null);
  const [metricType] = useState(props?.metricType);

  useEffect(() => {
    barChartOptions.current = props.chartOptions;
    if (chartValueType === "count") {
      setChartData(props.chartDataByCount);
    } else {
      setChartData(props.chartDataByValue);
    }
  }, [chartValueType, props.chartDataByValue, props.chartDataByCount]);

  function handleChartTypeChange(e) {
    // chartValueType.current = e?.target?.value;
    setChartValueType(e?.target?.value);
  }

  const historicChart = () => {
    return (
      <div className="p-grid p-mt-5">
        <div className="p-col-12">
          <div className="card">
            <div
              className="p-text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="p-mt-0" style={{ fontWeight: "900" }}>
                {metricType === "transactions" ? "Transaction" : "Dispute"}{" "}
                Metrics
              </h3>
              {/* <small>{moment().format("HH:mm")}</small> */}
            </div>
            <div className="p-grid">
              <div className="p-col-6">
                <div
                  style={{
                    marginBottom: "2rem",
                  }}
                >
                  <label className="label">
                    <input
                      id="count"
                      value="count"
                      name="chartValueType"
                      type="radio"
                      checked={chartValueType === "count"}
                      onChange={handleChartTypeChange}
                    />
                    <span>
                      {metricType === "transactions"
                        ? "Transactions"
                        : "Disputes"}{" "}
                      by Count
                    </span>
                  </label>
                  <label className="label">
                    <input
                      id="value"
                      value="value"
                      name="chartValueType"
                      type="radio"
                      checked={chartValueType === "value"}
                      onChange={handleChartTypeChange}
                    />
                    <span>
                      {metricType === "transactions"
                        ? "Transactions"
                        : "Disputes"}{" "}
                      by Value
                    </span>
                  </label>
                </div>
              </div>
            </div>
            {!props.loading && (
              <div>
                {/* {props.userType === "front-office" ? (
                  <Pie
                    data={chartData}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                  />
                ) : ( */}
                <Bar options={barChartOptions.current} data={chartData} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const currentDayChart = () => {
    return (
      <div className="p-grid p-mt-5">
        <div className="p-col-12">
          <div className="card">
            <div
              className="p-text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="p-mt-0" style={{ fontWeight: "900" }}>
                {metricType === "transactions" ? "Transaction" : "Dispute"}{" "}
                Metrics
              </h3>
              {/* <small>{moment().format("HH:mm")}</small> */}
            </div>
            <div
              style={{
                marginBottom: "2rem",
              }}
            >
              <label className="label">
                <input
                  id="count"
                  value="count"
                  name="chartValueType"
                  type="radio"
                  checked={chartValueType === "count"}
                  onChange={handleChartTypeChange}
                />
                <span>
                  {metricType === "transactions" ? "Transactions" : "Disputes"}{" "}
                  by Count
                </span>
              </label>
              <label className="label">
                <input
                  id="value"
                  value="value"
                  name="chartValueType"
                  type="radio"
                  checked={chartValueType === "value"}
                  onChange={handleChartTypeChange}
                />
                <span>
                  {metricType === "transactions" ? "Transactions" : "Disputes"}{" "}
                  by Value
                </span>
              </label>
            </div>
            {/* {!loading && (
              <Bar options={barChartOptions.current} data={chartData} />
            )} */}
            {!props.loading && (
              <div>
                {props.userType === "front-office" ? (
                  <Pie
                    data={chartData}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                  />
                ) : (
                  <Bar options={barChartOptions.current} data={chartData} />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const chartView = () => {
    switch (props.dateRange) {
      case "today":
        return currentDayChart();
      default:
        return historicChart();
    }
  };

  if (props.hasNoData) {
    return (
      <div className="card p-mt-5 empty" style={{ textAlign: "center" }}>
        <Icon icon="not-found" />
        <p style={{ fontSize: "0.8rem" }}>No data found!</p>
        <div>
          <button className="primary-button" onClick={props.onReload}>
            Reload
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div>{chartView()}</div>
    </div>
  );
}
