import { TabPanel, TabView } from "primereact/tabview";
import React, { useRef, useState } from "react";
import { Icon } from "components/elements/icons/icon";
import { SpecialLabelCases } from "utils/models/utilities";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { Optional } from "components/elements/optional/optional";
import { UpdatePaymentRefundStatus } from "./update-payment-refund-status";

export function ManualPaymentDetails(props) {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState(1);

  function closeModal(reload) {
    setVisible(false);
    if (reload === true) {
      props.goBack(true);
    }
  }

  function openModal(index) {
    setCurrentModalIndex(index);
    setVisible(true);
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = () => {
    return props.formattedPaymentDetails.map((details, index) => (
      <div key={index.toString()} className="p-grid dcir-row">
        <div className="p-col-6">
          <p className="details-label">{details.label}:</p>
        </div>
        <div className="p-col-6">
          <p className="details-value">
            {transformView(details.itemCase, details.value)}
          </p>
        </div>
      </div>
    ));
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <UpdatePaymentRefundStatus
            manualPaymentType={props.manualPaymentType}
            paymentDetails={props.paymentDetails}
            closeModal={closeModal}
          />
        );
    }
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div>
        <CustomModal
          onHide={closeModal}
          visible={visible}
          modalContent={modalContent}
          closeModal={closeModal}
        />
      </div>
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header="Payment Details">
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
                <Optional
                  show={
                    HELPER.HAS_AUTHORITY("bo_process_manual_dispute_refund") ||
                    HELPER.HAS_AUTHORITY("bo_process_manual_payment_request")
                  }
                >
                  <div className="p-col-4">
                    <div
                      style={{
                        width: "200px",
                        float: "right",
                      }}
                    >
                      <button
                        onClick={() => openModal(1)}
                        className="primary-button"
                      >
                        Update Status
                      </button>
                    </div>
                  </div>
                </Optional>
              </div>
              <div className="p-grid">
                <div className="p-col-12">
                  <div>
                    <div className="p-text-left">{detailsView()}</div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}
