import { Optional } from "components/elements/optional/optional";
import { useEffect, useRef, useState } from "react";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import "./dcir-services.css";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";

const mockServices = [
  { serviceName: "Management", status: true, serviceId: "CRON" },
  { serviceName: "CRON", status: false, serviceId: "CRON" },
  { serviceName: "Management", status: true, serviceId: "Management" },
  { serviceName: "CRON", status: false, serviceId: "CRON" },
  { serviceName: "CRON", status: false, serviceId: "CRON" },
  // { name: "CRON", status: false },
];

export function ServicesHealthStatus() {
  const toast = useRef(null);
  const [services, setServices] = useState(mockServices);
  const [loading, setLoading] = useState(false);
  const [loadingRestart, setLoadingRestart] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState({});

  function getServiceStatus() {
    setLoading(true);
    MONITORING_SERVICE.MAKE_GET_REQUEST("/service/health/status")
      .then((data) => {
        setServices(data?.response);
        setLoading(false);
      })
      .catch((error) => {
        console.log("err", error);
        setLoading(false);
      });
  }

  useEffect(() => {
    getServiceStatus();
  }, []);

  const handleSuccessResponse = (response) => {
    setLoadingRestart(false);
    closeModal();
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: response?.message,
      life: 10000,
    });
    reload();
    setSelectedService({});
  };

  const handleErrorResponse = (error) => {
    setLoadingRestart(false);
    closeModal();
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: HELPER.PROCESS_ERROR("toast", error),
      life: 10000,
    });
  };

  const restartService = () => {
    setLoadingRestart(true);
    MONITORING_SERVICE.MAKE_PUT_REQUEST(
      `/service/restart/${selectedService.serviceId}`
    )
      .then((data) => {
        if (data.success) {
          handleSuccessResponse(data?.response);
        }
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  };

  const handleServiceAction = (service) => {
    setSelectedService(service);
    setShowModal(true);
  };

  const reload = () => {
    getServiceStatus();
  };

  function closeModal() {
    setShowModal(false);
  }

  const modalContent = () => {
    return (
      <div className="d-flex p-flex-column align-center ">
        <i
          className="pi pi-question-circle"
          style={{ fontSize: "8rem", color: "#0371ac" }}
        />
        <p className="p-py-3">
          Are you sure you want to restart{" "}
          <b> {selectedService?.serviceName}</b> service?
        </p>
        <div className="d-flex  " style={{ width: "100%" }}>
          <button
            disabled={loadingRestart}
            className="primary-button  p-mr-3 "
            onClick={closeModal}
          >
            No
          </button>
          <button
            disabled={loadingRestart}
            className="secondary-button "
            onClick={restartService}
          >
            {" "}
            {loadingRestart ? (
              <ProgressSpinner
                style={{ width: "20px", height: "20px" }}
                strokeWidth="4"
              />
            ) : (
              "Yes"
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <CustomModal
        closeModal={closeModal}
        visible={showModal}
        modalContent={modalContent}
      />
      <CustomCard loading={loading}>
        <div style={{ paddingBottom: "1rem" }}>
          <p className="p-text-bold p-mt-0">Services</p>
          <table className="service-list-tabl p-mt-5">
            {services.map((service, index) => (
              <tr key={index}>
                <td>{service.serviceName}</td>
                <td>
                  <div
                    onMouseEnter={() => setSelectedService(service)}
                    style={{ position: "relative" }}
                  >
                    {
                      <span
                        className={`custom-badge custom-badge-text ${
                          service?.active
                            ? "custom-badge-success"
                            : "custom-badge-error"
                        }`}
                        style={{
                          color: `${service?.active ? "#28c76f" : "#ea5455"}`,
                        }}
                      >
                        <span className="p-text-bold">
                          {service?.active ? "Active" : "Inactive"}{" "}
                        </span>
                      </span>
                    }
                    <Optional show={selectedService === service}>
                      <div className="service-details">
                        {typeof service?.details === "string"
                          ? service?.details
                          : service?.details?.join("\n")}
                      </div>
                    </Optional>
                  </div>
                </td>
                <td>
                  <button
                    onClick={() => handleServiceAction(service)}
                    className="restart-button"
                  >
                    <i className="pi pi-refresh px-1 text-error p-mr-1 " />
                    <p>Restart</p>
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </CustomCard>
    </>
  );
}
