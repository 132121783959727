import { useEffect, useState } from "react";
import { FormInput } from "components/form/form-input";
import { CUSTOM_VALIDATION } from "utils/validation";

export function ChargeTypesFilter(props) {
  const [formValues, setFormValues] = useState({
    chargeCode: null,
  });
  const [formErrors, setFormErrors] = useState({
    chargeCode: null,
  });
  const [shouldDisableButton, setShouldDisableButton] = useState(true);

  function validateForm(e, name) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    if (!isEmpty) {
      setFormValues({ ...formValues, [name]: value });
      setFormErrors({ ...formErrors, [name]: null });
    } else {
      setFormValues({ ...formValues, [name]: "" });
    }
  }

  function filterSearch() {
    let payload = {};
    if (formValues["chargeCode"]) {
      payload.chargeCode = formValues["chargeCode"];
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    return (
      <button onClick={cancelModal} className="secondary-button">
        Cancel
      </button>
    );
  };

  useEffect(() => {
    console.log(formValues["chargeCode"]);
    if (formValues["chargeCode"]) {
      if (formValues["chargeCode"]?.length > 0) {
        setShouldDisableButton(false);
      } else {
        setShouldDisableButton(false);
      }
      console.log(
        "has-values",
        formValues["chargeCode"]?.length,
        formValues["chargeCode"]
      );
    } else {
      setShouldDisableButton(true);
    }
  }, [formValues]);

  const submitButton = () => {
    return (
      <button
        onClick={() => props?.searchFunction(filterSearch())}
        className="primary-button"
        disabled={shouldDisableButton}
      >
        Filter
      </button>
    );
  };

  const filterFormView = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5">
          <p className="custom-dialog-subtitle">
            Filter charge types by charge code below:
          </p>
        </div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormInput
              value={formValues["chargeCode"]}
              required={false}
              field="chargeCode"
              type="INPUT"
              error={formErrors["chargeCode"]}
              fn={validateForm}
              placeholder="Charge code"
            />
          </div>

          <div className="p-col-12">
            <div className="p-mt-2">
              <div className="p-grid">
                <div className="p-col-6">{cancelButton()}</div>
                <div className="p-col-6">{submitButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div className="p-pb-2">{filterFormView()}</div>;
}
