import "./transaction-processing.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { Skeleton } from "primereact/skeleton";
import { Optional } from "components/elements/optional/optional";
import { Icon } from "components/elements/icons/icon";
import moment from "moment";
import { TransactionProcessingAnalyticsChart } from "./analytics-chart";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { MONITORING_API } from "../../../api/routes/monitoring-apis/index";
import { HELPER } from "utils/helper";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";

export function TransactionProcessing() {
  const toast = useRef(null);
  const [customDate, setCustomDate] = useState({
    startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  });
  const [dateFilterError, setDateFilterError] = useState(false);
  const dateFilter = useRef({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [noData, setNoData] = useState(false);
  const [currentDayMetrics, setCurrentDayMetrics] = useState(null);
  const [historicMetrics, setHistoricMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const reloadFunctionDetails = useRef(null);
  const [dateRangeValue, setDateRangeValue] = useState("today");
  const [showCustomDateField, setShowCustomDateField] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const selectedDateRange = useRef({
    desc: "Today",
    code: "today",
  });
  const dateRanges = [
    { desc: "Today", code: "today" },
    { desc: "Custom", code: "custom" },
  ];
  const [emptyText, setEmptyText] = useState("");

  function handleDateRangeSelection(e) {
    setDateFilterError(false);
    const selectedDate = e?.value;
    selectedDateRange.current = selectedDate;
    setShowCustomDateField(false);
    if (selectedDate?.code === "custom") {
      dateFilter.current = {
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
      setShowCustomDateField(true);
      return;
    } else if (selectedDate.code === "today") {
      getCurrentDayTransactionsProcessingMetrics();
    }
    setDateRangeValue(selectedDate?.code);
  }

  function handleCustomDateSearch() {
    const customDate = dateFilter?.current;
    if (customDate?.startDate && customDate?.endDate) {
      getHistoricTransactionProcessingMetrics(dateFilter.current);
    } else {
      setDateFilterError(true);
    }
  }

  function validateDateSelection(e, field) {
    const dateValue = e?.target?.value;
    setCustomDate({
      ...customDate,
      [field]: dateValue,
    });
    dateFilter.current = {
      ...dateFilter.current,
      [field]: dateValue,
    };
  }

  const getHistoricTransactionProcessingMetrics = (date) => {
    reloadFunctionDetails.current = {
      reloadFunction: getCurrentDayTransactionsProcessingMetrics,
      params: date,
    };
    setLoading(true);
    setDateFilterError(false);
    const params = HELPER.TO_URL_STRING(date);
    MONITORING_SERVICE.MAKE_GET_REQUEST(
      MONITORING_API.GET_HISTORIC_TRANSACTION_PROCESSING_METRICS,
      params
    )
      .then((data) => {
        setNoData(false);
        const result = data?.response;
        setDateRangeValue(selectedDateRange?.current?.code);
        setHistoricMetrics(result);
        setLoading(false);
      })
      .catch((error) => {
        setNoData(true);
        const message = error?.data?.message;
        if (message) {
          setErrorMessage(message);
          toast.current.show({
            severity: "error",
            summary: "Error!",
            detail: message,
            life: 5000,
          });
        } else if (error.data.responseCode === "03") {
          toast.current.show({
            severity: "error",
            summary: "Error!",
            detail: error?.data?.message,
            life: 5000,
          });
          setEmptyText(error?.data?.message);
        } else {
          setErrorMessage(HELPER.PROCESS_ERROR(error, "TOAST"));
          toast.current.show({
            severity: "error",
            summary: "Error!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 5000,
          });
        }
        setLoading(false);
      });
  };

  const getCurrentDayTransactionsProcessingMetrics = useCallback(() => {
    reloadFunctionDetails.current = {
      reloadFunction: getCurrentDayTransactionsProcessingMetrics,
      params: null,
    };
    setLoading(true);
    setDateFilterError(false);
    MONITORING_SERVICE.MAKE_GET_REQUEST(
      MONITORING_API.GET_CURRENT_DAY_TRANSACTION_PROCESSING_METRICS
    )
      .then((data) => {
        setNoData(false);
        const result = data?.response;
        setCurrentDayMetrics(result);
        setLoading(false);
      })
      .catch((error) => {
        setNoData(true);
        const message = error?.data?.message;
        if (message) {
          setErrorMessage(message);
          toast.current.show({
            severity: "error",
            summary: "Error!",
            detail: message,
            life: 5000,
          });
        } else {
          setErrorMessage(HELPER.PROCESS_ERROR(error, "TOAST"));
          toast.current.show({
            severity: "error",
            summary: "Error!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 5000,
          });
        }
        setLoading(false);
      });
  }, []);

  function reload() {
    const params = reloadFunctionDetails?.current?.params;
    reloadFunctionDetails?.current?.reloadFunction(params);
  }

  useEffect(() => {
    getCurrentDayTransactionsProcessingMetrics();
  }, [getCurrentDayTransactionsProcessingMetrics]);

  const currentChartView = (index) => {
    if (noData && errorMessage) {
      return (
        <div className="card p-mt-5 empty" style={{ textAlign: "center" }}>
          <Icon icon="not-found" />
          <p style={{ fontSize: "0.8rem" }}>{errorMessage}</p>
          <div>
            <button
              className="primary-button"
              style={{ width: "10rem" }}
              onClick={reload}
            >
              Reload
            </button>
          </div>
        </div>
      );
    } else if (noData && emptyText) {
      return (
        <div className="card p-mt-5 empty" style={{ textAlign: "center" }}>
          <Icon icon="no-item" />
          <p style={{ fontSize: "0.8rem" }}>{emptyText}</p>
          <div>
            <button
              className="primary-button"
              style={{ width: "10rem" }}
              onClick={reload}
            >
              Reload
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <TransactionProcessingAnalyticsChart
          dateRange={dateRangeValue}
          currentViewIndex={index}
          currentDayMetrics={currentDayMetrics}
          historicMetrics={historicMetrics}
        />
      );
    }
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="page-title p-text-left">
        Transaction Processing Metrics
      </div>
      <div className="p-mt-28">
        <CustomBreadcrumb page="Transaction Processing Metrics" />
      </div>
      <div className="p-grid p-mt-3">
        <div className="p-col-4 dropdown-wrapper">
          <FormDropdown
            required={true}
            label="desc"
            value={selectedDateRange.current}
            fn={handleDateRangeSelection}
            options={dateRanges}
            placeholder="Select time"
          />
        </div>
        <div className="p-col-6 date-select-row">
          <Optional show={showCustomDateField}>
            <div className="p-grid p-justify-center">
              <div className="p-col-4 p-pt-1">
                <FormInput
                  inputType="date"
                  value={customDate["startDate"]}
                  required={false}
                  field="startDate"
                  type="INPUT"
                  error={dateFilterError}
                  fn={validateDateSelection}
                  loading={false}
                  max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                  placeholder="Start date"
                />
              </div>
              <div className="p-col-4 p-pt-1">
                <FormInput
                  inputType="date"
                  value={customDate["endDate"]}
                  required={false}
                  field="endDate"
                  type="INPUT"
                  error={dateFilterError}
                  fn={validateDateSelection}
                  max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                  loading={false}
                  placeholder="End date"
                />
              </div>
              <div className="p-col-4 p-pt-2rem ">
                <button
                  className="primary-button"
                  style={{ width: "10rem", marginTop: "0.3rem" }}
                  onClick={handleCustomDateSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </Optional>
          <Optional show={dateFilterError}>
            <p
              className="p-text-error p-mt-0"
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "0.75rem",
                transform: "translateY(-15px)",
              }}
            >
              Please select a valid date range, 30 days or less!
            </p>
          </Optional>
          <Optional show={showCustomDateField && !dateFilter}>
            <p
              className="p-text-error p-mt-0"
              style={{ textAlign: "left", fontSize: "0.75rem" }}
            >
              Note: You can only retrieve metrics within 30 day intervals.
            </p>
          </Optional>
        </div>
      </div>

      <Optional show={dateRangeValue !== "today"}>
        <div>
          <p
            className="p-text-left"
            style={{ fontSize: "0.8rem", fontStyle: "italic" }}
          >
            Showing results from{" "}
            <span>
              {" "}
              {moment(dateFilter?.current?.startDate).format(
                "MMMM Do YYYY"
              )}{" "}
            </span>
            -{" "}
            <span>
              {moment(dateFilter?.current?.endDate).format("MMMM Do YYYY")}{" "}
            </span>
          </p>
        </div>
      </Optional>

      <Optional show={loading}>
        <div className="card p-mt-4">
          <Skeleton />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
        </div>
      </Optional>
      <Optional show={!loading}>{currentChartView(1)}</Optional>
      <div className="p-grid">
        <div className="p-col-12 ">
          <Optional show={loading}>
            <div className="card p-mt-4">
              <Skeleton />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
              <Skeleton className="p-mt-3" />
            </div>
          </Optional>
          <Optional show={!loading}>{currentChartView(0)}</Optional>
        </div>
      </div>
    </div>
  );
}
