const CREATE_CHARGE_MODEL = "/backoffice/charge-model";
const GET_ALL_CHARGE_MODELS = "/backoffice/charge-model";
const GET_CHARGE_TYPES = "/backoffice/charge-model/page";
const DELETE_CHARGE_MODEL = "/backoffice/charge-model/remove";
const GET_CHARGE_MODEL_TYPES = "/backoffice/charge-model/types";
const GET_CHARGE_MODEL_BY_CODE = "/backoffice/charge-model";

export const CHARGE_TYPE_ROUTES = {
  CREATE_CHARGE_MODEL,
  GET_CHARGE_MODELS: GET_CHARGE_TYPES,
  GET_CHARGE_TYPES,
  DELETE_CHARGE_MODEL,
  GET_CHARGE_MODEL_TYPES,
  GET_CHARGE_MODEL_BY_CODE,
  GET_ALL_CHARGE_MODELS,
};
