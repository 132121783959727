import "./bulk-settlement.css";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useEffect, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { Icon } from "components/elements/icons/icon";
import { SpecialLabelCases } from "utils/models/utilities";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { NewDispute } from "../disputes/new-dispute";
import { BulkTransactions } from "./bulk-transactions";
import { PaymentRequests } from "./payment-requests";
import { useWindowSize } from "hooks/use-window-size";
import { saveAs } from "file-saver";
import { HELPER } from "utils/helper";
import { CustomToast } from "components/elements/alert/custom-toast";
import { DownloadProgress } from "components/elements/download-progress/download-progress";
import { Optional } from "components/elements/optional/optional";
import { MainContext } from "App";
import { API_SERVICE } from "api/service";
import { useSingleAppConfigurationValue } from "hooks/use-single-configuration-value";
import { BACKOFFICE_CONFIGS } from "utils/constants";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function BulkSettlementBreakDown(props) {
  const mainContext = useContext(MainContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const [currentModalIndex, setCurrentModalIndex] = useState(1);
  const [error] = useState(null);
  const [detailsContent, setDetailsContent] = useState([]);
  const [disputeCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);
  const windowSize = useWindowSize();
  const isManualSettlementPaymentEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_MANUAL_SETTLEMENT_PAYMENT_ENABLED
  );

  function isMobile() {
    return windowSize.width <= 930;
  }

  useEffect(() => {
    function getBulkSettlementBySettlementKey() {
      setCurrentIndex(1);
      const params = HELPER.TO_URL_STRING({
        page: 0,
        size: 10,
        bulkSettlementKey: props.bulkSettlementKey,
      });
      const url = "/backoffice/settlement/bulk";
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          setDetailsContent(
            HELPER.GET_SETTLEMENT_DETAILS(data?.result?.content[0])
          );
          setCurrentIndex(0);
        })
        .catch((error) => {
          console.log("error", error);
          setCurrentIndex(0);
        });
    }

    if (props.shouldGetSettlementDetails) {
      setDetailsContent([]);
      getBulkSettlementBySettlementKey();
    } else {
      setDetailsContent(props?.details);
    }
  }, [
    props.details,
    props.bulkSettlementKey,
    props.shouldGetSettlementDetails,
  ]);

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (
          itemCase === "status" &&
          isManualSettlementPaymentEnabled === "true" &&
          value.toLowerCase() === "started"
        ) {
          result = HELPER.SETTLEMENT_WARNING_STATUS(value);
        } else if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = () => {
    return detailsContent.map((details, index) => (
      <div key={index.toString()} className="p-grid dcir-row">
        <div className="p-col-6">
          <p className="details-label">{details.label}:</p>
        </div>
        <div className="p-col-6">
          <p className="details-value">
            {transformView(details.itemCase, details.value, details.type)}
          </p>
        </div>
      </div>
    ));
  };

  const errorView = () => {
    return (
      <div>
        <div className="empty-container">
          <Icon icon="error-message-icon" />
          <div>
            <p className="empty-text">{error}</p>
          </div>
          <div className="p-mt-3">
            <div style={{ maxWidth: "200px" }}>
              <button className="primary-button">Reload</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const resolveButton = () => {
    if (!disputeCodes?.includes(detailsContent[3]?.value)) {
      return <div />;
    } else {
      return (
        <button onClick={() => openModal(1)} className="primary-button">
          Log Dispute
        </button>
      );
    }
  };

  async function downloadBulkSettlement() {
    setLoading(true);
    const url = BACK_OFFICE_API.BULK_SETTLEMENT.DOWNLOAD_BULK_SETTLEMENT;
    const isBlob = true;

    API_SERVICE.MAKE_GET_REQUEST(
      `${url}/${props.bulkSettlementKey}`,
      isBlob,
      mainContext
    )
      .then((data) => {
        saveAs(data, `${props.bulkSettlementKey}.xlsx`);
        closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    setLoading(false);
  }

  const [indexView, setIndexView] = useState(0);
  function setCurrentIndexView(index) {
    setIndexView(index);
  }

  function isReportCompleted() {
    let reportStatus = detailsContent.filter((detail) => {
      return detail.label === "Report Status";
    })[0];
    if (reportStatus?.value === "COMPLETED") {
      return true;
    }
    return false;
  }

  const bulkSettlementTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={isMobile() ? "BS" : "Bulk Settlement Summary"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
                <div className="p-col-4">
                  <div
                    style={{
                      width: "135px",
                      float: "right",
                    }}
                  >
                    {resolveButton()}
                  </div>
                  {isReportCompleted() && (
                    <div
                      style={{
                        width: "150px",
                        float: "right",
                      }}
                    >
                      <button
                        onClick={() => openModal(2)}
                        className="download-button"
                        style={{ fontSize: "0.75rem" }}
                      >
                        <i className="pi pi-download"></i> Download Report
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="centralize">
                <p
                  className={
                    isMobile() ? "custom-title p-ml-4 dcir-show" : "dcir-hide"
                  }
                >
                  Settlement Details
                </p>
                {detailsView()}
              </div>
            </div>
          </TabPanel>
          {HELPER.HAS_AUTHORITY("bo_dcir_get_transactions_per_bulk") ? (
            <TabPanel header={isMobile() ? "TS" : "Transaction Summary"}>
              <div
                style={{ position: "relative", top: "3.5em" }}
                className="p-text-left"
              >
                <p
                  className={
                    isMobile() && indexView === 0
                      ? "custom-title dcir-show"
                      : "dcir-hide"
                  }
                >
                  Transactions
                </p>
              </div>
              <BulkTransactions
                isMobile={isMobile()}
                currentIndexView={setCurrentIndexView}
                bulkSettlementKey={props.bulkSettlementKey}
              />
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
          {HELPER.HAS_AUTHORITY("bo_dcir_get_payment_request") ? (
            <TabPanel header={isMobile() ? "PR" : "Payment Requests"}>
              <div
                style={{ position: "relative", top: "3.5em" }}
                className="p-text-left"
              >
                <p
                  className={
                    isMobile() && indexView === 0
                      ? "custom-title dcir-show"
                      : "dcir-hide"
                  }
                >
                  Payment Request
                </p>
              </div>
              <PaymentRequests
                isMobile={isMobile()}
                currentIndexView={setCurrentIndexView}
                bulkSettlementKey={props.bulkSettlementKey}
              />
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    );
  };

  const bulkSettlementBreakdownView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return bulkSettlementTabView();
      case 1:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="Loading details ..." />
          </div>
        );
      case 2:
        return errorView();
      case 3:
        return (
          <div className="custom-mobile-table-card">
            <NewDispute
              transactionSearchKey={props?.transactionSearchKey}
              closeDisputeModal={closeModal}
            />
          </div>
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <NewDispute
            transactionSearchKey={props?.transactionSearchKey}
            closeDisputeModal={closeModal}
          />
        );
      case 2:
        return (
          <div>
            <h4 className="p-text-left p-mt-0">Confirm Download!</h4>
            <p className="p-text-left">
              Confirm that you want to download this report.
            </p>
            <div className="p-pb-1">
              {downloadError && (
                <CustomToast
                  title="Error"
                  description={downloadError}
                  type="error"
                />
              )}
            </div>
            <Optional show={loading}>
              <DownloadProgress />
            </Optional>
            {/* {loading ? (
              <CustomLoader loadingText="loading..." />
            ) : ( */}
            <Optional show={!loading}>
              <div className="p-mt-4 p-pb-2">
                <div className="p-grid">
                  <div className="p-col-6">
                    <button onClick={closeModal} className="secondary-button">
                      Cancel
                    </button>
                  </div>
                  <div className="p-col-6">
                    <button
                      onClick={downloadBulkSettlement}
                      className="primary-button"
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </Optional>
            {/* )} */}
          </div>
        );
    }
  };

  function onHide() {}

  function closeModal(reload) {
    if (props.mobile) {
      setCurrentIndex(0);
    } else {
      setVisible(false);
    }
    if (reload) {
      setCurrentIndex(0);
    }
  }

  function openModal(index) {
    if (props.mobile) {
      setCurrentIndex(3);
    } else {
      setCurrentModalIndex(index);
      setVisible(true);
    }
  }

  return (
    <div>
      <div>
        <CustomModal
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
          closeModal={closeModal}
        />
      </div>
      {bulkSettlementBreakdownView()}
    </div>
  );
}
