import { useContext } from "react";
import { MainContext } from "../../../App";
import { HELPER } from "utils/helper";
import { Icon } from "../icons/icon";

export function UserManualViewerButton({ fileName, isFAQ }) {
  const mainContext = useContext(MainContext);

  return (
    <div className="user-manual-button-wrapper">
      <div
        className="user-manual-viewer-button flex default"
        onClick={() => {
          HELPER.TOGGLE_USER_MANUAL_VIEW(mainContext, fileName);
        }}
        style={{ cursor: "pointer", color: "#0371ac" }}
      >
        {isFAQ ? (
          <Icon icon="question-circle" color="#0371ac" />
        ) : (
          <i className="pi pi-book" style={{ fontSize: "0.8rem" }} />
        )}

        <p className="text-small underline p-ml-1" style={{ color: "#0371ac" }}>
          {isFAQ ? "FAQ's" : "User Guide"}
        </p>
      </div>
    </div>
  );
}
