import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { STORAGE } from "../../../utils/storage";
import { MainContext, MonitoringContext } from "../../../App";
import { DcirServicesMonitoring } from "../../../features/monitoring/dcir-services/index";
import { FepProcessingMetrics } from "../../../features/monitoring/fep-processing-metrics/index";
import { ServerHealthMonitoring } from "../../../features/monitoring/server-health/index";
import { MonitoringSidebar } from "../monitoring-sidebar/monitoring-sidebar";
import { MonitoringHeader } from "../monitoring-header/monitoring-header";
import { PageNotFound } from "features/common/404/404-page";
import { TransactionProcessing } from "../../../features/monitoring/transaction-processing/transaction-processing";
import { PendingTransactions } from "../../../features/monitoring/pending-transactions/index";
import { BankApis } from "../../../features/monitoring/bank-apis/index";
import { MerchantsLastTransactionTimes } from "../../../features/monitoring/merchant-transaction-monitoring/merchant-transaction-monitoring";
import ManageAlertConfigurations from "features/monitoring/alert-configurations/alert-configurations";

export const MonitoringApp = () => {
  const [idleTimeInMinutes] = useState(60);
  const monitoringContext = useContext(MonitoringContext);
  const mainContext = useContext(MainContext);
  let history = useHistory();

  useEffect(() => {
    function setIdleTime() {
      const idleTimeInMilliseconds = idleTimeInMinutes * 60 * 1000;
      localStorage.setItem("_expiredTime", Date.now() + idleTimeInMilliseconds);
    }
    window.addEventListener("click", setIdleTime);
    window.addEventListener("mousemove", setIdleTime);
    window.addEventListener("scroll", setIdleTime);
    window.addEventListener("keydown", setIdleTime);

    function logout() {
      monitoringContext.monitoringDispatch({ type: "LOG_OUT" });
      STORAGE.CLEAR_STORAGE();
      history.push("/monitor");
    }

    setIdleTime();
    const interval = setInterval(() => {
      const expiredTime = localStorage.getItem("_expiredTime");
      if (expiredTime < Date.now()) {
        mainContext.mainDispatch({
          type: "SET_ALERT",
          alert: {
            title: "Info",
            description: "Your session has timed out, please login again.",
            type: "error",
            showAlert: true,
          },
        });
        logout();
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      window.removeEventListener("click", setIdleTime);
      window.removeEventListener("mousemove", setIdleTime);
      window.removeEventListener("scroll", setIdleTime);
      window.removeEventListener("keydown", setIdleTime);
    };
  }, [history, idleTimeInMinutes, monitoringContext, mainContext]);

  return (
    <div>
      <div>
        <MonitoringSidebar />
      </div>
      <div id="main">
        <div>
          <MonitoringHeader />
        </div>
        <div className="main-body inner-content">
          <Switch>
            <Route
              path="/monitor/dcir-services"
              component={DcirServicesMonitoring}
            />
            <Route
              path="/monitor/transaction-processing"
              component={TransactionProcessing}
            />
            <Route
              path="/monitor/pending-transactions"
              component={PendingTransactions}
            />
            <Route
              path="/monitor/alert-configurations"
              component={ManageAlertConfigurations}
            />
            <Route path="/monitor/banks-apis" component={BankApis} />
            <Route
              path="/monitor/fep-processing-metrics"
              component={FepProcessingMetrics}
            />
            <Route
              path="/monitor/last-transaction-times"
              component={MerchantsLastTransactionTimes}
            />
            <Route
              path="/monitor/server-health"
              component={ServerHealthMonitoring}
            />
            <Route path="**" component={PageNotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};
