import { useCallback, useEffect, useState } from "react";
import { FormInput } from "components/form/form-input";
import { CUSTOM_VALIDATION } from "utils/validation";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import { CustomUpload } from "components/elements/custom-upload/custom-upload";
import { ProgressSpinner } from "primereact/progressspinner";
import { FormDropdown } from "components/form/form-dropdown";
import { FormTextArea } from "components/form/form-text-area";
import { BACKOFFICE_CONFIGS, UserTypes } from "utils/constants";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { useSingleAppConfigurationValue } from "hooks/use-single-configuration-value";
import { COMMON_API } from "../../../api/routes/common/index";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function NewDispute(props) {
  const isNameEnquiryEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_NAME_ENQUIRY_ENABLED
  );
  const [loading, setLoading] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [messageTitle, setMessageTitle] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [message, setMessage] = useState(null);
  const [verifyingAccountNumber, setVerifyingAccountNumber] = useState(false);
  const [accountName, setAccountName] = useState(null);
  const [countdown, setCountdown] = useState(null);
  const [disputeExpiry, setDisputeExpiry] = useState(null);
  const [gettingCustomerInfo, setGettingCustomerInfo] = useState(null);
  const [customerFound, setCustomerFound] = useState(true);
  const [selectedDisputeReason, setSelectedDisputeReason] = useState({
    reason: { code: null, desc: null },
  });
  const [dispute, setDispute] = useState({
    customerAccountName: "",
    customerAccountNumber: "",
    transactionSearchKey: props.transactionSearchKey,
    reason: null,
    receiptDataBase64: null,
  });
  const [disputeError, setDisputeError] = useState({
    customerAccountName: null,
    customerAccountNumber: null,
    reason: null,
    receiptDataBase64: null,
  });
  const [disputeReasons, setDisputeReasons] = useState([]);
  const [nameEnquiryFailed, setNameEnquiryFailed] = useState(false);
  const [manualDisputeEntryConfirmation, setManuelDisputeEntryConfirmation] =
    useState(false);
  // const [gettingCustomerInformationError, setGettingCustomerInformationError] =
  //   useState(null);

  const verifyAccountNumber = useCallback((accNo) => {
    setVerifyingAccountNumber(true);
    setAccountName(null);
    setCustomerFound(true);
    setDisputeError((error) => {
      return { ...error, customerAccountNumber: null };
    });
    const url = COMMON_API.ACCOUNT_ENQUIRY.VERIFY_ACCOUNT_NUMBER;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${accNo}`)
      .then((data) => {
        setNameEnquiryFailed(false);
        setDispute((dispute) => {
          return {
            ...dispute,
            customerAccountNumber: accNo,
            customerAccountName: data?.result?.accountName,
          };
        });
        setVerifyingAccountNumber(false);
      })
      .catch((error) => {
        // setCustomerFound(false);
        setNameEnquiryFailed(true);
        setDisputeError((disputeError) => {
          return {
            ...disputeError,
            customerAccountNumber: HELPER.PROCESS_ERROR(error, "NAME_ENQUIRY"),
          };
        });
        setVerifyingAccountNumber(false);
        // setCustomerFound(false);
      });
  }, []);

  const checkValidForm = useCallback(() => {
    const hasOptionalFields = true;
    const validForm = CUSTOM_VALIDATION.VALID_OBJ(
      dispute,
      props.userType === UserTypes.FrontOffice ? 5 : 4,
      hasOptionalFields
    );
    const validErrorForm = !CUSTOM_VALIDATION.VALID_OBJ_ANY(disputeError);
    if (nameEnquiryFailed) {
      setValidForm(
        validForm &&
          dispute["customerAccountName"].length > 1 &&
          dispute["customerAccountNumber"].length === 10 &&
          manualDisputeEntryConfirmation
      );
    } else {
      setValidForm(validForm && validErrorForm);
    }
  }, [
    dispute,
    props.userType,
    disputeError,
    manualDisputeEntryConfirmation,
    nameEnquiryFailed,
  ]);

  useEffect(() => {
    checkValidForm();
  }, [checkValidForm]);

  const getCustomerInformation = useCallback(() => {
    setGettingCustomerInfo(true);
    const url = COMMON_API.ACCOUNT_ENQUIRY.GET_CUSTOMER_INFORMATION;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${props.hashedPan}`)
      .then((data) => {
        let userInfo = data.result;
        if (userInfo?.accountNumber) {
          setCustomerFound(true);
          setDispute((dispute) => {
            return {
              ...dispute,
              customerAccountNumber: userInfo.accountNumber,
            };
          });
          verifyAccountNumber(userInfo.accountNumber);
        } else {
          setCustomerFound(false);
        }
        setGettingCustomerInfo(false);
      })
      .catch((error) => {
        setCustomerFound(false);
        setGettingCustomerInfo(false);
        return;
      });
  }, [verifyAccountNumber, props.hashedPan]);

  useEffect(() => {
    getCustomerInformation();
  }, [getCustomerInformation]);

  useEffect(() => {
    function getDisputeReasons() {
      const url = BACK_OFFICE_API.CONFIGURATION.CONFIGURATIONS;
      API_SERVICE.MAKE_GET_REQUEST(
        `${url}/${BACKOFFICE_CONFIGS.DISPUTE_REASON_LIST}`
      )
        .then((response) => {
          const reasons = response?.result?.configValue?.split(",");
          let arr = [];
          // Trim cause config takes user entered input and can possibly have whitespaces
          if (reasons) {
            reasons.forEach((reason) => {
              arr.push({
                desc: reason.trim(),
                code: reason.trim(),
              });
            });
          }
          arr.push({
            desc: "Other",
            code: "other",
          });
          setDisputeReasons(arr);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getDisputeReasons();
  }, []);

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    // setDispute({ ...dispute, customerAccountNumber: value });
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    setDispute({ ...dispute, [name]: value });

    if (isValidInput) {
      if (name === "customerAccountNumber" && isNameEnquiryEnabled === "true") {
        setDispute({ ...dispute, [name]: value });
        setDisputeError({ ...disputeError, [name]: null });
        verifyAccountNumber(value);
      } else {
        setDispute({ ...dispute, [name]: value });
        setDisputeError({ ...disputeError, [name]: null });
      }
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setDisputeError({ ...disputeError, [name]: errorMessage });
    }
  }

  function getUploadFile(e) {
    setDispute({ ...dispute, receiptDataBase64: e });
  }

  function handleCheckboxChange(e) {
    setDisputeError({ ...disputeError, customerAccountNumber: null });
    setManuelDisputeEntryConfirmation(e?.target?.checked);
  }

  function submit() {
    setLoading(true);
    let reason = selectedDisputeReason["reason"];
    let payload = {
      customerAccountName: dispute["customerAccountName"],
      customerAccountNumber: dispute["customerAccountNumber"],
      transactionSearchKey: dispute["transactionSearchKey"],
      reason:
        reason?.code && reason?.code !== "other"
          ? reason?.code
          : dispute["reason"],
      receiptDataBase64: null,
    };
    if (props.userType === UserTypes.FrontOffice) {
      payload.receiptDataBase64 = dispute["receiptDataBase64"];
    } else {
      delete payload.receiptDataBase64;
    }
    const frontofficeUrl = FRONT_OFFICE_API.DISPUTE.CREATE_DISPUTE;
    const backofficeUrl = BACK_OFFICE_API.DISPUTE.CREATE_DISPUTE;

    const url =
      props.userType === UserTypes.FrontOffice ? frontofficeUrl : backofficeUrl;
    API_SERVICE.MAKE_POST_REQUEST(url, payload)
      .then((data) => {
        setMessageTitle(null);
        setSuccessMessage("Dispute logged successfully");
        setCurrentIndex(1);
        setLoading(false);
        setDisputeExpiry(data.result.tatExpiryDate);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  useEffect(() => {
    let counter;
    function inititializeCountdown() {
      counter = setInterval(function () {
        let countdown = HELPER.SET_COUNTDOWN(disputeExpiry);
        if (countdown) {
          setCountdown(countdown);
        } else {
          clearInterval(counter);
        }
      }, 1000);
    }
    if (disputeExpiry) {
      inititializeCountdown();
    }
    return () => {
      clearInterval(counter);
    };
  }, [disputeExpiry]);

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setDisputeError({ ...disputeError, [name]: "" });
      setSelectedDisputeReason({ [name]: value });
    } else {
      let errorMessage = "Select reason";
      setDisputeError({ ...disputeError, [name]: errorMessage });
    }
  }

  function shouldDisableAccountNameField() {
    if (isNameEnquiryEnabled === "false") {
      return false;
    }
    if (isNameEnquiryEnabled === "true" && nameEnquiryFailed) {
      return false;
    }
  }

  const viewAlert = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const cancelButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props.closeDisputeModal()}
          className="secondary-button"
        >
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      // if (props.isUpdate) {
      //   return (
      //     <button
      //       disabled={!validForm}
      //       onClick={upDateDispute}
      //       className="primary-button"
      //     >
      //       Update
      //     </button>
      //   );
      // } else {
      return (
        <button
          disabled={!validForm}
          onClick={submit}
          className="primary-button"
        >
          Submit
        </button>
      );
      // }
    } else {
      return (
        <div className="p-mt-2 p-text-center">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const disputeForm = () => {
    return (
      <div>
        <div>
          <p className="custom-modal-title p-text-left p-mt-0">
            Create Dispute
          </p>
        </div>
        <div className="custom-dialog-subtitle-container-lg">
          <p className="custom-dialog-subtitle p-mb-4">
            Fill the form below to log dispute
          </p>
        </div>
        <div className="p-pb-1">{viewAlert()}</div>
        <div className="p-grid">
          {!customerFound && (
            <div className="p-col-12">
              <p className="p-error-text p-text-left error-text">
                Customer Information was not found! You can enter it below or
                <button className="retry-btn" onClick={getCustomerInformation}>
                  Click here to retry!
                </button>
              </p>
              {/* <p className="p-error-text p-text-left error-text">
                Error message: {gettingCustomerInformationError}
              </p> */}
            </div>
          )}
          <div className="p-col-12">
            <FormInput
              verifyingField="customer's account number"
              verifying={verifyingAccountNumber}
              verified={accountName}
              value={dispute["customerAccountNumber"]}
              required={true}
              field="customerAccountNumber"
              type="NUBAN"
              error={disputeError["customerAccountNumber"]}
              fn={validateForm}
              loading={false}
              disabled={customerFound && !nameEnquiryFailed}
              placeholder="Customer's account number"
              controlled
            />
            {/* <input
              type="text"
              value={dispute["customerAccountNumber"]}
              onChange={validateForm}
            /> */}
          </div>
          <div className="p-col-12">
            <FormInput
              value={dispute["customerAccountName"]}
              required={true}
              field="customerAccountName"
              type="NAME"
              error={disputeError["customerAccountName"]}
              fn={validateForm}
              loading={false}
              disabled={shouldDisableAccountNameField()}
              placeholder="Customer account name"
              controlled
            />
          </div>
          <div className="p-col-12">
            <FormDropdown
              required={true}
              label="desc"
              field="reason"
              error={null}
              disabled={loading}
              value={selectedDisputeReason["reason"]}
              fn={validateDropdown}
              options={disputeReasons}
              placeholder="Dispute reason"
            />
          </div>
          {selectedDisputeReason["reason"]?.code === "other" && (
            <div className="p-col-12 dispute-reason">
              <FormTextArea
                required={false}
                field="reason"
                type="DESCRIPTION"
                error={disputeError["reason"]}
                fn={validateForm}
                loading={loading}
                placeholder="Dispute reason"
              />
            </div>
          )}
          <div className="p-col-12">
            {gettingCustomerInfo && (
              <div className="p-text-center p-mt-1">
                <span className="p-success">
                  <ProgressSpinner
                    style={{
                      width: "15px",
                      height: "15px",
                      marginLeft: ".1em",
                      position: "relative",
                      top: ".35em",
                    }}
                    strokeWidth="5"
                    animationDuration=".5s"
                  />
                </span>
                <p className="p-text-success">Fetching Customer Information</p>
              </div>
            )}
          </div>

          {props.userType === UserTypes.FrontOffice && (
            <div className="p-col-12">
              <CustomUpload
                getUploadedFile={getUploadFile}
                title="Receipt"
                clearUploadedFile={() =>
                  setDispute({ ...dispute, receiptDataBase64: null })
                }
              />
            </div>
          )}
          <div className="p-mb-4">
            {nameEnquiryFailed && !gettingCustomerInfo && (
              <label
                style={{
                  display: "flex",
                  padding: "0 0.5rem",
                  fontSize: "1rem",
                }}
                className=""
              >
                <input
                  type="checkbox"
                  className="confirmation-checkbox"
                  checked={manualDisputeEntryConfirmation}
                  value={manualDisputeEntryConfirmation}
                  onChange={handleCheckboxChange}
                />
                <small className="p-text-left p-ml-2">
                  I confirm that the account number and account name provided is
                  accurate.
                </small>
              </label>
            )}
          </div>
          <div className="p-col-12" style={{ marginBottom: "6rem" }}>
            <div>
              {!gettingCustomerInfo && (
                <div className="p-grid">
                  <div className="p-col-6">{cancelButton()}</div>
                  <div className={loading ? "p-col-12" : "p-col-6"}>
                    {submitButton()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const currentView = () => {
    if (currentIndex) {
      return (
        <div className="p-text-center p-mt-2">
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">{successMessage}</p>
            <p className="countdown-text success-message-text">Expires in:</p>
            <p className="countdown-text success-message-text">{countdown}</p>
          </div>
          <div className="success-message-btn-container p-text-center">
            <p
              className="close-modal add-cursor p-mt-3"
              onClick={() => props.closeDisputeModal(true)}
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return <div>{disputeForm()}</div>;
    }
  };

  return (
    <ErrorBoundary>
      <div className="p-pb-5">{currentView()}</div>
    </ErrorBoundary>
  );
}
