import React from "react";

export function PageNotFound() {
  return (
    <div id="wrapper">
      <img
        src="https://i.imgur.com/g3hgqe8.png"
        className="img-404"
        alt="page not found"
      />
      <div id="info">
        <h3>This page could not be found</h3>
      </div>
    </div>
  );
}
