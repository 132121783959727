import React, { useCallback, useEffect } from "react";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { Optional } from "components/elements/optional/optional";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import moment from "moment";
import { FormInput } from "components/form/form-input";
import { BankApisTable } from "./bank-apis-table";
// import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { MONITORING_SERVICE } from "api/services/monitoring-service";
import { MONITORING_API } from "api/routes/monitoring-apis/index";
import { HELPER } from "utils/helper";
import { FormDropdown } from "components/form/form-dropdown";
import { CustomCard } from "components/elements/custom-card/custom-card";

export function BankApis() {
  const toast = useRef(null);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [error, setError] = useState("");
  const range = 5;
  const reloadFunctionDetails = useRef(null);
  const [emptyText, setEmptyText] = useState("");
  const [showCustomDateField, setShowCustomDateField] = useState(false);
  const selectedDateRange = useRef({
    desc: "Today",
    code: "today",
  });
  const dateRanges = [
    { desc: "Today", code: "today" },
    { desc: "Custom", code: "custom" },
  ];

  const [dateRangeValue, setDateRangeValue] = useState("today");
  // const [customDate, setCustomDate] = useState({
  //   startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  //   endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  // });
  const [customDate, setCustomDate] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [dateFilterError, setDateFilterError] = useState(false);
  const dateFilter = useRef({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [banksApisData, setBanksApisData] = useState([]);

  const getHistoricBankApis = useCallback(
    (date) => {
      reloadFunctionDetails.current = {
        reloadFunction: getHistoricBankApis,
        params: date,
      };
      setCurrentIndex(0);
      setBanksApisData([]);
      let params = {
        page: pageNo,
        size: 10,
        startDate: date?.startDate,
        endDate: date?.endDate,
      };
      params = HELPER.TO_URL_STRING(params);
      MONITORING_SERVICE.MAKE_GET_REQUEST(
        MONITORING_API.GET_BANKS_APIC_CALL_LOG,
        params
      )
        .then((data) => {
          const result = data?.response?.content;
          if (!result.length) {
            setEmptyText("No bank api records found...");
          } else {
            setDateRangeValue(selectedDateRange?.current?.code);
            setTotalItems(data?.response?.totalElements);
            setTotalPages(data?.response?.totalPages);
            setBanksApisData(result);
            console.log("api-logs", data);
          }
          setError(null);
          setCurrentIndex(1);
        })
        .catch((error) => {
          // setDateRangeValue(selectedDateRange?.current?.code);
          // setTotalItems(dataModel?.totalElements);
          // setTotalPages(dataModel?.totalPages);
          // setBanksApisData(dataModel?.content);
          setError(HELPER.PROCESS_ERROR(error));
          // console.log("error", HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
        });
    },
    [pageNo]
  );
  useEffect(() => {
    if (dateRangeValue !== "today") getHistoricBankApis(dateFilter.current);
  }, [pageNo]);
  const getCurrentDayBankApis = useCallback(() => {
    const date = {
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().add(1, "days").format("YYYY-MM-DD"),
    };
    reloadFunctionDetails.current = {
      reloadFunction: getCurrentDayBankApis,
      params: date,
    };
    setPageNo(0);
    setCurrentIndex(0);
    setBanksApisData([]);
    let params = {
      page: pageNo,
      size: 10,
      startDate: date?.startDate,
      endDate: date?.endDate,
    };
    params = HELPER.TO_URL_STRING(params);
    MONITORING_SERVICE.MAKE_GET_REQUEST(
      MONITORING_API.GET_BANKS_APIC_CALL_LOG,
      params
    )
      .then((data) => {
        const result = data?.response?.content;
        if (!result.length) {
          setEmptyText("No bank api records found...");
        } else {
          console.log(data);
          setTotalItems(data?.response?.totalElements);
          setTotalPages(data?.response?.totalPages);
          setBanksApisData(result);
          console.log("api-logs", data);
        }
        setError(null);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
      });
  }, [pageNo]);

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  useEffect(() => {
    getCurrentDayBankApis();
  }, []);

  function handleDateRangeSelection(e) {
    setDateFilterError(false);
    const selectedDate = e?.value;
    selectedDateRange.current = selectedDate;
    setShowCustomDateField(false);
    if (selectedDate?.code === "custom") {
      dateFilter.current = {
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
      setShowCustomDateField(true);
      return;
    } else if (selectedDate.code === "today") {
      getCurrentDayBankApis();
    }
    setDateRangeValue(selectedDate?.code);
  }

  function handleCustomDateSearch() {
    const customDate = dateFilter?.current;
    if (customDate?.startDate && customDate?.endDate) {
      getHistoricBankApis(dateFilter.current);
    } else {
      setDateFilterError(true);
    }
  }

  function validateDateSelection(e, field) {
    const dateValue = e?.target?.value;
    setCustomDate({
      ...customDate,
      [field]: dateValue,
    });
    dateFilter.current = {
      ...dateFilter.current,
      [field]: dateValue,
    };
  }
  function reload() {
    const params = reloadFunctionDetails?.current?.params;
    reloadFunctionDetails?.current?.reloadFunction(params);
  }

  const bankApisView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading Banks APIs..." />
          </div>
        );
      case 1:
        return (
          <CustomCard>
            <BankApisTable
              banksApisData={banksApisData}
              totalPages={totalPages}
              totalItems={totalItems}
              currentPage={pageNo + 1}
              emptyText={emptyText}
              reload={reload}
              error={error}
              range={range}
              nextPage={() => setPageNo(pageNo + 1)}
              prevPage={() => setPageNo(pageNo - 1)}
              goToFirstPage={() => setPageNo(0)}
              goToLastPage={() => setPageNo(totalPages - 1)}
              goToPage={goToPage}
            />
          </CustomCard>
        );
    }
  };
  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="page-title p-text-left">Banks APIs</div>
      <div className="p-mt-28">
        <CustomBreadcrumb page="Banks APIs" />
      </div>
      <div className="p-grid p-mt-3">
        <div className="p-col-4 dropdown-wrapper">
          <FormDropdown
            required={true}
            label="desc"
            value={selectedDateRange.current}
            fn={handleDateRangeSelection}
            options={dateRanges}
            placeholder="Select time"
          />
        </div>
        <div className="p-col-6 date-select-row">
          <Optional show={showCustomDateField}>
            <div className="p-grid p-justify-center">
              <div className="p-col-4 p-pt-1">
                <FormInput
                  inputType="date"
                  value={customDate["startDate"]}
                  required={false}
                  field="startDate"
                  type="INPUT"
                  error={dateFilterError}
                  fn={validateDateSelection}
                  loading={false}
                  max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                  placeholder="Start date"
                />
              </div>
              <div className="p-col-4 p-pt-1">
                <FormInput
                  inputType="date"
                  value={customDate["endDate"]}
                  required={false}
                  field="endDate"
                  type="INPUT"
                  error={dateFilterError}
                  fn={validateDateSelection}
                  max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                  loading={false}
                  placeholder="End date"
                />
              </div>
              <div className="p-col-4 p-pt-2rem ">
                <button
                  className="primary-button"
                  style={{ width: "10rem", marginTop: "0.3rem" }}
                  onClick={handleCustomDateSearch}
                >
                  Search
                </button>
              </div>
            </div>
          </Optional>
          <Optional show={dateFilterError}>
            <p
              className="p-text-error p-mt-0"
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "0.75rem",
                transform: "translateY(-15px)",
              }}
            >
              Please select a valid date range, 30 days or less!
            </p>
          </Optional>
          {/* <Optional show={showCustomDateField && !dateFilter}>
            <p
              className="p-text-error p-mt-0"
              style={{ textAlign: "left", fontSize: "0.75rem" }}
            >
              Note: You can only retrieve metrics within 30 day intervals.
            </p>
          </Optional> */}
        </div>
      </div>

      <Optional show={dateRangeValue !== "today"}>
        <div>
          <p
            className="p-text-left"
            style={{ fontSize: "0.8rem", fontStyle: "italic" }}
          >
            Showing results from{" "}
            <span>
              {" "}
              {moment(dateFilter?.current?.startDate).format(
                "MMMM Do YYYY"
              )}{" "}
            </span>
            -{" "}
            <span>
              {moment(dateFilter?.current?.endDate).format("MMMM Do YYYY")}{" "}
            </span>
          </p>
        </div>
      </Optional>
      <div className="p-mt-5">{bankApisView()}</div>
    </div>
  );
}
