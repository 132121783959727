import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon } from "components/elements/icons/icon";
import { ProgressSpinner } from "primereact/progressspinner";
import "./azure-loader-page.css";
import { AUTH_SERVICE } from "api/service";
import { persistLoginDataAndRedirect } from "features/login/helpers";
import { MainContext } from "App";
import { HELPER } from "utils/helper";

export function AzureLoaderPage() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const mainContext = useContext(MainContext);

  useEffect(() => {
    // the slice removes the "#" prefixing the location.hash
    const formattedParams = new URLSearchParams(location.hash.slice(1));

    const idToken = formattedParams.get("id_token");
    // const sessionState = formattedParams.get("session_state");
    // const state = formattedParams.get("state");

    if (idToken) {
      setErrorMessage("");
      const payload = {
        token: idToken,
        provider: "azure",
      };
      AUTH_SERVICE.LOGIN(payload)
        .then((data) => {
          const response = data?.result;
          persistLoginDataAndRedirect(response, mainContext, history);
        })
        .catch((error) => {
          setErrorMessage(HELPER.PROCESS_ERROR(error));
        });
    } else {
      setErrorMessage("Required fields unavailable, kindly try again");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="azure-loader-container">
      {errorMessage ? (
        <div style={{ width: "100%" }}>
          <Icon icon="error-message-icon" />
          <p>{errorMessage}</p>
          <button
            onClick={() => history.push("/")}
            style={{ width: "9rem", cursor: "pointer" }}
            className="primary-button"
          >
            Back to login
          </button>
        </div>
      ) : (
        <div>
          <div style={{ position: "relative" }}>
            <ProgressSpinner
              style={{
                width: "250px",
                height: "250px",
                position: "absolute",
                top: "-3%",
                right: "-11%",
              }}
              strokeWidth="2"
            />
            <div
              style={{
                width: "200px",
                height: "150px",
              }}
            >
              <Icon icon="logo" />
            </div>
            <p
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                marginBlock: "0px",
              }}
            >
              Authenticating...
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
