import { HELPER } from "../../../utils/helper";
import { ConditionalRenderer } from "../conditional-renderer/conditional-renderer";
import { TableAction } from "./table-action";
import { Icon } from "../icons/icon";
import { useEffect, useState } from "react";

export function TableActions({ authorities, performAction, data }) {
  const [actions, setActions] = useState([]);
  useEffect(() => {
    if (authorities && authorities.length) {
      let actions = authorities.map((authority) => authority.label);
      setActions(actions);
    }
  }, [authorities]);
  return (
    <span className="dcir-tb-action-position" style={{ cursor: "pointer" }}>
      <ConditionalRenderer
        renderIf={
          actions.includes("UPDATE") &&
          HELPER.CAN_PERFORM_ACTION(authorities || "all", "UPDATE")
        }
      >
        <TableAction
          onclick={performAction}
          data={data}
          action="UPDATE"
          icon="pencil"
        />
      </ConditionalRenderer>
      <ConditionalRenderer
        renderIf={
          actions.includes("ACTIVATE") &&
          HELPER.CAN_PERFORM_ACTION(authorities || "all", "ACTIVATE")
        }
      >
        <TableAction
          onclick={performAction}
          data={data}
          action="ACTIVATE"
          icon="eye"
        />
      </ConditionalRenderer>
      <ConditionalRenderer
        renderIf={
          actions.includes("DEACTIVATE") &&
          HELPER.CAN_PERFORM_ACTION(authorities || "all", "DEACTIVATE")
        }
      >
        <TableAction
          onclick={performAction}
          data={data}
          action="DEACTIVATE"
          icon="eye-slash"
        />
      </ConditionalRenderer>
      <ConditionalRenderer
        renderIf={
          actions.includes("VIEW") &&
          HELPER.CAN_PERFORM_ACTION(authorities || "all", "VIEW")
        }
      >
        <TableAction
          onclick={performAction}
          data={data}
          action="VIEW"
          icon="eye"
        />
      </ConditionalRenderer>
      <ConditionalRenderer
        renderIf={
          actions.includes("DELETE") &&
          HELPER.CAN_PERFORM_ACTION(authorities || "all", "DELETE")
        }
      >
        <TableAction
          onclick={performAction}
          data={data}
          action="DELETE"
          icon="trash"
        />
      </ConditionalRenderer>
      <ConditionalRenderer
        renderIf={
          actions.includes("DOWNLOAD") &&
          HELPER.CAN_PERFORM_ACTION(authorities || "all", "DOWNLOAD")
        }
      >
        <TableAction
          onclick={performAction}
          data={data}
          action="DOWNLOAD"
          icon="download"
        />
      </ConditionalRenderer>
      <ConditionalRenderer
        renderIf={
          !actions.length
          // &&
          // HELPER.CAN_PERFORM_ACTION(authorities || "all", "OPEN")
        }
      >
        <button
          onClick={() => {
            // performAction(data, "OPEN");
            data?.detailsFunction(data, "VIEW");
          }}
          className={`table-action dcir-show bare-button`}
        >
          <Icon icon="back" />
        </button>
      </ConditionalRenderer>
    </span>
  );
}
