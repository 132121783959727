const dcir = document.getElementById("service-url").innerHTML?.length
  ? document.getElementById("service-url").innerHTML
  : "https://dcir-staging.teamapt.com/svc";

const isAzureAdEnabled = document.getElementById("azureAdEnabled").innerHTML
  ?.length
  ? document.getElementById("azureAdEnabled").innerHTML === "true"
  : true;

const azureClientId = document.getElementById("azureClientId").innerHTML?.length
  ? document.getElementById("azureClientId").innerHTML
  : "";

const azureTenantId = document.getElementById("azureTenantId").innerHTML?.length
  ? document.getElementById("azureTenantId").innerHTML
  : "";

const originUrl = window.location.origin;

const formatOriginUrlToRedirectUrl = (originUrl) => {
  return originUrl.replace(/:/g, "%3A").replace(/\//g, "%2F");
};

const azureLoginUrl = `https://login.microsoftonline.com/${azureTenantId}/oauth2/v2.0/authorize?client_id=${azureClientId}&response_type=id_token&redirect_uri=${formatOriginUrlToRedirectUrl(
  originUrl
)}%2Flogin%2Fazure-ad&response_mode=fragment&scope=openid+profile+email+user.read&state=12345&nonce=678910`;

const monitoringServiceUrl = document.getElementById(
  "monitoring-service-url"
)?.innerHTML;

const authUserName = "dcir-management-service";

const authPassword = "secret";

const grantType = "password";

const app_data = "mock";

const monitoringApiUrl = monitoringServiceUrl?.length
  ? monitoringServiceUrl
  : "https://aptpay-monitoring.development.teamapt.com";

export const CONFIG = {
  BASE_URL: `${dcir}/api/v1`,
  MONITORING_HOST: monitoringApiUrl,
  APP_DATA: `${app_data}`,

  AUTH_USER_NAME: `${authUserName}`,
  AUTH_PASSWORD: `${authPassword}`,
  DOMAIN_ID: "MONIEPOINT",
  GRANT_TYPE: `${grantType}`,
  CLIENT_ID: "WEB",
  mock: true,
  APP_TIMEOUT: 2000,
  REDIRECT_ACCESS_TOKEN: null,
  REDIRECT_LOGIN_USER_TYPE: "1",
  IS_AZURE_AD_ENABLED: isAzureAdEnabled,
  AZURE_TENANT_ID: azureTenantId,
  AZURE_CLIENT_ID: azureClientId,
  AZURE_LOGIN_URL: azureLoginUrl,
};
