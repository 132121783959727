const GET_BULK_DISPUTE_RESPONSES = "/frontoffice/dispute/bulk/resolve/batch";
const GET_BULK_DISPUTE_RESPONSE_TRANSACTIONS =
  "/frontoffice/dispute/bulk/resolve/transactions";
const UPLOAD_BULK_DISPUTE_RESPONSE = "/frontoffice/dispute/bulk/resolve/upload";
const GET_BULK_DISPUTE_RESPONSE_UPLOAD_SUMMARY =
  "/frontoffice/dispute/bulk/resolve/creation-summary";
const DOWNLOAD_BULK_DISPUTE_RESPONSE_TRANSACTIONS =
  "/frontoffice/dispute/bulk/resolve/transactions/download";

export const BULK_DISPUTE_RESPONSE_ROUTES = {
  GET_BULK_DISPUTE_RESPONSES,
  GET_BULK_DISPUTE_RESPONSE_TRANSACTIONS,
  UPLOAD_BULK_DISPUTE_RESPONSE,
  GET_BULK_DISPUTE_RESPONSE_UPLOAD_SUMMARY,
  DOWNLOAD_BULK_DISPUTE_RESPONSE_TRANSACTIONS,
};
