import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import { ReportsFilter } from "./reports-filter";
import { ConfirmationDialog } from "./confirmation-dialog";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";
import { CustomConfirmationDialog } from "components/elements/confirmation-dialog/confirmation-dialog";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function ParticipantReports() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const tableHeaders = [
    { label: "Participant Name", value: "ownerName" },
    { label: "Settlement Date", value: "reportTime" },
    { label: "Report Generation Date", value: "createdAt" },
    { label: "Report Generation Status", value: "reportGenerationStatus" },
    { label: "Actions", value: "actions" },
  ];
  const reportAuthorities = [
    { label: "DOWNLOAD", value: "bo_view_participant_report" },
    { label: "RETRY", value: "bo_view_participant_report" },
  ];
  const [settlementReports, setSettlementReports] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [reportRegenerationResponse, setReportGenerationResponse] =
    useState(null);
  const [reportRegenerationError, setReportGenerationError] = useState(null);
  const reportDetailId = useRef(null);

  const openAction = useCallback((e, action, isMobile) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "DOWNLOAD":
        setSelectedParticipant(e);
        openModal(1, isMobile);
        break;
      case "RETRY":
        reportDetailId.current = e?.detailId;
        openModal(2, isMobile);
    }
  }, []);

  const getSettlementReports = useCallback(() => {
    setCurrentIndex(0);
    setSettlementReports([]);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    const url = BACK_OFFICE_API.PARTICIPANT_REPORT.GET_PARTICIPANT_REPORTS;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data?.result?.content;
        if (!result?.length) {
          setEmptyText("No reports found ...");
        } else {
          let arr = [];
          setTotalItems(data?.result?.totalElements);
          setTotalPages(data?.result.totalPages);
          data?.result?.content?.forEach((e) => {
            arr.push({
              ...e,
              actions: e?.canRetry
                ? "RETRY"
                : e?.reportGenerationStatus === "COMPLETED"
                ? "DOWNLOAD"
                : "",
              detailsFunction: openAction,
            });
          });
          setSettlementReports(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [openAction, pageNo]);

  const getFilteredReports = useCallback(
    (payload) => {
      setCurrentIndex(0);
      setVisible(false);
      setLoading(true);
      setSettlementReports([]);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
      });
      const url = BACK_OFFICE_API.PARTICIPANT_REPORT.FILTER_PARTICIPANT_REPORTS;
      API_SERVICE.MAKE_PUT_REQUEST(`${url}?${params}`, payload)
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No reports found...");
            setSearch(true);
            setLoading(false);
          } else {
            let arr = [];
            setTotalItems(data?.result?.totalElements);
            setTotalPages(data?.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                actions: e?.canRetry
                  ? "RETRY"
                  : e?.reportGenerationStatus === "COMPLETED"
                  ? "DOWNLOAD"
                  : "",
                detailsFunction: openAction,
              });
            });
            setSettlementReports(arr);
          }
          setError(null);
          setLoading(false);
          setCurrentIndex(1);
        })
        .catch((error) => {
          HELPER.PROCESS_ERROR(error);
          setError(HELPER.PROCESS_ERROR(error));
          setLoading(false);
          setCurrentIndex(1);
        });
    },
    [pageNo, openAction]
  );

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  useEffect(() => {
    if (searchParams) {
      getFilteredReports(searchParams);
    } else {
      getSettlementReports();
    }
  }, [getSettlementReports, getFilteredReports, searchParams]);

  function retryReportRegeneration() {
    setReportGenerationError(null);
    const url =
      BACK_OFFICE_API.PARTICIPANT_REPORT.RETRY_PARTICIPANT_REPORT_REGENERATION;
    API_SERVICE.MAKE_PUT_REQUEST(`${url}/${reportDetailId?.current}`)
      .then((data) => {
        setReportGenerationResponse(data?.result);
      })
      .catch((error) => {
        setReportGenerationError(HELPER.PROCESS_ERROR(error));
      });
  }

  function reload(pageNo = 0) {
    setPageNo(pageNo);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    setSearchParams(null);
    getSettlementReports();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }
    setReportGenerationResponse(null);
    if (isReload === true) {
      reload(pageNo);
    }
  }

  function saveSearchParam(params) {
    setPageNo(0);
    setSearchParams(params);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <ReportsFilter
            closeModal={closeModal}
            searchFunction={saveSearchParam}
          />
        );
      case 1:
        return (
          <ConfirmationDialog
            selectedParticipant={selectedParticipant}
            closeModal={closeModal}
            warningText="Confirm that you want to download this report?"
            actionType="download"
          />
        );
      case 2:
        return (
          <CustomConfirmationDialog
            closeModal={closeModal}
            text="Confirm that you want to regenerate this report?"
            action={retryReportRegeneration}
            errorMessage={reportRegenerationError}
            responseMessage={reportRegenerationResponse}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <ReportsFilter
            closeModal={closeModal}
            searchFunction={saveSearchParam}
          />
        );
      case 1:
        return (
          <ConfirmationDialog
            selectedParticipant={selectedParticipant}
            closeModal={closeModal}
            warningText="Confirm that you want to download this report?"
            actionType="download"
          />
        );
      case 2:
        return (
          <CustomConfirmationDialog
            closeModal={closeModal}
            text="Confirm that you want to regenerate this report?"
            action={retryReportRegeneration}
            errorMessage={reportRegenerationError}
            responseMessage={reportRegenerationResponse}
          />
        );
    }
  };

  const participantReportsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading participant reports..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            authorities={reportAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={settlementReports}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        {/* <div className="page-title p-text-left">Participant Reports</div> */}
        <div className="page-header">
          <div className="page-title p-text-left">Participant Reports</div>
          <UserManualViewerButton fileName="participant_report" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Manage Participant Reports"} />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("bo_view_participant_report")
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-screen"></div>
        <div>{participantReportsView()}</div>
      </div>
    </ErrorBoundary>
  );
}
