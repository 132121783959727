import { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import "./user-manual-viewer.css";
import { CardSkeletonLoader } from "../custom-card/card-skeleton-loader";
import { HELPER } from "utils/helper";
import { TableErrorView } from "../custom-table/table-error-view";

export function UserManualViewer({fileName}) {
  const [markdown, setMarkdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const importFile = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://storage.googleapis.com/readme-storage/${fileName}.md`,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
      setMarkdown(response?.data);
      setLoading(false);
    } catch (error) {
      setError(HELPER.PROCESS_ERROR(error));
      setLoading(false);
    }
  }, [fileName]);

  useEffect(() => {
    importFile();
  }, [importFile]);


  return (
    <div className="markdown-wrapper">
      <div className="markdown-content">
        {loading ? (
          <div>
            <CardSkeletonLoader /> <CardSkeletonLoader /> <CardSkeletonLoader />
          </div>
        ) : (
          <ReactMarkdown children={markdown} />
        )}
        {error && <TableErrorView error={error} onReload={importFile} />}
      </div>
    </div>
  );
}
