const GENERATE_TRANSACTION_REPORT =
  "/backoffice/transaction/filter/generatereport";
const GET_TRANSACTION_REPORTS = "/backoffice/transaction/filter/reportinfo";
const GET_SINGLE_TRANSACTION_REPORT =
  "/backoffice/transaction/filter/reportinfo";
const DOWNLOAD_TRANSACTION_REPORT = "/backoffice/transaction/filter/download";

export const TRANSACTION_REPORT_ROUTES = {
  GENERATE_TRANSACTION_REPORT,
  GET_TRANSACTION_REPORTS,
  GET_SINGLE_TRANSACTION_REPORT,
  DOWNLOAD_TRANSACTION_REPORT,
};
