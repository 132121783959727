const GET_PENDING_DISPUTE_REFUNDS = "/backoffice/manual-dispute-refund/pending";
const DOWNLOAD_DISPUTE_REFUNDS = "/backoffice/manual-dispute-refund/download";
const PROCESS_DISPUTE_REFUND = "/backoffice/manual-dispute-refund";
const CHECK_PENDING_DISPUTE_REFUNDS_DOWNLOAD_STATUS =
  "/backoffice/manual-dispute-refund/download-info";
const GET_DISPUTE_REFUND_BULK_UPLOADS =
  "/backoffice/manual-dispute-refund/batches";
const GET_ITEMS_IN_DISPUTE_REFUND_BULK =
  "/backoffice/manual-dispute-refund/batch-items";
const UPLOAD_DISPUTE_REFUND_BULK = "/backoffice/manual-dispute-refund/upload";

const GET_DISPUTE_REFUND_BULK_SUMMARY =
  "/backoffice/manual-dispute-refund/transactions/summary";

export const MANUAL_DISPUTE_REFUND_ROUTES = {
  GET_PENDING_DISPUTE_REFUNDS,
  DOWNLOAD_DISPUTE_REFUNDS,
  PROCESS_DISPUTE_REFUND,
  CHECK_PENDING_DISPUTE_REFUNDS_DOWNLOAD_STATUS,
  GET_DISPUTE_REFUND_BULK_UPLOADS,
  GET_ITEMS_IN_DISPUTE_REFUND_BULK,
  UPLOAD_DISPUTE_REFUND_BULK,
  GET_DISPUTE_REFUND_BULK_SUMMARY,
};
