import { useCallback, useEffect, useState } from "react";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { CardSkeletonLoader } from "../../../components/elements/custom-card/card-skeleton-loader";
import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { CustomPagination } from "components/elements/custom-pagination/custom-pagination";
// import { TableEmptyView } from "../../../shared/components/custom-table/table-empty-view";
// import { TableErrorView } from "../../../shared/components/custom-table/table-error-view";
import { TableEmptyView } from "components/elements/custom-table/table-empty-view";
import { TableErrorView } from "components/elements/custom-table/table-error-view";
import { FormDropdown } from "components/form/form-dropdown";
import { HELPER } from "utils/helper";

export function LongRunningQueries() {
  const [loading, setLoading] = useState(false);
  const [dbNames, setDbNames] = useState([]);
  const [queries, setQueries] = useState([]);
  const [selectedDbName, setSelectedDbName] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [paginationProps, setPaginationProps] = useState({});
  const [selectedSqlStatement, setSelectedSqlStatement] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);

  const savePaginationProps = useCallback(
    (pageData) => {
      const { totalPages, totalElements: totalItems } = pageData;
      const props = {
        totalPages,
        totalItems,
        currentPage: pageNo + 1,
        nextPage: () => setPageNo(pageNo + 1),
        prevPage: () => setPageNo(pageNo - 1),
        goToFirstPage: () => setPageNo(0),
        goToLastPage: () => setPageNo(totalPages - 1),
        goToPage: (page) => setPageNo(page),
      };
      setPaginationProps({ ...props });
    },
    [pageNo]
  );

  const getLongRunningQueries = useCallback(
    (dbName) => {
      setLoading(true);
      setError(null);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
        dbName: dbName ? dbName : selectedDbName,
      });
      const url = "/db-metrics/long-running-queries";
      MONITORING_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          setQueries(data?.response?.content);
          savePaginationProps(data?.response);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setLoading(false);
        });
    },
    [savePaginationProps, pageNo, selectedDbName]
  );

  useEffect(() => {
    function getDatabaseNames() {
      setLoading(true);
      MONITORING_SERVICE.MAKE_GET_REQUEST("/configs/db-names")
        .then((data) => {
          const response = data?.response;
          setDbNames(response);
          const firstDbName = response[0];
          setSelectedDbName(firstDbName);
          getLongRunningQueries(firstDbName);
        })
        .catch((error) => {
          // handleError(error);
          setError(HELPER.PROCESS_ERROR(error));
          setLoading(false);
        });
    }
    if (!selectedDbName) {
      getDatabaseNames();
    } else {
      getLongRunningQueries();
    }
  }, [getLongRunningQueries]);

  function validateDropdown(e) {
    const value = e.target.value;
    setSelectedDbName(value);
    getLongRunningQueries(value);
  }

  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      // Truncate the text and add an ellipsis (...) at the end
      return text.slice(0, maxLength) + "...";
    }
  }

  function showSqlStatement(statement) {
    setSelectedSqlStatement(statement);
    setIsModalVisible(true);
  }

  function renderCurrentView() {
    if (loading) {
      return (
        <div className="p-my-5">
          {" "}
          <CardSkeletonLoader />{" "}
        </div>
      );
    } else if (error) {
      return <TableErrorView error={error} onReload={getLongRunningQueries} />;
    } else {
      return (
        <div>
          <table className="p-mt-5 ">
            {queries.length ? (
              <tr className="p-text-center">
                <td className="p-text-left">Statement Text</td>
                <td>Creation Time</td>
                <td>Last Execution Time</td>
                <td>Total Logical Reads</td>
                <td>Total logical Writes</td>
                <td>Total Elapsed Time (ms)</td>
                <td>Average Elapsed Time (ms)</td>
                <td>Execution Count</td>
                <td>CPU Time (ms)</td>
                <td>Avg CPU Time (ms)</td>
              </tr>
            ) : null}
            {queries.map((query, index) => (
              <tr key={index} className="p-text-center">
                <td
                  className="p-text-left"
                  style={{ width: "30%", cursor: "pointer" }}
                  onClick={() => {
                    showSqlStatement(query.statementText);
                  }}
                >
                  {/* <pre> {query.statementText}</pre> */}
                  {truncateText(query.statementText, 50)}
                </td>
                <td>{HELPER.FORMAT_DATE(query.creationTime)}</td>
                <td>{HELPER.FORMAT_DATE(query.lastExecutionTime)}</td>
                <td>{query.totalLogicalReads}</td>
                <td>{query.totalLogicalWrites}</td>
                <td>{query.totalElapsedTime}</td>
                <td>{query.averageElapsedTime}</td>
                <td>{query.executionCount}</td>
                <td>{query.cpuTime}</td>
                <td>{query.avgCpuTime}</td>
              </tr>
            ))}
          </table>
          {!loading && !queries.length && (
            <TableEmptyView
              emptyText="No results found"
              onReload={getLongRunningQueries}
            />
          )}
          {paginationProps?.totalPages ? (
            <div className="custom-page-card p-text-center">
              <CustomPagination {...paginationProps} range="5" />
            </div>
          ) : null}
        </div>
      );
    }
  }

  function modalContent() {
    return (
      <div className="p-my-2">
        <code>{selectedSqlStatement}</code>
      </div>
    );
  }

  return (
    <div>
      <CustomModal
        onHide={() => setIsModalVisible(false)}
        visible={isModalVisible}
        modalContent={modalContent}
        closeModal={() => setIsModalVisible(false)}
      />
      <CustomCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="">
            <p className="p-text-bold"> Long running queries</p>
            <small>A list of long running queries on {selectedDbName}</small>
          </div>

          <div style={{ width: "40%" }}>
            <label>Database:</label>
            <FormDropdown
              required={true}
              field="dbName"
              value={selectedDbName}
              fn={validateDropdown}
              options={dbNames}
              placeholder="Select DB"
              filter
            />
          </div>
        </div>
        {renderCurrentView()}
      </CustomCard>
    </div>
  );
}
