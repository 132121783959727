import React, {
  useCallback,
  // useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { DetailsBreakDown } from "components/elements/details-break-down/details-break-down";
import { Toast } from "primereact/toast";
// import { MainContext } from "App";
import { HELPER } from "utils/helper";
import { AccessDenied } from "components/elements/access-denied/access-denied";
import { BulkTransactionBreakDown } from "./bulk-transaction-break-down";
import { TransactionsFilter } from "../../back-office/transactions/transactions-filter";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function BulkTransactions(props) {
  const toast = useRef(null);
  // const op = useRef(null);
  // const mainContext = useContext(MainContext);
  // const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  // const [modalLoading, setModalLoading] = useState(false);
  // const [modalLoadingText, setModalLoadingText] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  // const [isMobileTransaction, setIsMobileTransaction] = useState(false);
  // const [currentPage] = useState(mainContext?.mainState?.currentPage);
  const [range] = useState(5);
  const [breakDownTitle] = useState("");
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [transactionSearchKey, setTransactionSearchKey] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);

  const tableHeaders = [
    { label: "Time", value: "transactionTime" },
    { label: "Masked pan", value: "maskedPan" },
    { label: "Rrn", value: "rrn" },
    { label: "Amount", value: "transactionAmount" },
    { label: "Terminal id", value: "terminalId" },
    { label: "Bulk Key", value: "bulkSettlementKey" },
    { label: "Actions", value: "actions" },
  ];

  const [details, setDetails] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const getTransactionDetails = useCallback((e, isMobile) => {
    let arr = [];
    setDetails([]);
    setTransactionSearchKey(e?.transactionSearchKey);
    arr.push({ label: "Card acceptor id", value: e?.cardAcceptorId });
    arr.push({
      label: "Charge amount",
      value: e?.chargeAmount,
      itemCase: "chargeAmount",
    });
    arr.push({ label: "Bulk Key", value: e?.bulkSettlementKey });
    arr.push({ label: "Masked pan", value: e?.maskedPan });
    arr.push({
      label: "Response code",
      value: e?.responseCode,
      itemCase: "responseCode",
    });
    arr.push({ label: "Bulk Key", value: e?.bulkSettlementKey });
    arr.push({ label: "Rrn", value: e?.rrn });
    arr.push({ label: "Stan", value: e?.stan });
    arr.push({ label: "Terminal id", value: e?.terminalId });
    arr.push({
      label: "Transaction amount",
      value: e?.transactionAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Transaction time",
      value: e?.transactionTime,
      itemCase: "transactionTime",
    });

    setDetails(arr);
    // setIsMobileTransaction(isMobile);
    // props.currentIndexView(3);
    setCurrentIndex(3);
    // openModal(2,isMobile)
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [getTransactionDetails]
  );

  const getTransactions = useCallback(
    (queryParams = null, isReload = false) => {
      if (queryParams) {
        setPageNo(0);
      }
      setCurrentIndex(0);
      setTransactions([]);
      // closeModal();
      setVisible(false);
      let params = {
        page: pageNo,
        size: 10,
      };
      if ((queryParams || searchParams) && !isReload) {
        setSearch(true);
        if (queryParams) {
          params = {
            ...params,
            page: 0,
            ...queryParams,
          };
          setSearchParams(params);
        } else {
          params = {
            ...params,
            ...searchParams,
            page: pageNo,
          };
        }
      }
      params = HELPER.TO_URL_STRING(params);
      const url =
        FRONT_OFFICE_API.BULK_SETTLEMENT.GET_BULK_SETTLEMENT_TRANSACTIONS;
      API_SERVICE.MAKE_GET_REQUEST(
        `${url}/${props.bulkSettlementKey}?${params}`
      )
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No transactions found...");
          } else {
            let arr = [];
            setTotalItems(data?.result.totalElements); //need adjustment
            setTotalPages(data?.result.totalPages); //need adjustment
            result.forEach((e) => {
              arr.push({ ...e, actions: "CR", detailsFunction: openAction });
            });
            setTransactions(arr);
          }
          setError(null);
          setCurrentIndex(1);
          // setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
        });
    },
    [pageNo, searchParams, props.bulkSettlementKey, openAction]
  );

  // useEffect(() => {
  //   getTransactions();
  // }, [pageNo]);

  useEffect(() => {
    // let mounted = true;
    // if (mounted) {
    getTransactions();
    // }
    // return () => {
    //   mounted = false;
    // };
  }, [getTransactions]);

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function reload() {
    setPageNo(0);
    setSearchParams(null);
    // setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getTransactions(null, true);
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function goBack() {
    setCurrentIndex(1);
  }

  const bulkTransactionView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container tab">
            <CustomLoader loadingText="Loading transactions..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={transactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="p-mt-2">
            <BulkTransactionBreakDown
              goBack={goBack}
              isMobile={props.isMobile}
              details={details}
            />
          </div>
        );
    }
  };

  function modalFooter(footer) {}

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return "";
      case 2:
        return (
          <DetailsBreakDown
            footer={modalFooter}
            transactionSearchKey={transactionSearchKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <TransactionsFilter
            searchFunction={getTransactions}
            closeModal={closeModal}
            type="fo"
            canDownload={false}
          />
        );
    }
  };

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return "";
      case 2:
        return (
          <DetailsBreakDown
            mobile={true}
            transactionSearchKey={transactionSearchKey}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <TransactionsFilter
            searchFunction={getTransactions}
            closeModal={closeModal}
            type="fo"
            canDownload={false}
          />
        );
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <CustomModal
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
          closeModal={closeModal}
        />
      </div>
      <div className="p-mt-6"></div>
      {currentIndex === 1 && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <button
            onClick={() => openModal(3)}
            className="primary-button"
            style={{ width: "135px", marginBottom: "2rem" }}
          >
            <i className="pi pi-filter"></i> Filter
          </button>
        </div>
      )}
      <div
        className={
          HELPER.HAS_AUTHORITY("fo_dcir_get_transactions_per_bulk")
            ? "dcir-show"
            : "dcir-hide"
        }
      >
        {bulkTransactionView()}
      </div>
      <div
        className={
          HELPER.HAS_AUTHORITY("fo_dcir_get_transactions_per_bulk")
            ? "dcir-hide"
            : "dcir-show"
        }
      >
        <AccessDenied />
      </div>
    </div>
  );
}
