const GET_BULK_SETTLEMENTS = "/frontoffice/settlement/bulk";
const GET_BULK_SETTLEMENT_TRANSACTIONS = "/frontoffice/settlement";
const GET_PAYMENT_REQUESTS = "/frontoffice/paymentrequest";
const DOWNLOAD_BULK_SETTLEMENT = "/frontoffice/settlement/download";

export const BULK_SETTLEMENT_ROUTES = {
  GET_BULK_SETTLEMENTS,
  GET_BULK_SETTLEMENT_TRANSACTIONS,
  GET_PAYMENT_REQUESTS,
  DOWNLOAD_BULK_SETTLEMENT,
};
