const RESET_USER_PASSWORD = "/frontoffice/user/reset-password";
const FILTER_USERS = "/frontoffice/user/filter";
const CHANGE_USER_STATUS = "/frontoffice/user/status/change";
const CREATE_USER = "/frontoffice/user";
const GET_USERS = "/frontoffice/user";
const GET_USER_ROLES = "/frontoffice/user/roles";

export const SUPER_AGENT_USER_ROUTES = {
  RESET_USER_PASSWORD,
  FILTER_USERS,
  CHANGE_USER_STATUS,
  CREATE_USER,
  GET_USER_ROLES,
  GET_USERS,
};
