const LOGIN = "/common/authentication/login";
const LOGOUT = "/common/authentication/logout";
const AUTHENTICATE_2FA_CODE = "/common/authentication/authenticate-2fa";
const GET_USER_DETAILS = "/common/authentication/user-detail";
const CHANGE_PASSWORD = "/common/authentication/change-password";
const UPDATE_USER_ROLE = "/common/authentication/roles/change-role";
const GET_SINGLE_ROLE = "/common/authentication/roles";
const GET_BACK_OFFICE_USER_DETAILS = "/common/authentication/get-user";
const CHANGE_BACK_OFFICE_USER_STATUS = "/common/authentication/change-status";
const RESET_BACK_OFFICE_USER_PASSWORD = "/common/authentication/reset-password";

export const AUTH_ROUTES = {
  LOGIN,
  LOGOUT,
  AUTHENTICATE_2FA_CODE,
  GET_USER_DETAILS,
  CHANGE_PASSWORD,
  UPDATE_USER_ROLE,
  GET_SINGLE_ROLE,
  GET_BACK_OFFICE_USER_DETAILS,
  CHANGE_BACK_OFFICE_USER_STATUS,
  RESET_BACK_OFFICE_USER_PASSWORD,
};
