import { saveAs } from "file-saver";
import { useState } from "react";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomUpload } from "components/elements/custom-upload/custom-upload";
import { Optional } from "components/elements/optional/optional";
import { HELPER } from "utils/helper";
import { ConfigureSettlement } from "./configure-settlement";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

function BulkUploadForm({ onSuccess, settlementInformation, goBack }) {
  const [loading, setLoading] = useState(false);
  const [bulkSuperAgents, setBulkSuperAgents] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  function getSettlementInformation() {
    const {
      vatAccount,
      vatAccountName,
      settlementType,
      concessionChargeType,
      chargeType,
      vatEnabled,
      participants,
    } = settlementInformation;
    return {
      participantsIds: participants.map((participant) => participant.id),
      vatEnabled,
      ...(vatAccount && { vatAccountNumber: vatAccount }),
      ...(vatAccountName && { vatAccountName: vatAccountName }),
      settlementType: settlementType?.code,
      chargeTypeCode: chargeType?.code,
      ...(concessionChargeType && {
        concessionChargeTypeCode: concessionChargeType?.code,
      }),
    };
  }

  function submit() {
    setLoading(true);
    let formData = new FormData();
    formData.append("file", bulkSuperAgents);
    const settlementInformation = getSettlementInformation();
    const settlementInfoProps = Object.keys(settlementInformation);
    settlementInfoProps.forEach((property) => {
      formData.append(property, settlementInformation[property]);
    });
    const url = BACK_OFFICE_API.SUPER_AGENT.UPLOAD_BULK_SUPER_AGENT;
    API_SERVICE.MAKE_POST_REQUEST(url, formData)
      .then((response) => {
        const { result } = response;
        onSuccess(result, result?.errors);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function downloadTemplate(fileFormat) {
    saveAs(
      `${process.env.PUBLIC_URL}/merchant-bulk-creation-sample.${fileFormat}`,
      `merchant-bulk-creation-sample.${fileFormat}`
    );
  }

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          disabled={!bulkSuperAgents}
          onClick={submit}
          className="primary-button"
        >
          Submit
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2 p-mt-5">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  return (
    <div>
      <CustomUpload
        accept=".csv,.xlsx"
        title={`Click to upload a .csv or .xlsx file for bulk merchant creation (Max 5MB)* `}
        fileType="spreadsheet"
        getUploadedFile={(file) => setBulkSuperAgents(file)}
        clearUploadedFile={() => setBulkSuperAgents(null)}
        noMaxSizeTip={true}
        maxFileSizeInMb="5"
      />
      <div className="p-text-left p-mt-4 p-d-flex p-justify-between">
        <button
          className="bare-button"
          onClick={() => downloadTemplate("xlsx")}
          style={{ fontSize: "0.9rem" }}
        >
          Download Excel Template
        </button>
        <button
          className="bare-button"
          onClick={() => downloadTemplate("csv")}
          style={{ fontSize: "0.9rem" }}
        >
          Download CSV Template
        </button>
      </div>
      <Optional show={errorMessage}>
        <div className="p-col-12">
          <CustomToast title="Error" description={errorMessage} type="error" />
        </div>
      </Optional>

      <div className="p-col-12 p-mb-5">
        <div className="p-mt-4">
          <div className="p-grid">
            <Optional show={!loading}>
              <div className="p-col-6 p-pl-0">
                <button onClick={goBack} className="secondary-button">
                  Back
                </button>
              </div>
            </Optional>
            <div className={`${loading ? "p-col-12" : "p-col-6"} p-pr-0`}>
              {submitButton()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function BulkSuperAgentsCreationForm(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [settlementInformation, setSettlementInformation] = useState(null);
  const [uploadSummary, setUploadSummary] = useState(null);
  const [uploadErrors, setUploadErrors] = useState([]);

  function currentFormView() {
    switch (currentIndex) {
      case 0:
        return (
          <ConfigureSettlement
            isBulkUpload={true}
            onSaveSettlementInformation={(data) => {
              setSettlementInformation(data);
              setCurrentIndex(1);
            }}
            fn={() => props.closeModal()}
            // onNext={() => setCurrentIndex(1)}
          />
        );
      case 1:
        return (
          <BulkUploadForm
            settlementInformation={settlementInformation}
            onSuccess={(message, errors) => {
              setUploadSummary(message);
              setUploadErrors(errors);
              setCurrentIndex(2);
            }}
            closeModal={props.closeModal}
            goBack={() => setCurrentIndex(0)}
          />
        );
      case 2:
        return (
          <div>
            <div>
              <CustomMessage messageType="success" />
            </div>
            <div>
              <Optional show={uploadSummary?.message}>
                <h4 className=" p-mb-0 p-text-left">
                  {" "}
                  {uploadSummary?.message}
                </h4>
                <p
                  className=" success-message-text  p-text-left"
                  style={{ fontSize: "0.9rem" }}
                >
                  Total entries: <span>{uploadSummary?.totalEntries}</span>{" "}
                </p>
                <p
                  className=" success-message-text p-text-left"
                  style={{ fontSize: "0.9rem" }}
                >
                  Successfully processed:{" "}
                  <span>{uploadSummary?.processedSuccessfully}</span>{" "}
                </p>
                <p
                  className=" success-message-text p-text-left"
                  style={{ fontSize: "0.9rem" }}
                >
                  Failed: <span>{uploadSummary?.failed}</span>{" "}
                </p>
                <p
                  className=" success-message-text p-text-left"
                  style={{ fontSize: "0.9rem" }}
                >
                  Download error response:{" "}
                  <a
                    style={{ color: "blue" }}
                    download
                    target="_blank"
                    rel="noreferrer"
                    href={uploadSummary?.errorDownloadLink}
                  >
                    Click here
                  </a>
                </p>
              </Optional>

              <Optional show={uploadErrors?.length}>
                <p className="p-mb-0 p-text-left text-small">Errors:</p>
                {uploadErrors?.map((error) => (
                  <p
                    className="p-mt-0 p-text-left"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {error}
                  </p>
                ))}
              </Optional>
            </div>
            <div className="success-message-btn-container p-text-center">
              <p
                onClick={() => {
                  props.closeModal(true);
                }}
                className="close-modal add-cursor"
              >
                Close
              </p>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  return (
    <>
      {currentIndex !== 2 && (
        <div>
          <div className="custom-modal-title p-text-left p-mb-3 p-mt-0">
            Bulk Super Agent Creation
          </div>
          <div className="custom-dialog-subtitle-container p-mb-5">
            <p className="custom-dialog-subtitle">Fill the form below</p>
            <p className="custom-dialog-subtitle">
              <span className="p-text-error">*</span> Required Fields
            </p>
          </div>
        </div>
      )}
      {currentFormView()}
    </>
  );
}
