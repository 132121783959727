import React, { useState } from "react";
import { API_SERVICE } from "api/service";
import { FormInput } from "components/form/form-input";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import { SYSTEM_ACCEPTED, UserTypes } from "utils/constants";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function ReopenDispute(props) {
  const [logCode, setLogCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);

  function reopenDispute() {
    setLoading(true);
    const payload = {
      disputeLogCode: props.logCode,
      transactionSearchKey: props.transactionKey,
    };

    let frontofficeUrl = FRONT_OFFICE_API.DISPUTE.REOPEN_DISPUTE;
    let backofficeUrl = BACK_OFFICE_API.DISPUTE.REOPEN_DISPUTE;
    let backofficeSystemAcceptedUrl =
      BACK_OFFICE_API.DISPUTE.REOPEN_SYSTEM_ACCEPTED_DISPUTE;
    let url;
    if (props.userType === UserTypes.FrontOffice) {
      url = frontofficeUrl;
    } else {
      url =
        props.disputeStatus === SYSTEM_ACCEPTED
          ? backofficeSystemAcceptedUrl
          : backofficeUrl;
    }
    API_SERVICE.MAKE_POST_REQUEST(url, payload)
      .then((data) => {
        props.getDisputeDetails();
        setSuccessMessage(`Dispute has been reopened!`);
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const actions = () => {
    if (loading) {
      return (
        <div>
          <CustomLoader loadingText="Reopening..." />
        </div>
      );
    } else if (!loading && !showSuccess) {
      return (
        <div className="p-mt-5 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.closeModal} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button
                disabled={logCode !== props?.logCode}
                onClick={reopenDispute}
                className="primary-button"
              >
                Reopen
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const alertView = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const resetView = () => {
    if (showSuccess) {
      return (
        <div>
          <CustomMessage
            closeModal={props.closeModal}
            close={true}
            message={successMessage}
            messageType="success"
          />
        </div>
      );
    } else {
      return (
        <div>
          <p className="p-mb-5 p-mt-0 confirm-text">
            Confirm that you want to reopen this dispute!
          </p>
          <div>
            <p className="p-text-left">
              To confirm, enter <b>{props?.logCode}</b> in the text input field
            </p>
            <FormInput
              value={logCode}
              required={true}
              field="reset"
              type="INPUT"
              fn={(e) => setLogCode(e?.target?.value)}
              loading={loading}
              placeholder="Enter log code"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-mb-3">
      <div>{resetView()}</div>
      <div className="p-pb-1">{alertView()}</div>
      <div className="p-mt-3">{actions()}</div>
    </div>
  );
}
