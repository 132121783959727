import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { RequeryRequestDetails } from "./request-details";
import { API_SERVICE } from "api/service";
import { FormRadioInput } from "components/form/form-radio-input";
import { RequeryTransactionsFilter } from "./requery-transactions-filter";
import { Optional } from "components/elements/optional/optional";
import { BulkSettlementBreakDown } from "../bulk-settlements/bulk-settlement-break-down";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";
// import { requeryMockData } from "./mock-data";

export function RequeryManagement() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const settlementTableHeaders = useRef([
    { label: "Merchant/Participant", value: "name" },
    { label: "Settlement Amount", value: "amount" },
    { label: "Source Account", value: "sourceAccountNumber" },
    { label: "Destination Account", value: "destinationAccountNumber" },
    { label: "Transaction Ref", value: "transactionReference" },
    { label: "Settlement Key", value: "bulkSettlementKey" },
    { label: "Settlement Time", value: "settlementAttemptDateTime" },
    { label: "Actions", value: "actions" },
  ]);
  const disputeTableHeaders = useRef([
    { label: "Merchant/Participant", value: "name" },
    { label: "Amount", value: "amount" },
    { label: "Source Account", value: "sourceAccountNumber" },
    { label: "Destination Account", value: "destinationAccountNumber" },
    { label: "Transaction Ref", value: "transactionReference" },
    { label: "Time", value: "disputeRefundAttemptDateTime" },
    { label: "Actions", value: "actions" },
  ]);
  const [tableHeaders, setTableHeaders] = useState(
    settlementTableHeaders.current
  );
  const authorities = [{ label: "VIEW", value: "all" }];
  const [transactionsOnRequery, setTransactionsOnRequery] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  const [requestData, setRequestData] = useState([]);
  const [transactionType, setTransactionType] = useState("settlements");
  const [transactionId, setTransactionId] = useState(null);
  const [bulkSettlementKey, setBulkSettlementKey] = useState(null);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW":
          let arr = [];
          arr.push({
            label: "Merchant/Participant",
            value: e?.name,
          });
          arr.push({
            label: "Amount",
            value: e?.amount,
            itemCase: "amount",
          });
          arr.push({
            label: "Debit Account",
            value: e?.sourceAccountNumber,
          });
          arr.push({
            label: "Debit Account Name",
            value: e?.sourceAccountName,
          });
          arr.push({
            label: "Credit Account",
            value: e?.destinationAccountNumber,
          });
          arr.push({
            label: "Credit Account Name",
            value: e?.destinationAccountName,
          });
          if (transactionType === "settlements") {
            arr.push({
              label: "Settlement Key",
              value: e?.bulkSettlementKey,
              action: "viewSettlement",
              bulkSettlementKey: e?.bulkSettlementKey,
            });
          }

          arr.push({
            label: "Transaction Ref",
            value: e?.transactionReference,
          });
          // arr.push({
          //   label: "Transaction Time",
          //   value: e?.attemptTime,
          // });
          arr.push({
            label: "Narration",
            value: e?.transactionNarration,
          });
          arr.push({
            label: "Created At",
            value:
              e?.settlementRequestCreationDateTime ||
              e?.disputeTransactionCreationDateTime,
          });
          arr.push({
            label: "Last Updated At",
            value: e?.lastUpdatedAt,
          });
          arr.push({
            label: "Recipient Type",
            value: e?.recipientType,
          });
          setTransactionId(
            transactionType === "settlements"
              ? e?.settlementRequestId
              : e?.disputeTransactionSearchKey
          );
          setRequestData(arr);
          // openModal(0, isMobile);
          setCurrentIndex(3);
          break;
      }
    },
    [transactionType]
  );

  const getTransactionsOnRequery = useCallback(() => {
    setCurrentIndex(0);
    setTransactionsOnRequery([]);
    setVisible(false);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
      ...searchParams,
    });
    const { GET_DISPUTES_ON_REQUERY, GET_SETTLEMENTS_ON_REQUERY } =
      BACK_OFFICE_API.REQUERY_MANAGEMENT;
    const url =
      transactionType === "disputes"
        ? GET_DISPUTES_ON_REQUERY
        : GET_SETTLEMENTS_ON_REQUERY;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data?.result;
        // const result = requeryMockData;
        if (!result?.content.length) {
          setEmptyText("No results found.");
        } else {
          let arr = [];
          setTotalItems(result?.totalElements);
          setTotalPages(result.totalPages);
          result?.content?.forEach((e) => {
            arr.push({
              ...e,
              name: e?.merchantName || e?.participantName,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setTransactionsOnRequery(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [openAction, pageNo, transactionType, searchParams]);

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  useEffect(() => {
    getTransactionsOnRequery();
  }, [getTransactionsOnRequery]);

  useEffect(() => {
    if (transactionType === "settlements") {
      setTableHeaders(settlementTableHeaders.current);
    } else {
      setTableHeaders(disputeTableHeaders.current);
    }
  }, [transactionType]);

  function handleTransactionTypeSelection(e) {
    const value = e?.target?.value;
    setTableHeaders(
      value === "settlements"
        ? settlementTableHeaders.current
        : disputeTableHeaders.current
    );
    setTransactionType(value);
  }

  function reload(pageNo = 0) {
    setPageNo(pageNo);
    setLoading(true);
    // setSearch(false);
    setCurrentIndex(0);
    setSearchParams(null);
    getTransactionsOnRequery();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }
  }

  function handleShowBulkSettlement(bulkSettlementKey) {
    // const tempKey = "2304300000007dfc2305010513230847";
    // setBulkSettlementKey(tempKey);
    setBulkSettlementKey(bulkSettlementKey);
    setCurrentIndex(4);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <RequeryRequestDetails
            closeModal={closeModal}
            requestData={requestData}
            transactionType={transactionType}
            transactionId={transactionId}
          />
        );
      case 1:
        return (
          <RequeryTransactionsFilter
            closeModal={closeModal}
            searchFunction={(params) => setSearchParams(params)}
            transactionType={transactionType}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <RequeryRequestDetails
            closeModal={closeModal}
            requestData={requestData}
            transactionType={transactionType}
            transactionId={transactionId}
          />
        );
      case 1:
        return (
          <RequeryTransactionsFilter
            closeModal={closeModal}
            searchFunction={(params) => {
              console.log("params", params);
              setSearchParams(params);
            }}
            transactionType={transactionType}
          />
        );
    }
  };

  const requeryManagementView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="" />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            authorities={authorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={true}
            reload={reload}
            error={error}
            items={transactionsOnRequery}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <RequeryRequestDetails
            closeModal={closeModal}
            requestData={requestData}
            transactionType={transactionType}
            transactionId={transactionId}
            onShowBulkSettlement={handleShowBulkSettlement}
            goBack={() => setCurrentIndex(1)}
          />
        );
      case 4:
        return (
          <BulkSettlementBreakDown
            bulkSettlementKey={bulkSettlementKey}
            goBack={() => setCurrentIndex(3)}
            // details={details}
            shouldGetSettlementDetails={true}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div className="requery-management">
        <Toast ref={toast} />
        <div className={currentModalIndex === 1 ? "filter" : ""}>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>

        <div className="page-header">
          <div className="page-title p-text-left">
            Transactions Requery Status Update
          </div>
          <UserManualViewerButton fileName="manage_transactions_request_status_update" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Manage Pending Requery Requests"} />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <Optional
                    show={
                      HELPER.HAS_AUTHORITY(
                        "bo_view_dispute_refunds_on_awaiting_requery"
                      ) ||
                      HELPER.HAS_AUTHORITY(
                        "bo_view_settlements_on_awaiting_requery"
                      )
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(1, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex default p-mb-4">
          <FormRadioInput
            id="settlements"
            value="settlements"
            name="transaction-type"
            checked={transactionType === "settlements"}
            handleChange={handleTransactionTypeSelection}
            label="Transaction Settlement Requests"
          />
          <div className="p-ml-3">
            <FormRadioInput
              id="disputes"
              value="disputes"
              name="transaction-type"
              checked={transactionType === "disputes"}
              handleChange={handleTransactionTypeSelection}
              label="Dispute Refund Requests"
            />
          </div>
        </div>
        <div>{requeryManagementView()}</div>
      </div>
    </ErrorBoundary>
  );
}
