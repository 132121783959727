import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import { BulkDisputeUpload } from "./bulk-dispute-upload";
import { BulkDisputeTransactions } from "./bulk-dispute-transactions";
import "./bulk-disputes.css";
import { BulkDisputesFilter } from "./bulk-disputes-filter";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
// import { Optional } from "components/elements/optional/optional";
import { Optional } from "components/elements/optional/optional";
import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";


export function BulkDisputes() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const tableHeaders = [
    { label: "Uploaded By", value: "uploadedBy" },
    { label: "Batch Id", value: "batchId" },
    { label: "Uploaded At", value: "uploadedAt" },
    { label: "Actions", value: "actions" },
  ];
  // const bulkDisputeAuthorities = [
  //   { label: "UPDATE", value: "bo_dcir_update_participant" },
  //   { label: "DELETE", value: "bo_dcir_remove_participant" },
  // ];
  const [bulkDisputes, setBulkDisputes] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [searchParams, setSearchParams] = useState(null);

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  const openAction = useCallback((e, action, isMobile) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "VIEW": {
        // viewBulk(e.participantId, isMobile);
        setSelectedBatchId(e?.batchId);
        setCurrentIndex(3);
        break;
      }
    }
  }, []);

  const getBulkDisputes = useCallback(
    (searchParams) => {
      setCurrentIndex(0);
      setBulkDisputes([]);
      let params = {
        page: pageNo,
        size: 10,
      };
      if (searchParams) {
        setSearch(true);
        params = {
          ...params,
          ...searchParams,
        };
      }
      const { GET_BULK_DISPUTES: url } = BACK_OFFICE_API.BULK_DISPUTE;
      params = HELPER.TO_URL_STRING(params);
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          const result = data?.result?.content;
          if (!result?.length) {
            setEmptyText("No bulk disputes found...");
          } else {
            let arr = [];
            setTotalItems(data?.result?.totalElements);
            setTotalPages(data?.result?.totalPages);
            result?.forEach((e) => {
              arr.push({
                ...e,
                actions: "VIEW",
                detailsFunction: openAction,
              });
            });
            setBulkDisputes(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
          setLoading(false);
        });
    },
    [pageNo, openAction]
  );

  useEffect(() => {
    if (searchParams) {
      getBulkDisputes(searchParams);
    } else {
      getBulkDisputes();
    }
  }, [getBulkDisputes, searchParams]);

  function onHide() {}

  function reload() {
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getBulkDisputes();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function saveFilters(filters) {
    closeModal();
    setPageNo(0);
    setSearchParams(filters);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <BulkDisputeUpload closeModal={closeModal} />;
      case 1:
        return (
          <BulkDisputesFilter
            closeModal={closeModal}
            searchFunction={saveFilters}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <BulkDisputeUpload closeModal={closeModal} />;
      case 1:
        return (
          <BulkDisputesFilter
            closeModal={closeModal}
            searchFunction={saveFilters}
          />
        );
    }
  };

  const bulkDisputeView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading bulk disputes..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            // authorities={bulkDisputeAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={bulkDisputes}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <BulkDisputeTransactions
            batchId={selectedBatchId}
            goBack={() => setCurrentIndex(1)}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-header">
        <div className="page-title p-text-left">Bulk Disputes</div>
        <UserManualViewerButton 
        fileName="backoffice_manage_disputes_bulk_disputes" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Bulk Disputes" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              {currentIndex === 1 && (
                <div className="p-grid">
                  <div className="p-col-6 p-d-flex p-jc-end p-ai-center">
                    <button
                      disabled={loading || bulkDisputes?.length === 0}
                      onClick={() => openModal(1, false)}
                      className="filter-button"
                    >
                      <i className="pi pi-filter" />
                      <span className="hide-btn-text"> Filter</span>
                    </button>
                  </div>
                  <div className="p-col-6">
                    <Optional
                      show={HELPER.HAS_AUTHORITY(
                        "bo_dcir_create_dispute_transaction"
                      )}
                    >
                      <button
                        disabled={loading}
                        onClick={() => openModal(0, false)}
                        className="primary-button hide-btn-text"
                      >
                        <i className="pi pi-plus" />
                        <span className="hide-btn-text"> New Bulk Upload</span>
                      </button>
                    </Optional>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>{bulkDisputeView()}</div>
      </div>
    </ErrorBoundary>
  );
}
