import "./custom-table.css";
import "./new-table.css";
import { Icon } from "../icons/icon";
import { CustomPagination } from "../custom-pagination/custom-pagination";
import { TableDesktopView } from "./desktop-view";
import { TableMobileView } from "./mobile-view";

export function CustomTable(props) {
  const errorView = () => {
    return (
      <div>
        <div className="empty-container">
          <Icon icon="error-message-icon" />
          <div>
            <p className="empty-text notification-text">{props.error}</p>
          </div>
          <div className="p-mt-3">
            <button onClick={() => props.reload()} className="primary-button">
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  };

  const emptyView = () => {
    if (props.search) {
      return (
        <div>
          <div className="empty-container">
            <div className="adjust-empty-icon">
              <Icon icon="no-item" />
            </div>
            <div>
              <p className="empty-text-two">{props.emptyText}</p>
            </div>
            <div className="p-mt-3">
              <button onClick={() => props.reload()} className="primary-button">
                Reset
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="empty-container">
            <div className="adjust-empty-icon">
              <Icon icon="no-item" />
            </div>
            <div>
              <p className="empty-text-two">{props.emptyText}</p>
            </div>
          </div>
        </div>
      );
    }
  };

  const tableView = () => {
    if (props.items.length) {
      return (
        <div>
          <div className="raiseTable desktop-screen">
            <TableDesktopView {...props} />
          </div>
          <div className="mobile-screen">
            <div className="custom-mobile-table-card mobile-table p-shadow-1">
              <div
                style={{
                  display: props?.isReload ? "block" : "none",
                  top: "-2em",
                }}
                onClick={() => props?.reload()}
                className="custom-table-refresh"
              >
                <span className="p-grid">
                  <span className="p-col p-d-inline-flex">
                    <i className="pi pi-refresh p-px-1" />
                    <span className="table-icon-text">Reset</span>
                  </span>
                </span>
              </div>
              <>
                <TableMobileView {...props} />
              </>
            </div>
          </div>
          <div className="p-text-center custom-page-card">
            <CustomPagination
              // totalPages={props.totalPages}
              // currentPage={props.currentPage}
              // range={props.range}
              // nextPage={props.nextPage}
              // prevPage={props.prevPage}
              // goToFirstPage={props.goToFirstPage}
              // goToLastPage={props.goToLastPage}
              // goToPage={props.goToPage}
              {...props}
            />
          </div>
        </div>
      );
    } else {
      if (props.error) {
        return <div>{errorView()}</div>;
      } else {
        return <div>{emptyView()}</div>;
      }
    }
  };

  return <div>{tableView()}</div>;
}
