import { useContext, useRef, useState } from "react";
import { MonitoringContext } from "../../../App";
import { useHistory } from "react-router-dom";
import { STORAGE } from "../../../utils/storage";
import { Optional } from "components/elements/optional/optional";
import { Toast } from "primereact/toast";
import { Icon } from "components/elements/icons/icon";

export function MonitoringHeader() {
  const mainContext = useContext(MonitoringContext);
  const toast = useRef(null);

  let history = useHistory();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  function logout() {
    setIsLoggingOut(true);

    clearStorageAndRedirect();
    setIsLoggingOut(false);
  }

  function clearStorageAndRedirect() {
    mainContext.monitoringDispatch({ type: "LOG_OUT" });
    STORAGE.CLEAR_STORAGE();
    history.push("/monitor");
  }

  function openNav(e) {
    e.preventDefault();
    let sideNav = document.getElementById("mySidenav");
    let mainContent = document.getElementById("main");
    const width = sideNav.style.width;
    if (window.innerWidth < 900) {
      if (width && width === "70vw") {
        sideNav.style.width = "0";
        mainContent.style.marginLeft = "0";
      } else {
        sideNav.style.width = "70vw";
        mainContent.style.marginLeft = "0";
      }
    } else {
      if (width && width === "250px") {
        sideNav.style.width = "0";
        mainContent.style.marginLeft = "0";
      } else {
        sideNav.style.width = "250px";
        mainContent.style.marginLeft = "250px";
      }
    }
  }

  return (
    <div className="header">
      <Toast ref={toast} />
      <div className="p-grid header-container">
        <div className="p-col-4">
          <div className="header-icon add-cursor">
            <button onClick={openNav} className="bare-button">
              <Icon icon="menu-icon" />
            </button>
          </div>
        </div>
        <div className="p-col-8 header-actions">
          {/* <Optional show={HELPER.HAS_ROLE(["DCIR_BACKOFFICE_ADMIN"])}>
            <div className="add-cursor">
              <a
                className="nav-profile-title p-mt-0"
                href="https://teamapt.atlassian.net/wiki/spaces/DUTM/overview?homepageId=703725830"
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <Icon icon="bulk-disputes" color="#0371ac" />
                </div>
                User Manual
              </a>
            </div>
          </Optional> */}
          <div onClick={logout} className="p-col-6 log-out add-cursor">
            <Optional show={!isLoggingOut}>
              <Icon icon="log-out" />
            </Optional>
            <Optional show={isLoggingOut}>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "1.5em" }}
              ></i>
            </Optional>
          </div>
        </div>
      </div>
    </div>
  );
}
