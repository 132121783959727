import "./custom-breadcrumb.css";
import { BreadCrumb } from "primereact/breadcrumb";
import { useEffect, useState } from "react";
import { STORAGE } from "utils/storage";
import { HELPER } from "utils/helper";

export function CustomBreadcrumb(props) {
  const items = [{ label: props.page }];
  const [homeUrl, setHomeUrl] = useState("");

  useEffect(() => {
    function getLoggedInUsersHomeRoute() {
      const authorities = STORAGE.GET_AUTHORITIES();
      let home = HELPER.GET_USER_LOGIN_ROUTE(authorities);
      setHomeUrl(home);
    }
    getLoggedInUsersHomeRoute();
  }, []);

  const home = { icon: "pi pi-home", url: homeUrl };

  return <BreadCrumb model={items} home={home} />;
}
