import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  Filler,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { TRANSACTION_PROCESSING_HELPERS } from "./helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
);
export function TransactionProcessingAnalyticsChart(props) {
  const [loading, setLoading] = useState(true);
  const [loadingTwo, setLoadinTwo] = useState(true);
  const [chartData, setChartData] = useState(null);
  const chartValueType = useRef("count");
  const [lineChartData, setLineChartData] = useState(null);
  const barChartOptionsTwo = useRef(null);
  const lineChartOptions = useRef(null);

  const formatProcessingData = (data) => {
    setLoading(true);
    const { chartOptions, chartData } =
      TRANSACTION_PROCESSING_HELPERS.FORMAT_CURRENT_DAY_METRICS(
        data,
        chartValueType.current,
        props?.dateRange === "today"
      );
    barChartOptionsTwo.current = chartOptions;
    setChartData(chartData);
    setLoading(false);
  };

  const formatDayAvgResponseData = (data) => {
    setLoadinTwo(true);
    const { chartOptions, chartData } =
      TRANSACTION_PROCESSING_HELPERS.FORMAT_CURRENT_DAY_AVG_RESPONSE_TIME_METRICS(
        data,
        props?.dateRange === "today"
      );
    lineChartOptions.current = chartOptions;
    setLineChartData(chartData);
    setLoadinTwo(false);
  };

  useEffect(() => {
    if (props?.dateRange === "today") {
      formatProcessingData(props?.currentDayMetrics);
      formatDayAvgResponseData(props?.currentDayMetrics);
    } else {
      formatProcessingData(props?.historicMetrics);
      formatDayAvgResponseData(props?.historicMetrics);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.currentDayMetrics, props?.historicMetrics, props?.dateRange]);

  const historicChart = () => {
    return (
      <div className="p-grid p-mt-2">
        <div className="p-col-12">
          <div className="card">
            <div
              className="p-text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="p-mt-0" style={{ fontWeight: "900" }}>
                Avg. Response Time Metrics
              </h3>
            </div>
            {!loading && (
              <div>
                <Line options={lineChartOptions.current} data={lineChartData} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const currentDayChart = () => {
    return (
      <div className="p-grid p-mt-0">
        <div className="p-col-12">
          <div className="card">
            <div
              className="p-text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="p-mt-0" style={{ fontWeight: "900" }}>
                Avg. Response Time Metrics
              </h3>
            </div>
            {!loadingTwo && (
              <div>
                <Line options={lineChartOptions.current} data={lineChartData} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  function handleChartTypeChange(e) {
    chartValueType.current = e?.target?.value;
    if (props?.dateRange === "today") {
      formatProcessingData(props?.currentDayMetrics, chartValueType.current);
      formatDayAvgResponseData(props?.currentDayMetrics);
    } else {
      formatProcessingData(props?.historicMetrics, chartValueType.current);
      formatDayAvgResponseData(props?.historicMetrics);
    }
  }

  const successAndFailureRateChart = () => {
    return (
      <div className="p-grid p-mt-5">
        <div className="p-col-12">
          <div className="card">
            <div
              className="p-text-left"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 className="p-mt-0" style={{ fontWeight: "900" }}>
                Success And Failure Rates
              </h3>
            </div>
            <div
              style={{
                marginBottom: "2rem",
              }}
            >
              <label className="label">
                <input
                  id="count"
                  value="count"
                  name="chartValueType"
                  type="radio"
                  checked={chartValueType.current === "count"}
                  onChange={handleChartTypeChange}
                />
                <span>Rates by Count</span>
              </label>
              <label className="label">
                <input
                  id="percentage"
                  value="percentage"
                  name="chartValueType"
                  type="radio"
                  checked={chartValueType.current === "percentage"}
                  onChange={handleChartTypeChange}
                />

                <span>Rates by Percentage</span>
              </label>
            </div>
            {!loading && (
              <Bar options={barChartOptionsTwo.current} data={chartData} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const chartView = () => {
    switch (props.dateRange) {
      case "today":
        return currentDayChart();
      default:
        return historicChart();
    }
  };

  return (
    <div>
      <div>
        {props.currentViewIndex === 0
          ? chartView()
          : successAndFailureRateChart()}
      </div>
    </div>
  );
}
