export function TableAction({ data, action, icon }) {
  return (
    <button
      onClick={() => {
        // onclick(data, action);
        data?.detailsFunction(data, action);
      }}
      className={`table-action dcir-show bare-button ${icon} ${
        action === "DELETE" || action === "DEACTIVATE"
          ? "table-action-icon-delete"
          : "table-action-icon"
      }`}
    >
      <span className="add-cursor">
        <i className={`pi pi-${icon}`} />
      </span>
    </button>
  );
}
