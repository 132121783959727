const GET_CURRENT_DAY_TRANSACTION_ANALYTICS =
  "/frontoffice/analytics/currentDay/transactions";
const GET_HISTORIC_TRANSACTION_ANALYTICS =
  "/frontoffice/analytics/historical/transactions";
const GET_CURRENT_DAY_DISPUTE_ANALYTICS =
  "/frontoffice/analytics/currentDay/dispute";
const GET_HISTORIC_DISPUTE_ANALYTICS =
  "/frontoffice/analytics/historical/dispute";

export const DASHBOARD = {
  GET_CURRENT_DAY_TRANSACTION_ANALYTICS,
  GET_HISTORIC_TRANSACTION_ANALYTICS,
  GET_CURRENT_DAY_DISPUTE_ANALYTICS,
  GET_HISTORIC_DISPUTE_ANALYTICS,
};
