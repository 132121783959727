import "./custom-upload.css";
import { Icon } from "../icons/icon";
import { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";
import { MAXIMUM_RECEIPT_SIZE_IN_MB } from "utils/constants";
import { Optional } from "components/elements/optional/optional";

export function CustomUpload(props) {
  const defaultMessage = "PNG, JPG, JPEG are accepted";
  const [fileName, setFileName] = useState(defaultMessage);
  const [uploadError, setUploadError] = useState(null);
  const fileInput = useRef();

  useEffect(() => {
    if (props.fileType) {
      setFileName(null);
    }
  }, [props.fileType]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader?.result.toString()));
    reader.readAsDataURL(img);
  }

  function onUpload(file) {
    const selectedFile = file.target.files[0];
    if (selectedFile) {
      if (props.fileType === "spreadsheet") {
        handleSpreadsheetUpload(selectedFile);
      } else if (props.fileType === "zip") {
        handleZipUpload(selectedFile);
      } else {
        handleImageUpload(selectedFile);
      }
    }
  }

  function handleZipUpload(file) {
    const fileSize = file.size;
    const fileSizeInMb = Math.round(fileSize / 1024);
    if (fileSizeInMb > props.maxSize * 1024) {
      setFileName(props.title);
      setUploadError(
        `File size too large. Size cannot be greater than ${props.maxSize}MB.`
      );
      return;
    }
    const name = file?.name;
    setFileName(name);
    props.getUploadedFile(file);
  }

  function handleMaxSizeError(maxSize) {
    setFileName(props.title || defaultMessage);
    setUploadError(
      `File size too large. Size cannot be greater than ${maxSize}MB.`
    );
  }

  function handleSpreadsheetUpload(file) {
    setUploadError(null);
    const name = file?.name;
    const fileSize = file?.size;
    // const fileSizeInMb = Math.round(fileSize / (1024 * 1024));
    const maxFileSizeInBytes = props.maxFileSizeInMb * 1024 * 1024;
    if (props.maxFileSizeInMb && fileSize > maxFileSizeInBytes) {
      handleMaxSizeError(props.maxFileSizeInMb);
      return;
    } else {
      setFileName(name);
      props.getUploadedFile(file);
    }
  }

  function handleImageUpload(file) {
    setUploadError(null);
    const x = file.type + "";
    const fileType = x.substr(x.indexOf("/") + 1) + "";
    const isValidFile = new RegExp("jpeg|png|jpg").test(fileType);
    const fileSize = file.size;
    const fileSizeInMb = Math.round(fileSize / 1024);
    if (fileSizeInMb > MAXIMUM_RECEIPT_SIZE_IN_MB * 1024) {
      handleMaxSizeError(MAXIMUM_RECEIPT_SIZE_IN_MB);
      return;
    }
    if (isValidFile) {
      const name = file.name;
      setFileName(name);

      new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          getBase64(compressedResult, (img) => {
            props.getUploadedFile(img);
          });
        },
      });
    } else {
      setFileName(defaultMessage);
      setUploadError(
        "Please select a valid file type. Supported file types are PNG, JPG and JPEG."
      );
      props.clearUploadedFile();
    }
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        onChange={onUpload}
        ref={fileInput}
        accept={props.accept ? props.accept : ""}
      />
      <div
        onClick={() => {
          fileInput.current.click();
        }}
        className="custom-upload-container"
      >
        <div className="p-grid p-text-left">
          <span className="p-col-3">
            <Icon icon="upload" />
          </span>
          <span className="p-col-9">
            <span className="p-grid upload-grid-position">
              <Optional
                show={
                  props.fileType !== "zip" && props.fileType !== "spreadsheet"
                }
              >
                <span className="p-col-12">
                  <span className="upload-title">{props.title}</span>
                </span>
                <span className="p-col-12">
                  <span className="upload-text">{fileName}</span>
                </span>
              </Optional>
              <Optional
                show={
                  props.fileType === "zip" || props.fileType === "spreadsheet"
                }
              >
                <span className="p-col-12">
                  <span className="upload-title">
                    {fileName ? fileName : props.title}
                  </span>
                </span>
              </Optional>
            </span>
          </span>
        </div>
      </div>
      <Optional show={!props.noMaxSizeTip}>
        <div>
          <p
            className="p-text-left text-small"
            style={{
              color: "#5D7F8D",
            }}
          >
            Note: File size cannot exceed {MAXIMUM_RECEIPT_SIZE_IN_MB}
            MB
          </p>
        </div>
      </Optional>
      <Optional show={uploadError}>
        <p className="p-text-left text-small error-text">{uploadError}</p>
      </Optional>
    </div>
  );
}
