export const NavItems = [
  // Monitoring

  {
    text: "Dashboard",
    route: "/front-office/dashboard",
    icon: "dashboard",
    authority: "fo_view_analytics_dashboard",
    roles: ["DCIR_MERCHANT_SUPER_ADMIN"],
    type: "fo",
    id: "dashboard",
    children: [],
  },
  {
    text: "Dashboard",
    route: "/back-office/dashboard",
    icon: "dashboard",
    authority: "bo_view_analytics_dashboard",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "bo",
    id: "dashboard",
    children: [],
  },
  {
    text: "Charge Types",
    route: "/back-office/charge-types",
    icon: "charge-type",
    authority: "bo_dcir_get_charge_types",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "bo",
    id: "charge-types",
    children: [],
  },
  {
    text: "Participants",
    route: "/back-office/settlement-participants",
    icon: "settlement",
    authority: "bo_dcir_view_participant",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "bo",
    id: "participants",
    children: [],
  },
  {
    text: "Participant Reports",
    route: "/back-office/participant-reports",
    icon: "participant-reports",
    authority: "bo_view_participant_report",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "bo",
    id: "participant-reports",
    children: [],
  },
  {
    text: "Super Agents",
    route: "/back-office/super-agents",
    icon: "merchant",
    authority: "bo_dcir_get_merchants",
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "bo",
    id: "super-agents",
    children: [],
  },
  {
    text: "Super Agent Users",
    route: "/front-office/super-agent-users",
    icon: "merchant-user",
    authority: "fo_dcir_get_all_merchant_users",
    roles: ["DCIR_MERCHANT_SUPER_ADMIN"],
    type: "fo",
    id: "super-agent-users",
    children: [],
  },
  {
    text: "Manage Users",
    icon: "merchant-user",
    authorities: [
      "bo_view_participant_users",
      "bo_dcir_view_backoffice_users",
      "bo_dcir_view_merchant_users",
    ],
    roles: [],
    id: "manage-users",
    children: [
      {
        text: "Participant Users",
        route: "/back-office/participant-users",
        authority: "bo_view_participant_users",
        type: "bo",
        id: "participant-users",
      },
      {
        text: "Back Office Users",
        route: "/back-office/user-management",
        authority: "bo_dcir_view_backoffice_users",
        type: "bo",
        id: "backoffice-users",
        roles: [],
      },
      {
        text: "Super Agent Users",
        route: "/back-office/super-agent-users",
        authority: "bo_dcir_view_merchant_users",
        type: "bo",
        id: "super-agent-users",
        roles: [],
      },
    ],
  },

  {
    text: "Manage Transactions",
    icon: "transaction",
    authorities: [
      "bo_dcir_get_all_transactions",
      "fo_dcir_get_all_transactions",
      "bo_view_dispute_refunds_on_awaiting_requery",
      "bo_view_settlements_on_awaiting_requery",
    ],
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    id: "manage-transactions",
    children: [
      {
        text: "Transactions",
        route: "/back-office/transactions",
        authority: "bo_dcir_get_all_transactions",
        type: "bo",
        id: "transactions",
        roles: [],
      },
      {
        text: "Transaction Reports",
        route: "/back-office/transaction-reports",
        authority: "bo_dcir_get_all_transactions",
        type: "bo",
        id: "transaction-reports",
        roles: [],
      },
      {
        text: "Requery Status Update",
        route: "/back-office/requery-management",
        authorities: [
          "bo_view_settlements_on_awaiting_requery",
          "bo_view_dispute_refunds_on_awaiting_requery",
        ],
        type: "bo",
        id: "requery-status-update",
        roles: [],
      },
      {
        text: "Transactions",
        route: "/front-office/transactions",
        authority: "fo_dcir_get_all_transactions",
        type: "fo",
        id: "transactions",
        roles: [],
      },
      {
        text: "Transaction Reports",
        route: "/front-office/transaction-reports",
        authority: "fo_dcir_get_all_transactions",
        type: "fo",
        id: "transaction-reports",
        roles: [],
      },
    ],
  },
  {
    text: "Settlements",
    route: "/back-office/bulk-settlements",
    icon: "terminals",
    authorities: ["bo_dcir_get_all_bulk_settlement"],
    roles: ["DCIR_BACKOFFICE_ADMIN"],
    type: "bo",
    id: "settlements",
    children: [
      {
        text: "Bulk Settlements",
        route: "/back-office/bulk-settlements",
        icon: "terminals",
        authority: "bo_dcir_get_all_bulk_settlement",
        type: "bo",
        id: "bulk-settlements",
        roles: [],
      },
      {
        text: "Pending Settlements",
        route: "/back-office/disputes/pending-manual-settlements",
        icon: "dispute",
        authority: "bo_view_manual_payment_request",
        type: "bo",
        id: "pending-settlements",
        roles: [],
      },
    ],
  },
  {
    text: "Bulk Settlements",
    route: "/front-office/bulk-settlements",
    icon: "terminals",
    authority: "fo_dcir_get_all_bulk_settlement",
    roles: ["DCIR_MERCHANT_SUPER_ADMIN"],
    type: "fo",
    id: "bulk-settlements",
    children: [],
  },
  {
    text: "Manage Disputes",
    route: "/back-office/disputes",
    icon: "dispute",
    authorities: ["bo_dcir_get_dispute_transactions"],
    roles: [],
    id: "manage-disputes",
    children: [
      {
        text: "Back Office Disputes",
        route: "/back-office/disputes/backoffice",
        icon: "dispute",
        authority: "bo_dcir_get_dispute_transactions",
        type: "bo",
        id: "backoffice-disputes",
        roles: [],
      },
      {
        text: "Super Agent Disputes",
        route: "/back-office/disputes/super-agents",
        icon: "dispute",
        authority: "bo_dcir_get_dispute_transactions",
        type: "bo",
        id: "superagent-disputes",
        roles: [],
      },
      {
        text: "Pending Refunds",
        route: "/back-office/disputes/pending-manual-refunds",
        icon: "dispute",
        authority: "bo_view_manual_dispute_refund",
        type: "bo",
        id: "pending-dispute-refunds",
        roles: [],
      },

      {
        text: "Bulk Disputes",
        route: "/back-office/bulk-disputes",
        icon: "bulk-disputes",
        authority: "bo_dcir_get_dispute_transactions",
        type: "bo",
        id: "bulk-disputes",
        roles: [],
      },
    ],
  },

  {
    text: "Manage Disputes",
    route: "/front-office/disputes",
    icon: "dispute",
    authorities: [
      "fo_dcir_get_agent_disputes",
      "fo_dcir_get_backoffice_disputes",
      "fo_dcir_update_disputes",
    ],
    roles: [],
    id: "disputes",
    children: [
      {
        text: "Agent Disputes",
        route: "/front-office/agent-disputes",
        icon: "dispute",
        authority: "fo_dcir_get_agent_disputes",
        type: "fo",
        id: "agent-disputes",
      },
      {
        text: "Bank Disputes",
        route: "/front-office/bank-disputes",
        icon: "dispute",
        authority: "fo_dcir_get_backoffice_disputes",
        type: "fo",
        id: "bank-disputes",
      },
      {
        text: "Bulk Dispute Response",
        route: "/front-office/bulk-dispute-response",
        icon: "bulk-disputes",
        authority: "fo_dcir_update_disputes",
        type: "bo",
        id: "bulk-dispute-response",
      },
    ],
  },
  // {
  //   text: "Reconciliation",
  //   icon: "reconciliation",
  //   authority: "all",
  //   roles: [],
  //   id: "reconciliation",
  //   children: [
  //     {
  //       text: "Statement Upload",
  //       route: "/back-office/reconciliation/file-upload",
  //       authority: "all",
  //       type: "bo",
  //       id: "statement-upload",
  //       roles: [],
  //     },
  //     {
  //       text: "Daily Upload",
  //       route: "/back-office/reconciliation/daily-uploads",
  //       authority: "bo_view_reconciliation_uploads",
  //       type: "bo",
  //       id: "daily-upload",
  //       roles: [],
  //     },
  //     {
  //       text: "Credit Reconciliation",
  //       route: "/back-office/reconciliation/credit-reconciliation",
  //       authority: "bo_view_reconciliation_uploads",
  //       type: "bo",
  //       id: "credit-reconciliation",
  //       roles: [],
  //     },
  //     {
  //       text: "Debit Reconciliation",
  //       route: "/back-office/reconciliation/debit-reconciliation",
  //       authority: "bo_view_reconciliation_uploads",
  //       type: "bo",
  //       id: "debit-reconciliation",
  //       roles: [],
  //     },
  //     {
  //       text: "Spreadsheet Configurations",
  //       route: "/back-office/reconciliation/configurations",
  //       authority: "bo_view_reconciliation_uploads",
  //       type: "bo",
  //       id: "recon-configurations",
  //       roles: [],
  //     },
  //   ],
  // },
  {
    text: "Workflow Authorizations",
    icon: "workflow",
    authorities: ["bo_view_all_workflow_requests"],
    id: "workflow-authorizations",
    children: [
      {
        text: "My Requests",
        route: "/back-office/workflow-requests/self",
        authority: "bo_view_all_workflow_requests",
        type: "bo",
        id: "my-requests",
      },
      {
        text: "All Requests",
        route: "/back-office/workflow-requests/all",
        authority: "bo_view_all_workflow_requests",
        type: "bo",
        id: "all-requests",
      },
    ],
  },
  {
    text: "Settings",
    icon: "settings",
    authority: "all",
    roles: [],
    id: "settings",
    children: [
      {
        text: "Change Password",
        route: "/account/change-password",
        authority: "all",
        type: "all",
        id: "change-password",
        roles: [],
      },
      {
        text: "Public Holidays",
        route: "/back-office/public-holidays",
        authority: "bo_dcir_view_public_holidays",
        type: "bo",
        id: "public-holidays",
        roles: [],
      },
      {
        text: "Configurations",
        route: "/back-office/configurations",
        authority: "bo_view_configurations",
        type: "bo",
        id: "configurations",
        roles: [],
      },
      {
        text: "Transit Account Setup",
        route: "/back-office/transit-accounts",
        authority: "bo_view_all_settlement_transit_accounts",
        type: "bo",
        id: "transit-account-setup",
        roles: [],
      },
    ],
  },
];
