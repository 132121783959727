import React, { useCallback, useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Icon } from "components/elements/icons/icon";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Optional } from "components/elements/optional/optional";
import { HELPER } from "utils/helper";
import { ReconFileDownload } from "../statement-upload/download-recon-statement";
import { TransactionByDaysFilter } from "./transactions-by-days-filter";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import { BACKOFFICE_SERVICE } from "../../../../api/services/backoffice-service";

export function ReconTransactionsByDay(props) {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("No results found...");
  const [isSearch, setIsSearch] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [reportDownloadStatus, setReportDownloadStatus] = useState(null);
  const [fileDownloadKey, setFileDownloadKey] = useState(null);
  const reportProgressTimeout = useRef(null);
  const [reportGenerationProgress, setReportGenerationProgress] = useState(0);
  const [tableHeaders, setTableHeaders] = useState([]);

  const getTableHeaders = useCallback((obj) => {
    let properties = [];
    for (const property in obj) {
      if (obj[property]) {
        properties.push(property);
      }
    }
    const possibleTableHeaders = [
      "transactionDate",
      "narration",
      "maskedPan",
      "rrn",
      "stan",
      "amount",
      "terminalId",
      "isDebit",
    ];
    let tableHeaders = [];
    properties.forEach((property) => {
      if (possibleTableHeaders.includes(property)) {
        switch (property) {
          case "transactionDate":
            tableHeaders.push({
              label: "Date",
              value: "transactionDate",
              type: "dateOnly",
            });
            break;
          case "narration":
            tableHeaders.push({
              label: "Narration",
              value: "narration",
            });
            break;
          case "maskedPan":
            tableHeaders.push({
              label: "Masked Pan",
              value: "maskedPan",
            });
            break;
          case "rrn":
            tableHeaders.push({
              label: "Rrn",
              value: "rrn",
            });
            break;
          case "stan":
            tableHeaders.push({
              label: "Stan",
              value: "stan",
            });
            break;
          case "amount":
            tableHeaders.push({
              label: "Amount",
              value: "amount",
            });
            break;
          case "terminalId":
            tableHeaders.push({
              label: "Terminal Id",
              value: "terminalId",
            });
            break;
          case "isDebit":
            tableHeaders.push({
              label: "Transaction Type",
              value: "transactionType",
            });
            break;
          default:
            break;
        }
      }
    });
    setTableHeaders(tableHeaders);
  }, []);

  const getTransactions = useCallback(() => {
    // closeModal();
    setVisible(false);
    setTransactions([]);
    setCurrentPageIndex(0);
    let payload = {
      page: pageNo,
      size: 10,
      transactionDate: props.transactionDate,
    };
    if (searchParams) {
      setIsSearch(true);
      payload = {
        ...payload,
        ...searchParams,
      };
    }
    BACKOFFICE_SERVICE.GET_RECON_FILE_ACCOUNT_ENTRIES(payload)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No results found!");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalElements);
          setTotalPages(data?.result.totalPages);
          getTableHeaders(result[0]);
          result.forEach((e) => {
            arr.push({
              ...e,
              transactionType: e?.isDebit ? "Debit" : "Credit",
            });
          });
          setTransactions(arr);
        }
        setError(null);
        setCurrentPageIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
      });
  }, [pageNo, searchParams, getTableHeaders, props.transactionDate]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const getReportDownloadStatus = useCallback(() => {
    const payload = {
      transactionDate: props.transactionDate,
    };
    BACKOFFICE_SERVICE.CHECK_OR_GENERATE_RECON_REPORT(payload)
      .then((data) => {
        const result = data?.result;
        setReportDownloadStatus(result?.downloadStatus);
        setFileDownloadKey(result?.downloadRecordKey);
        setReportGenerationProgress(result?.downloadPercentage);
        if (
          result.downloadStatus === "IN_PROGRESS" ||
          result.downloadStatus === "PENDING"
        ) {
          reportProgressTimeout.current = setTimeout(() => {
            getReportDownloadStatus();
          }, 5000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [props.transactionDate]);

  useEffect(() => {
    getReportDownloadStatus();

    return () => {
      clearTimeout(reportProgressTimeout.current);
    };
  }, [getReportDownloadStatus]);

  function closeModal(reload) {
    setVisible(false);
    if (reload) {
      getReportDownloadStatus();
    }
  }

  function openModal(index) {
    setCurrentModalIndex(index);
    setVisible(true);
  }

  function reload() {
    setSearchParams(null);
    setPageNo(0);
    setLoading(true);
    setIsSearch(false);
  }

  function currentTabView() {
    switch (currentPageIndex) {
      case 0:
        return <CustomLoader loadingText="Loading" />;
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={0}
            emptyText={emptyText}
            search={isSearch}
            reload={reload}
            error={error}
            items={transactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      default:
        break;
    }
  }

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return (
          <ReconFileDownload
            closeModal={closeModal}
            fileKey={props.fileKey}
            reportDownloadStatus={reportDownloadStatus}
            downloadKey={fileDownloadKey}
            transactionDate={props.transactionDate}
            isTransactionsByDays={true}
          />
        );
      case 1:
        return (
          <TransactionByDaysFilter
            closeModal={closeModal}
            tableHeaders={tableHeaders}
            searchFunction={(params) => setSearchParams(params)}
          />
        );
      default:
        return;
    }
  };

  const transactionsView = () => {
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          paddingTop: "1em",
          paddingBottom: currentPageIndex === 0 ? "8rem" : "2rem",
        }}
        className="p-shadow-1"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <div></div>
        <TabView>
          <TabPanel
            header={`Transactions ( ${moment(props.transactionDate).format(
              "MMM DD, YYYY"
            )} )`}
          >
            <div className="p-my-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: "3rem",
                }}
              >
                <button
                  disabled={loading || transactions?.length === 0}
                  onClick={() => openModal(1, false)}
                  className="filter-button p-mr-4"
                >
                  <i className="pi pi-filter" />
                  <span className="hide-btn-text"> Filter</span>
                </button>
                <Optional show={reportDownloadStatus}>
                  <Optional
                    show={
                      reportDownloadStatus !== "IN_PROGRESS" &&
                      reportDownloadStatus !== "PENDING"
                    }
                  >
                    <button
                      className="download-button"
                      style={{ fontSize: "0.75rem", margin: "1rem 0" }}
                      onClick={() => openModal(0)}
                    >
                      <i className="pi pi-download"></i>{" "}
                      {reportDownloadStatus === "COMPLETED"
                        ? "Download"
                        : "Generate Report"}
                    </button>
                  </Optional>
                  <Optional
                    show={
                      reportDownloadStatus === "IN_PROGRESS" ||
                      reportDownloadStatus === "PENDING"
                    }
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <small>File generation progress:</small>
                      <div
                        style={{
                          width: 50,
                          height: 50,
                        }}
                        className="p-ml-2"
                      >
                        <CircularProgressbar
                          value={reportGenerationProgress}
                          text={`${reportGenerationProgress}%`}
                        />
                      </div>
                    </div>
                  </Optional>
                </Optional>
              </div>
              <div className="">{currentTabView()}</div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  };

  return (
    <div>
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div>{transactionsView()}</div>
    </div>
  );
}
