const GET_DISPUTE_BY_TRANSACTION_KEY = "/frontoffice/dispute/transaction";
const GET_DISPUTES_BY_SUPER_AGENT = "/frontoffice/dispute/by-superagent";
const GET_DISPUTES_BY_BACK_OFFICE = "/frontoffice/dispute/by-backoffice";
const GET_FILTERED_DISPUTES_BY_SUPER_AGENT =
  "/frontoffice/dispute/filter/superagent";
const GET_FILTERED_DISPUTES_BY_BACK_OFFICE =
  "/frontoffice/dispute/filter/backoffice";
const DOWNLOAD_DISPUTES_BY_SUPER_AGENT =
  "/frontoffice/dispute/download/filter/superagent";
const DOWNLOAD_DISPUTES_BACK_OFFICE =
  "/frontoffice/dispute/download/filter/backoffice";
const REOPEN_DISPUTE = "/frontoffice/dispute/reopen";
const CREATE_DISPUTE = "/frontoffice/dispute";
const UPDATE_DISPUTE = "/frontoffice/dispute";
const GET_DISPUTEABLE_CODES = "/frontoffice/dispute/disputable-codes";
const GET_DISPUTE_REFUND_ATTEMPTS =
  "/frontoffice/dispute/dispute-refund-attempts";

export const DISPUTE_ROUTES = {
  GET_DISPUTE_BY_TRANSACTION_KEY,
  GET_DISPUTEABLE_CODES,
  GET_DISPUTES_BY_SUPER_AGENT,
  GET_DISPUTES_BY_BACK_OFFICE,
  GET_FILTERED_DISPUTES_BY_SUPER_AGENT,
  GET_FILTERED_DISPUTES_BY_BACK_OFFICE,
  DOWNLOAD_DISPUTES_BACK_OFFICE,
  DOWNLOAD_DISPUTES_BY_SUPER_AGENT,
  REOPEN_DISPUTE,
  CREATE_DISPUTE,
  UPDATE_DISPUTE,
  GET_DISPUTE_REFUND_ATTEMPTS,
};
