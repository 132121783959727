import { useCallback, useEffect, useState } from "react";
import { API_SERVICE } from "api/service";
import { DetailsBreakDown } from "components/elements/details-break-down/details-break-down";
import { HELPER } from "utils/helper";
import { ChangeUserRole } from "./change-user-role";
import { ChangeUserStatus } from "./change-user-status";
import { ResetPassword } from "./reset-password";
import { UnblockUser } from "./unblock-user";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function ViewUser(props) {
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userStatus, setUserStatus] = useState(null);
  const [userType, setUserType] = useState(null);
  const [username, setUsername] = useState("");
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [userActions, setUserActions] = useState({
    canHavePasswordReset: false,
    canBeUnblocked: false,
    canHaveStatusChanged: false,
  });
  const [userRole, setUserRole] = useState("");

  const getUserDetails = useCallback(() => {
    setLoading(true);
    const url = BACK_OFFICE_API.USER_MANAGEMENT.GET_SINGLE_USER;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${props.userId}`)
      .then((data) => {
        handleResponse(data?.result);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }, [props.userId]);

  function handleResponse(user) {
    let arr = [];
    setUserStatus(user?.isActive ? "Active" : "Inactive");
    setUsername(user?.username);
    setUserType(user?.userType);
    setIsUserBlocked(user?.locked);
    setUserRole(user?.roles[0]?.name);
    arr.push({ label: "Firstname", value: user?.firstname });
    arr.push({ label: "Lastname", value: user?.lastname });
    arr.push({ label: "Username", value: user?.username });
    arr.push({ label: "Company Name", value: user?.companyName });
    arr.push({ label: "Email", value: user?.email });

    arr.push({
      label: "Role(s)",
      value: user?.roles.map((role) => role.friendlyName).join(", "),
    });
    arr.push({
      label: "User Type",
      value: user?.userType,
    });
    arr.push({
      label: "Created On",
      value: HELPER.FORMAT_DATE_IN_MILLISECONDS(user?.createdOn),
    });
    arr.push({
      label: "Profile Status",
      value: user?.isActive
        ? "Active"
        : user?.isActive === null
        ? ""
        : "Inactive",
      itemCase: "active",
    });
    arr.push({
      label: "Password Status",
      value: user?.locked ? "Blocked" : "Ok",
      itemCase: "passwordStatus",
    });
    if (user.userType === "Local User") {
      setUserActions({
        canHavePasswordReset: true,
        canBeUnblocked: user?.locked,
        canHaveStatusChanged: true,
      });
    } else {
      setUserActions({
        canHavePasswordReset: false,
        canBeUnblocked: user?.locked,
        canHaveStatusChanged: false,
      });
    }
    setUserDetails(arr);
  }

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  const userView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <DetailsBreakDown
            reload={getUserDetails}
            error={error}
            loading={loading}
            title="User"
            breakDown={userDetails}
            closeModal={props.closeModal}
            isResetPassword={userActions.canHavePasswordReset}
            resetPassword={() => setCurrentIndex(1)}
            isChangeStatus={userActions.canHaveStatusChanged}
            isUnblockUser={userActions.canBeUnblocked}
            changeStatus={() => setCurrentIndex(2)}
            unblockUser={() => setCurrentIndex(3)}
            userStatus={userStatus}
            isUserBlocked={isUserBlocked}
            isBackofficeUser={true}
            showChangeRoleView={() => setCurrentIndex(4)}
          />
        );
      case 1:
        return (
          <ResetPassword
            username={username}
            userStatus={userStatus}
            closeModal={props.closeModal}
            cancel={() => setCurrentIndex(0)}
          />
        );
      case 2:
        return (
          <ChangeUserStatus
            username={username}
            userStatus={userStatus}
            closeModal={props.closeModal}
            cancel={() => setCurrentIndex(0)}
          />
        );
      case 3:
        return (
          <UnblockUser
            username={username}
            userType={userType}
            closeModal={props.closeModal}
            cancel={() => setCurrentIndex(0)}
          />
        );
      case 4:
        return (
          <ChangeUserRole
            closeModal={props.closeModal}
            cancel={() => setCurrentIndex(0)}
            roles={props?.roles}
            username={username}
            userRole={userRole}
          />
        );
      default:
        break;
    }
  };

  return <>{userView()}</>;
}
