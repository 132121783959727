const GET_PARTICIPANT_REPORTS = "/backoffice/settlement-participant-report";
const DOWNLOAD_PARTICIPANT_REPORT =
  "/backoffice/settlement-participant-report/download";
const FILTER_PARTICIPANT_REPORTS = "/backoffice/settlement-participant-report";
const RETRY_PARTICIPANT_REPORT_REGENERATION =
  "/backoffice/settlement-participant-report/retry-report-generation";

export const PARTICIPANT_REPORT_ROUTES = {
  GET_PARTICIPANT_REPORTS,
  DOWNLOAD_PARTICIPANT_REPORT,
  FILTER_PARTICIPANT_REPORTS,
  RETRY_PARTICIPANT_REPORT_REGENERATION,
};
