import { Icon } from "../icons/icon";

export function TableEmptyView({ emptyText, onReload }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="empty-container">
        <div className="adjust-empty-icon">
          <Icon icon="no-item" />
        </div>
        <div>
          <p className="empty-text-two p-text-center">{emptyText}</p>
        </div>
        <div className="p-mt-3">
          <button onClick={() => onReload()} className="primary-button">
            Reload
          </button>
        </div>
      </div>
    </div>
  );
}
