import React from "react";
import { CustomPagination } from "components/elements/custom-pagination/custom-pagination";
import { Icon } from "components/elements/icons/icon";
import { HELPER } from "utils/helper";

export function BankApisTable(props) {
  const tableView = () => {
    if (props.banksApisData.length) {
      return (
        <div>
          <table>
            <tr className="p-text-center">
              <td className="p-text-bold ">Api Name</td>
              <td className="p-text-bold ">Service Name</td>
              <td className="p-text-bold ">Success Count</td>
              <td className="p-text-bold ">Failure Count</td>
              <td className="p-text-bold ">Avg. Success Response Time (ms)</td>
              <td className="p-text-bold ">Avg. Failure Response Time (ms)</td>
              <td className="p-text-bold ">Date</td>
            </tr>
            {props.banksApisData.map((api, i) => {
              return (
                <tr className="p-text-center" key={api?.apiName + i}>
                  <td>{api?.apiName}</td>
                  <td>{api?.serviceName}</td>
                  <td>{api?.successCount}</td>
                  <td>{api?.failureCount}</td>
                  <td>{api?.averageSuccessResponseTime}</td>
                  <td>{api?.averageFailureResponseTime}</td>
                  <td>
                    {api?.logDate ? HELPER.FORMAT_DATE_ONLY(api?.logDate) : "____"}
                  </td>
                </tr>
              );
            })}
          </table>
          <div className="p-pt-2">
            <CustomPagination
              totalPages={props.totalPages}
              totalItems={props.totalItems}
              currentPage={props.currentPage}
              range={props.range}
              nextPage={props.nextPage}
              prevPage={props.prevPage}
              goToFirstPage={props.goToFirstPage}
              goToLastPage={props.goToLastPage}
              goToPage={props.goToPage}
            />
          </div>
        </div>
      );
    } else {
      if (props.error) {
        return <div>{errorView()}</div>;
      } else {
        return <div>{emptyView()}</div>;
      }
    }
  };

  const errorView = () => {
    return (
      <div>
        <div className="empty-container">
          <Icon icon="error-message-icon" />
          <div>
            <p className="empty-text notification-text">{props.error}</p>
          </div>
          <div className="p-mt-3">
            <button
              onClick={() => props.reload()}
              style={{ width: "10rem" }}
              className="primary-button"
            >
              Reload
            </button>
          </div>
        </div>
      </div>
    );
  };

  const emptyView = () => {
    if (props.search) {
      return (
        <div>
          <div className="empty-container">
            <div className="adjust-empty-icon">
              <Icon icon="no-item" />
            </div>
            <div>
              <p className="empty-text-two">{props.emptyText}</p>
            </div>
            <div className="p-mt-3">
              <button
                onClick={() => props.reload()}
                style={{ width: "10rem" }}
                className="primary-button"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="empty-container">
            <div className="adjust-empty-icon">
              <Icon icon="no-item" />
            </div>
            <div>
              <p className="empty-text-two">{props.emptyText}</p>
            </div>
            <div className="p-mt-3">
              <button
                onClick={() => props.reload()}
                style={{ width: "10rem" }}
                className="primary-button"
              >
                Reload
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return tableView();
}
