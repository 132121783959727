import "./custom-form.css";
import { MultiSelect } from "primereact/multiselect";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";

export function FormMultiselect(props) {
  const showError = () => {
    if (props.error && props.required) {
      return <p className="p-error p-text-left">{props.error}</p>;
    } else <small />;
  };
  return (
    <div
      className={`${
        props.loading ? "caret-hidden" : "caret-shown"
      } dropdown-wrapper`}
    >
      <MultiSelect
        className={props.error ? "validation-error" : ""}
        optionLabel={props.label}
        value={props.value}
        options={props.options}
        onChange={(e) => props.fn(e, props.field)}
        placeholder={props.placeholder}
        display="chip"
        dataKey={props.dataKey}
      />
      {props.loading && (
        <div className="dropdown-loading-container">
          <CustomLoader />
        </div>
      )}
      {showError()}
    </div>
  );
}
