import React, { useContext, useState } from "react";
import { saveAs } from "file-saver";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import moment from "moment";
import { Optional } from "components/elements/optional/optional";
// import { DownloadProgress } from "components/elements/download-progress/download-progress";
import { MainContext } from "App";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function DisputeCreationResultDownload(props) {
  const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  function download() {
    const currentDate = Date.now();
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      batchId: props.batchId,
    });
    const url =
      FRONT_OFFICE_API.BULK_DISPUTE_RESPONSE
        .DOWNLOAD_BULK_DISPUTE_RESPONSE_TRANSACTIONS;
    const isBlob = true;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`, isBlob, mainContext)
      .then((data) => {
        saveAs(
          data,
          `${props.batchId}-${moment(currentDate).format("YYYY-MM-DD")}.xlsx`
        );
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    resetError();
    setLoading(false);
  }

  function resetError() {
    setTimeout(() => {
      setDownloadError(null);
    }, 5000);
  }

  return (
    <div>
      <div className="p-pb-1">
        {downloadError && (
          <CustomToast title="Error" description={downloadError} type="error" />
        )}
      </div>
      <h4 className="p-text-left p-mt-0">Confirm Download</h4>
      <p className="p-text-left">
        Confirm that you want to download these transactions.
      </p>
      <Optional show={loading}>
        {/* <DownloadProgress /> */}
        <CustomLoader loadingText="Loading..." />
      </Optional>
      <Optional show={!loading}>
        <div className="p-mt-4 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.closeModal} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button onClick={download} className="primary-button">
                Download
              </button>
            </div>
          </div>
        </div>
      </Optional>
    </div>
  );
}
