import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { FepAverageResponseTimeChart } from "./average-response-time";
import { FepDailyAverageResponseTimeChart } from "./daily-average-response-times";
import { FepHistoricAverageResponseTimeChart } from "./historic-average-response";

export function FepProcessingMetrics() {
  return (
    <div className="fep-processing-metrics p-text-lef">
      <div className="page-title p-text-left">FEP Processing Metrics</div>
      <div className="p-mt-2">
        <CustomBreadcrumb page="Monitor FEP Processing Metrics" />
      </div>
      <div className="p-grid p-mt-5">
        <div className="p-col-6">
          <FepAverageResponseTimeChart />
        </div>
        <div className="p-col-12">
          <FepDailyAverageResponseTimeChart />
        </div>
      </div>
      <div className="p-grid p-mt-5">
        {/* <div className="p-col-4"><FepAverageResponseTimeChart /></div> */}

        <div className="p-col-12">
          <FepHistoricAverageResponseTimeChart />
        </div>
      </div>
    </div>
  );
}
