import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import { DownloadConfirmation } from "./download-confirmation";
import { API_SERVICE } from "api/service";
// import ErrorBoundary from "../error-boundary/error-boundary";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";

export function TransactionReports(props) {
  const toast = useRef(null);
  // const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const tableHeaders = [
    // { label: "Report Owner", value: "owner" },
    { label: "Report Generation Date", value: "createdAt" },
    { label: "Completion Date", value: "completedAt" },
    {
      label: "Percentage Proccessed",
      value: "percentageProcessed",
    },
    { label: "Report Generation Status", value: "reportGenerationStatus" },
    // {
    //   label: "Retention Status",
    //   value: "retentionStatus",
    // },
    { label: "Actions", value: "actions", type: "reportDownload" },
  ];
  const reportAuthorities = [
    {
      label: "DOWNLOAD",
      value:
        props.type === "fo"
          ? "fo_dcir_get_all_transactions"
          : "bo_dcir_get_all_transactions",
    },
  ];
  const [transactionReports, setTransactionReports] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [report, setReport] = useState(null);
  // const [hasIncompleteReport, setHasIncompleteReport] = useState(false);
  const refreshTransactionReports = useRef(null);
  const hasIncompleteReport = useRef(null);

  const openAction = useCallback((e, action, isMobile) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "DOWNLOAD":
        // setSelectedParticipant(e);
        setReport(e);
        openModal(1, isMobile);
        break;
    }
  }, []);

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  useEffect(() => {
    function reloadTransactionReports() {
      refreshTransactionReports.current = setTimeout(() => {
        if (hasIncompleteReport.current) {
          // setHasIncompleteReport(false);
          hasIncompleteReport.current = false;
          getTransactionReports(true);
        }
      }, 5000);
    }

    function getTransactionReports(isRefresh) {
      if (!isRefresh) {
        setCurrentIndex(0);
      }
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
      });
      const frontofficeUrl =
        FRONT_OFFICE_API.TRANSACTION_REPORT.GET_TRANSACTION_REPORTS;
      const backofficeUrl =
        BACK_OFFICE_API.TRANSACTION_REPORT.GET_TRANSACTION_REPORTS;
      const url = props.type === "fo" ? frontofficeUrl : backofficeUrl;
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          if (!data?.result?.content?.length) {
            setEmptyText("No reports found ...");
          } else {
            let arr = [];
            setTotalItems(data?.result?.totalElements);
            setTotalPages(data?.result.totalPages);
            data?.result?.content?.forEach((e) => {
              if (
                e?.reportGenerationStatus !== "COMPLETED" &&
                e?.reportGenerationStatus !== "FAILED"
              ) {
                // setHasIncompleteReport(true);
                hasIncompleteReport.current = true;
              }
              arr.push({
                ...e,
                percentageProcessed:
                  e?.reportGenerationStatus === "FAILED"
                    ? null
                    : e?.percentageProcessed,
                actions:
                  e?.reportGenerationStatus === "COMPLETED" &&
                  e?.retentionStatus === "AVAILABLE"
                    ? "DOWNLOAD"
                    : "",
                detailsFunction: openAction,
              });
            });
            setTransactionReports(arr);
          }
          setError(null);
          setCurrentIndex(1);
          reloadTransactionReports();
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setCurrentIndex(1);
        });
    }

    getTransactionReports();

    return () => {
      clearTimeout(refreshTransactionReports.current);
    };
  }, [pageNo, props.type, openAction]);

  function reload() {
    // setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    // getTransactionReports();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload === true) {
      reload();
    }
  }

  // function saveSearchParam(params) {
  //   setPageNo(0);
  //   setSearchParams(params);
  // }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <DownloadConfirmation
            report={report}
            closeModal={closeModal}
            type={props.type}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <DownloadConfirmation
            report={report}
            closeModal={closeModal}
            type={props.type}
          />
        );
    }
  };

  const transactionReportsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading transaction reports..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            authorities={reportAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={transactionReports}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        {/* <div className="page-title p-text-left">Transaction Reports</div> */}
        <div className="page-header">
          <div className="page-title p-text-left">Transaction Reports</div>
          <UserManualViewerButton 
            fileName={
              props.type === "fo"
                ? "front_office_transactions_report"
                : "backoffice_transactions_report"
            }
          />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Manage Transaction Reports"} />
        </div>
        <div className="mobile-screen"></div>
        <div className="p-mt-5">{transactionReportsView()}</div>
      </div>
    </ErrorBoundary>
  );
}
