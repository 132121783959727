import { MONITORING_SERVICE } from "api/services/monitoring-service";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import React, { useEffect, useMemo, useState } from "react";
import { HELPER } from "utils/helper";
import { CUSTOM_VALIDATION } from "utils/validation";

const AlertConfigurationForm = (props) => {
  const [validForm, setValidForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorDetails, setErrorDetails] = useState({
    message: null,
    title: null,
  });
  const [updatedAlert, setUpdateAlert] = useState({
    windowSize: null,
    emailRecipients: null,
    thresholdValue: null,
    coolDownPeriod: null,
    logicalOperator: null,
  });
  const [updatedAlertError, setUpdateAlertError] = useState({
    windowSize: null,
    emailRecipients: null,
    thresholdValue: null,
    coolDownPeriod: null,
  });
  const LOGICAL_OPERATORS = useMemo(
    () => [
      {
        code: "LESS_THAN",
      },
      {
        code: "GREATER_THAN",
      },
    ],
    []
  );

  const handleError = (error) => {
    const message = error?.data?.message ?? HELPER.PROCESS_ERROR(error);
    setErrorDetails({ message, title: "Error" });
    setLoading(false);
  };

  const handleSuccess = () => {
    props.onSuccess();
    setErrorDetails({
      message: null,
      title: null,
    });
    setLoading(false);
  };

  const handleSubmission = async (payload) => {
    try {
      const url = `/alert-configs/update?metricCode=${props?.alert?.metricCode}`;
      await MONITORING_SERVICE.MAKE_PUT_REQUEST(url, payload);
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  const submit = () => {
    setLoading(true);
    const formattedEmailRecipients = refactorEmailRecipients(
      updatedAlert.emailRecipients
    );
    const formattedPayload = {
      metricName: props?.alert?.metricName,
      ...updatedAlert,
      logicalOperator: updatedAlert?.logicalOperator?.code,
      emailRecipients: formattedEmailRecipients,
    };
    handleSubmission(formattedPayload);
  };

  const refactorEmailRecipients = (email) => {
    const refactoredEmailRecipients = email.split(",").map((e) => e.trim());

    return refactoredEmailRecipients;
  };

  const validateInput = (e, name, type, refineName, required) => {
    let value = e?.target?.value;

    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setUpdateAlert({ ...updatedAlert, [name]: value });
      setUpdateAlertError({ ...updatedAlertError, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setUpdateAlertError({ ...updatedAlertError, [name]: errorMessage });
    }
  };

  const validateDropdown = (e, name) => {
    const value = e.target.value;
    if (value) {
      setUpdateAlertError({ ...updatedAlertError, [name]: "" });
      setUpdateAlert({ ...updatedAlert, [name]: value });
    } else {
      let errorMessage = "Select logical operator";
      setUpdateAlertError({ ...updatedAlertError, [name]: errorMessage });
    }
  };

  useEffect(() => {
    const formattedLogicalOperator = LOGICAL_OPERATORS.find(
      (item) => item.code === props?.alert?.logicalOperator
    );
    const formattedEmailRecipients = props?.alert?.emailRecipients.join(",");

    setUpdateAlert({
      windowSize: props?.alert?.windowSize,
      thresholdValue: props?.alert?.thresholdValue,
      coolDownPeriod: props?.alert?.coolDownPeriod,
      emailRecipients: formattedEmailRecipients,
      logicalOperator: formattedLogicalOperator,
    });
  }, [props.alert, LOGICAL_OPERATORS]);

  useEffect(() => {
    let mounted = true;

    const validateForm = () => {
      const validForm = CUSTOM_VALIDATION.VALID_OBJ(updatedAlert, 5);
      const validErrorForm =
        !CUSTOM_VALIDATION.VALID_OBJ_ANY(updatedAlertError);
      setValidForm(validForm && validErrorForm);
    };
    if (mounted) {
      validateForm();
    }
    return () => {
      mounted = false;
    };
  }, [updatedAlert, updatedAlertError]);

  const viewAlert = () => {
    if (errorDetails.title) {
      return (
        <div>
          <CustomToast
            title={errorDetails.title}
            description={errorDetails.message}
            type="error"
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderButtons = () => {
    return (
      <div className="p-mt-5">
        <div className="p-grid">
          {loading ? (
            <div className="p-col-12 ">
              <CustomLoader loadingText="Submitting..." />
            </div>
          ) : (
            <>
              <div className={loading ? "p-col-12" : "p-col-6"}>
                <button onClick={props.closeModal} className="secondary-button">
                  Cancel
                </button>
              </div>
              <div className={loading ? "p-col-12" : "p-col-6"}>
                <button
                  disabled={!validForm}
                  onClick={submit}
                  className="primary-button"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className="custom-modal-title p-text-left">
          Edit Alert Configuration
        </div>
        <div className="custom-dialog-subtitle-container p-mb-2" />
        <div className="p-pb-1">{viewAlert()}</div>
        <div className="p-grid">
          <div className="p-col-12">
            <FormInput
              value={updatedAlert["windowSize"]}
              field="windowSize"
              inputType="number"
              type="NUMBER"
              error={updatedAlertError["windowSize"]}
              fn={validateInput}
              loading={loading}
              placeholder="Window Size (mins)"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={updatedAlert["coolDownPeriod"]}
              field="coolDownPeriod"
              inputType="number"
              type="NUMBER"
              error={updatedAlertError["coolDownPeriod"]}
              fn={validateInput}
              loading={loading}
              placeholder="Cool down period (mins)"
            />
          </div>
          <div className="p-col-12">
            <FormInput
              value={updatedAlert["emailRecipients"]}
              field="emailRecipients"
              type="EMAIL"
              error={updatedAlertError["emailRecipients"]}
              fn={validateInput}
              loading={loading}
              placeholder="Email recipients"
            />
            <p className=" text-small d-flex align-center text-light-grey p-my-1">
              <i
                style={{ color: "primary" }}
                className="pi pi-info-circle p-pr-1"
              ></i>
              <span>
                A comma-separated list e.g. john.doe@mail.com,mike.doe@mail.com
              </span>
            </p>
          </div>
          <div className="p-col-12">
            <FormInput
              field="thresholdValue"
              type="NUMBER"
              value={updatedAlert["thresholdValue"]}
              error={updatedAlertError["thresholdValue"]}
              fn={validateInput}
              loading={loading}
              placeholder="Threshold value"
            />
          </div>
          <div className="p-col-12">
            <FormDropdown
              label="code"
              field="logicalOperator"
              error={updatedAlertError["logicalOperator"]}
              disabled={loading}
              value={updatedAlert["logicalOperator"]}
              fn={validateDropdown}
              options={LOGICAL_OPERATORS}
              placeholder="Select logical operator"
            />
          </div>
          <div className="p-col-12">{renderButtons()}</div>
        </div>
      </div>
    </div>
  );
};

export default AlertConfigurationForm;
