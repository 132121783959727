const GET_SETTLEMENT_PARTICIPANTS = "/backoffice/settlement-participant";
const VIEW_PARTICIPANT = "/backoffice/settlement-participant";
const UPDATE_SETTLEMENT_PARTICIPANT = "/backoffice/settlement-participant";
const GET_NON_GLOBAL_SETTLEMENT_PARTICIPANTTS =
  "/backoffice/settlement-participant/all/non-global";
const CREATE_SETTLEMENT_PARTICIPANT = "/backoffice/settlement-participant";
const DELETE_SETTLEMENT_PARTICIPANT =
  "/backoffice/settlement-participant/remove";

export const PARTICIPANT_ROUTES = {
  GET_SETTLEMENT_PARTICIPANTS,
  GET_NON_GLOBAL_SETTLEMENT_PARTICIPANTTS,
  CREATE_SETTLEMENT_PARTICIPANT,
  DELETE_SETTLEMENT_PARTICIPANT,
  VIEW_PARTICIPANT,
  UPDATE_SETTLEMENT_PARTICIPANT,
};
