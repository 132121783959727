import { COMMON_API } from "api/routes/common/index";
import { API_SERVICE } from "api/service";
import { useLayoutEffect, useState } from "react";

export function useBankFeature(feature) {
  const [hasBankFeature, setHasBankFeature] = useState(null);
  useLayoutEffect(() => {
    function getBankFeatures() {
      API_SERVICE.MAKE_GET_REQUEST(COMMON_API.CONFIG_FEATURES.GET_BANK_FEATURES)
        .then((res) => {
          const result = res?.result;
          setHasBankFeature(result[feature]);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    getBankFeatures();
  }, [feature]);
  return hasBankFeature;
}
