import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Average Response Time For Today",
    },
  },
};

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const labels = [
  "8:00am",
  "8:05am",
  "8:10am",
  "8:15am",
  "8:20am",
  "8:25am",
  "8:30am",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 1, max: 5 })),
      borderColor: "#7bd54f",
      backgroundColor: "#7bd54f",
    },
    // {
    //   label: 'Dataset 2',
    //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
    //   borderColor: 'rgb(53, 162, 235)',
    //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
    // },
  ],
};
export function FepDailyAverageResponseTimeChart() {
  return (
    <CustomCard>
      <p>Daily Average Response Time</p>
      <div>
        <Line options={options} data={data} />
      </div>
    </CustomCard>
  );
}
