import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div style={{ fontSize: ".9375rem" }}>
          <p style={{ fontWeight: "500" }}>Something isn't working.</p>
          <p style={{ color: "#b2b4b8" }}>
            This may be because of a technical error that we're working to fix.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
