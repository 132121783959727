import "./side-nav.css";
import React, { useContext, useEffect, useState } from "react";
import { Icon } from "components/elements/icons/icon";
import { Accordion, AccordionTab } from "primereact/accordion";
import { NavItems } from "utils/models/nav-items";
import { Link, useHistory } from "react-router-dom";
import { MainContext } from "../../../App";
import { HELPER } from "utils/helper";
import { STORAGE } from "utils/storage";
import { version } from "../../../../package.json";
import { Optional } from "components/elements/optional/optional";
import { useSingleAppConfigurationValue } from "hooks/use-single-configuration-value";
import { BACKOFFICE_CONFIGS } from "utils/constants";

export function SideNav() {
  const mainContext = useContext(MainContext);
  const isManualSettlementPaymentEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_MANUAL_SETTLEMENT_PAYMENT_ENABLED
  );
  const isManualDisputeRefundEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_MANUAL_DISPUTE_REFUND_ENABLED
  );
  let history = useHistory();
  const [colorIndex, setColorIndex] = useState(0);
  const defaultColor = "#6D8C98";
  const defaultActiveColor = "#0371AC";
  const [menuClicked, setMenuClicked] = useState(
    mainContext?.mainState?.selectedSideNav
  );
  const [windowWidth, setWindowWidth] = useState(900);
  const role = HELPER.GET_ROLE();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const sideNav = STORAGE.GET_CURRENT_NAV();
      setMenuClicked(sideNav.selectedSideNav);
      setColorIndex(sideNav.selectedSideNavIndex);
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function setNavColorOnMouseEnter(index, menuName) {
    if (menuName !== menuClicked) {
      setColorIndex(index);
    }
  }

  function setNavColorOnMouseLeave(index, menuName) {
    if (menuName !== menuClicked) {
      setColorIndex(mainContext?.mainState?.selectedSideNavIndex);
    }
  }

  function setNavColorOnClicked(navId, clickedMenu, route) {
    setMenuClicked(clickedMenu);
    mainContext.mainDispatch({
      type: "CLICKED_SIDE_NAV",
      selectedSideNav: clickedMenu,
      selectedSideNavIndex: navId,
    });
    STORAGE.STORE_CURRENT_PAGE(clickedMenu, navId);
    history.push(route);
  }

  function closeSideNav() {
    const sidenav = document.getElementById("mySidenav");
    sidenav.style.width = "0";
  }

  const sideMenuItems = () => {
    return NavItems.map((navItem) => {
      if (navItem.children.length === 0) {
        const hasAuthority = HELPER.HAS_AUTHORITY(navItem.authority);
        return (
          <Optional show={hasAuthority} key={`${navItem.id}-${navItem.type}`}>
            <div id={navItem.id}>
              <div
                onClick={() =>
                  setNavColorOnClicked(navItem.id, navItem.text, navItem.route)
                }
                onMouseLeave={() =>
                  setNavColorOnMouseLeave(navItem.id, navItem.text)
                }
                onMouseEnter={() =>
                  setNavColorOnMouseEnter(navItem.id, navItem.text)
                }
                className={
                  menuClicked?.toLowerCase() === navItem.text.toLowerCase()
                    ? "singleNavContainer singleNavContainer-active"
                    : "singleNavContainer"
                }
              >
                <div className="p-grid p-text-left">
                  <div className="p-col-2">
                    <div className="singleNavContainer-icon">
                      {navItem.primeIcon ? (
                        <i
                          className={`pi ${navItem.primeIcon}`}
                          style={
                            menuClicked?.toLowerCase() ===
                            navItem.text.toLowerCase()
                              ? { color: defaultActiveColor }
                              : colorIndex === navItem.id
                              ? { color: defaultActiveColor }
                              : { color: defaultColor }
                          }
                        ></i>
                      ) : (
                        <Icon
                          color={
                            menuClicked?.toLowerCase() ===
                            navItem.text.toLowerCase()
                              ? defaultActiveColor
                              : colorIndex === navItem.id
                              ? defaultActiveColor
                              : defaultColor
                          }
                          icon={navItem.icon}
                        />
                      )}
                    </div>
                  </div>
                  <div className="p-col-10">
                    <span
                      style={{
                        color:
                          menuClicked?.toLowerCase() ===
                          navItem.text.toLowerCase()
                            ? defaultActiveColor
                            : colorIndex === navItem.id
                            ? defaultActiveColor
                            : defaultColor,
                      }}
                      className="singleNavContainer-text adjust-single-nav"
                    >
                      {navItem.text}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Optional>
        );
      } else {
        let hasAuthority;
        if (navItem.authorities) {
          navItem.authorities.forEach((authority) => {
            if (HELPER.HAS_AUTHORITY(authority)) {
              hasAuthority = true;
            }
          });
        } else {
          hasAuthority = HELPER.HAS_AUTHORITY(navItem.authority);
        }

        return (
          hasAuthority && (
            <Accordion key={`${navItem.id}`}>
              <AccordionTab
                header={
                  <React.Fragment>
                    <span className="d-fle">
                      <span
                        className={`custom-tab-text d-flex align-center custom-font-family ${
                          navItem.icon === "settings" ? "settings" : ""
                        }`}
                        style={{
                          color: defaultColor,
                        }}
                      >
                        <Icon color={defaultColor} icon={navItem.icon} />
                        <p
                          style={{
                            paddingLeft: "1.2rem",
                          }}
                        >
                          {navItem.text}
                        </p>
                      </span>
                    </span>
                  </React.Fragment>
                }
              >
                {navItem.children.map((subNav) => {
                  let hasAuthority = false;
                  if (subNav.authorities) {
                    subNav.authorities.forEach((authority) => {
                      if (HELPER.HAS_AUTHORITY(authority)) {
                        hasAuthority = true;
                      }
                    });
                  } else {
                    hasAuthority = HELPER.HAS_AUTHORITY(subNav.authority);
                  }
                  if (
                    subNav.id === "pending-dispute-refunds" &&
                    isManualDisputeRefundEnabled === "false"
                  ) {
                    return "";
                  }
                  if (
                    subNav.id === "pending-settlements" &&
                    isManualSettlementPaymentEnabled === "false"
                  ) {
                    return "";
                  }
                  if (hasAuthority) {
                    return (
                      <Link
                        key={`${subNav.id}-${subNav.type}`}
                        to={`${subNav.route}`}
                      >
                        <div
                          onClick={() =>
                            setNavColorOnClicked(
                              subNav.id,
                              subNav.text,
                              subNav.route
                            )
                          }
                          className={
                            menuClicked === subNav?.text
                              ? "custom-tab-content p-mt-1 custom-tab-active"
                              : "custom-tab-content"
                          }
                          id={subNav.id}
                        >
                          <span>
                            <span className="custom-font-family custom-tab-title">
                              {subNav?.text}
                            </span>
                          </span>
                        </div>
                      </Link>
                    );
                  } else {
                    return "";
                  }
                })}
              </AccordionTab>
            </Accordion>
          )
        );
      }
    });
  };

  return (
    <div id="mySidenav" className="sidenav p-shadow-1">
      {windowWidth < 900 && (
        <div style={{ float: "right" }} className="p-mt-2 p-mr-2">
          <button className="bare-button" onClick={closeSideNav}>
            <Icon icon="go-back-icon" />
          </button>
        </div>
      )}

      <div className="p-grid">
        <div className="p-col-12">
          <div className="logo">
            <Icon icon="logo" />
          </div>
          <h1 className="app-name top">Direct Card Issuer Routing</h1>
        </div>
        <div className="p-col-12 p-mt-3">
          <div className="nav-profile-container">
            <div className="p-grid">
              <div className="p-col-12">
                <div className="nav-profile-text-container">
                  <div>
                    <span className="nav-profile-title">
                      {mainContext?.mainState?.name
                        ? mainContext?.mainState?.name
                        : ""}
                    </span>
                  </div>
                  <div>
                    <span className="nav-profile-subtitle">{role}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-pt-0">
          <a
            className="nav-profile-title version underline p-mt-0"
            // href="https://teamapt.atlassian.net/wiki/spaces/DUTM/pages/707198977/DCIR+Change+Log"
            target="_blank"
            rel="noreferrer"
          >
            Version {version}
          </a>
        </div>
        <div className="p-col-12">
          <div>{sideMenuItems()}</div>
        </div>
      </div>
    </div>
  );
}
