import { BACKOFFICE_CONFIGS } from "utils/constants";
import { useSingleAppConfigurationValue } from "./use-single-configuration-value";

export function useGlNameEnquiryOption() {
  const glNameEnquiryOption = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.GL_NAME_ENQUIRY_OPTION
  );

  return glNameEnquiryOption;
}
