import "./change-password.css";
import { useState, useEffect, useRef, useContext } from "react";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";
import { MainContext } from "../../../App";
import { STORAGE } from "utils/storage";
import { FormInput } from "components/form/form-input";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import ErrorBoundary from "../error-boundary/error-boundary";
import { UserManualViewerButton } from "../user-manual-viewer/user-manual-viewer-button";
import { CUSTOM_VALIDATION } from "utils/validation";
import { PASSWORD_VALIDATION_ERROR_MESSAGE } from "utils/constants";
import { COMMON_API } from "../../../api/routes/common/index";

export function ChangePassword() {
  const history = useHistory();
  const mainContext = useContext(MainContext);
  const toast = useRef(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText] = useState("Updating...");
  const [isValidForm, setIsValidForm] = useState(false);
  const [passwordMismatchError, setPasswordMismatchError] = useState(null);
  const [passwordValidationError, setPasswordValidationError] = useState(null);

  useEffect(() => {
    function validateForm() {
      if (
        oldPassword.length &&
        newPassword.length &&
        newPassword === passwordConfirmation &&
        !passwordValidationError
      ) {
        setIsValidForm(true);
        setPasswordMismatchError(false);
      } else {
        setIsValidForm(false);
        if (
          passwordConfirmation.length &&
          newPassword !== passwordConfirmation
        ) {
          setPasswordMismatchError("Passwords do not match!");
        }
      }
    }
    validateForm();
  }, [newPassword, passwordConfirmation, oldPassword, passwordValidationError]);

  function handleOldPasswordChange(e) {
    setOldPassword(e.target.value);
  }

  function handleNewPasswordChange(e) {
    const value = e?.target?.value;
    setNewPassword(value);
    if (CUSTOM_VALIDATION.IS_VALID_PASSWORD(value)) {
      setPasswordValidationError(null);
    } else {
      setPasswordValidationError(PASSWORD_VALIDATION_ERROR_MESSAGE);
    }
  }

  function handlePasswordConfirmationChange(e) {
    setPasswordConfirmation(e.target.value);
  }

  function submit(e) {
    e.preventDefault();
    setLoading(true);
    const payload = {
      oldPassword,
      newPassword,
    };
    const { CHANGE_PASSWORD } = COMMON_API.AUTH;
    API_SERVICE.MAKE_PUT_REQUEST(CHANGE_PASSWORD, payload)
      .then((data) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Password successfully changed!",
          life: 10000,
        });
        setLoading(false);
        setTimeout(() => {
          mainContext.mainDispatch({
            type: "SET_ALERT",
            alert: {
              title: "Password Changed",
              description:
                "Your password has been changed. You can now login with your new password.",
              type: "success",
              showAlert: true,
            },
          });
          STORAGE.CLEAR_STORAGE();
          history.push("/login");
        }, 1000);
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
        setLoading(false);
      });
  }

  return (
    <ErrorBoundary>
      <div className="change-password-wrapper">
        <div>
          <Toast ref={toast} />
        </div>
        {/* <div className="page-title p-text-left">Change Password</div> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="page-title p-text-left">Change Password</div>
          <UserManualViewerButton fileName="settings_changepassword" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Change Password"} />
        </div>
        <div className="card p-mt-5">
          <form onSubmit={submit}>
            <div className="form-container">
              <div className="">
                <p className="title">Fill form below to change your password</p>
                <p className="custom-dialog-subtitle">* Required Fields</p>
              </div>
              <div>
                <div>
                  <FormInput
                    value={oldPassword}
                    required={true}
                    type="INPUT"
                    error={false}
                    fn={handleOldPasswordChange}
                    loading={loading}
                    placeholder="Enter current password *"
                    inputType="password"
                  />
                </div>
                <br />
                <div>
                  <FormInput
                    value={newPassword}
                    required={true}
                    type="INPUT"
                    error={passwordValidationError}
                    fn={handleNewPasswordChange}
                    loading={loading}
                    placeholder="Enter new password *"
                    inputType="password"
                  />
                </div>
                <br />
                <div>
                  <FormInput
                    value={passwordConfirmation}
                    required={true}
                    type="INPUT"
                    error={passwordMismatchError}
                    fn={handlePasswordConfirmationChange}
                    loading={loading}
                    placeholder="Confirm new password *"
                    inputType="password"
                  />
                </div>
                <div>
                  <div className="p-mt-5">
                    {!loading ? (
                      <div>
                        <button
                          disabled={!isValidForm}
                          onClick={submit}
                          className="primary-button"
                        >
                          Submit
                        </button>
                      </div>
                    ) : (
                      <div>
                        <CustomLoader loadingText={loadingText} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ErrorBoundary>
  );
}
