const CREATE_PUBLIC_HOLIDAY = "/backoffice/public-holiday";
const GET_ALL_PUBLIC_HOLIDAYS = "/backoffice/public-holiday/all";
const FILTER_PUBLIC_HOLIDAYS = "/backoffice/public-holiday/filter";
const DELETE_PUBLIC_HOLIDAY = "/backoffice/public-holiday";
const UPDATE_PUBLIC_HOLIDAY = "/backoffice/public-holiday";

export const PUBLIC_HOLIDAY_ROUTES = {
  CREATE_PUBLIC_HOLIDAY,
  GET_ALL_PUBLIC_HOLIDAYS,
  FILTER_PUBLIC_HOLIDAYS,
  DELETE_PUBLIC_HOLIDAY,
  UPDATE_PUBLIC_HOLIDAY,
};
