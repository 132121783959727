import axios from "axios";
import { CONFIG } from "config/config";
import { LOCAL_STORAGE } from "utils/models/utilities";

export const monitoringApiClient = axios.create({
  baseURL: `${CONFIG.MONITORING_HOST}/api/v1`,
});

const getBasicHeader = () => {
  const token = localStorage.getItem(LOCAL_STORAGE.MONITORING_TOKEN);
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

function LOGIN(payload) {
  return new Promise((resolve, reject) => {
    monitoringApiClient
      .post(`/auth`, payload)
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_GET_REQUEST(url, params, isBlob = false) {
  const header = getBasicHeader();
  url = params ? `${url}?${params}` : url;
  return new Promise((resolve, reject) => {
    monitoringApiClient
      .get(url, {
        headers: header,
        ...(isBlob && { responseType: "blob" }),
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_PUT_REQUEST(url, payload, isBlob) {
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    monitoringApiClient
      .put(url, payload, {
        headers: header,
        ...(isBlob && { responseType: "blob" }),
      })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_POST_REQUEST(url, payload) {
  const header = getBasicHeader();
  return new Promise((resolve, reject) => {
    monitoringApiClient
      .post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const MONITORING_SERVICE = {
  MAKE_GET_REQUEST,
  MAKE_POST_REQUEST,
  MAKE_PUT_REQUEST,
  LOGIN,
};
