import { BULK_DISPUTE_ROUTES } from "./bulk-disputes";
import { BULK_SETTLEMENT_ROUTES } from "./bulk-settlements";
import { CHARGE_TYPE_ROUTES } from "./charge-types";
import { CONFIGURATION_ROUTES } from "./configurations";
import { DASHBOARD_ANALYTICS_ROUTES } from "./dashboard";
import { DISPUTE_ROUTES } from "./disputes";
import { MANUAL_DISPUTE_REFUND_ROUTES } from "./manual-dispute-refunds";
import { MANUAL_SETTLEMENT_PAYMENT_ROUTES } from "./manual-settlement-payments";
import { PARTICIPANT_REPORT_ROUTES } from "./participant-reports";
import { PARTICIPANT_USERS_ROUTES } from "./participant-users";
import { PARTICIPANT_ROUTES } from "./participants";
import { PUBLIC_HOLIDAY_ROUTES } from "./public-hoildays";
import { REQUERY_MANAGEMENT_ROUTES } from "./requery-management";
import { SUPER_AGENT_USERS_ROUTES } from "./super-agent-users";
import { SUPER_AGENT_ROUTES } from "./super-agents";
import { TRANSACTION_REPORT_ROUTES } from "./transaction-reports";
import { TRANSACTIONS_ROUTES } from "./transactions";
import { TRANSIT_ACCOUNT_ROUTES } from "./transit-accounts";
import { USER_MANGEMENT_ROUTES } from "./user-management";

export const BACK_OFFICE_API = {
  BULK_DISPUTE: {
    ...BULK_DISPUTE_ROUTES,
  },
  BULK_SETTLEMENT: {
    ...BULK_SETTLEMENT_ROUTES,
  },
  CHARGE_TYPE: {
    ...CHARGE_TYPE_ROUTES,
  },
  CONFIGURATION: {
    ...CONFIGURATION_ROUTES,
  },
  DASHBOARD: {
    ...DASHBOARD_ANALYTICS_ROUTES,
  },
  DISPUTE: {
    ...DISPUTE_ROUTES,
  },
  MANUAL_DISPUTE_REFUND: {
    ...MANUAL_DISPUTE_REFUND_ROUTES,
  },
  MANUAL_SETTLEMENT_PAYMENT: {
    ...MANUAL_SETTLEMENT_PAYMENT_ROUTES,
  },
  PARTICIPANT_REPORT: {
    ...PARTICIPANT_REPORT_ROUTES,
  },
  PARTICIPANT_USER: {
    ...PARTICIPANT_USERS_ROUTES,
  },
  PARTICIPANT: {
    ...PARTICIPANT_ROUTES,
  },
  PUBLIC_HOLIDAY: {
    ...PUBLIC_HOLIDAY_ROUTES,
  },
  REQUERY_MANAGEMENT: {
    ...REQUERY_MANAGEMENT_ROUTES,
  },
  SUPER_AGENT_USER: {
    ...SUPER_AGENT_USERS_ROUTES,
  },
  SUPER_AGENT: {
    ...SUPER_AGENT_ROUTES,
  },
  TRANSACTION: {
    ...TRANSACTIONS_ROUTES,
  },
  TRANSACTION_REPORT: {
    ...TRANSACTION_REPORT_ROUTES,
  },
  TRANSIT_ACCOUNT: {
    ...TRANSIT_ACCOUNT_ROUTES,
  },
  USER_MANAGEMENT: {
    ...USER_MANGEMENT_ROUTES,
  },
};
