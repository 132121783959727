import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Icon } from "components/elements/icons/icon";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { ReconFileTransactions } from "./recon-file-transactions";
import "./styles.css";
import { DisputeBreakdown } from "../../disputes/dispute-breakdown";
import { FormRadioInput } from "components/form/form-radio-input";
import { HELPER } from "utils/helper";
import moment from "moment";

export function TransactionReconciliationBreakdown(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [metricType, setMetricType] = useState("count");
  const [visible, setVisible] = useState(false);
  const [currentModalIndex] = useState(0);
  // const transactionsProps = {
  //   transactionIndicator: props.transactionIndicator,
  //   transactionDate: props.transactionDate,
  // };
  const [totalInDB, setTotalInDB] = useState();
  const [totalCredit, setTotalCredit] = useState();

  const [disputeBreakdownProps, setDisputeBreakdownProps] = useState({
    loggedBy: "loggedBy",
    disputeExpiringDate: "",
    disputeStatus: "",
    logCode: "",
    disputeDetails: "",
    goBack: goBack,
    mobile: "",
    receipt: "",
    transactionSearchKey: "",
    isRecon: true,
  });

  useEffect(() => {
    const fileDetails = props.fileDetails;
    if (metricType === "count") {
      setTotalInDB(fileDetails.totalDbCount);
      setTotalCredit(fileDetails.totalAccountCount);
    } else {
      setTotalInDB(fileDetails.totalDbValue);
      setTotalCredit(fileDetails.totalAccountValue);
    }
  }, [metricType, props.fileDetails]);

  function closeModal() {
    setVisible(false);
  }

  function showDisputeDetails(logCode) {
    setDisputeBreakdownProps({ ...disputeBreakdownProps, logCode: logCode });
    setCurrentIndex(1);
  }

  function goBack() {
    setCurrentIndex(0);
  }

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return <div />;
      case 1:
        return <div />;
      default:
        return;
    }
  };

  const reconciliationBreakdownView = () => {
    if (!currentIndex) {
      return (
        <div
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "1em",
            paddingBottom: currentIndex === 0 ? "8rem" : "2rem",
          }}
          className="p-shadow-1"
        >
          <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
            <span onClick={props?.goBack} className="add-cursor">
              <span>
                <Icon icon="go-back-icon" />
              </span>
            </span>
          </div>
          <div className="p-text-left p-pl-4 p-mb-3">
            <p className="text-small underline">
              {moment(props.transactionDate).format("MMM DD, YYYY")}
            </p>
          </div>
          <div className="breakdown-wrapper">
            <div className="flex default">
              <div className="recon breakdown-card">
                <h3>
                  {metricType === "count"
                    ? totalCredit
                    : HELPER.TO_CURRENCY_FORMAT(totalCredit)}
                </h3>
                <small>Total Credit to the account</small>
              </div>
              <div className="recon breakdown-card">
                <h3>
                  {metricType === "count"
                    ? totalInDB
                    : HELPER.TO_CURRENCY_FORMAT(totalInDB)}
                </h3>
                <small>Total in DB </small>
              </div>
            </div>
          </div>
          <div>
            <div className="flex default p-mb-4 p-ml-3">
              <FormRadioInput
                id="count-metric"
                value="count"
                name="metric-type"
                checked={metricType === "count"}
                handleChange={(e) => setMetricType(e?.target.value)}
                label="Count"
              />
              <div className="p-ml-3">
                <FormRadioInput
                  id="value-metric"
                  value="value"
                  name="metric-type"
                  checked={metricType === "value"}
                  handleChange={(e) => setMetricType(e?.target.value)}
                  label="Value"
                />
              </div>
            </div>
          </div>
          <TabView>
            <TabPanel
              header={`Matched Successful Transactions (${props?.fileDetails?.totalMatchedAndSuccessful})`}
            >
              <div className="p-mt-5 p-pt-3">
                <ReconFileTransactions
                  transactionIndicator={props.transactionIndicator}
                  transactionDate={props.transactionDate}
                  transactionType="successful"
                  reconStatus="MATCHED_SUCCESS"
                />
              </div>
            </TabPanel>
            {props.transactionIndicator === "CREDIT" ? (
              <TabPanel
                header={`Matched Failed Transactions (${props.fileDetails?.totalMatchedAndFailed})`}
              >
                <ReconFileTransactions
                  transactionIndicator={props.transactionIndicator}
                  transactionDate={props.transactionDate}
                  transactionType="failed"
                  reconStatus="MATCHED_FAILED"
                  showDisputeDetails={showDisputeDetails}
                />
              </TabPanel>
            ) : (
              <TabPanel></TabPanel>
            )}

            <TabPanel
              header={`Exceptions (${props?.fileDetails?.totalAccountEntryUnmatched})`}
            >
              <ReconFileTransactions
                transactionIndicator={props.transactionIndicator}
                transactionDate={props.transactionDate}
                transactionType="exceptions"
                reconStatus="UNMATCHED"
              />
            </TabPanel>
          </TabView>
        </div>
      );
    } else {
      return <DisputeBreakdown {...disputeBreakdownProps} />;
    }
  };

  return (
    <div>
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div>{reconciliationBreakdownView()}</div>
    </div>
  );
}
