const GET_BULK_SETTLEMENTS = "/backoffice/settlement/bulk";
const GET_BULK_SETTLEMENT_TRANSACTIONS = "/backoffice/settlement";
const GET_BULK_SETTLEMENT_PAYMENT_REQUESTS = "/backoffice/paymentrequest";
const GET_BULK_SETTLEMENT_PAYMENT_ATTEMPTS = "/backoffice/payment-attempt";
const DOWNLOAD_BULK_SETTLEMENT = "/backoffice/settlement/download";

export const BULK_SETTLEMENT_ROUTES = {
  GET_BULK_SETTLEMENTS,
  GET_BULK_SETTLEMENT_TRANSACTIONS,
  GET_BULK_SETTLEMENT_PAYMENT_REQUESTS,
  GET_BULK_SETTLEMENT_PAYMENT_ATTEMPTS,
  DOWNLOAD_BULK_SETTLEMENT,
};
