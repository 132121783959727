import "./custom-card.css";
import { CardSkeletonLoader } from "./card-skeleton-loader";

export function CustomCard({ children, background, loading }) {
  return (
    <div className="app-card" style={{ background: `${background}` }}>
      {loading ? <CardSkeletonLoader /> : children}
    </div>
  );
}
