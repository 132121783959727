const get_current_day_transaction_processing_metrics = "/response/metric";
const get_historic_transaction_processing_metrics = "/response/filter";
const get_banks_api_call_log = "/api-call-logs";
const get_pending_processes_count = "/pending-processes-reports";

export const MONITORING_API = {
  GET_CURRENT_DAY_TRANSACTION_PROCESSING_METRICS:
    get_current_day_transaction_processing_metrics,
  GET_HISTORIC_TRANSACTION_PROCESSING_METRICS:
    get_historic_transaction_processing_metrics,
  GET_BANKS_APIC_CALL_LOG: get_banks_api_call_log,
  GET_PENDING_PROCESSES_COUNT: get_pending_processes_count,
};
