import { ACCOUNT_ENQUIRY_ROUTES } from "./account-enquiry";
import { AUTH_ROUTES } from "./authentication";
import { WORKFLOW_ROUTES } from "./workflow";
import { CONFIG_FEATURES_ROUTES } from "./config-features";

export const COMMON_API = {
  AUTH: {
    ...AUTH_ROUTES,
  },
  ACCOUNT_ENQUIRY: {
    ...ACCOUNT_ENQUIRY_ROUTES,
  },
  WORKFLOW: {
    ...WORKFLOW_ROUTES,
  },
  CONFIG_FEATURES: {
    ...CONFIG_FEATURES_ROUTES,
  },
};
