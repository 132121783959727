import "./transactions.css";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { API_SERVICE } from "api/service";
import { Icon } from "components/elements/icons/icon";
import { SpecialLabelCases } from "utils/models/utilities";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { NewDispute } from "../disputes/new-dispute";
import { useWindowSize } from "hooks/use-window-size";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { DisputeBreakdown } from "../disputes/dispute-breakdown";
import { Optional } from "components/elements/optional/optional";
import { BACKOFFICE_CONFIGS, UserTypes } from "utils/constants";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function TransactionBreakdown(props) {
  const toast = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visible, setVisible] = useState(false);
  const windowSize = useWindowSize();
  const [currentModalIndex, setCurrentModalIndex] = useState(1);
  const [error] = useState(null);
  const [disputeResolutionStatus, setDisputeResolutionStatus] = useState("");
  const [detailsContent, setDetailsContent] = useState([]);
  const [showDisputeNotification, setShowDisputeNotification] = useState(false);
  const [showLogDisputeButton, setShowLogDisputeButton] = useState(false);
  const [disputeLogCode, setDisputeLogCode] = useState(null);
  const [gettingTransactionDetails, setGettingTransactionDetails] =
    useState(true);
  const [disputeBreakdownProps, setDisputeBreakdownProps] = useState({});
  const [userType] = useState(props.userType);

  // const handleDisputeGoBack = useCallback((shouldReload) => {

  // }, []);

  const getDisputeByTransactionKey = useCallback(
    (disputeableCodes) => {
      setGettingTransactionDetails(true);
      setShowLogDisputeButton(false);
      const frontofficeUrl =
        FRONT_OFFICE_API.DISPUTE.GET_DISPUTE_BY_TRANSACTION_KEY;
      const backofficeUrl =
        BACK_OFFICE_API.DISPUTE.GET_DISPUTE_BY_TRANSACTION_KEY;

      const url =
        props.userType === UserTypes.FrontOffice
          ? frontofficeUrl
          : backofficeUrl;
      API_SERVICE.MAKE_GET_REQUEST(`${url}/${props.transactionSearchKey}`)
        .then((data) => {
          const result = data?.result;
          // Null result means no dispute logged
          if (result === null) {
            if (disputeableCodes?.includes(props.responseCode)) {
              setShowLogDisputeButton(true);
              setShowDisputeNotification(false);
            }
          } else {
            setDisputeResolutionStatus(result?.resolutionStatus);
            setShowDisputeNotification(true);
            setDisputeLogCode(result?.logCode);
            setDisputeBreakdownProps({
              loggedBy: result?.loggedBy,
              disputeExpiringDate: result?.tatExpiryDate,
              disputeStatus: result?.status,
              logCode: result?.logCode,
              disputeDetails: HELPER.GET_DISPUTE_DETAILS_ARRAY(result),
              goBack: (shouldReload) => {
                if (shouldReload === true) {
                  getDisputeByTransactionKey();
                } else {
                  setCurrentIndex(0);
                }
              },
              mobile: windowSize.width <= 948,
              receipt: result?.receiptData,
              transactionSearchKey: result?.transactionSearchKey,
            });
            let transactionDetails = props.details;
            transactionDetails.push({
              label: "Dispute Reason",
              value: result?.reason,
            });
            setDetailsContent(transactionDetails);
          }

          setCurrentIndex(0);
          setGettingTransactionDetails(false);
        })
        .catch((error) => {
          setCurrentIndex(0);
          setGettingTransactionDetails(false);
          toast.current.show({
            severity: "error",
            summary: "Error checking if dispute is logged!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    },
    [
      props.transactionSearchKey,
      props.responseCode,
      props.details,
      windowSize.width,
      userType,
      // handleDisputeGoBack,
    ]
  );

  const getDisputeableCodes = useCallback(() => {
    // setCurrentIndex(1)
    setGettingTransactionDetails(true);
    const url = BACK_OFFICE_API.CONFIGURATION.CONFIGURATIONS;
    const config =
      props.userType === UserTypes.FrontOffice
        ? BACKOFFICE_CONFIGS.FRONTOFFICE_DISPUTE_RESPONSE_CODES
        : BACKOFFICE_CONFIGS.BACKOFFICE_DISPUTE_RESPONSE_CODES;
    API_SERVICE.MAKE_GET_REQUEST(`${url}/${config}`)
      .then((data) => {
        let disputeableCodes = data?.result?.configValue?.split(",");
        disputeableCodes = disputeableCodes.map((code) => {
          // Trim cause config takes user entered input and can possibly have whitespaces
          return code.trim();
        });
        if (
          HELPER.HAS_AUTHORITY("bo_dcir_get_dispute_transactions") ||
          HELPER.HAS_AUTHORITY("fo_dcir_get_backoffice_disputes")
        ) {
          getDisputeByTransactionKey(disputeableCodes);
        } else {
          setCurrentIndex(0);
          setGettingTransactionDetails(false);
        }
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Error getting dispute codes!",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
        setGettingTransactionDetails(false);
      });
    // setGettingTransactionDetails(false);
  }, [getDisputeByTransactionKey, props.userType]);

  useEffect(() => {
    setDetailsContent(props.details);
    getDisputeableCodes();
  }, [getDisputeableCodes, props.details]);

  function isTabMobile() {
    return windowSize.width <= 948;
  }

  function showDisputeDetails() {
    if (props.isDisputePage) {
      // On the dispute page, the dispute details is the previous page
      props.goBack();
    } else {
      setCurrentIndex(4);
    }
  }

  function onHide() {}

  function closeModal(reload) {
    if (props.mobile) {
      setCurrentIndex(0);
    } else {
      setVisible(false);
    }
    if (reload) {
      setCurrentIndex(0);
      getDisputeableCodes();
    }
  }

  function openModal(index) {
    if (props.mobile) {
      setCurrentIndex(3);
    } else {
      setCurrentModalIndex(index);
      setVisible(true);
    }
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = () => {
    return detailsContent.map((details, index) => (
      <div key={index.toString()} className="p-grid dcir-row">
        <div className="p-col-6">
          <p className="details-label">{details.label}:</p>
        </div>
        <div className="p-col-6">
          <p className="details-value">
            {transformView(details.itemCase, details.value)}
          </p>
        </div>
      </div>
    ));
  };

  const errorView = () => {
    return (
      <div>
        <div className="empty-container">
          <Icon icon="error-message-icon" />
          <div>
            <p className="empty-text">{error}</p>
          </div>
          <div className="p-mt-3">
            <div style={{ maxWidth: "200px" }}>
              <button className="primary-button">Reload</button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showResolveDisputeButtton = () => {
    if (!showLogDisputeButton) {
      return <div />;
    } else {
      return (
        <button onClick={() => openModal(1)} className="primary-button">
          Log Dispute
        </button>
      );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <NewDispute
            hashedPan={props?.hashedPan}
            transactionSearchKey={props?.transactionSearchKey}
            closeDisputeModal={closeModal}
            userType={userType}
          />
        );
    }
  };

  function disputeNotificationView() {
    return (
      <div className="p-col-12">
        <div className={`info-card ${disputeResolutionStatus?.toLowerCase()}`}>
          <div>
            <span className="info-card-text">
              Dispute Resolution Status: <b> {disputeResolutionStatus}</b>
            </span>
          </div>
          <div className="">
            <span className="info-card-text">
              Dispute Log Code:{" "}
              <button className="bare-button" onClick={showDisputeDetails}>
                {disputeLogCode}
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }

  const transactionBreakdownTabView = () => {
    return (
      <div
        style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
        className="p-shadow-1 p-pb-5"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={isTabMobile() ? "TS" : "Transaction Summary"}>
            <div className="p-text-left">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
                <Optional
                  show={
                    HELPER.HAS_AUTHORITY(
                      "bo_dcir_create_dispute_transaction"
                    ) || HELPER.HAS_AUTHORITY("fo_dcir_create_disputes")
                  }
                >
                  <div className="p-col-4">
                    <div
                      style={{
                        width: "135px",
                        float: "right",
                      }}
                    >
                      {showResolveDisputeButtton()}
                    </div>
                  </div>
                </Optional>
              </div>
              <Optional show={gettingTransactionDetails}>
                <div
                  className="p-mt-5 p-text-center"
                  style={{ padding: "10rem 0" }}
                >
                  <CustomLoader loadingText="Loading transaction details ..." />
                </div>
              </Optional>
              <Optional show={!gettingTransactionDetails}>
                <div className="p-grid">
                  <Optional show={showDisputeNotification}>
                    {disputeNotificationView()}
                  </Optional>
                  <div className="p-col-12">
                    <div>
                      <div className="p-text-left">
                        <p
                          className={
                            isTabMobile()
                              ? "dcir-show custom-title p-ml-4"
                              : "dcir-hide"
                          }
                        >
                          Transaction Details
                        </p>
                      </div>
                      <div className="p-text-left">{detailsView()}</div>
                    </div>
                  </div>

                  <div className="p-col-12">
                    <div style={{ marginTop: "-2.2em" }}>
                      <div></div>
                    </div>
                  </div>
                </div>
              </Optional>
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  };

  const transactionBreakdownView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return transactionBreakdownTabView();
      case 1:
        return (
          <div className="p-mt-5">
            <CustomLoader loadingText="Loading transaction details ..." />
          </div>
        );
      case 2:
        return errorView();
      case 3:
        return (
          <div className="custom-mobile-table-card">
            <NewDispute
              transactionSearchKey={props?.transactionSearchKey}
              closeDisputeModal={closeModal}
              userType={userType}
            />
          </div>
        );
      case 4:
        return <DisputeBreakdown {...disputeBreakdownProps} />;
    }
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div>
        <CustomModal
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
          closeModal={closeModal}
        />
      </div>
      {transactionBreakdownView()}
    </div>
  );
}
