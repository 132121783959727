import "./details-break-down.css";
import { SpecialLabelCases } from "../../../utils/models/utilities";
import { useState } from "react";
import { NewDispute } from "../../../features/back-office/disputes/new-dispute";
import { Icon } from "../icons/icon";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { HELPER } from "utils/helper";
import { Optional } from "components/elements/optional/optional";

export function DetailsBreakDown(props) {
  const [currentIndex] = useState(0);
  const detailsContent = props.breakDown;

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          if (value) {
            result = e.action(value);
          }
        }
      });
    }
    return result ? result : "___";
  };
  function hasChangeStatusAuthority() {
    return (
      HELPER.HAS_AUTHORITY("bo_dcir_create_merchant_users") ||
      HELPER.HAS_AUTHORITY("fo_dcir_update_merchant_status")
    );
  }

  function hasResetPasswordAuthority() {
    return (
      HELPER.HAS_AUTHORITY("bo_dcir_reset_user_password") ||
      HELPER.HAS_AUTHORITY("fo_dcir_reset_user_password")
    );
  }

  function hasUnblockUserAuthority() {
    return HELPER.HAS_AUTHORITY("bo_dcir_create_backoffice_users");
  }

  const errorView = () => {
    return (
      <div>
        <div
          style={{ maxWidth: "400px" }}
          className="empty-container p-text-center"
        >
          <Icon icon="error-message-icon" />
          <div>
            <p className="empty-text">{props.error}</p>
          </div>
          <div className="p-mt-6">
            <div className="p-grid">
              <div className="p-col-6">
                <button
                  onClick={() => props.closeModal()}
                  className="secondary-button"
                >
                  Close
                </button>
              </div>
              <div className="p-col-6">
                <button
                  onClick={() => props.reload()}
                  className="primary-button"
                >
                  Reload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const detailsView = detailsContent.map((details, index) => (
    <div key={index.toString()} className="p-grid dcir-row">
      <div className="p-col-5">
        <p className="details-label">{details.label}:</p>
      </div>
      <div className="p-col-7">
        <div className="fle defaul">
          <p className="details-value">
            {transformView(details.itemCase, details.value)}
          </p>
          {/* Change isUnblockUser to canBeUnblocked and changeStatus to canHaveStatusChanged */}
          <Optional show={props?.isUnblockUser || props.changeStatus}>
            <Optional
              show={
                hasChangeStatusAuthority() &&
                props.isChangeStatus &&
                details.label === "Profile Status"
              }
            >
              <button
                className="bare-button underline p-mx-0 p-px-0"
                onClick={props.changeStatus}
              >
                {props.userStatus === "Active" ? "Disable" : "Enable"} User
              </button>
            </Optional>
            <Optional
              show={
                hasUnblockUserAuthority() &&
                props?.isUnblockUser &&
                details.label === "Password Status"
              }
            >
              <button
                className="bare-button underline p-mx-0 p-px-0"
                onClick={props.unblockUser}
              >
                Unblock User
              </button>
            </Optional>
            <Optional show={details.label === "Role(s)"}>
              <button
                className="bare-button underline p-mx-0 p-px-0"
                onClick={props.showChangeRoleView}
              >
                Change Role
              </button>
            </Optional>
          </Optional>
        </div>
      </div>
    </div>
  ));

  const scrollableDetails = () => {
    if (props.mobile) {
      return <div>{detailsView}</div>;
    } else {
      return (
        // <ScrollPanel style={{ width: "100%", height: "100@" }}>
        <div>{detailsView}</div>
        // </ScrollPanel>
      );
    }
  };

  const transformButton = () => {
    return (
      <div
        className={
          props.isResetPassword || props.isChangeStatus
            ? "p-d-flex p-jc-between action-text"
            : ""
        }
      >
        <Optional show={!props.hideCloseButton}>
          <p
            className="close-modal add-cursor p-text-bold"
            onClick={props.closeModal}
          >
            Close
          </p>
        </Optional>
        <div className="flex default">
          <Optional
            show={
              hasUnblockUserAuthority() &&
              props.isUnblockUser &&
              !props.isBackofficeUser
            }
          >
            <p
              className="close-modal add-cursor p-text-bold p-mr-5"
              onClick={props.unblockUser}
            >
              Unblock User
            </p>
          </Optional>
          <Optional
            show={
              hasChangeStatusAuthority() &&
              props.isChangeStatus &&
              !props.isBackofficeUser
            }
          >
            <p
              className="close-modal add-cursor p-text-bold p-mr-5"
              onClick={props.changeStatus}
            >
              {props.userStatus === "Active" ? "Disable" : "Enable"} User
            </p>
          </Optional>
          <Optional show={hasResetPasswordAuthority() && props.isResetPassword}>
            <p
              className="close-modal add-cursor p-text-bold"
              onClick={props.resetPassword}
            >
              Reset Password
            </p>
          </Optional>
        </div>
      </div>
    );
  };

  const transformDetailsView = () => {
    if (props.loading) {
      return (
        <div className="p-mt-3 p-pb-2">
          <div className="p-text-center">
            <CustomLoader loadingText="Loading..." />
          </div>
        </div>
      );
    } else {
      if (currentIndex) {
        return (
          <NewDispute
            transactionSearchKey={props.transactionSearchKey}
            closeDisputeModal={props.closeModal}
          />
        );
      } else {
        if (props.error) {
          return <div>{errorView()}</div>;
        } else {
          return (
            <>
              <div>
                <p className="details-title p-mt-0">{props.title} Data</p>
              </div>
              <div>
                <p className="details-subtitle">
                  {`Find more details about the ${props.title} below`}{" "}
                </p>
              </div>
              <div className="p-mt-3">{scrollableDetails()}</div>
              <div className="p-mt-4 p-p-1 p-text-center">
                {transformButton()}
              </div>
            </>
          );
        }
      }
    }
  };

  return <div className="details-container">{transformDetailsView()}</div>;
}
