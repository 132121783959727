import { useState, useEffect } from "react";

export function useHasCustomLogo() {
  const [hasCustomLogo, setHasCustomLogo] = useState(false);
  useEffect(() => {
    const bankName = document.getElementById("bank-name").innerHTML;
    // console.log("bank-name", bankName);
    if (bankName.length) {
      if (bankName === "dev") {
        setHasCustomLogo(false);
      } else {
        setHasCustomLogo(true);
      }
    }
  }, []);
  return hasCustomLogo;
}
