// import { Dashboard } from "common/dashboard/dashboard";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { Dashboard } from "../../common/dashboard/dashboard";

export function BackOfficeDashboard() {
  return (
    <ErrorBoundary>
      <Dashboard />
    </ErrorBoundary>
  );
}
