export const BACKOFFICE_AUTHORITIES_ROUTE_MAP = {
  bo_view_analytics_dashboard: "dashboard",
  bo_dcir_get_charge_types: "charge-types",
  bo_dcir_view_global_participant: "settlement-participants",
  bo_view_participant_users: "participant-users",
  bo_view_participant_report: "participant-reports",
  bo_dcir_get_merchants: "super-agents",
  bo_dcir_view_merchant_users: "super-agent-users",
  bo_dcir_get_all_transactions: "transactions",
  bo_dcir_get_all_bulk_settlement: "bulk-settlements",
  bo_dcir_get_dispute_transactions: "disputes",
  bo_dcir_manage_public_holidays: "public-holidays",
  bo_manage_configurations: "configurations",
};

export const FRONTOFFICE_AUTHORITIES_ROUTE_MAP = {
  fo_view_analytics_dashboard: "dashboard",
  fo_dcir_get_all_merchant_users: "super-agent-users",
  fo_dcir_get_all_transactions: "transactions",
  fo_dcir_get_all_bulk_settlement: "bulk-settlements",
  fo_dcir_get_agent_disputes: "agent-disputes",
  fo_dcir_get_backoffice_disputes: "bank-disputes",
};
