import "./login.css";
import { Icon } from "components/elements/icons/icon";
import { useState, useContext, useEffect } from "react";
import { MainContext, MonitoringContext } from "../../App";
import {
  persistLoginDataAndRedirect,
  persistMonitoringLoginDataAndRedirect,
} from "./helpers";
import { useLocation } from "react-router";
import { MONITORING_SERVICE } from "api/services/monitoring-service";
import { CustomToast } from "components/elements/alert/custom-toast";
import { useHistory } from "react-router-dom";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormInput } from "components/form/form-input";
import { HELPER } from "utils/helper";
import { LOCAL_STORAGE } from "utils/models/utilities";
import { useHasCustomLogo } from "hooks/has-custom-logo";
import { AUTH_SERVICE } from "api/service";
import { CONFIG } from "config/config";
import { Optional } from "components/elements/optional/optional";
import { AzureAdButton } from "components/elements/azure-ad-button/index";

export function Login({ isMonitoringLogin }) {
  let history = useHistory();
  const mainContext = useContext(MainContext);
  const monitoringContext = useContext(MonitoringContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingText] = useState("Verifying credentials...");
  const [credentialsError, setCredentialsError] = useState({
    password: null,
    username: null,
  });
  const hasCustomLogo = useHasCustomLogo();
  const { search } = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const isSessionExpired = queryParams.get("session_expired");
    if (isSessionExpired) {
      mainContext.mainDispatch({
        type: "SET_ALERT",
        alert: {
          title: "Session Expired",
          description: "Your previous session has expired. Please login again.",
          type: "error",
          showAlert: true,
        },
      });
      history.push("/login");
    }
  }, [search, mainContext, history]);

  function handleUsernameChange(e) {
    setCredentialsError({ ...credentialsError, username: null });
    setUsername(e.target.value);
  }

  function handlePasswordChange(e) {
    setCredentialsError({ ...credentialsError, password: null });
    setPassword(e.target.value);
  }

  function handleMonitoringLogin(payload) {
    // MONITORING_SERVICE.MAKE_GET_REQUEST("/service/health/status")
    MONITORING_SERVICE.LOGIN(payload)
      .then((data) => {
        const response = data?.response;
        persistMonitoringLoginDataAndRedirect(
          response,
          monitoringContext,
          history
        );
      })
      .catch((error) => {
        handleErrors(error);
      });
  }

  function submit(e) {
    e.preventDefault();
    closeAlert();
    setErrorTitle("");
    if (username.length && password.length) {
      setLoading(true);
      const payload = {
        username: username,
        password: password,
      };
      if (isMonitoringLogin) {
        handleMonitoringLogin(payload);
        return;
      }
      AUTH_SERVICE.LOGIN(payload)
        .then((data) => {
          const response = data?.result;
          if (response["2faEnabled"]) {
            handleTwoFactorAuthentication(response?.secondFactorAuthKey);
          } else if (response?.resetPassword) {
            handlePasswordReset(
              response?.cosmosResponse?.accessToken,
              response
            );
          } else {
            persistLoginDataAndRedirect(response, mainContext, history);
          }
        })
        .catch((error) => {
          handleErrors(error);
        });
    } else {
      setCredentialsError({
        password: password.length ? null : true,
        username: username.length ? null : true,
      });
    }
  }

  function handlePasswordReset(accessToken, loginData) {
    mainContext.mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "Change Default Password",
        description:
          "You just tried to login with a system generated default password. You need to change this password to access your account.",
        type: "error",
        showAlert: true,
      },
    });
    mainContext.mainDispatch({
      type: "TEMPORARILY_SET_LOGIN_DETAILS",
      loginData,
      access_token: accessToken,
    });
    history.push("/change-password");
  }

  function handleTwoFactorAuthentication(secondFactorAuthKey) {
    sessionStorage.setItem(LOCAL_STORAGE.TWO_FA_KEY, secondFactorAuthKey);
    history.push("/login/second-factor");
  }

  function closeAlert() {
    mainContext.mainDispatch({
      type: "SET_ALERT",
      alert: {
        title: "",
        description: "",
        type: "",
        showAlert: false,
      },
    });
  }

  function handleErrors(error) {
    setErrorTitle("Error");
    setErrorMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  }

  return (
    <div>
      <div className="login-alert-container">
        {errorTitle ? (
          <CustomToast
            title={errorTitle}
            description={errorMessage}
            type="error"
          />
        ) : (
          <div />
        )}
      </div>
      <form onSubmit={submit} className="auth-form" autoComplete="off">
        <div className="login-container">
          <div className="log-icon-top-container">
            <div className="login-icon-position">
              <div
                className={`logo-size ${hasCustomLogo ? "default" : "default"}`}
              >
                <Icon icon="logo" />
              </div>
              <h1 className={`app-name ${hasCustomLogo ? "" : "default"}`}>
                {/* {isMonitoring} */}
                {isMonitoringLogin
                  ? "Application Monitoring"
                  : "Direct Card Issuer Routing"}
              </h1>
            </div>
          </div>
          <div className="login-card">
            <div className="login-form-container">
              <div>
                <div>
                  <input
                    disabled={loading}
                    onChange={handleUsernameChange}
                    placeholder="Username"
                    value={username}
                    type="text"
                    className={
                      credentialsError.username ? "validation-error" : ""
                    }
                    autoComplete="off"
                  />
                  {credentialsError.username && (
                    <p className="p-error p-text-left">Username is required</p>
                  )}
                </div>
                <div>
                  <div className="p-mt-4">
                    <FormInput
                      value={password}
                      required={true}
                      type="INPUT"
                      error={credentialsError["password"]}
                      fn={handlePasswordChange}
                      loading={loading}
                      placeholder="Password"
                      inputType="password"
                      noFloatingLabel={true}
                    />
                    {credentialsError.password && (
                      <p className="p-error p-text-left">
                        Password is required
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <div className="p-mt-5">
                    {!loading ? (
                      <div>
                        <button type="submit" className="primary-button">
                          Sign in
                        </button>
                        <Optional
                          show={
                            CONFIG.IS_AZURE_AD_ENABLED && !isMonitoringLogin
                          }
                        >
                          <AzureAdButton />
                        </Optional>
                      </div>
                    ) : (
                      <div>
                        <CustomLoader loadingText={loadingText} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
