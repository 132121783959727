const GET_DISPUTES_ON_REQUERY = "/backoffice/transfers-on-requery/disputes";
const GET_SETTLEMENTS_ON_REQUERY =
  "/backoffice/transfers-on-requery/settlements";
const UPDATE_DISPUTE_ON_REQUERY = "/backoffice/transfers-on-requery/dispute";
const UPDATE_SETTLEMENT_ON_REQUERY =
  "/backoffice/transfers-on-requery/settlement";
const DOWNLOAD_DISPUTES_ON_REQUERY =
  "/backoffice/transfers-on-requery/disputes/download";
const DOWNLOAD_SETTLEMENTS_ON_REQUERY =
  "/backoffice/transfers-on-requery/settlements/download";

export const REQUERY_MANAGEMENT_ROUTES = {
  GET_DISPUTES_ON_REQUERY,
  GET_SETTLEMENTS_ON_REQUERY,
  UPDATE_DISPUTE_ON_REQUERY,
  UPDATE_SETTLEMENT_ON_REQUERY,
  DOWNLOAD_DISPUTES_ON_REQUERY,
  DOWNLOAD_SETTLEMENTS_ON_REQUERY,
};
