export function ConditionalRenderer({ renderIf, children }) {
  const view = () => {
    if (renderIf) {
      return children;
    } else {
      return <></>;
    }
  };
  return view();
}
