const upload_reconciliation_file = "/backoffice/reconciliation/new-upload";
const get_uploaded_reconciliation_files = "/backoffice/reconciliation/uploads";
// const get_uploaded_reconciliation_files_by_days =
//   "/backoffice/reconciliation/transaction/daily-metric";
const get_uploaded_reconciliation_files_by_days =
  "/backoffice/reconciliation/transactions/daily";
const get_recon_file_upload_errors = "/backoffice/reconciliation/upload-errors";
const get_recon_uploads_by_day =
  "/backoffice/reconciliation/transactions/daily";
const get_recon_file_account_entries =
  "/backoffice/reconciliation/transactions";
const recon_configurations = "/backoffice/reconciliation/configs";
const download_recon_statement =
  "/backoffice/reconciliation/transactions/download";
const check_or_generate_recon_report =
  "/backoffice/reconciliation/transactions/check-or-generate-report";
const get_daily_credit_debit_summaries =
  "/backoffice/reconciliation/daily-credit-debit-summaries";
const get_unmatched_db_exceptions =
  "/backoffice/reconciliation/exceptions/unmatched-on-db";
const initiate_disputes_on_failed_transactions =
  "/backoffice/reconciliation/initiate-dispute";
const resolve_recon_account_entry = "/backoffice/reconciliation/resolve";
const reinitiate_dispute_on_failed_transactions =
  "/backoffice/reconciliation/refresh-dispute-status";

export const RECON_API = {
  UPLOAD_RECONCILIATION_FILE: upload_reconciliation_file,
  GET_UPLOADED_RECONCILIATION_FILES: get_uploaded_reconciliation_files,
  GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS:
    get_uploaded_reconciliation_files_by_days,
  GET_RECON_FILE_UPLOAD_ERRORS: get_recon_file_upload_errors,
  GET_RECON_UPLOADS_BY_DAY: get_recon_uploads_by_day,
  RECON_CONFIGURATIONS: recon_configurations,
  GET_RECON_FILE_ACCOUNT_ENTRIES: get_recon_file_account_entries,
  DOWNLOAD_RECON_STATEMENT: download_recon_statement,
  CHECK_OR_GENERATE_RECON_REPORT: check_or_generate_recon_report,
  GET_DAILY_CREDIT_DEBIT_SUMMARIES: get_daily_credit_debit_summaries,
  GET_UNMATCHED_DB_EXCEPTIONS: get_unmatched_db_exceptions,
  INITIATE_DISPUTE_ON_FAILED_TRANSACTIONS:
    initiate_disputes_on_failed_transactions,
  RESOLVE_RECON_ACCOUNT_ENTRY: resolve_recon_account_entry,
  REINITIATE_DISPUTE_ON_FAILED_TRANSACTIONS:
    reinitiate_dispute_on_failed_transactions,
};
