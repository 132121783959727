import "./header.css";
import { Icon } from "components/elements/icons/icon";
import { useContext, useRef, useState } from "react";
import { MainContext, MonitoringContext } from "../../../App";
import { useHistory } from "react-router-dom";
import { STORAGE } from "utils/storage";
import { Optional } from "components/elements/optional/optional";
import { HELPER } from "utils/helper";
import { API_SERVICE } from "api/service";
import { Toast } from "primereact/toast";
import { COMMON_API } from "../../../api/routes/common/index";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";

export function Header() {
  const mainContext = useContext(MainContext);
  const monitoringContext = useContext(MonitoringContext);

  const toast = useRef(null);

  let history = useHistory();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  function handleMonitoringLogout() {
    monitoringContext.monitoringDispatch({ type: "LOG_OUT" });
    STORAGE.CLEAR_STORAGE();
    history.push("/monitor");
  }

  function logout() {
    if (STORAGE.IS_MONITORING_LOGIN()) {
      handleMonitoringLogout();
      return;
    }
    setIsLoggingOut(true);
    const { LOGOUT } = COMMON_API.AUTH;
    API_SERVICE.MAKE_PUT_REQUEST(LOGOUT, null)
      .then(() => {
        setIsLoggingOut(false);
        clearStorageAndRedirect();
      })
      .catch((error) => {
        setIsLoggingOut(false);
        clearStorageAndRedirect();
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
      });
  }

  function clearStorageAndRedirect() {
    mainContext.mainDispatch({ type: "LOG_OUT" });
    STORAGE.CLEAR_STORAGE();
    history.push("/login");
  }

  function openNav(e) {
    e.preventDefault();
    let sideNav = document.getElementById("mySidenav");
    let mainContent = document.getElementById("main");
    const width = sideNav.style.width;
    if (window.innerWidth < 900) {
      if (width && width === "70vw") {
        sideNav.style.width = "0";
        mainContent.style.marginLeft = "0";
      } else {
        sideNav.style.width = "70vw";
        mainContent.style.marginLeft = "0";
      }
    } else {
      if (width && width === "250px") {
        sideNav.style.width = "0";
        mainContent.style.marginLeft = "0";
      } else {
        sideNav.style.width = "250px";
        mainContent.style.marginLeft = "250px";
      }
    }
  }

  function isBackofficeUserLoggedIn() {
    return window?.location?.pathname?.split("/").includes("back-office");
  }

  return (
    <div className="header">
      <Toast ref={toast} />
      <div className="p-grid header-container">
        <div className="p-col-4">
          <div className="header-icon add-cursor">
            <button onClick={openNav} className="bare-button">
              <Icon icon="menu-icon" />
            </button>
          </div>
        </div>
        <div className="p-col-8 header-actions">
          {/* <Optional show={HELPER.HAS_ROLE(["DCIR_BACKOFFICE_ADMIN"])}>
            <div className="add-cursor">
              <a
                className="nav-profile-title p-mt-0"
                href="https://teamapt.atlassian.net/wiki/spaces/DUTM/overview?homepageId=703725830"
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <Icon icon="bulk-disputes" color="#0371ac" />
                </div>
                User Manual
              </a>
            </div>
          </Optional> */}
          <UserManualViewerButton
            fileName={
              isBackofficeUserLoggedIn() ? "faq-backoffice" : "faq-frontoffice"
            }
            isFAQ={true}
          />
          <div onClick={logout} className="p-col-6 log-out add-cursor">
            <Optional show={!isLoggingOut}>
              <Icon icon="log-out" />
            </Optional>
            <Optional show={isLoggingOut}>
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "1.5em" }}
              ></i>
            </Optional>
          </div>
        </div>
      </div>
    </div>
  );
}
