// function validUsername(val) {
//   return val ? val.toString().length > 1 : false;
// }

function validMobileNumber(param) {
  if (!param || param === "" || isNaN(param)) {
    return false;
  }

  param = param.replace(/\D/g, "");

  return !isNaN(param) && param.length >= 11 && param.length <= 16;
}

function validateObjectModel(obj, totalProperties, hasOptionalFields = false) {
  let totalCurrentProperties = 0;
  for (const property in obj) {
    if (obj[property]) {
      totalCurrentProperties++;
    }
  }
  if (hasOptionalFields && totalCurrentProperties < totalProperties) {
    return true;
  }
  return totalCurrentProperties === totalProperties;
}

function validateObjectModelAny(obj) {
  let result = false;
  for (const property in obj) {
    if (obj[property]) {
      result = true;
      break;
    }
  }
  return result;
}

function isFormValid(obj, errorObj, optionalFields = []) {
  let hasInvalidFields = false;
  for (const property in obj) {
    const value = obj[property];
    if (value) {
      return;
    }
    // Valid form values can be false
    if (!value && value === false) {
      return;
    }
    if (!value && optionalFields.includes(property)) {
      return;
    }
    if (!value) {
      hasInvalidFields = true;
    }
  }
  let hasValidErrors = validateObjectModelAny(errorObj);
  if (hasInvalidFields || hasValidErrors) {
    return false;
  } else {
    return true;
  }
}

function isValidForm(obj, optionalFields = []) {
  let isValidForm = true;
  for (const property in obj) {
    // Valid form values can be false
    if (obj[property] !== false) {
      if (!Boolean(obj[property])) {
        if (!optionalFields.includes(property)) {
          isValidForm = false;
        }
      }
    }
  }
  return isValidForm;
}

function validNumber(value) {
  if (value == null || typeof value === "undefined") {
    return false;
  }
  return !isNaN(value);
}
function validPercentage(param) {
  if (validNumber(param)) {
    return param >= 0 && param <= 100;
  } else {
    return false;
  }
}

function validName(param) {
  return (
    param != null && param.length >= 2 && param.length <= 150
    // && validNameFormat(param)
  );
}

function validEmail(email) {
  let splitMails = email.split(",");
  const isValid =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let isValidMails = true;
  splitMails.forEach((mail) => {
    let trimmedEmail = mail.trim();
    if (!isValid.test(trimmedEmail)) {
      isValidMails = false;
    }
  });
  return isValidMails;
}

function isNullOrEmptyOrUndefined(obj) {
  return obj === undefined || obj === null || (obj + "").trim().length === 0;
}

function validNuban(param) {
  if (!param || param === "" || isNaN(param)) {
    return false;
  }

  param = param.replace(/\D/g, "");

  return !isNaN(param) && param.length === 10;
}

function validGlAccount(value, validLength) {
  if (!value || value === "" || isNaN(value)) {
    return false;
  }
  value = value.replace(/\D/g, "");
  return !isNaN(value) && value.length === Number(validLength);
}

function isNullOrEmpty(object) {
  if (
    typeof object === undefined ||
    object == null ||
    object === "" ||
    object.toString().replace(/\s/g, "") === "" ||
    JSON.stringify(object) === JSON.stringify({})
  ) {
    return true;
  }
}

function validCashInput(param) {
  if (isNullOrEmpty(param)) {
    return false;
  }

  param = param.toString().replace(/,/g, "");

  return !isNullOrEmpty(param) && !isNaN(param) && parseFloat(param) > 0;
}

function validInput(param) {
  if (isNullOrEmpty(param)) {
    return false;
  }

  return !isNullOrEmpty(param) && param.length > 0;
}

function validDescription(param) {
  if (isNullOrEmpty(param)) {
    return false;
  }

  return !isNullOrEmpty(param) && param.length > 2;
}

function validCardAcceptanceId(param) {
  if (isNullOrEmpty(param)) {
    return false;
  }

  return !isNullOrEmpty(param) && param.length === 15;
}

// function isEmpty(obj) {
//   return Object.keys(obj).length === 0;
// }

function basicValidation(input, inputType, validInputLength) {
  // eslint-disable-next-line default-case
  switch (inputType) {
    case "NAME":
      return validName(input);
    case "EMAIL":
      return validEmail(input);
    case "MOBILE_NUMBER":
      return validMobileNumber(input);
    case "NUBAN":
      return validNuban(input);
    case "GL":
      return validGlAccount(input, validInputLength);
    case "PERCENT":
      return validPercentage(input);
    case "CASH_INPUT":
      return validCashInput(input);
    case "DESCRIPTION":
      return validDescription(input);
    case "INPUT":
      return validInput(input);
    case "CARD_ACCEPTANCE_ID":
      return validCardAcceptanceId(input);
    case "NUMBER":
      return validNumber(input);
    default:
      return true; //No validation
  }
}

function isValidPassword(value) {
  const exp =
    /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
  return exp.test(value);
}

export const CUSTOM_VALIDATION = {
  BASIC_VALIDATION: basicValidation,
  IS_EMPTY: isNullOrEmptyOrUndefined,
  VALID_OBJ: validateObjectModel,
  VALID_OBJ_ANY: validateObjectModelAny,
  IS_VALID_FORM: isValidForm,
  IS_VALID_PASSWORD: isValidPassword,
  IS_FORM_VALID: isFormValid,
};
