import React, { useCallback, useEffect, useState } from "react";
import { HELPER } from "utils/helper";
import { TabPanel, TabView } from "primereact/tabview";
import { ManualPaymentTypes } from "utils/constants";
import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { API_SERVICE } from "api/service";
import { getDisputeDetails, getSettlementDetails } from "./utils";
import { Icon } from "components/elements/icons/icon";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { BulkTransactionBreakDown } from "../../bulk-settlements/bulk-transaction-break-down";

export function ManualPaymentBulkUploadItems(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [bulkItems, setBulkItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("No items found...");
  const [search, setSearch] = useState(false);
  const [error, setError] = useState(null);
  const [selectedItemDetails, setSelectedItemDetails] = useState([]);
  const [bulkUploadSummary, setBulkUploadSummary] = useState({
    pending: 0,
    failed: 0,
    successful: 0,
  });

  const commonHeaders = [
    { label: "Credit Account", value: "creditAccount" },
    { label: "Credit Account Name", value: "creditAccountName" },
    { label: "Amount", value: "amount" },
    { label: "Process Status", value: "processStatus" },
  ];
  const disputeHeaders = [
    ...commonHeaders,
    { label: "Resolution Status", value: "resolutionStatus" },
    { label: "Refund Status", value: "refundStatus" },
    { label: "Created On", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const settlementHeaders = [
    ...commonHeaders,
    { label: "Bulk Settlement Key", value: "bulkId" },
    { label: "Created On", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];
  const [tableHeaders] = useState(
    props.manualPaymentType === ManualPaymentTypes.Dispute
      ? disputeHeaders
      : settlementHeaders
  );

  const getTransactionDetails = useCallback(
    (e) => {
      let arr;
      if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
        arr = getDisputeDetails(e);
      } else {
        arr = getSettlementDetails(e);
      }
      setSelectedItemDetails(arr);
      setCurrentIndex(2);
    },
    [props.manualPaymentType]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [getTransactionDetails]
  );

  const getUrl = useCallback(() => {
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      return BACK_OFFICE_API.MANUAL_DISPUTE_REFUND
        .GET_ITEMS_IN_DISPUTE_REFUND_BULK;
    } else {
      return BACK_OFFICE_API.MANUAL_SETTLEMENT_PAYMENT
        .GET_ITEMS_IN_MANUAL_SETTLEMENT_BULK;
    }
  }, [props.manualPaymentType]);

  const getBulkItems = useCallback(() => {
    setCurrentIndex(0);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
      batchId: props.batchId,
    });
    const url = getUrl();
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data?.result;
        if (!result?.content?.length) {
          setEmptyText("No results found ...");
        } else {
          let arr = [];
          setTotalItems(result.totalElements);
          setTotalPages(result.totalPages);
          result?.content?.forEach((e) => {
            arr.push({
              ...e,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setBulkItems(arr);
        }
        setError(null);
        setCurrentIndex(1);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
      });
  }, [pageNo, openAction, props.batchId, getUrl]);

  useEffect(() => {
    getBulkItems();
  }, [getBulkItems]);

  useEffect(() => {
    const getSummaryUrl = () => {
      if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
        return BACK_OFFICE_API.MANUAL_DISPUTE_REFUND
          .GET_DISPUTE_REFUND_BULK_SUMMARY;
      } else {
        return BACK_OFFICE_API.MANUAL_SETTLEMENT_PAYMENT
          .GET_MANUAL_SETTLEMENT_BULK_SUMMARY;
      }
    };

    function getBulkUploadSummary() {
      const url = getSummaryUrl();
      API_SERVICE.MAKE_GET_REQUEST(`${url}/${props.batchId}`)
        .then((data) => {
          const result = data?.result;
          const summaryObj = {
            pending: result?.PENDING,
            successful: result?.SUCCESSFUL,
            failed: result?.FAILED,
          };
          setBulkUploadSummary(summaryObj);
        })
        .catch((error) => {
          // setError(HELPER.PROCESS_ERROR(error));
          console.log("error", error);
        });
    }

    getBulkUploadSummary();
  }, [props.batchId, props.manualPaymentType]);

  function reload() {
    setPageNo(0);
    setSearch(false);
    getBulkItems();
  }

  function goBack() {
    setCurrentIndex(currentIndex - 1);
  }

  const renderBulkUploadItems = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={bulkItems}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(no) => setPageNo(no)}
          />
        );
      case 2:
        return (
          <BulkTransactionBreakDown
            goBack={goBack}
            details={selectedItemDetails}
            showHeader={false}
          />
          // <div></div>
          // <DetailsBreakDown breakDown={selectedItemDetails} />
        );
      default:
        return;
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        paddingTop: "1em",
        paddingBottom: currentIndex === 0 ? "8rem" : "2rem",
      }}
      className="p-shadow-1"
    >
      <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
        <span
          onClick={() => {
            currentIndex === 2 ? setCurrentIndex(1) : props?.goBack();
          }}
          className="add-cursor"
        >
          <span>
            <Icon icon="go-back-icon" />
          </span>
        </span>
      </div>
      <TabView>
        <TabPanel header="Bulk Items">
          <div className="">
            <div className="p-grid p-mt-2">
              <div className="p-col-8" />
              <div className="p-col-4">
                <div
                  style={{
                    width: "135px",
                    float: "right",
                  }}
                >
                  {HELPER.HAS_AUTHORITY("bo_dcir_create_dispute_transaction")}
                </div>
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12">
                <div>
                  {currentIndex === 1 && (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <div>
                        <p>Successful</p>
                        <div
                          className="custom-badge custom-badge-text custom-badge-success"
                          style={{ width: "3rem", margin: "0 auto" }}
                        >
                          {bulkUploadSummary.successful}
                        </div>
                      </div>
                      <div className="p-ml-4">
                        <p>Failed</p>
                        <div
                          className="custom-badge custom-badge-text custom-badge-error"
                          style={{ width: "3rem", margin: "0 auto" }}
                        >
                          {bulkUploadSummary.failed}
                        </div>
                      </div>
                      <div className="p-ml-4">
                        <p>Pending</p>
                        <div
                          className="custom-badge custom-badge-text custom-badge-pending"
                          style={{ width: "3rem", margin: "0 auto" }}
                        >
                          {bulkUploadSummary.pending}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="p-mt-5">{renderBulkUploadItems()}</div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
}
