


export function AccessDenied (props){
    return(
        <div>
            <p>Access Denied !!!</p>
        </div>
    )
}
