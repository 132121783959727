import { useState } from "react";
import { FormInput } from "components/form/form-input";
import { CUSTOM_VALIDATION } from "utils/validation";
import { FormDropdown } from "components/form/form-dropdown";

export function SuperAgentUsersFilter(props) {
  const [merchantUser, setMerchantUser] = useState({
    firstname: null,
    lastname: null,
    email: null,
    username: null,
    status: null,
  });
  const [merchantUserError, setMerchantUserError] = useState({
    firstname: null,
    lastname: null,
    email: null,
    username: null,
    status: null,
  });
  // const [merchants, setMerchants] = useState([]);

  const status = [
    { desc: "Active", code: "Active" },
    { desc: "Inactive", code: "Inactive" },
  ];

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    if (props.isSearch) {
      setMerchantUser({ ...merchantUser, [name]: value });
    } else {
      const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
      const isValidInput = !isEmpty
        ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
        : false;
      if (isValidInput) {
        setMerchantUser({ ...merchantUser, [name]: value });
        setMerchantUserError({ ...merchantUserError, [name]: null });
      } else {
        let errorMessage =
          required && isEmpty ? `${refineName} is required` : null;
        if (!isValidInput) {
          errorMessage = `${refineName} is invalid`;
        }
        setMerchantUserError({ ...merchantUserError, [name]: errorMessage });
      }
    }
  }

  function filterSearch() {
    let payload = {};

    if (merchantUser["firstname"]) {
      payload.firstname = merchantUser["firstname"];
    }
    if (merchantUser["lastname"]) {
      payload.lastname = merchantUser["lastname"];
    }
    if (merchantUser["email"]) {
      payload.email = merchantUser["email"];
    }
    if (merchantUser["status"]) {
      payload.active = merchantUser["status"].code === "Active" ? true : false;
    }
    if (merchantUser["username"]) {
      payload.username = merchantUser["username"];
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    return (
      <button onClick={cancelModal} className="secondary-button">
        Cancel
      </button>
    );
  };

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setMerchantUserError({ ...merchantUserError, [name]: "" });
      setMerchantUser({ ...merchantUser, [name]: value });
    } else {
      let errorMessage = "Select Super Agent";
      setMerchantUserError({ ...merchantUserError, [name]: errorMessage });
    }
  }

  const submitButton = () => {
    return (
      <button
        onClick={() => props?.searchFunction(filterSearch())}
        className="primary-button"
      >
        Filter
      </button>
    );
  };

  const titleView = () => {
    if (props.isSearch) {
      return "Filter";
    } else if (props.isUpdate) {
      return "Update super agent user";
    } else {
      return "New super agent user";
    }
  };

  const subTitleView = () => {
    if (props.isSearch) {
      return "";
    } else if (props.isUpdate) {
      return "Update the form below";
    } else {
      return "Fill the form below to create super agent user";
    }
  };

  const filterFormView = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">{titleView()}</div>
        <div className="custom-dialog-subtitle-container p-mb-5">
          <p className="custom-dialog-subtitle">{subTitleView()}</p>
        </div>
        <div className="p-grid">
          <div className={props.isSearch ? "p-col-12" : "p-col-6"}>
            <FormInput
              value={merchantUser["firstname"]}
              required={false}
              field="firstname"
              type="NAME"
              error={merchantUserError["firstname"]}
              fn={validateForm}
              placeholder="First Name"
            />
          </div>
          <div className={props.isSearch ? "p-col-12" : "p-col-6"}>
            <FormInput
              value={merchantUser["lastname"]}
              required={false}
              field="lastname"
              type="NAME"
              error={merchantUserError["lastname"]}
              fn={validateForm}
              placeholder="Last Name"
            />
          </div>
          <div className={props.isSearch ? "p-col-12" : "p-col-12"}>
            <FormInput
              value={merchantUser["username"]}
              required={false}
              field="username"
              type="NAME"
              error={merchantUserError["username"]}
              fn={validateForm}
              placeholder="Username"
            />
          </div>
          <div className={props.isSearch ? "p-col-12" : "p-col-12"}>
            <FormInput
              value={merchantUser["email"]}
              required={false}
              field="email"
              type="EMAIL"
              error={merchantUserError["email"]}
              fn={validateForm}
              placeholder="Email"
            />
          </div>
          <div className="p-col-12">
            <FormDropdown
              required={false}
              label="code"
              field="status"
              error={merchantUserError["status"]}
              value={merchantUser["status"]}
              fn={validateDropdown}
              options={status}
              placeholder="Status"
            />
          </div>
          <div className="p-col-12">
            <div className="p-mt-2">
              <div className="p-grid">
                <div className="p-col-6">{cancelButton()}</div>
                <div className="p-col-6">{submitButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div className="p-pb-2">{filterFormView()}</div>;
}
