//initial State
export const initialState = {
  selectedSideNav: "Dashboard",
  selectedSideNavIndex: 0,
  showDialog: false,
  username: null,
  isAuthenticated: false,
  access_token: null,
  currentPage: 0,
  authorities: [],
  expires_in: 0,
  firstName: null,
  lastName: null,
  name: null,
  roles: [],
  alert: {
    title: "",
    description: "",
    type: "",
    showAlert: false,
  },
  percentDownloaded: 0,
  loginData: null,
  markdown: {
    showMarkdown: false,
    name: null,
  },
};

export const monitoringInitialState = {
  selectedSideNav: "Dashboard",
  selectedSideNavIndex: 0,
  showDialog: false,
  username: null,
  isAuthenticated: false,
  access_token: null,
  currentPage: 0,
  authorities: [],
  expires_in: 0,
  firstName: null,
  lastName: null,
  name: null,
  roles: [],
  alert: {
    title: "",
    description: "",
    type: "",
    showAlert: false,
  },
  percentDownloaded: 0,
  loginData: null,
};
