import { useEffect } from "react";
import { useHistory } from "react-router";

export function Home() {
  let history = useHistory();

  useEffect(() => {
    history.push("/login");
  }, [history]);

  return null;
}
