// import { Icon } from "../icons/icon";
import { Icon } from "../icons/icon";

export function TableErrorView({ error, onReload }) {
  return (
    <div>
      <div className="empty-container p-text-center">
        <Icon icon="error-message-icon" />
        <div>
          <p className="empty-text notification-text">{error}</p>
        </div>
        <div className="p-mt-3">
          <button onClick={() => onReload()} className="primary-button">
            Reload
          </button>
        </div>
      </div>
    </div>
  );
}
