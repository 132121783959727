import React from "react";
import { Skeleton } from "primereact/skeleton";
import { HELPER } from "utils/helper";
import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import "./metrics-cards.css";
import { Icon } from "../../../components/elements/icons/icon";

export function PendingTransactionsMetrics(props) {
  if (props.errorMessage && props.loading === false) {
    return (
      <div className="card p-mt-5 empty" style={{ textAlign: "center" }}>
        <Icon icon="not-found" />
        <p style={{ fontSize: "0.8rem" }}>{props.errorMessage}</p>
        <div>
          <button
            className="primary-button"
            style={{ width: "10rem" }}
            onClick={props.reload}
          >
            Reload
          </button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="p-grid p-mt-2">
        <div className="p-col-3">
          <CustomCard>
            <p>Pending Settlements </p>
            {props.loading ? (
              <div className="p-mt-5">
                <Skeleton />
                <br />
                <Skeleton />
              </div>
            ) : (
              <>
                <h2 className="p-mt-0 number">
                  {HELPER.TO_NUMBER_FORMAT(props.data.pendingSettlements)}
                </h2>{" "}
                <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                  Last updated: {props.currentTime}
                </span>{" "}
              </>
            )}
          </CustomCard>
        </div>
        <div className="p-col-3">
          <CustomCard>
            <p>Pending Dispute Refunds </p>
            {props.loading ? (
              <div className="p-mt-5">
                <Skeleton />
                <br />
                <Skeleton />
              </div>
            ) : (
              <>
                <h2 className="p-mt-0">
                  {HELPER.TO_NUMBER_FORMAT(props.data.pendingDisputeRefunds)}
                </h2>{" "}
                <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                  Last updated: {props.currentTime}
                </span>{" "}
              </>
            )}
          </CustomCard>
        </div>
        <div className="p-col-3">
          <CustomCard>
            <p>Pending Participant Reports </p>
            {props.loading ? (
              <div className="p-mt-5">
                <Skeleton />
                <br />
                <Skeleton />
              </div>
            ) : (
              <>
                <h2 className="p-mt-0">
                  {HELPER.TO_NUMBER_FORMAT(
                    props.data.pendingParticipantReports
                  )}
                </h2>{" "}
                <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                  Last Updated: {props.currentTime}
                </span>{" "}
              </>
            )}
          </CustomCard>
        </div>
        <div className="p-col-3">
          <CustomCard>
            <p> Pending Settlement Reports </p>
            {props.loading ? (
              <div className="p-mt-5">
                <Skeleton />
                <br />
                <Skeleton />
              </div>
            ) : (
              <>
                <h2 className="p-mt-0">
                  {HELPER.TO_NUMBER_FORMAT(
                    props.data.pendingSettlementsReports
                  )}
                </h2>{" "}
                <span style={{ fontSize: "0.8rem" }} className="p-text-primary">
                  Last Updated: {props.currentTime}
                </span>{" "}
              </>
            )}
          </CustomCard>
        </div>
      </div>
    </>
  );
}
