const UPLOAD_BULK_DISPUTE = "/backoffice/bulk-dispute/upload";
const GET_BULK_DISPUTES = "/backoffice/bulk-dispute";
const GET_BULK_DISPUTE_TRANSACTIONS = "/backoffice/bulk-dispute/transactions";
const DOWNLOAD_BULK_DISPUTE_TRANSACTIONS =
  "/backoffice/bulk-dispute/transactions/download";
const GET_BULK_DISPUTE_UPLOAD_SUMMARY =
  "/backoffice/bulk-dispute/transactions/summary";

export const BULK_DISPUTE_ROUTES = {
  UPLOAD_BULK_DISPUTE,
  GET_BULK_DISPUTES,
  GET_BULK_DISPUTE_TRANSACTIONS,
  DOWNLOAD_BULK_DISPUTE_TRANSACTIONS,
  GET_BULK_DISPUTE_UPLOAD_SUMMARY,
};
