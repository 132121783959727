import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { Servers } from "./servers";

export function ServerHealthMonitoring() {
  return (
    <div className="server-health-monitoring p-text-left">
      <div className="page-title p-text-left">Server Health</div>
      <div className="p-mt-2">
        <CustomBreadcrumb page="Monitor Server Health" />
      </div>
      {/* <div className="p-grid">
        <div className="p-col-6"> */}
      <Servers status={true} />
      {/* </div>
      </div> */}
      {/* <SingleServerHealth status={true} />
      <SingleServerHealth service="APTENT" status={false} />
      <SingleServerHealth service="COSMOS" status={true} /> */}
    </div>
  );
}
