import { useEffect, useRef, useState } from "react";
import { API_SERVICE } from "../api/service";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import { BACK_OFFICE_API } from "../api/routes/back-office/index";

export function useMerchantsList() {
  const toast = useRef(null);
  // const [error, setError] = useState(null);
  const [merchants, setMerchants] = useState([]);
  useEffect(() => {
    function getMerchants() {
      const url = BACK_OFFICE_API.SUPER_AGENT.GET_ALL_SUPER_AGENTS;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          const result = data?.result;
          setMerchants(result);
        })
        .catch((error) => {
          // setError(error);
          toast?.current?.show({
            severity: "error",
            summary: "Error getting merchants!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    if (HELPER.HAS_AUTHORITY("bo_dcir_get_merchants")) {
      getMerchants();
    }
  }, []);

  if (merchants) {
    return merchants;
  }

  return <Toast ref={toast} />;
}
