import React from "react";
import "./image-view.css";

export function ImageView(props) {
  function closeModal(e) {
    let modal = document.getElementById("modal");
    if (e.target === modal) {
      props.close();
    }
  }

  return (
    <div id="modal" className="image-modal" onClick={closeModal}>
      <span className="close" onClick={() => props.close()}>
        &times;
      </span>
      <div className="modal-content">
        <img src={props.image} alt="receipt" className="image" />
      </div>
    </div>
  );
}
