import "./custom-form.css";
import { Dropdown } from "primereact/dropdown";
import { Optional } from "components/elements/optional/optional";
import { ProgressSpinner } from "primereact/progressspinner";

export function FormDropdown(props) {
  const showError = () => {
    if (props.error && props.required) {
      return <p className="p-error p-text-left">{props.error}</p>;
    } else <small />;
  };
  return (
    <div
      className={`${
        props.isLoading ? "caret-hidden" : "caret-shown"
      } dropdown-wrapper`}
    >
      <Dropdown
        className={props.error ? "validation-error" : ""}
        optionLabel={props.label}
        disabled={props.loading || props.disabled}
        value={props.value}
        options={props.options}
        onChange={(e) => props.fn(e, props.field)}
        placeholder={props.placeholder}
        filter={props.filter}
      />
      <Optional show={props.isLoading}>
        <div className="dropdown-loading-container">
          <ProgressSpinner
            style={{ width: "20px", height: "20px" }}
            strokeWidth="4"
          />
        </div>
      </Optional>
      {showError()}
    </div>
  );
}
