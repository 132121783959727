import React, { useState } from "react";
import { API_SERVICE } from "api/service";
import { FormInput } from "components/form/form-input";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";
import { UserTypes } from "../../../utils/constants";

export function ChangeUserStatus(props) {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [message, setMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);

  function changeUserStatus() {
    setLoading(true);
    const payload = {
      id: props.userDetails.username,
      status: props.userDetails.active === "Active" ? false : true,
    };
    const frontofficeUrl = FRONT_OFFICE_API.SUPER_AGENT_USER.CHANGE_USER_STATUS;
    const backofficeUrl =
      BACK_OFFICE_API.SUPER_AGENT_USER.CHANGE_SUPER_AGENT_USER_STATUS;
    const url =
      props.userType === UserTypes.FrontOffice ? frontofficeUrl : backofficeUrl;
    API_SERVICE.MAKE_PUT_REQUEST(url, payload)
      .then((data) => {
        setSuccessMessage(
          `${props.userDetails.username} has been ${
            props.userDetails.active === "Active" ? "disabled" : "enabled"
          }.`
        );
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setMessageTitle("Error");
        setMessage(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  const actions = () => {
    if (loading) {
      return (
        <div>
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else if (!loading && !showSuccess) {
      return (
        <div className="p-mt-4 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.closeModal} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button
                disabled={username !== props.userDetails.username}
                onClick={changeUserStatus}
                className="primary-button"
              >
                {props.userDetails.active === "Active" ? "Disable" : "Enable"}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const alertView = () => {
    if (messageTitle) {
      return (
        <div>
          <CustomToast
            title={messageTitle}
            description={message}
            type="error"
          />
        </div>
      );
    } else {
      return <div />;
    }
  };

  const changeStatusView = () => {
    if (showSuccess) {
      return (
        <div>
          <CustomMessage
            closeModal={props.closeModal}
            close={true}
            message={successMessage}
            messageType="success"
          />
        </div>
      );
    } else {
      return (
        <div>
          <p className="p-mb-5 p-mt-0 confirm-text">
            Confirm that you want to{" "}
            {props.userDetails.active === "Active" ? "disable" : "enable"} this
            user?
          </p>
          <div>
            <p className="p-text-left">
              To confirm, type <b>{props.userDetails.username}</b> in the text
              input field
            </p>
            <FormInput
              value={username}
              required={false}
              field="reset"
              type="INPUT"
              fn={(e) => setUsername(e?.target?.value)}
              loading={loading}
              placeholder="Username"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="p-my-3">
      <div className="p-pb-1">{alertView()}</div>
      <div>{changeStatusView()}</div>
      <div className="p-mt-5">{actions()}</div>
    </div>
  );
}
