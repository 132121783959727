import { useCallback, useEffect, useRef, useState } from "react";
import { API_SERVICE } from "api/service";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { CreateUser } from "./create-user";
import { ViewUser } from "./view-user";
import { UsersFilter } from "./users-filter";
import { Optional } from "components/elements/optional/optional";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { CreateBulkUsers } from "./create-bulk-users";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function UserManagement() {
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [emptyText, setEmptyText] = useState("");
  const [error, setError] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [users, setUsers] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({
    totalPages: 0,
    totalItems: 0,
    range: 5,
  });
  const toast = useRef(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Username", value: "username" },
    { label: "Email", value: "email" },
    { label: "Created On", value: "createdDate" },
    { label: "Actions", value: "actions" },
  ];
  const [resetFilters, setResetFilters] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const [userRoles, setUserRoles] = useState([]);

  const viewSingleUser = useCallback((e, action, isMobile) => {
    setSelectedUserId(e?.id);
    openModal(1, isMobile);
  }, []);

  const handleResponse = useCallback(
    (result) => {
      if (!result?.content?.length) {
        setEmptyText("No users found!");
      } else {
        let arr = [];
        setPaginationMeta((paginationMeta) => {
          return {
            ...paginationMeta,
            totalPages: result?.totalPages,
            totalItems: result?.totalElements,
          };
        });
        result.content.forEach((user) => {
          arr.push({
            ...user,
            name: `${user.firstname} ${user.lastname}`,
            createdDate: HELPER.FORMAT_DATE_IN_MILLISECONDS(user?.createdOn),
            actions: "VIEW",
            detailsFunction: viewSingleUser,
          });
        });
        setUsers(arr);
      }
      setCurrentIndex(1);
      setLoading(false);
      setError(null);
    },
    [viewSingleUser]
  );

  const handleError = useCallback((error) => {
    setError(HELPER.PROCESS_ERROR(error));
    setLoading(false);
    setCurrentIndex(1);
  }, []);

  const getAllUsers = useCallback(() => {
    setUsers([]);
    setCurrentIndex(0);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    const url = BACK_OFFICE_API.USER_MANAGEMENT.GET_ALL_BACK_OFFICE_USERS;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        handleResponse(data?.result);
      })
      .catch((error) => {
        handleError(error);
      });
  }, [handleResponse, handleError, pageNo]);

  const filterUsers = useCallback(
    (searchBy = "username", payload = null) => {
      if (!searchParams) {
        setSearchParams({
          searchBy,
          payload,
        });
      }
      setIsSearch(true);
      setUsers([]);
      setCurrentIndex(0);
      let params = {
        page: pageNo,
        size: 10,
      };
      let url;
      const getUsersByRoleUrl =
        BACK_OFFICE_API.USER_MANAGEMENT.GET_USERS_BY_ROLE;
      const getUsersByUsernameUrl =
        BACK_OFFICE_API.USER_MANAGEMENT.GET_USER_BY_USERNAME;
      if (searchBy === "role") {
        url = `${getUsersByRoleUrl}/${payload}`;
      } else {
        params = {
          ...params,
          username: payload,
        };
        url = getUsersByUsernameUrl;
      }
      params = HELPER.TO_URL_STRING(params);
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          let result;
          result = data?.result;
          handleResponse(result);
        })
        .catch((error) => {
          handleError(error);
        });
    },
    [handleResponse, handleError, pageNo, searchParams]
  );

  useEffect(() => {
    if (searchParams) {
      filterUsers(searchParams.searchBy, searchParams.payload);
    } else {
      getAllUsers();
    }
  }, [getAllUsers, filterUsers, searchParams]);

  useEffect(() => {
    function getUserRoles() {
      const url = BACK_OFFICE_API.USER_MANAGEMENT.GET_USER_ROLES;
      API_SERVICE.MAKE_GET_REQUEST(url)
        .then((data) => {
          const result = data.result;
          setUserRoles(result);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    }
    getUserRoles();
  }, []);

  function saveSearchFilters(searchBy = "username", payload) {
    setPageNo(0);
    setSearchParams({
      searchBy,
      payload,
    });
  }

  function reload(resetPageNo = true) {
    if (resetPageNo) {
      setPageNo(0);
    }
    setResetFilters(true);
    setLoading(true);
    setIsSearch(false);
    setCurrentIndex(0);
    setSearchParams(null);
    getAllUsers();
  }

  function closeModal(isReload) {
    setVisible(false);
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }
    if (isReload === true) {
      reload(false);
    }
  }

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    // if (isMobile) {
    //   setCurrentIndex(2);
    // } else {
    setVisible(true);
    // }
  }

  const userManagementView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading..." />
          </div>
        );
      case 1:
        return (
          <div className="p-mt-4">
            <CustomTable
              isReload={true}
              totalPages={paginationMeta.totalPages}
              totalItems={paginationMeta.totalItems}
              currentPage={pageNo + 1}
              range={paginationMeta.range}
              emptyText={emptyText}
              search={isSearch}
              reload={reload}
              error={error}
              items={users}
              headers={tableHeaders}
              nextPage={() => setPageNo(pageNo + 1)}
              prevPage={() => setPageNo(pageNo - 1)}
              goToFirstPage={() => setPageNo(0)}
              goToLastPage={() => setPageNo(paginationMeta.totalPages - 1)}
              goToPage={(no) => setPageNo(no)}
            />
          </div>
        );
      default:
        break;
    }
  };

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return <CreateUser closeModal={closeModal} />;
      case 1:
        return (
          <ViewUser
            userId={selectedUserId}
            closeModal={closeModal}
            roles={userRoles}
          />
        );
      case 2:
        return <CreateBulkUsers closeModal={closeModal} />;
      default:
        break;
    }
  };

  return (
    <ErrorBoundary>
      <div className="user-management">
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={closeModal}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        {/* <div className="page-title p-text-left">
          Back Office Users Management
        </div> */}
        <div className="page-header">
          <div className="page-title p-text-left">
            Back Office Users Management
          </div>
          <UserManualViewerButton fileName="manage_users_backoffice_user" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Back Office Users" />
        </div>
        <div className="floating-buttons">
          <div className="p-grid">
            <div className="p-col-6"></div>
            <div className="p-col-6">
              <div className="p-grid">
                <div className="p-col-6 p-d-flex p-jc-end p-ai-center">
                  <Optional
                    show={HELPER.HAS_AUTHORITY(
                      "bo_dcir_create_backoffice_users"
                    )}
                  >
                    <button
                      disabled={false}
                      onClick={() => openModal(2, false)}
                      className="primary-button bulk-creation"
                    >
                      {/* <i className="pi pi-plus" />{" "} */}
                      <span className="hide-btn-text">Bulk User Creation</span>
                    </button>
                  </Optional>
                </div>
                <div className="p-col-6">
                  <Optional
                    show={HELPER.HAS_AUTHORITY(
                      "bo_dcir_create_backoffice_users"
                    )}
                  >
                    <button
                      disabled={false}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      {/* <i className="pi pi-plus" /> */}
                      <span className="hide-btn-text"> New User </span>
                    </button>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Optional show={!loading}>
          <UsersFilter
            onSearch={saveSearchFilters}
            resetFilters={resetFilters}
            roles={userRoles}
          />
        </Optional>
        <div>{userManagementView()}</div>
      </div>
    </ErrorBoundary>
  );
}
