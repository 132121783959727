import { useCallback, useEffect, useState } from "react";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CardSkeletonLoader } from "../../../components/elements/custom-card/card-skeleton-loader";
import { CustomCard } from "../../../components/elements/custom-card/custom-card";
// import { TableEmptyView } from "../../../shared/components/custom-table/table-empty-view";
// import { TableErrorView } from "../../../shared/components/custom-table/table-error-view";
import { TableErrorView } from "../../../components/elements/custom-table/table-error-view";
import { TableEmptyView } from "../../../components/elements/custom-table/table-empty-view";
import { HELPER } from "utils/helper";

export function MerchantsLastTransactionTimes() {
  const [loading, setLoading] = useState(false);
  const [lastTransactionTimes, setLastTransactionTimes] = useState([]);
  const [error, setError] = useState(null);

  const getLastTransactionTimes = useCallback(() => {
    setLoading(true);
    setError(null);
    const url = "/response/merchant/last-transaction-time";
    MONITORING_SERVICE.MAKE_GET_REQUEST(`${url}`)
      .then((data) => {
        setLastTransactionTimes(data?.response?.results);
        setLoading(false);
      })
      .catch((error) => {
        const message = error?.data?.message;
        if (message) {
          setError(message);
          setLoading(false);
          return;
        }
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getLastTransactionTimes();
  }, [getLastTransactionTimes]);

  function renderCurrentView() {
    if (loading) {
      return (
        <div className="p-my-4">
          {" "}
          <CardSkeletonLoader />{" "}
        </div>
      );
    } else {
      return (
        <div>
          <table className="p-mt-0">
            {/* <thead></thead> */}
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Merchant</td>
                <td style={{ fontWeight: "bold" }}>Card Acceptor Id</td>
                <td style={{ fontWeight: "bold" }}>Last Transaction Time</td>
              </tr>
              {lastTransactionTimes.map((transaction, index) => (
                <tr key={index}>
                  <td>{transaction.merchantName}</td>
                  <td>{transaction.cardAcceptorId}</td>
                  <td>{HELPER.FORMAT_DATE(transaction.lastTransactionTime)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loading && !lastTransactionTimes?.length && (
            <TableEmptyView emptyText="No results found!" />
          )}
        </div>
      );
    }
  }

  return (
    <div className="p-text-left">
      <div className="page-title p-text-left">
        Merchant Last Transaction Times
      </div>
      <div className="p-mt-2 p-mb-5">
        <CustomBreadcrumb page="Monitor Last Transaction Times" />
      </div>
      <CustomCard>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="">
            <p className="p-text-bold">Last Transaction Times:</p>
          </div>
        </div> */}
        {!error && renderCurrentView()}
        {!loading && error && (
          <TableErrorView error={error} onReload={getLastTransactionTimes} />
        )}
      </CustomCard>
    </div>
  );
}
