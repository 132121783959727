import "./disputes.css";
import React, {
  useCallback,
  // useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import { DisputeBreakdown } from "../../back-office/disputes/dispute-breakdown";
import { DisputesFilter } from "../../back-office/disputes/disputes-filter";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";

import { HELPER } from "utils/helper";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { DisputeTypes, UserTypes } from "utils/constants";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";

export function FrontOfficeDisputes(props) {
  const toast = useRef(null);
  const op = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [emptyText, setEmptyText] = useState("");
  const [mobile, setMobile] = useState(false);
  const [logCode, setLogCode] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [disputeDetails, setDisputeDetails] = useState([]);
  const [disputes, setDisputes] = useState([]);
  const [disputeExpiringDate, setDisputeExpiringDate] = useState(null);
  const [disputeStatus, setDisputeStatus] = useState(null);
  const [loggedBy, setLoggedBy] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [transactionSearchKey, setTransactionSearchKey] = useState(null);

  const tableHeaders = [
    { label: "Log code", value: "logCode" },
    { label: "Customer Name", value: "customerAccountName" },
    { label: "Amount", value: "transactionAmount" },
    { label: "Transaction Time", value: "transactionTime" },
    { label: "Log Date", value: "createdOn" },
    { label: "Expiry", value: "tatExpiryDate" },
    { label: "Dispute status", value: "status" },
    { label: "Resolution status", value: "resolutionStatus" },
    { label: "Actions", value: "actions" },
  ];

  const populateDetails = useCallback((e) => {
    const arr = [];
    setLogCode(e?.logCode);
    setLoggedBy(e?.loggedBy);
    setDisputeStatus(e?.status);
    setDisputeExpiringDate(e?.tatExpiryDate);
    setReceipt(e?.receiptData);
    setTransactionSearchKey(e?.transactionSearchKey);
    arr.push({
      label: "Resolution status",
      value: e?.resolutionStatus,
      itemCase: "resolutionStatus",
    });
    arr.push({
      label: "Dispute status",
      value: e?.status,
      itemCase: "status",
    });
    arr.push({ label: "Log code", value: e?.logCode });
    arr.push({ label: "Logged by", value: e?.loggedBy });
    arr.push({ label: "Reason", value: e?.reason });
    arr.push({ label: "Resolved by", value: e?.resolvedBy || "___" });
    arr.push({
      label: "Transaction amount",
      value: e?.transactionAmount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Transaction response code",
      value: e?.transactionResponseCode,
      itemCase: "responseCode",
    });
    // arr.push({ label: "Super Agent id", value: e?.merchantId });
    // arr.push({ label: "Super Agent name", value: e?.merchantName });
    arr.push({
      label: "Customer account name",
      value: e?.customerAccountName,
    });
    arr.push({
      label: "Customer account no",
      value: e?.customerAccountNumber,
    });
    arr.push({
      label: "Transaction search key",
      value: e?.transactionSearchKey,
      action: "viewTransaction",
      transactionDetails: e,
    });
    arr.push({
      label: "Transaction time",
      value: e?.transactionTime,
      itemCase: "createdOn",
    });
    if (e?.refundTransaction) {
      arr.push({
        label: "Refund status",
        value: e?.refundTransaction?.status,
        itemCase: "status",
      });
      arr.push({
        label: "Refund amount",
        value: e?.refundTransaction?.amount,
        itemCase: "transactionAmount",
      });
      arr.push({
        label: "Refund destination account",
        value: e?.refundTransaction?.destinationAccount,
      });
      arr.push({
        label: "Refund destination account name",
        value: e?.refundTransaction?.destinationAccountName,
      });
      arr.push({
        label: "Refund source account",
        value: e?.refundTransaction?.sourceAccount,
      });
      arr.push({
        label: "Refund source account name",
        value: e?.refundTransaction?.sourceAccountName,
      });
      arr.push({
        label: "Refund request creation time",
        value: e?.refundTransaction?.createdOn,
        itemCase: "createdOn",
      });
      arr.push({
        label: "Refund last update time",
        value: e?.refundTransaction?.lastUpdatedTime,
        itemCase: "createdOn",
      });
      arr.push({
        label: "Refund attempts",
        value: e?.refundTransaction?.attempts,
      });
      arr.push({
        label: "Refund narration",
        value: e?.refundTransaction?.narration,
      });
    }

    // arr.push({
    //   label: "Created date",
    //   value: e?.createdOn,
    //   itemCase: "createdOn",
    // });
    setDisputeDetails(arr);
  }, []);

  const getTransactionDetails = useCallback(
    (e, isMobile) => {
      populateDetails(e);
      setMobile(!!isMobile);
      setCurrentIndex(3);
    },
    [populateDetails]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [getTransactionDetails]
  );

  const handleDisputeResponse = useCallback(
    (data) => {
      const result = data?.result;
      if (!result.content.length) {
        setEmptyText("No disputes found ...");
      } else {
        let arr = [];
        setTotalItems(result?.totalElements);
        setTotalPages(result?.totalPages);
        result?.content.forEach((e) => {
          arr.push({
            ...e,
            actions: "CR",
            detailsFunction: openAction,
          });
        });
        setDisputes(arr);
      }
      setError(null);
      setCurrentIndex(1);
      setLoading(false);
    },
    [openAction]
  );

  const getDisputes = useCallback(() => {
    setCurrentIndex(0);
    setVisible(false);
    setLoading(true);
    setDisputes([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);

    const frontofficeDisputesUrl =
      FRONT_OFFICE_API.DISPUTE.GET_DISPUTES_BY_SUPER_AGENT;
    const frontofficeBackofficeDisputeUrl =
      FRONT_OFFICE_API.DISPUTE.GET_DISPUTES_BY_BACK_OFFICE;
    const url =
      props.disputeType === DisputeTypes.AgentDisputes
        ? frontofficeDisputesUrl
        : frontofficeBackofficeDisputeUrl;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        handleDisputeResponse(data);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, props.disputeType, handleDisputeResponse]);

  const searchDisputes = useCallback(() => {
    setCurrentIndex(0);
    setVisible(false);
    setLoading(true);
    setDisputes([]);
    setSearch(true);
    let payload = {
      ...searchParams,
    };
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    const frontofficeDisputesUrl =
      FRONT_OFFICE_API.DISPUTE.GET_FILTERED_DISPUTES_BY_SUPER_AGENT;
    const frontofficeBackofficeDisputeUrl =
      FRONT_OFFICE_API.DISPUTE.GET_FILTERED_DISPUTES_BY_BACK_OFFICE;
    const url =
      props.disputeType === DisputeTypes.AgentDisputes
        ? frontofficeDisputesUrl
        : frontofficeBackofficeDisputeUrl;
    API_SERVICE.MAKE_PUT_REQUEST(`${url}?${params}`, payload)
      .then((data) => {
        handleDisputeResponse(data);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, searchParams, props.disputeType, handleDisputeResponse]);

  useEffect(() => {
    if (searchParams) {
      searchDisputes();
    } else {
      getDisputes();
    }
  }, [getDisputes, searchDisputes, searchParams]);

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function onHide() {}

  function openModal(index, isMobile) {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }

  function saveSearchParams(params) {
    setPageNo(0);
    setSearchParams(params);
  }

  function reload() {
    setLoading(true);
    setCurrentIndex(0);
    if (searchParams) {
      searchDisputes();
    } else {
      getDisputes();
    }
  }

  function reset() {
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getDisputes();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload === true) {
      reload();
    }
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <DisputesFilter
            disputeType={props.disputeType}
            searchFunction={saveSearchParams}
            closeModal={closeModal}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 1:
        return (
          <DisputesFilter
            disputeType={props.disputeType}
            searchFunction={saveSearchParams}
            closeModal={closeModal}
          />
        );
    }
  };

  const disputeView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading disputes..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reset}
            error={error}
            items={disputes}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <DisputeBreakdown
            loggedBy={loggedBy}
            disputeExpiringDate={disputeExpiringDate}
            disputeStatus={disputeStatus}
            logCode={logCode}
            disputeDetails={disputeDetails}
            goBack={goBack}
            mobile={mobile}
            receipt={receipt}
            disputeType={props.disputeType}
            transactionSearchKey={transactionSearchKey}
            userType={UserTypes.FrontOffice}
          />
        );
    }
  };

  const filterView = () => {
    if (currentIndex === 1) {
      return (
        <button
          disabled={loading}
          onClick={() => openModal(1, false)}
          className="primary-button"
        >
          <i className="pi pi-filter" />
          <span className="hide-btn-text"> Filter</span>
        </button>
      );
    } else {
      return <div />;
    }
  };

  const viewMobileFilter = () => {
    if (currentIndex === 1) {
      return (
        <div className="floating-mobile-buttons add-cursor">
          <i
            onClick={(e) => op.current.toggle(e)}
            className="pi pi-ellipsis-v"
            style={{ fontSize: "1.5em", color: "#464DF2" }}
          />
          <OverlayPanel
            ref={op}
            id="overlay_panel"
            style={{ width: "100px" }}
            className="overlaypanel-demo"
          >
            <div className="p-mb-2 p-ml-1">
              <span
                onClick={() => openModal(1, true)}
                className="custom-over-flow-text"
              >
                <i className="pi pi-filter" /> Filter
              </span>
            </div>
          </OverlayPanel>
        </div>
      );
    } else {
      return <div />;
    }
  };

  function goBack(refresh) {
    if (refresh === true) {
      reload();
    }
    setCurrentIndex(1);
  }

  return (
    <ErrorBoundary>
      <div className="disputes">
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
<div className="page-title p-text-left">
{props.disputeType === DisputeTypes.AgentDisputes ? "Agent" : "Bank"} Disputes
</div>
<UserManualViewerButton fileName={ props.disputeType === DisputeTypes.AgentDisputes
  ? "front_office_manage_disputes_agent_disputes"
  : "front_office_manage_disputes_bank_disputes"}/>
  <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Disputes" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">{filterView()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-scree dcir-hide">
          <div className="p-grid">
            <div className="p-col-9"></div>
            <div className="p-col-3">{viewMobileFilter()}</div>
          </div>
        </div>
        <div>{disputeView()}</div>
      </div>
    </ErrorBoundary>
  );
}
