import moment from "moment";
// import { HELPER } from "../../../shared/helper/helper";
import { HELPER } from "utils/helper";

function getChartOptions(
  chartTitle,
  callbacks = false,
  ticks = false,
  line = false
) {
  const options = {
    plugins: {
      legend: {
        labels: {
          //this removes the aptent response time from the chart legend
          filter: function (item, chart) {
            return !item.text.includes("Aptent Response Time");
          },
        },
      },

      tooltip: {
        callbacks: callbacks,
      },
      title: {
        display: true,
        text: `${chartTitle}`,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: line ? false : true,
      },
      y: {
        ticks: ticks,
        stacked: line ? false : true,
      },
    },
  };
  return options;
}

const getValPercentage = (val, total) => {
  return Math.max(
    parseFloat(((Number(val) * 100) / Number(total)).toFixed(1)),
    0
  );
};

function formatCurrentDayProcessingMetrics(data, valueType = "count", today) {
  const chartOptions = getChartOptions(
    `Transactions Processing metrics by ${valueType} ${
      today ? moment().format("HH:mm") : ""
    }`,
    {
      label: (context) => {
        // this operation formats the value seen in the tooltip on the chart
        if (context?.dataset?.label === "Success Rates") {
          const dataLabelTimeorDate = context?.label;
          const successRate = data?.filter(
            (e) =>
              (today ? e?.time : HELPER.FORMAT_DATE_ONLY(e?.dateOrTime)) ===
              dataLabelTimeorDate
          );

          const successRateSuccessCount =
            context?.chart?.config?.options?.plugins?.title?.text?.includes(
              "percentage"
            )
              ? getValPercentage(
                  successRate[0]?.successCount,
                  successRate[0]?.totalCount
                )
              : successRate[0]?.successCount;
          return `${context?.dataset?.label}: ${successRateSuccessCount}${
            valueType === "count" ? "" : "%"
          }`;
        } else {
          return `${context.dataset.label}: ${context.parsed.y}${
            valueType === "count" ? "" : "%"
          }`;
        }
      },
    },
    {
      callback: (value) => `${value}${valueType === "count" ? "" : "%"}`,
    }
  );
  const labels = data?.map((i) =>
    today ? i.time : HELPER.FORMAT_DATE_ONLY(i.dateOrTime)
  );

  let metricsObj = {
    successByCount: [],
    successByPercentage: [],
    approvedByCount: [],
    approvedByPercentage: [],
    failureByCount: [],
    failureByPercentage: [],
  };
  data?.forEach((e) => {
    metricsObj.approvedByPercentage.push(
      getValPercentage(e?.approvedCount, e?.totalCount)
    );
    metricsObj.approvedByCount.push(e?.approvedCount);
    metricsObj.successByPercentage.push(
      getValPercentage(e?.successCount - e?.approvedCount, e?.totalCount)
    );
    metricsObj.successByCount.push(
      Math.max(e?.successCount - e?.approvedCount, 0)
    );
    metricsObj.failureByPercentage.push(
      getValPercentage(e?.failureCount, e?.totalCount)
    );
    metricsObj.failureByCount.push(e?.failureCount);
  });
  const {
    successByCount,
    successByPercentage,
    approvedByCount,
    approvedByPercentage,
    failureByCount,
    failureByPercentage,
  } = metricsObj;
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Approved Rates",
        data: valueType === "count" ? approvedByCount : approvedByPercentage,

        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Success Rates",
        data: valueType === "count" ? successByCount : successByPercentage,
        backgroundColor: "rgb(152, 226, 226)",
      },

      {
        label: "Failure Rates",
        data: valueType === "count" ? failureByCount : failureByPercentage,

        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };
  return { chartOptions, chartData };
}

function formatCurrentDayAvgResponseMetrics(data, today) {
  const chartOptions = getChartOptions(
    "",
    {
      label: (context) => {
        // this operation formats the value seen in the tooltip on the chart
        if (context?.dataset?.label === "Aptent Response Time") {
          const dataLabelTimeorDate = context?.label;
          const currAptentResponseTime = data?.filter(
            (e) =>
              (today ? e?.time : HELPER.FORMAT_DATE_ONLY(e?.dateOrTime)) ===
              dataLabelTimeorDate
          );
          const aptentAvgResponseTime =
            Math.max(
              currAptentResponseTime[0]?.aptentResponseDurationAvg / 1000,
              0
            ) -
            Math.max(
              currAptentResponseTime[0]?.bankResponseDurationAvg / 1000,
              0
            );
          return `${context?.dataset?.label}: ${aptentAvgResponseTime}secs`;
        } else {
          return `${context.dataset.label}: ${context.parsed.y}secs`;
        }
      },
    },
    {
      callback: (value) => `${value}secs`,
    },
    true
  );
  const labels = data.map((i) =>
    today ? i.time : HELPER.FORMAT_DATE_ONLY(i.dateOrTime)
  );

  let metricsObj = {
    banksAvgResponseTime: [],
    totalResponseTime: [],
  };
  data.forEach((item) => {
    metricsObj.banksAvgResponseTime.push(
      Math.max(item.bankResponseDurationAvg / 1000, 0)
    );
    metricsObj.totalResponseTime.push(
      Math.max(item.aptentResponseDurationAvg / 1000, 0)
    );
  });
  const { banksAvgResponseTime, totalResponseTime } = metricsObj;
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Issuing System",
        data: banksAvgResponseTime,
        borderColor: "rgb(255, 99, 132)",
        fill: "origin",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Total Response Time",
        data: totalResponseTime,
        fill: "origin",
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Aptent Response Time",
        data: totalResponseTime,
        borderColor: "rgb(160, 208, 245)",
        backgroundColor: "rgba(160, 208, 245, 0.5)",
      },
    ],
  };
  return { chartOptions, chartData };
}

export const TRANSACTION_PROCESSING_HELPERS = {
  FORMAT_CURRENT_DAY_METRICS: formatCurrentDayProcessingMetrics,
  FORMAT_CURRENT_DAY_AVG_RESPONSE_TIME_METRICS:
    formatCurrentDayAvgResponseMetrics,
};
