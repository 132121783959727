import axios from "axios";
// import { CONFIG } from "../../shared/config/config";
import { CONFIG } from "../../config/config";
// import { RECON_API } from "../apis/backoffice-apis";
// import { getAuthHeaders } from "./api-header";
import { getAuthHeaders } from "../client";
import { RECON_API } from "../routes/back-office/reconciliation";
const Axios = axios.create({
  baseURL: CONFIG.BASE_URL,
});

function uploadReconciliationFile(payload) {
  const url = RECON_API.UPLOAD_RECONCILIATION_FILE;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUploadReconciliationFiles(params) {
  const url = `${RECON_API.GET_UPLOADED_RECONCILIATION_FILES}?${params}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getUploadReconciliationFilesByDays(params) {
  const url = `${RECON_API.GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS}?${params}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconFileUploadErrors(fileKey, params) {
  const url = `${RECON_API.GET_RECON_FILE_UPLOAD_ERRORS}/${fileKey}?${params}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconUploadsByDay(params) {
  const url = `${RECON_API.GET_RECON_UPLOADS_BY_DAY}?${params}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function checkOrGenerateReconReport(payload) {
  const url = `${RECON_API.CHECK_OR_GENERATE_RECON_REPORT}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function downloadReconStatement(downloadKey) {
  const url = `${RECON_API.DOWNLOAD_RECON_STATEMENT}/${downloadKey}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header, responseType: "blob" })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconFileAccountEntries(payload) {
  const url = `${RECON_API.GET_RECON_FILE_ACCOUNT_ENTRIES}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}
function getReconFileTransactionMetrics(fileKey, params) {
  const url = `${RECON_API.GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS}/${fileKey}?${params}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getReconConfigurations() {
  const url = `${RECON_API.RECON_CONFIGURATIONS}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function updateReconConfiguration(payload) {
  const url = `${RECON_API.RECON_CONFIGURATIONS}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function getDailyCreditDebitSummaries(params) {
  const url = `${RECON_API.GET_DAILY_CREDIT_DEBIT_SUMMARIES}?${params}`;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makeGETRequest(url, params) {
  const header = getAuthHeaders();
  url = params ? `${url}?${params}` : url;
  return new Promise((resolve, reject) => {
    Axios.get(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makePUTRequest(url, payload, params, path) {
  // const url = `${BACKOFFICE_API.RECON_CONFIGURATIONS}`;
  url = path ? `${url}/${path}` : url;
  url = params ? `${url}?${params}` : url;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.put(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function makePOSTRequest(url, payload, params, path) {
  url = path ? `${url}/${path}` : url;
  url = params ? `${url}?${params}` : url;
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    Axios.post(url, payload, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const BACKOFFICE_SERVICE = {
  UPLOAD_RECONCILIATION_FILE: uploadReconciliationFile,
  GET_UPLOADED_RECONCILIATION_FILES: getUploadReconciliationFiles,
  GET_UPLOADED_RECONCILIATION_FILES_BY_DAYS: getUploadReconciliationFilesByDays,
  GET_RECON_FILE_UPLOAD_ERRORS: getReconFileUploadErrors,
  GET_RECON_UPLOADS_BY_DAY: getReconUploadsByDay,
  GET_RECON_CONFIGURATIONS: getReconConfigurations,
  UPDATE_RECON_CONFIGURATION: updateReconConfiguration,
  GET_RECON_FILE_ACCOUNT_ENTRIES: getReconFileAccountEntries,
  GET_RECON_FILE_TRANSACTION_METRICS: getReconFileTransactionMetrics,
  DOWNLOAD_RECON_STATEMENT: downloadReconStatement,
  CHECK_OR_GENERATE_RECON_REPORT: checkOrGenerateReconReport,
  GET_DAILY_CREDIT_DEBIT_SUMMARIES: getDailyCreditDebitSummaries,
  MAKE_GET_REQUEST: makeGETRequest,
  MAKE_PUT_REQUEST: makePUTRequest,
  MAKE_POST_REQUEST: makePOSTRequest,
};
