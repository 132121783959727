import { useState } from "react";
import { API_SERVICE } from "api/service";
import { CustomMessage } from "components/elements/alert/custom-message";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { Optional } from "components/elements/optional/optional";
import { HELPER } from "utils/helper";
import { SpecialLabelCases } from "utils/models/utilities";
import { TabPanel, TabView } from "primereact/tabview";
import { Icon } from "components/elements/icons/icon";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { RequeryActionConfirmation } from "./requery-action-confirmation";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function RequeryRequestDetails(props) {
  const [requestData] = useState(props.requestData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [actionType, setActionType] = useState("SUCCESSFUL");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [responseMessage, setResponseMessage] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  function submit() {
    setLoading(true);
    let params;
    if (props.transactionType === "disputes") {
      params = HELPER.TO_URL_STRING({
        disputeStatus: actionType,
      });
    } else {
      params = HELPER.TO_URL_STRING({
        settlementStatus: actionType,
      });
    }
    const { UPDATE_DISPUTE_ON_REQUERY, UPDATE_SETTLEMENT_ON_REQUERY } =
      BACK_OFFICE_API.REQUERY_MANAGEMENT;
    const url =
      props.transactionType === "disputes"
        ? UPDATE_DISPUTE_ON_REQUERY
        : UPDATE_SETTLEMENT_ON_REQUERY;
    API_SERVICE.MAKE_PUT_REQUEST(`${url}/${props.transactionId}?${params}`)
      .then((data) => {
        setResponseMessage(data?.result?.message);
        setLoading(false);
        setCurrentIndex(2);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function markTransaction(action) {
    setActionType(action);
    // setCurrentIndex(1);
    setIsModalVisible(true);
  }

  function showBulkSettlement(bulkSettlementKey) {
    props.onShowBulkSettlement(bulkSettlementKey);
  }

  function closeModal() {
    setIsModalVisible(false);
  }

  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          if (value) {
            result = e.action(value);
          }
        }
      });
    }
    return result ? result : "___";
  };

  const requestDetails = requestData.map((details, index) => (
    <div key={index.toString()} className="p-grid dcir-row">
      <div className="p-col-5 p-pl-0">
        <p className="details-label">{details.label}:</p>
      </div>
      <div className="p-col-7 p-pl-0">
        <Optional show={details.action === "viewSettlement"}>
          <p
            className="details-value cursor-pointer underline"
            style={{ color: "#0371A0" }}
            onClick={() => showBulkSettlement(details.bulkSettlementKey)}
          >
            {transformView(details.itemCase, details.value)}
          </p>
        </Optional>
        <Optional show={!details.action}>
          <div className="flex default">
            <p className="details-value">
              {transformView(details.itemCase, details.value)}
            </p>
          </div>
        </Optional>
      </div>
    </div>
  ));

  function requestDetailsView() {
    switch (currentIndex) {
      case 0:
        return (
          <div
            style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
            className="p-shadow-1 p-pb-5"
          >
            <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
              <span onClick={props?.goBack} className="add-cursor">
                <span>
                  <Icon icon="go-back-icon" />
                </span>
              </span>
            </div>
            <TabView>
              <TabPanel
                header={`${
                  props.transactionType === "disputes"
                    ? "Dispute Refund"
                    : "Transaction Settlement"
                }
                      Request Data`}
              >
                <div>
                  <div className="p-grid p-mt-2">
                    <div className="p-col-8" />
                    <div className="p-col-4">
                      <div className="p-grid">
                        <Optional
                          show={
                            !loading &&
                            HELPER.HAS_AUTHORITY(
                              "bo_update_dispute_refunds_on_awaiting_requery" ||
                                HELPER.HAS_AUTHORITY(
                                  "bo_update_settlements_on_awaiting_requery"
                                )
                            )
                          }
                        >
                          <div className="p-col-6 p-pl-0">
                            {/* {cancelButton()} */}
                            <button
                              className="secondary-button"
                              onClick={() => markTransaction("FAILED")}
                            >
                              Mark as failed
                            </button>
                          </div>
                          <div className="p-col-6 p-pr-0">
                            {/* {submitButton()} */}
                            <button
                              className="primary-button"
                              onClick={() => markTransaction("SUCCESSFUL")}
                            >
                              Mark as successful
                            </button>
                          </div>
                        </Optional>
                      </div>
                    </div>
                  </div>

                  <div className="p-mt-5 p-mb-3">{requestDetails}</div>
                </div>
              </TabPanel>
            </TabView>
          </div>
        );
      case 1:
        return (
          <div>
            <div>
              <h3>Confirm Action.</h3>
            </div>
            <div>
              <p>
                Confirm that you want to mark this transaction as{" "}
                {actionType.toLowerCase()}!
              </p>
            </div>
            <Optional show={error}>
              <div className="p-col-12">
                <CustomToast title="Error" description={error} type="error" />
              </div>
            </Optional>
            <div className="p-col-12">
              <div className="p-mt-5">
                <div className="p-grid">
                  <Optional show={!loading}>
                    <div className={loading ? "p-col-12" : "p-col-6"}>
                      {/* {cancelButton()} */}
                      <button
                        className="secondary-button"
                        onClick={() => setCurrentIndex(0)}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className={loading ? "p-col-12" : "p-col-6"}>
                      {/* {submitButton()} */}
                      <button className="primary-button" onClick={submit}>
                        Confirm
                      </button>
                    </div>
                  </Optional>
                  <Optional show={loading}>
                    <div className="p-col-12 p-text-center">
                      <CustomLoader loadingText="Loading..." />
                    </div>
                  </Optional>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="p-text-center p-mt-2">
            <div>
              <CustomMessage messageType="success" />
            </div>
            <div className="success-message-btn-container p-text-center">
              <p>{responseMessage}</p>
              <p
                className="close-modal add-cursor p-mt-3"
                onClick={() => props.closeModal(true)}
              >
                Close
              </p>
            </div>
          </div>
        );
      default:
        break;
    }
  }

  const modalContent = () => {
    return (
      <RequeryActionConfirmation
        actionType={actionType}
        transactionType={props.transactionType}
        transactionId={props.transactionId}
        closeModal={closeModal}
      />
    );
  };

  return (
    <>
      <CustomModal
        closeModal={closeModal}
        onHide={closeModal}
        visible={isModalVisible}
        modalContent={modalContent}
      />
      <div className="p-text-left requery-management p-px-2">
        {requestDetailsView()}
      </div>
    </>
  );
}
