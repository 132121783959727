const GET_PARTICIPANT_USERS = "/backoffice/settlement-participant-user";
const CREATE_PARTICIPANT_USER = "/backoffice/settlement-participant-user";
const UPDATE_PARTICIPANT_USER = "/backoffice/settlement-participant-user";
const GET_PARTICIPANTS_FOR_USER =
  "/backoffice/settlement-participant-user/user";
const GET_USER_PARTICIPANTS =
  "/backoffice/settlement-participant-user/participants";
const VALIDATE_PARTICIPANT_USERNAME =
  "/backoffice/settlement-participant-user/validate";

export const PARTICIPANT_USERS_ROUTES = {
  GET_PARTICIPANT_USERS,
  CREATE_PARTICIPANT_USER,
  UPDATE_PARTICIPANT_USER,
  GET_PARTICIPANTS_FOR_USER,
  GET_USER_PARTICIPANTS,
  VALIDATE_PARTICIPANT_USERNAME,
};
