import React, { useCallback, useEffect, useState } from "react";
import { API_SERVICE } from "api/service";
import { HELPER } from "../../../utils/helper";
import { Icon } from "../../../components/elements/icons/icon";
import { CustomLoader } from "../../../components/elements/custom-loader/custom-loader";
import { CustomTable } from "../../../components/elements/custom-table/custom-table";
import { TabPanel, TabView } from "primereact/tabview";
import { DownloadConfirmation } from "./download-bulk-transaction-confirmation";
import { useWindowSize } from "../../../hooks/use-window-size";
import { BulkTransactionBreakDown } from "../bulk-settlements/bulk-transaction-break-down";
import { CustomModal } from "../../../components/elements/custom-modal/custom-modal";
import { BulkTransactionsFilter } from "./bulk-transactions-filter";
import { DisputeBreakdown } from "../disputes/dispute-breakdown";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function BulkDisputeTransactions(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [bulkTransactions, setBulkTransactions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [emptyText, setEmptyText] = useState("No transactions found...");
  const [search, setSearch] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const windowSize = useWindowSize();
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchParams, setSearchParams] = useState(null);
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [bulkUploadSummary, setBulkUploadSummary] = useState({
    failed: 0,
    pending: 0,
    successful: 0,
  });
  const [disputeLogCode, setDisputeLogCode] = useState(null);

  const tableHeaders = [
    { label: "Transaction Date", value: "transactionTime", type: "dateOnly" },
    { label: "Masked pan", value: "maskedPan" },
    { label: "Rrn", value: "rrn" },
    { label: "Amount", value: "amount" },
    { label: "Terminal id", value: "terminalId" },
    { label: "Dispute creation status", value: "disputeCreationStatus" },
    { label: "Log Code", value: "disputeLogCode" },
    // { label: "Creation attempts", value: "creationAttempts" },
    { label: "Actions", value: "actions" },
  ];

  const getTransactionDetails = useCallback((e, isMobile) => {
    let arr = [];
    setTransactionDetails([]);
    arr.push({
      label: "Transaction amount",
      value: e?.amount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Transaction time",
      value: e?.transactionTime,
      itemCase: "transactionTime",
    });
    arr.push({
      label: "Dispute creation status",
      value: e?.disputeCreationStatus,
      itemCase: "disputeCreationStatus",
    });
    arr.push({
      label: "Dispute creation message",
      value: e?.creationMessage,
    });
    arr.push({
      label: "Dispute log code",
      value: e?.disputeLogCode,
      itemCase: "disputeLogCode",
    });
    arr.push({
      label: "Creation attempts",
      value: e?.creationAttempts,
    });
    arr.push({ label: "Masked pan", value: e?.maskedPan });
    arr.push({ label: "Rrn", value: e?.rrn });
    arr.push({ label: "Stan", value: e?.stan });
    arr.push({ label: "Terminal id", value: e?.terminalId });
    setDisputeLogCode(e?.disputeLogCode);
    setTransactionDetails(arr);
    setCurrentIndex(2);
  }, []);

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          getTransactionDetails(e, isMobile);
          break;
        }
      }
    },
    [getTransactionDetails]
  );

  const getBulkSettlementTransactions = useCallback(
    (searchParams) => {
      setCurrentIndex(0);

      let payload = {};
      if (searchParams) {
        setBulkTransactions([]);
        setVisible(false);
        setSearch(true);
        payload = {
          ...searchParams,
        };
      }
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
      });
      const { GET_BULK_DISPUTE_TRANSACTIONS } = BACK_OFFICE_API.BULK_DISPUTE;

      API_SERVICE.MAKE_PUT_REQUEST(
        `${GET_BULK_DISPUTE_TRANSACTIONS}/${props.batchId}?${params}`,
        payload
      )
        .then((data) => {
          const result = data?.result?.content;
          if (!result.length) {
            setEmptyText("No transactions found ...");
          } else {
            let arr = [];
            setTotalItems(data?.result.totalElements);
            setTotalPages(data?.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setBulkTransactions(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          setLoading(false);
          setCurrentIndex(1);
        });
    },
    [pageNo, openAction, props.batchId]
  );

  useEffect(() => {
    if (searchParams) {
      getBulkSettlementTransactions(searchParams);
    } else {
      getBulkSettlementTransactions();
    }
  }, [getBulkSettlementTransactions, searchParams]);

  useEffect(() => {
    function getBulkDisputesUploadSummary() {
      const { GET_BULK_DISPUTE_UPLOAD_SUMMARY } = BACK_OFFICE_API.BULK_DISPUTE;
      API_SERVICE.MAKE_GET_REQUEST(
        `${GET_BULK_DISPUTE_UPLOAD_SUMMARY}/${props.batchId}`
      )
        .then((data) => {
          const result = data?.result;
          const summaryObj = {
            pending: result?.PENDING,
            successful: result?.SUCCESSFUL,
            failed: result?.FAILED,
          };
          setBulkUploadSummary(summaryObj);
        })
        .catch((error) => {
          // setError(HELPER.PROCESS_ERROR(error));
          console.log("error", error);
        });
    }

    getBulkDisputesUploadSummary();
  }, [props.batchId]);

  function reload() {
    setSearchParams(null);
    setPageNo(0);
    setLoading(true);
    setSearch(false);
    getBulkSettlementTransactions(null, true);
  }

  function isTabMobile() {
    return windowSize.width <= 948;
  }

  function goBack() {
    setCurrentIndex(1);
  }

  function openModal(index) {
    setCurrentModalIndex(index);
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }

  function saveSearchParams(params) {
    setSearchParams(params);
  }

  function showDisputeDetails() {
    setCurrentIndex(3);
  }

  function onHide() {}

  const modalContent = () => {
    switch (currentModalIndex) {
      case 0:
        return (
          <BulkTransactionsFilter
            searchFunction={saveSearchParams}
            closeModal={closeModal}
            batchId={props.batchId}
          />
        );
      case 1:
        return (
          <DownloadConfirmation
            closeModal={closeModal}
            batchId={props.batchId}
          />
        );
      default:
        return;
    }
  };

  const transactionsView = () => {
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading transactions..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={bulkTransactions}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(no) => setPageNo(no)}
          />
        );
      case 2:
        return (
          <BulkTransactionBreakDown
            goBack={goBack}
            isMobile={props.isMobile}
            details={transactionDetails}
            showDisputeDetails={showDisputeDetails}
          />
        );
      case 3:
        return (
          <div>
            <div className="p-text-left">
              <p
                onClick={() => setCurrentIndex(2)}
                className="close-modal add-cursor p-ml-4"
              >
                <i className="pi pi-arrow-left"></i>
              </p>
            </div>
            <DisputeBreakdown
              logCode={disputeLogCode}
              getDisputeByLogCode={true}
            />
          </div>
        );
      default:
        return;
    }
  };

  const bulkDisputeTransactionsView = () => {
    return (
      <div
        style={{
          backgroundColor: "#ffffff",
          paddingTop: "1em",
          paddingBottom: currentIndex === 0 ? "8rem" : "2rem",
        }}
        className="p-shadow-1"
      >
        <div className="p-text-left p-ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header={isTabMobile() ? "Transactions" : "Transactions"}>
            <div className="">
              <div className="p-grid p-mt-2">
                <div className="p-col-8" />
                <div className="p-col-4">
                  <div
                    style={{
                      width: "135px",
                      float: "right",
                    }}
                  >
                    {HELPER.HAS_AUTHORITY("bo_dcir_create_dispute_transaction")}
                  </div>
                </div>
              </div>
              <div className="p-grid">
                <div className="p-col-12">
                  <div>
                    <div className="p-text-left">
                      <p
                        className={
                          isTabMobile()
                            ? "dcir-show custom-title p-ml-4"
                            : "dcir-hide"
                        }
                      >
                        Transaction Details
                      </p>
                    </div>
                    {currentIndex === 1 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          marginBottom: "2rem",
                        }}
                      >
                        {bulkTransactions.length > 0 && (
                          <button
                            onClick={() => openModal(1)}
                            className="download-button"
                            style={{ fontSize: "0.75rem", marginRight: "1rem" }}
                          >
                            <i className="pi pi-download"></i> Download
                            Transactions
                          </button>
                        )}
                        <button
                          disabled={loading}
                          onClick={() => openModal(0)}
                          className="primary-button"
                          style={{
                            width: "135px",
                            fontSize: "0.75rem",
                          }}
                        >
                          <i className="pi pi-filter"></i> Filter
                        </button>
                      </div>
                    )}
                    {currentIndex === 1 && (
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "30px",
                        }}
                      >
                        <div>
                          <p>Successful</p>
                          <div
                            className="custom-badge custom-badge-text custom-badge-success"
                            style={{ width: "3rem", margin: "0 auto" }}
                          >
                            {bulkUploadSummary.successful}
                          </div>
                        </div>
                        <div className="p-ml-4">
                          <p>Failed</p>
                          <div
                            className="custom-badge custom-badge-text custom-badge-error"
                            style={{ width: "3rem", margin: "0 auto" }}
                          >
                            {bulkUploadSummary.failed}
                          </div>
                        </div>
                        <div className="p-ml-4">
                          <p>Pending</p>
                          <div
                            className="custom-badge custom-badge-text custom-badge-pending"
                            style={{ width: "3rem", margin: "0 auto" }}
                          >
                            {bulkUploadSummary.pending}
                          </div>
                        </div>
                      </div>
                    )}
                    {transactionsView()}
                  </div>
                </div>

                <div className="p-col-12">
                  <div style={{ marginTop: "-2.2em" }}>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    );
  };
  return (
    <div>
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={onHide}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div>{bulkDisputeTransactionsView()}</div>
    </div>
  );
}
