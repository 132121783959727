import { SpecialLabelCases } from "utils/models/utilities";

export function BulkTransactionBreakDown(props) {
  const transformView = (itemCase, value) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          result = e.action(value);
        }
      });
    }
    return result;
  };

  const detailsView = () => {
    return props.details.map((details, index) => (
      <div key={index.toString()} className="p-grid dcir-row">
        <div className="p-col-6">
          <p className="details-label">{details.label}:</p>
        </div>
        <div className="p-col-6">
          <p className="details-value">
            {transformView(details.itemCase, details.value)}
          </p>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <div className="p-text-left">
        <p
          onClick={() => props.goBack()}
          className="close-modal add-cursor p-ml-4"
        >
          <span>
            <i className="pi pi-arrow-left"></i>
          </span>
        </p>
        <p className="custom-title p-ml-4">Transaction Details</p>
      </div>
      <div className="centralize p-text-left">{detailsView()}</div>
    </div>
  );
}
