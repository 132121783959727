import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { Toast } from "primereact/toast";
import { HELPER } from "utils/helper";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { Optional } from "components/elements/optional/optional";
import { API_SERVICE } from "api/service";
import { ManualPaymentTypes } from "utils/constants";
// import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { BACK_OFFICE_API } from "api/routes/back-office/index";
import { ManualPaymentBulkUploadItems } from "./bulk-upload-items";
import { ManuelPaymentsBulkFileUpload } from "./bulk-file-upload";

export function ManualPaymentsBulkUploads(props) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [pageNo, setPageNo] = useState(0);
  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const tableHeaders = [
    { label: "Uploaded By", value: "uploadedBy" },
    { label: "Batch Id", value: "batchId" },
    { label: "Uploaded At", value: "uploadedAt" },
    { label: "Actions", value: "actions" },
  ];
  const bulkDisputeAuthorities = [
    { label: "UPDATE", value: "bo_dcir_update_participant" },
    { label: "DELETE", value: "bo_dcir_remove_participant" },
  ];
  const [bulkUploads, setBulkUploads] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState(null);

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  const openAction = useCallback((e, action) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case "VIEW": {
        // viewBulk(e.participantId, isMobile);
        setSelectedBatchId(e?.batchId);
        setCurrentIndex(3);
        break;
      }
    }
  }, []);

  const getUrl = useCallback(() => {
    if (props.manualPaymentType === ManualPaymentTypes.Dispute) {
      return BACK_OFFICE_API.MANUAL_DISPUTE_REFUND
        .GET_DISPUTE_REFUND_BULK_UPLOADS;
    } else {
      return BACK_OFFICE_API.MANUAL_SETTLEMENT_PAYMENT
        .GET_MANUAL_SETTLEMENT_BULK_UPLOADS;
    }
  }, [props.manualPaymentType]);

  const getBulkUploads = useCallback(() => {
    setCurrentIndex(0);
    setBulkUploads([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    const url = getUrl();
    API_SERVICE.MAKE_GET_REQUEST(url, params)
      .then((data) => {
        const result = data?.result;
        if (!result?.content?.length) {
          setEmptyText("No bulks found...");
        } else {
          let arr = [];
          setTotalItems(result?.totalElements);
          setTotalPages(result?.totalPages);
          result?.content?.forEach((e) => {
            arr.push({
              ...e,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setBulkUploads(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, openAction, getUrl]);

  useEffect(() => {
    getBulkUploads();
  }, [getBulkUploads]);

  function reload() {
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getBulkUploads();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <ManuelPaymentsBulkFileUpload
            closeModal={closeModal}
            manualPaymentType={props.manualPaymentType}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <ManuelPaymentsBulkFileUpload
            closeModal={closeModal}
            manualPaymentType={props.manualPaymentType}
          />
        );
    }
  };

  const renderBulkUploadsView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            authorities={bulkDisputeAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={bulkUploads}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
      case 3:
        return (
          <ManualPaymentBulkUploadItems
            batchId={selectedBatchId}
            goBack={() => setCurrentIndex(1)}
            manualPaymentType={props.manualPaymentType}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={closeModal}
            visible={visible}
            modalContent={modalContent}
          />
        </div>

        <div style={{ marginTop: "3rem" }}>
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              {currentIndex === 1 && (
                <div className="p-grid">
                  <div className="p-col-6 p-d-flex p-jc-end p-ai-center"></div>
                  <div className="p-col-6">
                    <Optional
                      show={
                        HELPER.HAS_AUTHORITY(
                          "bo_process_manual_dispute_refund"
                        ) ||
                        HELPER.HAS_AUTHORITY(
                          "bo_process_manual_payment_request"
                        )
                      }
                    >
                      <button
                        disabled={loading}
                        onClick={() => openModal(0, false)}
                        className="primary-button hide-btn-text"
                      >
                        <i className="pi pi-plus" />
                        <span className="hide-btn-text"> New Bulk </span>
                      </button>
                    </Optional>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="p-mt-5">{renderBulkUploadsView()}</div>
      </div>
    </ErrorBoundary>
  );
}
