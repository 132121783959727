import "./custom-pagination.css";
import { Icon } from "../icons/icon";
import { useCallback, useEffect, useState } from "react";

export function CustomPagination(props) {
  const [rangeArray, setRangeArray] = useState([]);

  const calculateMinPage = useCallback(
    (currentPage) => {
      if (currentPage === props.totalPages && currentPage - 4 >= 1) {
        return currentPage - 4;
      }
      if (currentPage - 2 > 0) {
        return currentPage - 2;
      } else if (currentPage - 1 > 0) {
        return currentPage - 1;
      }
    },
    [props.totalPages]
  );

  const calculateMaxPage = useCallback(
    (currentPage) => {
      if (currentPage === 2 && currentPage + 3 <= props.totalPages) {
        return 5;
      } else if (currentPage === props.totalPages) {
        return currentPage;
      } else if (currentPage + 2 <= props.totalPages) {
        return currentPage + 2;
      } else if (currentPage + 1 <= props.totalPages) {
        return currentPage + 1;
      }
    },
    [props.totalPages]
  );

  const fillRangeArray = useCallback(
    (currentPg, goToPage = null) => {
      let arr = [];
      let currentMaxPage = calculateMaxPage(currentPg);
      let minPage = calculateMinPage(currentPg);
      for (let i = minPage; i <= currentMaxPage; i++) {
        arr.push(i);
      }
      // if (goToPage) {
      //   props.goToPage(currentPg - 1);
      // }
      // mainContext.mainDispatch({
      //   type: "ON_PAGE_CHANGE",
      //   currentPage: currentPg,
      // });
      setRangeArray(arr);
    },
    [calculateMaxPage, calculateMinPage]
  );

  useEffect(() => {
    // console.log("use-effect", props.totalPages);
    function initialize() {
      // setIsInitialized(true);
      let range = [];
      let maxPage = props.totalPages > 5 ? 5 : props.totalPages;
      for (let i = 1; i <= maxPage; i++) {
        range.push(i);
      }
      setRangeArray(range);
    }
    if (props.currentPage === 1) {
      // console.log("initializing ");
      initialize();
    } else if (props.currentPage > 1) {
      fillRangeArray(props.currentPage);
    } else {
      setRangeArray([1]);
    }
    // if (mounted && props.currentPage === 1) {
    //   initialize();
    // } else if (mounted && props.currentPage > 1) {
    //   console.log("refill", props.currentPage);
    //   fillRangeArray(props.currentPage);
    // } else if (mounted && (!props.totalPages || !props.currentPage)) {
    //   setRangeArray([1]);
    // }
  }, [props.totalPages, props.currentPage, fillRangeArray]);

  function goToNextPage() {
    if (props.currentPage === props.totalPages) {
      return;
    }
    props.nextPage();
  }

  function goToPrevPage() {
    if (props.currentPage > 1) {
      props.prevPage();
    }
  }

  function goToLastPage() {
    props.goToLastPage();
  }

  function goToFirstPage() {
    props.goToFirstPage();
  }

  const pageButton = rangeArray.map((item) => (
    <span
      key={item.toString()}
      onClick={() => {
        props.goToPage(item - 1);
        console.log("clicked");
      }}
      className={
        item === props.currentPage
          ? "p-ml-3 pagination-active-nav"
          : "p-ml-3 pagination-inactive-nav"
      }
    >
      {item}
    </span>
  ));

  return (
    <div>
      <div className="p-mt-3">
        <span>
          <span onClick={goToFirstPage} className="pagination-nav-position">
            <Icon icon="pagination-strong-left" />
          </span>
          <span
            onClick={goToPrevPage}
            className="p-ml-2 pagination-nav-position"
          >
            <Icon icon="pagination-left" />
          </span>
          {pageButton}
          <span
            onClick={goToNextPage}
            className="p-ml-3 pagination-nav-position"
          >
            <Icon icon="pagination-right" />
          </span>
          <span
            onClick={goToLastPage}
            className="p-ml-2 pagination-nav-position"
          >
            <Icon icon="pagination-strong-right" />
          </span>
        </span>
      </div>
    </div>
  );
}
