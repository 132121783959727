export function getDisputeDetails(dispute) {
  let arr = [];
  arr.push({
    label: "Amount",
    value: dispute?.amount,
    itemCase: "transactionAmount",
  });
  arr.push({
    label: "Process Status",
    value: dispute?.processStatus,
    itemCase: "status",
  });
  arr.push({
    label: "Process Message",
    value: dispute?.processMessage,
  });
  arr.push({
    label: "Destination Account",
    value: dispute?.creditAccount,
  });
  arr.push({
    label: "Destination Account Name",
    value: dispute?.creditAccountName,
  });
  arr.push({ label: "Source Account", value: dispute?.debitAccount });
  arr.push({ label: "Source Account Name", value: dispute?.debitAccountName });
  arr.push({
    label: "Resolution Status",
    value: dispute?.resolutionStatus,
    itemCase: "status",
  });
  arr.push({
    label: "Refund Status",
    value: dispute?.refundStatus,
    itemCase: "status",
  });
  arr.push({
    label: "Refund Transaction Reference",
    value: dispute?.refundTransactionReference,
  });
  arr.push({
    label: "Refund Narration",
    value: dispute?.refundNarration,
  });
  arr.push({
    label: "Transaction Search Key",
    value: dispute?.transactionSearchKey,
  });

  arr.push({
    label: "Transaction Date",
    value: dispute?.transactionDate,
    itemCase: "transactionTime",
  });

  arr.push({
    label: "Dispute Created On",
    value: dispute?.disputeCreateTime,
    itemCase: "transactionTime",
  });
  arr.push({
    label: "Last Updated On",
    value: dispute?.updatedAt,
    itemCase: "transactionTime",
  });
  return arr;
}

export function getSettlementDetails(settlement) {
  let arr = [];
  arr.push({
    label: "Bulk Settlement Key",
    value: settlement?.bulkId,
  });
  arr.push({
    label: "Amount",
    value: settlement?.amount,
    itemCase: "transactionAmount",
  });
  arr.push({
    label: "Process Status",
    value: settlement?.processStatus,
    itemCase: "status",
  });
  arr.push({
    label: "Process Message",
    value: settlement?.processMessage,
  });
  arr.push({
    label: "Credit Account",
    value: settlement?.creditAccount,
  });
  arr.push({
    label: "Credit Account Name",
    value: settlement?.creditAccountName,
  });
  arr.push({ label: "Debit Account", value: settlement?.debitAccount });
  arr.push({
    label: "Debit Account Name",
    value: settlement?.debitAccountName,
  });
  arr.push({
    label: "Transaction Status",
    value: settlement?.transactionStatus,
  });
  arr.push({
    label: "Transaction Reference",
    value: settlement?.transactionReference,
  });

  arr.push({
    label: "Request Created On",
    value: settlement?.requestCreateTime,
    itemCase: "transactionTime",
  });
  arr.push({
    label: "Last Updated On",
    value: settlement?.updatedAt,
    itemCase: "transactionTime",
  });
  return arr;
}
