import "./super-agent-user.css";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import { CreateSuperAgentUser } from "./create-super-agent-user";
// import { MainContext } from "App";
import { MainContext } from "App";
import { HELPER } from "utils/helper";
import { API_SERVICE } from "api/service";
import { DetailsBreakDown } from "components/elements/details-break-down/details-break-down";
import { CustomConfirmDialog } from "components/elements/custom-confirm-dialog/custom-confirm-dialog";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { ResetPassword } from "../../back-office/super-agent-users/reset-password";
import { ChangeUserStatus } from "../../back-office/super-agent-users/change-user-status";
import { SuperAgentUsersFilter } from "../../back-office/super-agent-users/super-agent-users-filter";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";
import { UserTypes } from "../../../utils/constants";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";
export function FrontOfficeSuperAgentUsers() {
  const toast = useRef(null);
  const op = useRef(null);
  const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [modalLoading] = useState(false);
  const [confirmText] = useState("");
  const [itemIdForDelete] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  // const [currentPage] = useState(mainContext?.mainState?.currentPage);
  const [range] = useState(5);
  const [breakDownTitle, setBreakDownTitle] = useState("");
  // const [chargeTypeForEdit, setChargeTypeForEdit] = useState(null);

  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsError, setDetailsError] = useState(null);
  const [detailsId, setDetailsId] = useState(null);
  const [detailsMobile, setDetailsMobile] = useState(false);

  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);
  const [merchantId, setMerchantId] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [searchParams, setSearchParams] = useState(null);

  const tableHeaders = [
    { label: "Firstname", value: "firstname" },
    { label: "Lastname", value: "lastname" },
    // { label: "Super Agent", value: "merchant" },
    { label: "Status", value: "active" },
    { label: "Date", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];

  const [details, setDetails] = useState([]);

  const [merchantUsers, setMerchantUsers] = useState([]);

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  const viewMerchantUser = useCallback(
    (e, isMobile) => {
      setDetailsError(null);
      setDetailsId(e);
      setDetailsMobile(isMobile);
      setDetailsLoading(true);
      let arr = [];
      setDetails([]);
      arr.push({ label: "First name", value: e?.firstname });
      arr.push({ label: "Last name", value: e?.lastname });
      arr.push({ label: "Username", value: e?.username });
      arr.push({ label: "Email", value: e?.email });
      arr.push({
        label: "Status",
        value: e?.active.toLowerCase() === "active" ? "Active" : "Inactive",
        itemCase: "active",
      });
      arr.push({
        label: "Created Date",
        value: e?.createdAt,
        itemCase: "transactionTime",
      });
      arr.push({ label: "Super Agent Name", value: e?.merchantName });
      arr.push({ label: "Super Agent Id", value: e?.merchantId });
      setDetails(arr);
      setBreakDownTitle("Super Agent User");
      setDetailsLoading(false);
      openModal(2, isMobile);
    },
    [openModal]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          viewMerchantUser(e, isMobile);
          break;
        }
      }
    },
    [viewMerchantUser]
  );

  const searchMerchantUsers = useCallback(
    (e) => {
      if (e) {
        setPageNo(0);
      }
      setVisible(false);
      setLoading(true);
      setSearch(true);
      setCurrentIndex(0);
      setMerchantUsers([]);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: 10,
      });
      let payload = {};
      if (e || searchParams) {
        if (e) {
          payload = { ...e };
          setSearchParams(payload);
        } else {
          payload = { ...searchParams };
        }
      }
      const url = FRONT_OFFICE_API.SUPER_AGENT_USER.FILTER_USERS;
      API_SERVICE.MAKE_PUT_REQUEST(`${url}?${params}`, payload)
        .then((data) => {
          const result = data.result.content;
          if (!result.length) {
            setEmptyText("No super agent user found ...");
          } else {
            let arr = [];
            setTotalItems(data.result.totalElements);
            setTotalPages(data.result.totalPages);
            result.forEach((e) => {
              arr.push({
                ...e,
                merchant: e?.merchantName,
                merchantId: e?.merchantId,
                active: e?.active ? "Active" : "Inactive",
                actions: "CR",
                detailsFunction: openAction,
              });
            });
            setMerchantUsers(arr);
          }
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        })
        .catch((error) => {
          setError(HELPER.PROCESS_ERROR(error));
          // setCurrentIndex(1);
          setLoading(false);
        });
    },
    [pageNo, searchParams, openAction]
  );

  const getMerchantUsers = useCallback(() => {
    setCurrentIndex(0);
    setMerchantUsers([]);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    const url = FRONT_OFFICE_API.SUPER_AGENT_USER.GET_USERS;

    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data.result.content;
        if (!result.length) {
          setEmptyText("No users found...");
        } else {
          let arr = [];
          setTotalItems(data.result.totalElements);
          setTotalPages(data.result.totalPages);
          result.forEach((e) => {
            if (e.username === mainContext?.mainState?.username) {
              setMerchantId(e?.merchantId);
            }
            arr.push({
              ...e,
              merchant: e?.merchantName,
              merchantId: e?.merchantId,
              active: e?.active ? "Active" : "Inactive",
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setMerchantUsers(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, openAction, mainContext.mainState.username]);

  useEffect(() => {
    if (searchParams) {
      searchMerchantUsers();
    } else {
      getMerchantUsers();
    }
  }, [searchParams, searchMerchantUsers, getMerchantUsers]);

  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     getMerchantUsers();
  //   }
  //   return () => {
  //     mounted = false;
  //   };
  // }, [currentPage]);

  function deleteChargeType(e) {}

  function reloadDetails() {
    viewMerchantUser(detailsId, detailsMobile);
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  function onHide() {}

  function reload() {
    setPageNo(0);
    setSearchParams(null);
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getMerchantUsers(null, true);
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload) {
      reload();
    }
  }

  function showResetPasswordModal() {
    setCurrentModalIndex(4);
  }

  function showChangeStatusModal() {
    setCurrentModalIndex(5);
  }

  const merchantUsersView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading users..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={merchantUsers}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <CreateSuperAgentUser
            merchantId={merchantId}
            closeModal={closeModal}
          />
        );
      case 1:
        return (
          <SuperAgentUsersFilter
            searchFunction={searchMerchantUsers}
            isSearch={true}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            reload={reloadDetails}
            error={detailsError}
            loading={detailsLoading}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
            isResetPassword={true}
            resetPassword={showResetPasswordModal}
            isChangeStatus={true}
            changeStatus={showChangeStatusModal}
            userStatus={detailsId.active}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting charge type..."
            fn={deleteChargeType}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <ResetPassword
            userDetails={detailsId}
            closeModal={closeModal}
            type="fo"
          />
        );
      case 5:
        return (
          <ChangeUserStatus
            userDetails={detailsId}
            closeModal={closeModal}
            userType={UserTypes.FrontOffice}
          />
        );
    }
  };

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return (
          <CreateSuperAgentUser
            merchantId={merchantId}
            closeModal={closeModal}
          />
        );
      case 1:
        return (
          <SuperAgentUsersFilter
            searchFunction={searchMerchantUsers}
            isSearch={true}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            reload={reloadDetails}
            error={detailsError}
            loading={detailsLoading}
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
            isResetPassword={true}
            resetPassword={showResetPasswordModal}
            isChangeStatus={true}
            changeStatus={showChangeStatusModal}
            userStatus={detailsId.active}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting charge type..."
            fn={deleteChargeType}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <ResetPassword
            userDetails={detailsId}
            closeModal={closeModal}
            type="fo"
          />
        );
      case 5:
        return (
          <ChangeUserStatus
            userDetails={detailsId}
            closeModal={closeModal}
            userType={UserTypes.FrontOffice}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        <div className="page-header">
        <div className="page-title p-text-left">Super Agent Users</div>
        <UserManualViewerButton fileName="front_office_super_users" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page={"Manage Super Agent users"} />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6 p-d-flex p-jc-end p-ai-center">
                  <button
                    disabled={loading || merchantUsers?.length === 0}
                    onClick={() => openModal(1, false)}
                    className="filter-button"
                  >
                    <i className="pi pi-filter" />
                    <span className="hide-btn-text"> Filter</span>
                  </button>
                </div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("fo_dcir_create_merchant_user")
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading || error}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text"> New user </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-screen">
          <div className="p-grid">
            <div className="p-col-9"></div>
            <div className="p-col-3">
              <div className="floating-mobile-buttons add-cursor">
                <div
                  className={
                    HELPER.HAS_AUTHORITY("fo_dcir_create_merchant_user")
                      ? "dcir-hide"
                      : "dcir-hide"
                  }
                >
                  <i
                    onClick={(e) => op.current.toggle(e)}
                    className="pi pi-ellipsis-v"
                    style={{ fontSize: "1.5em", color: "#464DF2" }}
                  />
                  <OverlayPanel
                    ref={op}
                    id="overlay_panel"
                    style={{ width: "100px" }}
                    className="overlaypanel-demo"
                  >
                    <div className="p-mb-3 p-ml-1">
                      <span
                        onClick={() => openModal(0, true)}
                        className="custom-over-flow-text"
                      >
                        <i className="pi pi-plus" /> New
                      </span>
                    </div>
                    {/*<div className="p-mb-2 p-ml-1"><span onClick={()=>openModal(1,true)} className="custom-over-flow-text"><i className="pi pi-filter"/> Filter</span></div>*/}
                  </OverlayPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{merchantUsersView()}</div>
      </div>
    </ErrorBoundary>
  );
}
