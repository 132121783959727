import { useCallback, useEffect, useRef, useState } from "react";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CardSkeletonLoader } from "../../../components/elements/custom-card/card-skeleton-loader";
import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import { TableErrorView } from "../../../components/elements/custom-table/table-error-view";
import { TableEmptyView } from "../../../components/elements/custom-table/table-empty-view";
import { HELPER } from "utils/helper";
import { Toast } from "primereact/toast";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import AlertConfigurationForm from "./alert-configurations-form";

const ManageAlertConfigurations = () => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [alertConfigs, setAlertConfigs] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState({});

  const getAlertConfigs = useCallback(async () => {
    setLoading(true);
    setError(null);
    const url = "/alert-configs";
    try {
      const data = await MONITORING_SERVICE.MAKE_GET_REQUEST(url);
      setAlertConfigs(data?.response);
      setLoading(false);
    } catch (error) {
      const message = error?.data?.message ?? HELPER.PROCESS_ERROR(error);
      setError(message);
      setLoading(false);
    }
  }, []);

  function closeModal() {
    setShowModal(false);
  }
  const reload = () => {
    getAlertConfigs();
  };

  const onSuccess = () => {
    closeModal();
    toast.current.show({
      severity: "success",
      summary: "Success!",
      detail: "Successfully updated alert configuration",
      life: 10000,
    });
    reload();
  };

  const handleShowModal = (alert) => {
    setSelectedAlert(alert);
    setShowModal(true);
  };

  const modalContent = () => {
    return (
      <AlertConfigurationForm
        alert={selectedAlert}
        closeModal={closeModal}
        onSuccess={onSuccess}
      />
    );
  };

  useEffect(() => {
    getAlertConfigs();
  }, [getAlertConfigs]);

  const renderCurrentView = () => {
    if (loading) {
      return (
        <div className="p-my-4">
          {" "}
          <CardSkeletonLoader />{" "}
        </div>
      );
    } else {
      return (
        <div>
          <table className="p-mt-0">
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>Metric Name</td>
                <td style={{ fontWeight: "bold" }}>Logical Operator</td>
                <td style={{ fontWeight: "bold" }}>Threshold Value</td>
                <td style={{ fontWeight: "bold" }}>Metric Unit</td>
                <td style={{ fontWeight: "bold" }}>Window Size</td>
                <td style={{ fontWeight: "bold" }}>Cool-Down Period</td>
                <td style={{ fontWeight: "bold" }}>Actions</td>
              </tr>
              {alertConfigs?.map((alert, index) => (
                <tr key={index}>
                  <td>{alert.metricName}</td>
                  <td>{alert.logicalOperator}</td>
                  <td className="p-text-center">{alert.thresholdValue}</td>
                  <td>{alert.metricUnit}</td>
                  <td className="p-text-center">{alert.windowSize}</td>
                  <td className="p-text-center">{alert.coolDownPeriod}</td>
                  <td>
                    {" "}
                    <button
                      onClick={() => handleShowModal(alert)}
                      className="table-action-icon table-action dcir-show bare-button p-justify-center p-align-center d-flex"
                    >
                      <span className="add-cursor">
                        <i className={`pi pi-pencil`} />
                      </span>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!loading && !alertConfigs?.length && (
            <TableEmptyView
              emptyText="No results found!"
              onReload={getAlertConfigs}
            />
          )}
        </div>
      );
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <CustomModal
        closeModal={closeModal}
        visible={showModal}
        modalContent={modalContent}
      />
      <div className="p-text-left">
        <div className="page-title p-text-left">Alert Configurations</div>
        <div className="p-mt-2 p-mb-5">
          <CustomBreadcrumb page="Alert Configurations" />
        </div>
        <CustomCard>
          {!error && renderCurrentView()}
          {!loading && error && (
            <TableErrorView error={error} onReload={getAlertConfigs} />
          )}
        </CustomCard>
      </div>
    </div>
  );
};

export default ManageAlertConfigurations;
