import "./dashboard.css";
import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
// import { Icon } from "components/elements/icons/icon";
import { AnalyticsChart } from "./analytics-chart";
import { API_SERVICE } from "api/service";
import { HELPER } from "utils/helper";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { Skeleton } from "primereact/skeleton";
import { Toast } from "primereact/toast";
// import { Icon } from "../icons/icon";
import { Icon } from "components/elements/icons/icon";
import { MetricsSummaryCards } from "./metrics-summary-cards";
import { UserTypes } from "utils/constants";
import { DASHBOARD_HELPERS } from "./helpers";
import { MAX_ANALYTICS_DATE_RANGE_IN_DAYS } from "utils/constants";
import { Optional } from "components/elements/optional/optional";
import { FRONT_OFFICE_API } from "../../../api/routes/front-office/index";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";
import { useMerchantsList } from "hooks/use-merchants-list";

export function Dashboard(props) {
  const toast = useRef(null);
  const [selectedMetricType, setSelectedMetricType] = useState("transactions");
  const [dateRangeValue, setDateRangeValue] = useState("today");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentDayMetrics, setCurrentDayMetrics] = useState(null);
  const [historicMetrics, setHistoricMetrics] = useState(null);
  const [disputeMetricsBreakdown, setDisputeMetricsBreakdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [overallMetrics, setOverallMetrics] = useState(null);
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm"));
  const [noData, setNoData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const reloadFunctionDetails = useRef({
    reloadFunction: null,
    params: null,
  });
  const selectedSuperagent = useRef("All");
  const [selectedSuperagentName, setSelectedSuperagentName] = useState("All");
  const merchantList = useMerchantsList();
  const [merchants, setMerchants] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [chartDataByValue, setChartDataByValue] = useState(null);
  const [chartDataByCount, setChartDataByCount] = useState(null);
  const dataByCount = useRef(null);
  const dataByValue = useRef(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [analyticsChartValueType, setAnalyticsChartValueType] =
    useState("count");
  // Filter
  const selectedReport = useRef({
    desc: "Transactions",
    code: "transactions",
  });
  const selectedDateRange = useRef({
    desc: "Today",
    code: "today",
  });
  const dataOptions = [
    { desc: "Transactions", code: "transactions" },
    { desc: "Disputes", code: "disputes" },
  ];
  const dateRanges = [
    { desc: "Today", code: "today" },
    { desc: "Yesterday", code: "1" },
    { desc: "Last 7 days", code: "7" },
    { desc: "Last 14 days", code: "14" },
    { desc: "Last 30 days", code: "30" },
    { desc: "Custom", code: "custom" },
  ];
  const [showCustomDateField, setShowCustomDateField] = useState(false);
  const [dateFilterError, setDateFilterError] = useState(false);
  const dateFilter = useRef({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  const [customDate, setCustomDate] = useState({
    startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
  });
  const [superagentsWithAnalyticsData, setSuperagentWithAnalyticsData] =
    useState([]);
  const [noDataForSelectedAgent, setNoDataForSelectedAgent] = useState(false);

  useEffect(() => {
    if (merchantList && merchantList?.length) {
      const merchantNameArr = [];
      merchantNameArr.push("All");
      merchantList?.forEach((merchant) => {
        merchantNameArr.push(merchant?.merchantName);
      });
      setMerchants(merchantNameArr);
    }
  }, [merchantList]);

  function handleNoAnalyticsData(message) {
    setNoData(true);
    setErrorMessage(message);
    toast.current.show({
      severity: "success",
      summary: "No data!",
      detail: message,
      life: 5000,
    });
  }

  function handleNoAgentData() {
    resetMetrics();
    const err = "No data for selected agent";
    toast.current.show({
      severity: "success",
      summary: "No data!",
      detail: err,
      life: 5000,
    });
    setErrorMessage(err);
    setNoDataForSelectedAgent(true);
  }

  const resetMetrics = useCallback(() => {
    setOverallMetrics({
      totalCount: 0,
      totalAmount: 0,
      successfulCount: 0,
      successfulAmount: 0,
      failedCount: 0,
      failedAmount: 0,
    });
  }, []);

  const handleGetMetricsError = useCallback(
    (error) => {
      setNoData(true);
      setErrorMessage(HELPER.PROCESS_ERROR(error, "TOAST"));
      toast.current.show({
        severity: "error",
        summary: "Error!",
        detail: HELPER.PROCESS_ERROR(error, "TOAST"),
        life: 10000,
      });
      resetMetrics();
      setLoading(false);
    },
    [resetMetrics]
  );

  const handleCurrentDayTransactionsData = useCallback((response) => {
    const dataFormatFunction =
      props.userType === UserTypes.FrontOffice
        ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_CURRENT_DAY_TRANSACTIONS_DATA
        : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_CURRENT_DAY_TRANSACTIONS_DATA;
    const { chartOptions, chartDataByCount, chartDataByValue } =
      dataFormatFunction(response);
    setChartDataByCount(chartDataByCount);
    dataByCount.current = chartDataByCount;
    dataByValue.current = chartDataByValue;
    setChartDataByValue(chartDataByValue);
    setChartOptions(chartOptions);
  }, []);

  const handleCurrentDayDisputeData = useCallback((response) => {
    const dataFormatFunction =
      props.userType === UserTypes.FrontOffice
        ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_CURRENT_DAY_DISPUTE_DATA
        : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_CURRENT_DAY_DISPUTE_DATA;
    const { chartOptions, chartDataByCount, chartDataByValue } =
      dataFormatFunction(response);
    setChartDataByCount(chartDataByCount);
    setChartDataByValue(chartDataByValue);
    dataByCount.current = chartDataByCount;
    dataByValue.current = chartDataByValue;
    setChartOptions(chartOptions);
  }, []);

  const formatHistoricTransactionsData = useCallback(
    (data) => {
      const dataFormatFunction =
        props.userType === UserTypes.FrontOffice
          ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_HISTORIC_TRANSACTIONS_DATA
          : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_HISTORIC_TRANSACTIONS_DATA;
      const {
        chartOptions,
        chartDataByValue,
        chartDataByCount,
        superagentsWithData,
        overallMetrics,
      } = dataFormatFunction(data, analyticsChartValueType);
      if (props.userType !== UserTypes.FrontOffice) {
        setOverallMetrics({ ...overallMetrics });
      }
      setChartOptions(chartOptions);
      // setMerchants(formattedSuperAgentArr);
      setSuperagentWithAnalyticsData(superagentsWithData);
      setChartDataByCount(chartDataByCount);
      setChartDataByValue(chartDataByValue);
      dataByCount.current = chartDataByCount;
      dataByValue.current = chartDataByValue;
    },
    [props.userType]
  );

  const formatHistoricDisputesData = useCallback(
    (data) => {
      setLoading(true);
      const dataFormatFunction =
        props.userType === UserTypes.FrontOffice
          ? DASHBOARD_HELPERS.FORMAT_FRONTOFFICE_HISTORIC_DISPUTES_DATA
          : DASHBOARD_HELPERS.FORMAT_BACKOFFICE_HISTORIC_DISPUTES_DATA;
      const {
        chartOptions,
        superagentsWithData,
        chartDataByCount,
        chartDataByValue,
        overallMetrics,
      } = dataFormatFunction(data, analyticsChartValueType);
      // barChartOptions.current = chartOptions;
      if (props.userType !== UserTypes.FrontOffice) {
        setOverallMetrics({ ...overallMetrics });
      }
      setChartOptions(chartOptions);
      // setMerchants(formattedSuperAgentArr);
      setSuperagentWithAnalyticsData(superagentsWithData);
      setChartDataByCount(chartDataByCount);
      setChartDataByValue(chartDataByValue);
      dataByCount.current = chartDataByCount;
      dataByValue.current = chartDataByValue;
      setLoading(false);
    },
    [props.userType]
  );

  function formatHistoricTransactionsDataBySuperAgent() {
    if (superagentsWithAnalyticsData.includes(selectedSuperagent?.current)) {
      setLoading(true);
      const {
        chartOptions,
        chartDataByValue,
        chartDataByCount,
        overallMetrics,
      } = DASHBOARD_HELPERS.FORMAT_SELECTED_SUPERAGENT_TRANSACTIONS_DATA(
        historicMetrics,
        selectedSuperagent?.current,
        analyticsChartValueType
      );
      // barChartOptions.current = chartOptions;
      setOverallMetrics({ ...overallMetrics });
      setChartOptions(chartOptions);
      setChartDataByCount(chartDataByCount);
      setChartDataByValue(chartDataByValue);
      dataByCount.current = chartDataByCount;
      dataByValue.current = chartDataByValue;
      setErrorMessage(null);
      // setNoData(false);
      setNoDataForSelectedAgent(false);
      setLoading(false);
      // setNoData(false);
    } else {
      // handleNoAnalyticsData("No records for selected Superagent!");
      // setNoDataForSelectedAgent(true)
      // resetMetrics();
      handleNoAgentData();
    }
  }

  function formatHistoricDisputesDataBySuperAgent() {
    if (superagentsWithAnalyticsData.includes(selectedSuperagent?.current)) {
      const {
        chartOptions,
        chartDataByCount,
        chartDataByValue,
        overallMetrics,
      } = DASHBOARD_HELPERS.FORMAT_SELECTED_SUPERAGENT_DISPUTES_DATA(
        historicMetrics,
        selectedSuperagent?.current,
        analyticsChartValueType
      );
      // barChartOptions.current = chartOptions;
      setOverallMetrics({ ...overallMetrics });
      setChartDataByCount(chartDataByCount);
      setChartDataByValue(chartDataByValue);
      dataByCount.current = chartDataByCount;
      dataByValue.current = chartDataByValue;
      setChartOptions(chartOptions);
      setNoDataForSelectedAgent(false);
      setLoading(false);
    } else {
      handleNoAgentData();
    }
  }

  const getCurrentDayTransactionAnalytics = useCallback(() => {
    reloadFunctionDetails.current = {
      reloadFunction: getCurrentDayTransactionAnalytics,
      params: null,
    };
    setLoading(true);
    const frontofficeUrl =
      FRONT_OFFICE_API.DASHBOARD.GET_CURRENT_DAY_TRANSACTION_ANALYTICS;
    const backofficeUrl =
      BACK_OFFICE_API.DASHBOARD.GET_CURRENT_DAY_TRANSACTION_ANALYTICS;
    const url =
      props.userType === UserTypes.FrontOffice ? frontofficeUrl : backofficeUrl;
    API_SERVICE.MAKE_GET_REQUEST(url)
      .then((data) => {
        let result = data?.result;
        setCurrentDayMetrics(result);
        let overallMetrics =
          props.userType === UserTypes.FrontOffice
            ? result
            : result.overallMetrics;
        const {
          totalCount,
          totalAmount,
          successfulCount,
          successfulAmount,
          failedCount,
          failedAmount,
        } = overallMetrics;
        setOverallMetrics({
          totalCount,
          totalAmount,
          successfulCount,
          successfulAmount,
          failedCount,
          failedAmount,
        });
        if (overallMetrics?.totalCount === 0) {
          handleNoAnalyticsData("No data found!");
        } else {
          setNoData(false);
          setErrorMessage(null);
        }
        setCurrentTime(moment().format("HH:mm"));
        handleCurrentDayTransactionsData(result);
        // selectedSuperagent.current = "All";
        setSelectedSuperagentName("All");
        setLoading(false);
      })
      .catch((error) => {
        handleGetMetricsError(error);
      });
  }, [props.userType, handleGetMetricsError]);

  const saveOverallDisputeMetrics = useCallback((metrics) => {
    const totalCount =
      metrics?.backofficeLoggedCount + metrics?.merchantLoggedCount;
    setOverallMetrics({
      totalCount: totalCount,
      totalAmount:
        metrics?.backofficeLoggedValue + metrics?.merchantLoggedValue,
      successfulCount:
        metrics?.backofficeAcceptedCount + metrics?.merchantAcceptedCount,
      successfulAmount:
        metrics?.backofficeAcceptedValue + metrics?.merchantAcceptedValue,
      failedCount:
        metrics?.backofficeRejectedCount + metrics?.merchantRejectedCount,
      failedAmount:
        metrics?.backofficeRejectedValue + metrics?.merchantRejectedValue,
    });
  }, []);

  const saveDisputeMetricsBreakdown = useCallback((metrics) => {
    setDisputeMetricsBreakdown({
      backofficeTotalCount: metrics?.backofficeLoggedCount,
      agentTotalCount: metrics?.merchantLoggedCount,
      backofficeTotalValue: metrics?.backofficeLoggedValue,
      agentTotalValue: metrics?.merchantLoggedValue,
      backofficeAcceptedCount: metrics?.backofficeAcceptedCount,
      agentAcceptedCount: metrics?.merchantAcceptedCount,
      backofficeAcceptedValue: metrics?.backofficeAcceptedValue,
      agentAcceptedValue: metrics?.merchantAcceptedValue,
      backofficeRejectedCount: metrics?.backofficeRejectedCount,
      agentRejectedCount: metrics?.merchantRejectedCount,
      backofficeRejectedValue: metrics?.backofficeRejectedValue,
      agentRejectedValue: metrics?.merchantRejectedValue,
    });
  }, []);

  const getCurrentDayDisputeAnalytics = useCallback(() => {
    reloadFunctionDetails.current = {
      reloadFunction: getCurrentDayDisputeAnalytics,
      params: null,
    };
    setLoading(true);
    const frontofficeUrl =
      FRONT_OFFICE_API.DASHBOARD.GET_CURRENT_DAY_DISPUTE_ANALYTICS;
    const backofficeUrl =
      BACK_OFFICE_API.DASHBOARD.GET_CURRENT_DAY_DISPUTE_ANALYTICS;
    const url =
      props.userType === UserTypes.FrontOffice ? frontofficeUrl : backofficeUrl;
    API_SERVICE.MAKE_GET_REQUEST(url)
      .then((data) => {
        let result = data?.result;
        const overallMetrics =
          props.userType === UserTypes.FrontOffice
            ? result
            : result?.overallMetrics;
        const totalCount =
          overallMetrics?.backofficeLoggedCount +
          overallMetrics?.merchantLoggedCount;
        saveOverallDisputeMetrics(overallMetrics);
        if (props.userType === UserTypes.FrontOffice) {
          saveDisputeMetricsBreakdown(overallMetrics);
        }
        if (totalCount === 0) {
          handleNoAnalyticsData("No data found!");
        } else {
          setNoData(false);
          setErrorMessage(null);
        }
        setCurrentDayMetrics(result);
        setCurrentTime(moment().format("HH:mm"));
        handleCurrentDayDisputeData(result);
        // selectedSuperagent.current = "All";
        setSelectedSuperagentName("All");

        setLoading(false);
      })
      .catch((error) => {
        console.log("err", error);
        handleGetMetricsError(error);
      });
  }, [props.userType, saveOverallDisputeMetrics, saveDisputeMetricsBreakdown]);

  const getHistoricTransactionAnalytics = useCallback(
    (date) => {
      reloadFunctionDetails.current = {
        reloadFunction: getHistoricTransactionAnalytics,
        params: date,
      };
      setLoading(true);
      setDateFilterError(false);
      const params = HELPER.TO_URL_STRING(date);
      const frontofficeUrl =
        FRONT_OFFICE_API.DASHBOARD.GET_HISTORIC_TRANSACTION_ANALYTICS;
      const backofficeUrl =
        BACK_OFFICE_API.DASHBOARD.GET_HISTORIC_TRANSACTION_ANALYTICS;
      const url =
        props.userType === UserTypes.FrontOffice
          ? frontofficeUrl
          : backofficeUrl;
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          const result = data?.result;
          const overallMetrics = result?.generalAnalyticsSummary;
          const totalCount =
            overallMetrics?.totalSuccessfulTransactionCount +
            overallMetrics?.totalFailedTransactionCount;
          setOverallMetrics({
            totalCount: totalCount,
            totalAmount:
              overallMetrics?.totalSuccessfulTransactionValue +
              overallMetrics?.totalFailedTransactionValue,
            successfulCount: overallMetrics?.totalSuccessfulTransactionCount,
            successfulAmount: overallMetrics?.totalSuccessfulTransactionValue,
            failedCount: overallMetrics?.totalFailedTransactionCount,
            failedAmount: overallMetrics?.totalFailedTransactionValue,
          });
          if (totalCount === 0) {
            handleNoAnalyticsData("No data found!");
          } else {
            setNoData(false);
            setErrorMessage(null);
          }
          setDateRangeValue(selectedDateRange?.current?.code);
          setHistoricMetrics(result);
          formatHistoricTransactionsData(result);
          // selectedSuperagent.current = "All";
          setSelectedSuperagentName("All");

          setLoading(false);
        })
        .catch((error) => {
          handleGetMetricsError(error);
        });
    },
    [props.userType, formatHistoricTransactionsData, handleGetMetricsError]
  );

  const getHistoricDisputeAnalytics = useCallback(
    (date) => {
      reloadFunctionDetails.current = {
        reloadFunction: getHistoricDisputeAnalytics,
        params: date,
      };
      setLoading(true);
      setDateFilterError(false);
      const params = HELPER.TO_URL_STRING(date);
      const frontofficeUrl =
        FRONT_OFFICE_API.DASHBOARD.GET_HISTORIC_DISPUTE_ANALYTICS;
      const backofficeUrl =
        BACK_OFFICE_API.DASHBOARD.GET_HISTORIC_DISPUTE_ANALYTICS;
      const url =
        props.userType === UserTypes.FrontOffice
          ? frontofficeUrl
          : backofficeUrl;
      API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
        .then((data) => {
          const result = data?.result;
          const overallMetrics = result?.generalSummary;
          const totalCount =
            overallMetrics?.backofficeLoggedCount +
            overallMetrics?.merchantLoggedCount;
          saveOverallDisputeMetrics(overallMetrics);

          if (props.userType === UserTypes.FrontOffice) {
            saveDisputeMetricsBreakdown(overallMetrics);
          }
          if (totalCount === 0) {
            handleNoAnalyticsData("No data found!");
          } else {
            setNoData(false);
            setErrorMessage(null);
          }

          // setDateRangeValue(selectedDateRange?.current?.code);
          setHistoricMetrics(result);
          formatHistoricDisputesData(result);
          // selectedSuperagent.current = "All";
          // co
          setSelectedSuperagentName("All");

          setLoading(false);
        })
        .catch((error) => {
          handleGetMetricsError(error);
        });
    },
    [
      props.userType,
      formatHistoricDisputesData,
      saveOverallDisputeMetrics,
      saveDisputeMetricsBreakdown,
    ]
  );

  useEffect(() => {
    getCurrentDayTransactionAnalytics();
  }, [getCurrentDayTransactionAnalytics]);

  // Filter
  function handleReportTypeSelection(e) {
    selectedReport.current = e?.value;
    setSelectedMetricType(e?.value?.code);
    if (dateRangeValue === "today") {
      if (selectedReport?.current?.code === "transactions") {
        getCurrentDayTransactionAnalytics();
      } else {
        getCurrentDayDisputeAnalytics();
      }
    } else {
      if (selectedReport?.current?.code === "transactions") {
        getHistoricTransactionAnalytics(dateFilter.current);
      } else {
        getHistoricDisputeAnalytics(dateFilter.current);
      }
    }
    e?.value?.code === "disputes" ? setCurrentIndex(1) : setCurrentIndex(0);
  }

  function handleDateRangeSelection(e) {
    setDateFilterError(false);
    const selectedDate = e?.value;
    selectedDateRange.current = selectedDate;
    setShowCustomDateField(false);
    if (selectedDate?.code === "custom") {
      dateFilter.current = {
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
      setShowCustomDateField(true);
      return;
    } else if (selectedDate.code === "today") {
      if (selectedReport?.current?.code === "transactions") {
        getCurrentDayTransactionAnalytics();
      } else {
        getCurrentDayDisputeAnalytics();
      }
    } else if (selectedDate.code !== "custom") {
      let startAndEndDate = {
        startDate: moment()
          .subtract(Number(selectedDate.code), "days")
          .format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      };
      dateFilter.current = startAndEndDate;
      if (selectedReport?.current?.code === "transactions") {
        getHistoricTransactionAnalytics(startAndEndDate);
      } else {
        getHistoricDisputeAnalytics(startAndEndDate);
      }
    }
    setDateRangeValue(selectedDate?.code);
  }

  function validateDateSelection(e, field) {
    const dateValue = e?.target?.value;
    setCustomDate({
      ...customDate,
      [field]: dateValue,
    });
    dateFilter.current = {
      ...dateFilter.current,
      [field]: dateValue,
    };
  }

  function handleSuperagentSelection(e) {
    setLoading(true);
    const superagent = e?.value;
    selectedSuperagent.current = superagent;
    setSelectedSuperagentName(superagent);

    if (selectedMetricType === "transactions") {
      if (superagent === "All") {
        formatHistoricTransactionsData(historicMetrics);
      } else {
        console.log("!all-agent");
        formatHistoricTransactionsDataBySuperAgent();
      }
    } else {
      console.log("!trxns");
      if (superagent === "All") {
        formatHistoricDisputesData(historicMetrics);
        // getHistoricDisputeAnalytics();
      } else {
        formatHistoricDisputesDataBySuperAgent();
      }
    }
    setLoading(false);
  }

  function handleCustomDateSearch() {
    const customDate = dateFilter?.current;
    if (customDate?.startDate && customDate?.endDate) {
      const dateDifference = HELPER.GET_DATE_DIFFERENCE(
        dateFilter?.current?.startDate,
        dateFilter?.current?.endDate
      );
      if (
        dateDifference > MAX_ANALYTICS_DATE_RANGE_IN_DAYS ||
        dateDifference < 0
      ) {
        setDateFilterError(true);
      } else {
        if (selectedReport?.current?.code === "transactions") {
          getHistoricTransactionAnalytics(dateFilter.current);
        } else {
          getHistoricDisputeAnalytics(dateFilter.current);
        }
      }
    } else {
      setDateFilterError(true);
    }
  }

  function reload() {
    const params = reloadFunctionDetails?.current?.params;
    reloadFunctionDetails?.current?.reloadFunction(params);
  }

  // const currentChartView = () => {
  //   console.log("chart-view-no-data", noData);
  //   if (noData) {
  //     return (
  //       <div className="card p-mt-5 empty" style={{ textAlign: "center" }}>
  //         <Icon icon="not-found" />
  //         <p style={{ fontSize: "0.8rem" }}>{errorMessage}</p>
  //         <div>
  //           <button className="primary-button" onClick={reload}>
  //             Reload
  //           </button>
  //         </div>
  //       </div>
  //     );
  //   } else if ((!noData && !dataByCount.current) || !dataByValue.current) {
  //     return <div>Omo</div>;
  //   } else {
  //     return (

  //     );
  //   }
  // };
  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="page-title p-text-left">Dashboard</div>
      <div className="p-mt-2">
        <CustomBreadcrumb page="Dashboard" />
      </div>
      <pre> {loading}</pre>
      <div className="p-grid p-mt-3">
        <div className="p-col-4 dropdown-wrapper">
          <FormDropdown
            required={true}
            label="desc"
            value={selectedReport.current}
            fn={handleReportTypeSelection}
            options={dataOptions}
            placeholder="Select analytics type"
          />
        </div>
        <div className="p-col-4 dropdown-wrapper">
          <FormDropdown
            required={true}
            label="desc"
            value={selectedDateRange.current}
            fn={handleDateRangeSelection}
            options={dateRanges}
            placeholder="Select time"
          />
        </div>
        <div className="p-col-4 date-select-row">
          <Optional show={showCustomDateField}>
            <div>
              <div className="p-grid">
                <div className="p-col-6 p-pt-0">
                  <FormInput
                    inputType="date"
                    value={customDate["startDate"]}
                    required={false}
                    field="startDate"
                    type="INPUT"
                    error={dateFilterError}
                    fn={validateDateSelection}
                    loading={false}
                    max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                    placeholder="Start date"
                  />
                </div>
                <div className="p-col-6 p-pt-0">
                  <FormInput
                    inputType="date"
                    value={customDate["endDate"]}
                    required={false}
                    field="endDate"
                    type="INPUT"
                    error={dateFilterError}
                    fn={validateDateSelection}
                    max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                    loading={false}
                    placeholder="End date"
                  />
                </div>
              </div>
            </div>
          </Optional>
          <Optional show={dateFilterError}>
            <p
              className="p-text-error p-mt-0"
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "0.75rem",
                transform: "translateY(-15px)",
              }}
            >
              Please select a valid date range, 30 days or less!
            </p>
          </Optional>
          <Optional show={showCustomDateField && !dateFilter}>
            <p
              className="p-text-error p-mt-0"
              style={{ textAlign: "left", fontSize: "0.75rem" }}
            >
              Note: You can only retrieve metrics within 30 day intervals.
            </p>
          </Optional>
          <Optional show={showCustomDateField}>
            <div style={{ textAlign: "right" }}>
              <button
                className="primary-button"
                style={{ width: "10rem" }}
                onClick={handleCustomDateSearch}
              >
                Search
              </button>
            </div>
          </Optional>
        </div>
      </div>

      <Optional show={dateRangeValue !== "today" && !noData}>
        <div className="p-grid p-mt-3">
          <div className="p-col-7">
            <p
              className="p-text-left"
              style={{ fontSize: "0.8rem", fontStyle: "italic" }}
            >
              Showing results from{" "}
              <span>
                {" "}
                {moment(dateFilter?.current?.startDate).format(
                  "MMMM Do YYYY"
                )}{" "}
              </span>
              -{" "}
              <span>
                {moment(dateFilter?.current?.endDate).format("MMMM Do YYYY")}{" "}
              </span>
            </p>
          </div>
          {props.userType !== UserTypes.FrontOffice && (
            <div className="p-col-5">
              <FormDropdown
                required={true}
                value={selectedSuperagentName}
                fn={handleSuperagentSelection}
                options={merchants}
                placeholder="Select superagent"
                filter
                filterBy="merchantName"
              />
            </div>
          )}
        </div>
      </Optional>

      <MetricsSummaryCards
        overallMetrics={overallMetrics}
        userType={props.userType}
        currentIndex={currentIndex}
        selectedDateRange={selectedDateRange?.current}
        currentTime={currentTime}
        disputeMetricsBreakdown={disputeMetricsBreakdown}
        loading={loading}
        selectedReport={selectedReport?.current}
        reload={reload}
      />
      <Optional show={loading}>
        <div className="card p-mt-4">
          <Skeleton />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
          <Skeleton className="p-mt-3" />
        </div>
      </Optional>
      {/* <Optional show={!loading}> */}
      {/* {currentChartView()} */}
      {/* </Optional> */}
      <Optional show={noData || noDataForSelectedAgent}>
        <div className="card p-mt-5 empty" style={{ textAlign: "center" }}>
          <Icon icon="not-found" />
          <p style={{ fontSize: "0.8rem" }}>{errorMessage}</p>
          <div>
            <button className="primary-button" onClick={reload}>
              Reload
            </button>
          </div>
        </div>
      </Optional>
      {/* <Optional show={!noData && !noDataForSelectedAgent}>
        <Optional show={dataByCount.current && dataByValue.current}>
          <AnalyticsChart
            userType={props.userType}
            dateRange={dateRangeValue}
            metricType={selectedMetricType}
            chartOptions={chartOptions}
            chartData={chartData}
            chartDataByCount={dataByCount.current}
            chartDataByValue={dataByValue.current}
            hasNoData={noData}
            loading={loading}
          />
        </Optional>
      </Optional> */}
      <Optional show={!noData}>
        <div
          style={{
            opacity: `${noDataForSelectedAgent || loading ? "0" : "1"}`,
          }}
        >
          <AnalyticsChart
            userType={props.userType}
            dateRange={dateRangeValue}
            metricType={selectedMetricType}
            chartOptions={chartOptions}
            chartData={chartData}
            chartDataByCount={dataByCount.current}
            chartDataByValue={dataByValue.current}
            hasNoData={noData}
            loading={loading}
          />
        </div>
      </Optional>
    </div>
  );
}
