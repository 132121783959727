const GET_DISPUTE_BY_TRANSACTION_KEY = "/backoffice/dispute/transaction";
const GET_FILTERED_DISPUTES = "/backoffice/dispute/filter";
const GET_DISPUTE_BY_LOG_CODE = "/backoffice/dispute";
const CREATE_DISPUTE = "/backoffice/dispute";
const UPDATE_DISPUTE = "/backoffice/dispute";
const DOWNLOAD_BACK_OFFICE_DISPUTES = "/backoffice/dispute/download/filter";
const REOPEN_DISPUTE = "/backoffice/dispute/reopen";
const REOPEN_SYSTEM_ACCEPTED_DISPUTE =
  "/backoffice/dispute/reopen-system-accepted";
const GET_DISPUTEABLE_CODES = "/backoffice/dispute/disputable-codes";
const GET_DISPUTE_REFUND_ATTEMPTS =
  "/backoffice/dispute/dispute-refund-attempts";
const GET_BACK_OFFICE_DISPUTES = "/backoffice/dispute/backoffice";
const GET_SUPER_AGENT_DISPUTES = "/backoffice/dispute/superagent";
const GET_FILTERED_BACK_OFFICE_DISPUTES =
  "/backoffice/dispute/backoffice/filter";
const GET_FILTERED_SUPER_AGENT_DISPUTES =
  "/backoffice/dispute/superagent/filter";
const DOWNLOAD_SUPER_AGENT_DISPUTES =
  "/backoffice/dispute/superagent/download/filter";

export const DISPUTE_ROUTES = {
  GET_DISPUTE_BY_TRANSACTION_KEY,
  GET_FILTERED_DISPUTES,
  CREATE_DISPUTE,
  UPDATE_DISPUTE,
  DOWNLOAD_BACK_OFFICE_DISPUTES,
  REOPEN_DISPUTE,
  REOPEN_SYSTEM_ACCEPTED_DISPUTE,
  GET_DISPUTEABLE_CODES,
  GET_DISPUTE_REFUND_ATTEMPTS,
  GET_BACK_OFFICE_DISPUTES,
  GET_SUPER_AGENT_DISPUTES,
  GET_FILTERED_BACK_OFFICE_DISPUTES,
  GET_FILTERED_SUPER_AGENT_DISPUTES,
  DOWNLOAD_SUPER_AGENT_DISPUTES,
  GET_DISPUTE_BY_LOG_CODE,
};
