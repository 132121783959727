import { useEffect } from "react";
import { useSingleAppConfigurationValue } from "../../../hooks/use-single-configuration-value";
import { BACKOFFICE_CONFIGS } from "../../../utils/constants";
import { HELPER } from "../../../utils/helper";
import { SpecialLabelCases } from "../../../utils/models/utilities";
import { TableActions } from "./table-actions";

export function TableDesktopView(props) {
  const isManualSettlementPaymentEnabled = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.IS_MANUAL_SETTLEMENT_PAYMENT_ENABLED
  );

  useEffect(() => {
    function reDivideColumns() {
      // const columns = props.showCheckbox ? props.columns - 1 : props.columns;
      const columns = props?.headers?.length;
      const maxWidth = props.showCheckbox ? 95 : 100;
      let i;
      const newColumnSize = maxWidth / columns;
      let elements = document.getElementsByClassName("dcir-column");
      for (i = 0; i < elements.length; i++) {
        elements[i].style.width = `${newColumnSize}%`;
      }
      if (props.showCheckbox) {
        let checkboxColumns = document.getElementsByClassName("checkbox-col");
        for (i = 0; i < checkboxColumns.length; i++) {
          checkboxColumns[i].style.width = `5%`;
        }
      }
    }
    if (props?.columns !== 5) {
      reDivideColumns();
    }
  }, [props.columns, props.showCheckbox, props?.headers?.length]);

  const transformView = (itemCase, value, type) => {
    let result = value;
    if (itemCase) {
      SpecialLabelCases.forEach((e) => {
        if (e.case === itemCase) {
          if (type === "dateOnly") {
            result = HELPER.FORMAT_DATE_ONLY(value);
          } else if (
            type === "settlementStatus" &&
            isManualSettlementPaymentEnabled === "true" &&
            value.toLowerCase() === "started"
          ) {
            result = HELPER.SETTLEMENT_WARNING_STATUS(value);
          } else {
            result = e.action(value, itemCase);
          }
        }
      });
    }
    return result ? result : result === 0 ? result : "___";
  };

  const tableContent = (item, label, type) => {
    if (label === "actions") {
      return (
        <div key={`${item.id} ${label}`} className="dcir-column">
          {/* {tableAction(item[label], item)} */}
          <TableActions data={item} authorities={props.authorities} />
        </div>
      );
    } else {
      return (
        <div className="dcir-column">
          <p>{transformView(label, item[label], type)}</p>
        </div>
      );
    }
  };

  const tableValues = () => {
    return props.items.map((item, index) => {
      return (
        <div key={index.toString()} className="dcir-row table-body-text">
          <>
            {props.headers.map((headerContent, index) => {
              return (
                <div key={index.toString()}>
                  {tableContent(item, headerContent.value, headerContent.type)}
                </div>
              );
            })}
          </>
        </div>
      );
    });
  };

  const headers = () => {
    return props.headers.map((item, index) => {
      return (
        <div key={index.toString()}>
          <>
            <div className="dcir-column">
              <p>{item.label}</p>
            </div>
          </>
        </div>
      );
    });
  };

  return (
    <div style={{ position: "relative" }} className="table">
      <div
        style={{ display: props?.isReload ? "block" : "none" }}
        onClick={() => props?.reload()}
        className="custom-table-refresh"
      >
        <span>
          <i className="pi pi-refresh p-px-1" />
          <span className="p-px-1">Reset</span>
        </span>
      </div>
      <div className="table-container">
        <div className="table-card">
          <div className="dcir-row table-header">{headers()}</div>
          {tableValues()}
        </div>
      </div>
    </div>
  );
}
