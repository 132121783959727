const CREATE_AD_USER = "/backoffice/user/create/aduser";
const CREATE_LOCAL_USER = "/backoffice/user/create/localuser";
const UPLOAD_BULK_USERS = "/backoffice/user/upload-users";
const GET_ALL_BACK_OFFICE_USERS = "/backoffice/user/fetch/backofficeusers";
const GET_USERS_BY_ROLE = "/backoffice/user/fetch/backofficeuser-by-role";
const GET_USER_BY_USERNAME =
  "/backoffice/user/fetch/backofficeuser-by-username";
const GET_SINGLE_USER = "/backoffice/user/fetch/backofficeuser";
const GET_AD_USER_BY_USERNAME =
  "/backoffice/user/get/backofficeuser/ad-user-by-username";
const GET_USER_ROLES = "/backoffice/user/roles";
const UNBLOCK_USER = "/common/authentication/unblock-user";

export const USER_MANGEMENT_ROUTES = {
  CREATE_AD_USER,
  CREATE_LOCAL_USER,
  UPLOAD_BULK_USERS,
  GET_ALL_BACK_OFFICE_USERS,
  GET_USERS_BY_ROLE,
  GET_USER_BY_USERNAME,
  GET_SINGLE_USER,
  GET_AD_USER_BY_USERNAME,
  GET_USER_ROLES,
  UNBLOCK_USER,
};
