const CREATE_SUPER_AGENT_USER = "/backoffice/merchant-user";
const GET_SUPER_AGENT_USERS = "/backoffice/merchant-user";
const VIEW_SUPER_AGENT_USER = "/backoffice/merchant-user";
const GET_SUPER_AGENT_USER_ROLES = "/backoffice/merchant-user/roles";
const FILTER_SUPER_AGENT_USERS = "/backoffice/merchant-user/filter";
const RESET_SUPER_AGENT_USER_PASSWORD =
  "/backoffice/merchant-user/reset-password";
const CHANGE_SUPER_AGENT_USER_STATUS =
  "/backoffice/merchant-user/status/change";

export const SUPER_AGENT_USERS_ROUTES = {
  CREATE_SUPER_AGENT_USER,
  VIEW_SUPER_AGENT_USER,
  GET_SUPER_AGENT_USER_ROLES,
  FILTER_SUPER_AGENT_USERS,
  RESET_SUPER_AGENT_USER_PASSWORD,
  CHANGE_SUPER_AGENT_USER_STATUS,
  GET_SUPER_AGENT_USERS,
};
