import { Disputes } from "./disputes";
import { TabPanel, TabView } from "primereact/tabview";
import { useEffect, useState } from "react";
import { BACKOFFICE_CONFIGS, DisputeTypes } from "utils/constants";
import "./dispute.css";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { useSingleAppConfigurationValue } from "../../../hooks/use-single-configuration-value";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";

export function BackofficeDisputes() {
  const [failedResponseDisputeCodes, setFailedResponseDisputeCodes] =
    useState("");
  const disputeableCodes = useSingleAppConfigurationValue(
    BACKOFFICE_CONFIGS.BACKOFFICE_DISPUTE_RESPONSE_CODES
  );

  useEffect(() => {
    function formatDisputeableCodes(disputeableCodes) {
      let formattedCodes = disputeableCodes.split(",");
      formattedCodes = formattedCodes.map((code) => {
        // Trim cause config takes user entered input and can possibly have whitespaces
        return code.trim();
      });
      return formattedCodes;
    }
    function handleDisputeCodesResponse(disputeableCodes) {
      if (disputeableCodes?.length && disputeableCodes?.length > 1) {
        if (disputeableCodes.includes("00")) {
          disputeableCodes.splice(disputeableCodes.indexOf("00"), 1);
          setFailedResponseDisputeCodes(disputeableCodes.join(","));
        } else {
          setFailedResponseDisputeCodes(disputeableCodes?.join(","));
        }
      }
    }
    if (disputeableCodes) {
      const disputeableCode = formatDisputeableCodes(disputeableCodes);
      handleDisputeCodesResponse(disputeableCode ? disputeableCode : []);
    }
  }, [disputeableCodes]);
  return (
    <div className="back-office-disputes">
      {/* <div className="page-title p-text-left">Back Office Disputes</div> */}
      <div className="page-header">
        <div className="page-title p-text-left">Back Office Disputes</div>
        <UserManualViewerButton fileName="manage_disputes-backoffice" />
      </div>
      <div className="p-mt-2">
        <CustomBreadcrumb page="Manage Disputes" />
      </div>
      <div className="p-mt-5">
        <TabView>
          <TabPanel header="All Disputes">
            <div className="p-mt-5">
              <Disputes disputeType={DisputeTypes.Backoffice} />
            </div>
          </TabPanel>
          {failedResponseDisputeCodes?.length ? (
            <TabPanel header="Disputes Pending Approval">
              <div className="p-mt-5">
                <Disputes
                  disputeType={DisputeTypes.BackofficeFailedResponseDisputes}
                  failedResponseDisputeCodes={failedResponseDisputeCodes}
                />
              </div>
            </TabPanel>
          ) : (
            <TabPanel></TabPanel>
          )}
        </TabView>
      </div>
    </div>
  );
}
