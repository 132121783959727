import { BULK_DISPUTE_RESPONSE_ROUTES } from "./bulk-dispute-response";
import { BULK_SETTLEMENT_ROUTES } from "./bulk-settlements";
import { DASHBOARD } from "./dashboard";
import { DISPUTE_ROUTES } from "./disputes";
import { SUPER_AGENT_USER_ROUTES } from "./super-agent-users";
import { TRANSACTION_REPORT_ROUTES } from "./transaction-reports";
import { TRANSACTION_ROUTES } from "./transactions";

export const FRONT_OFFICE_API = {
  BULK_DISPUTE_RESPONSE: {
    ...BULK_DISPUTE_RESPONSE_ROUTES,
  },
  BULK_SETTLEMENT: {
    ...BULK_SETTLEMENT_ROUTES,
  },
  DASHBOARD: {
    ...DASHBOARD,
  },
  DISPUTE: {
    ...DISPUTE_ROUTES,
  },
  SUPER_AGENT_USER: {
    ...SUPER_AGENT_USER_ROUTES,
  },
  TRANSACTION_REPORT: {
    ...TRANSACTION_REPORT_ROUTES,
  },
  TRANSACTION: {
    ...TRANSACTION_ROUTES,
  },
};
