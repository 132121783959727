import "./pending-transactions.css";
import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { PendingTransactionsMetrics } from "./metrics-cards";
import { MONITORING_API } from "../../../api/routes/monitoring-apis/index";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { HELPER } from "utils/helper";
import moment from "moment";

export function PendingTransactions() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [pendingTransactionsCount, setPendingTransactionsCount] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [currentTime, setCurrentTime] = useState("");

  const getPendingTransactionsCount = () => {
    setLoading(true);
    MONITORING_SERVICE.MAKE_GET_REQUEST(
      MONITORING_API.GET_PENDING_PROCESSES_COUNT
    )
      .then((data) => {
        const result = data?.response;
        setPendingTransactionsCount(result);
        setCurrentTime(moment().format("HH:mm"));
        setLoading(false);
        setErrorMessage("");
      })
      .catch((error) => {
        setErrorMessage(HELPER.PROCESS_ERROR(error));
        toast.current.show({
          severity: "error",
          summary: "Error!",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 5000,
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    getPendingTransactionsCount();
  }, []);

  const reload = () => {
    getPendingTransactionsCount();
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="page-title p-text-left">Pending Transactions</div>
      <div className="p-mt-28">
        <CustomBreadcrumb page="Pending Transactions" />
      </div>
      <PendingTransactionsMetrics
        reload={reload}
        currentTime={currentTime}
        errorMessage={errorMessage}
        loading={loading}
        data={pendingTransactionsCount}
      />
    </div>
  );
}
