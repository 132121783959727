import { CONFIG } from "config/config";
import microsoft from "../../../ms-512.png";

export const AzureAdButton = () => {
  const azureLoginUrl = CONFIG.AZURE_LOGIN_URL;
  return (
    <div className="p-mt-2">
      <div className="seperator">
        <div className="line"></div>
        <p>or</p> <div className="line"></div>
      </div>
      <a href={azureLoginUrl} className="secondary-button">
        <img
          style={{
            width: "20px",
            marginRight: "4px",
          }}
          src={microsoft}
          alt="microsoft-logo"
        />
        <p>Sign in with your Microsoft Account</p>
      </a>
    </div>
  );
};
