const CREATE_SUPER_AGENT = "/backoffice/merchant";
const GET_SUPER_AGENT = "/backoffice/merchant";
const UPDATE_SUPER_AGENT = "/backoffice/merchant";
const UPLOAD_BULK_SUPER_AGENT = "/backoffice/merchant/bulk-upload";
const UPDATE_SUPER_AGENT_SETTLEMENT = "/backoffice/merchant/change-settlement";
const GET_SETTLEMENT_TYPE = "/backoffice/merchant/settlement/types";
const DEACTIVATE_SUPER_AGENT = "/backoffice/merchant/status/change";
const GET_ALL_SUPER_AGENTS = "/backoffice/merchant/merchants";
const GET_SUPER_AGENTS_TIED_TO_USER = "/backoffice/merchant/user-merchants";
const GET_FILTERED_SUPER_AGENTS = "/backoffice/merchant/filter";
const DOWNLOAD_FILTERED_SUPER_AGENTS = "/backoffice/merchant/download";

export const SUPER_AGENT_ROUTES = {
  CREATE_SUPER_AGENT,
  UPLOAD_BULK_SUPER_AGENT,
  UPDATE_SUPER_AGENT_SETTLEMENT,
  GET_SETTLEMENT_TYPE,
  DEACTIVATE_SUPER_AGENT,
  GET_ALL_SUPER_AGENTS,
  GET_FILTERED_SUPER_AGENTS,
  DOWNLOAD_FILTERED_SUPER_AGENTS,
  GET_SUPER_AGENTS_TIED_TO_USER,
  GET_SUPER_AGENT,
  UPDATE_SUPER_AGENT,
};
