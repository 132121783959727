import { useRef, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormInput } from "components/form/form-input";
import { CUSTOM_VALIDATION } from "utils/validation";
import { Toast } from "primereact/toast";
import { FormDropdown } from "components/form/form-dropdown";

export function BulkDisputeCreationResultFilter(props) {
  const toast = useRef(null);
  const [loading] = useState(false);
  const [currentIndex] = useState(0);
  const [filters, setFilters] = useState({
    logCode: "",
    disputeResolutionStatus: "",
    creationStatus: "",
  });
  const [filtersError, setFiltersError] = useState({
    logCode: null,
    disputeResolutionStatus: null,
    creationStatus: null,
  });

  const disputeResolutionStatuses = [
    { desc: "Pending", code: "PENDING" },
    { desc: "Accepted", code: "ACCEPTED" },
    { desc: "Declined", code: "DECLINED" },
  ];

  const creationStatuses = [
    { desc: "Pending", code: "PENDING" },
    { desc: "Successful", code: "SUCCESSFUL" },
    { desc: "Failed", code: "FAILED" },
  ];

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;
    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      if (name === "logCode") {
        // Encode log code to allow pipes(|) in log code be handled properly
        setFilters({ ...filters, [name]: encodeURIComponent(value) });
      } else {
        setFilters({ ...filters, [name]: value });
      }
      setFiltersError({ ...filtersError, [name]: null });
    }
  }

  function validateDropdown(e, name) {
    const value = e.target.value;
    if (value) {
      setFiltersError({ ...filtersError, [name]: null });
      setFilters({ ...filters, [name]: value });
    }
  }

  function getFilters() {
    let payload = {};

    for (const property in filters) {
      if (filters[property]) {
        if (
          property === "creationStatus" ||
          property === "disputeResolutionStatus"
        ) {
          payload[property] = filters[property]?.code;
        } else {
          payload[property] = filters[property];
        }
      }
    }
    return payload;
  }

  function cancelModal() {
    props.closeModal();
  }

  const cancelButton = () => {
    if (!loading) {
      return (
        <button onClick={cancelModal} className="secondary-button">
          Cancel
        </button>
      );
    } else {
      return <div />;
    }
  };

  const submitButton = () => {
    if (!loading) {
      return (
        <button
          onClick={() => props?.searchFunction(getFilters())}
          className="primary-button"
        >
          Filter
        </button>
      );
    } else {
      return (
        <div className="pull-up-element-2">
          <CustomLoader loadingText="Submitting..." />
        </div>
      );
    }
  };

  const bulkDisputesFilterView = () => {
    if (currentIndex) {
      return (
        <div>
          <div className="success-message-btn-container">
            <button
              onClick={() => {
                props.closeModal(true);
              }}
              className="primary-button success-message-btn"
            >
              Close
            </button>
          </div>
        </div>
      );
    } else {
      return <div>{bulkDisputesFilterForm()}</div>;
    }
  };

  const bulkDisputesFilterForm = () => {
    return (
      <div>
        <div className="custom-modal-title p-text-left">Filter</div>
        <div className="custom-dialog-subtitle-container p-mb-5 p-text-left">
          <small>Select start and end date.</small>
        </div>
        <div className="p-grid p-mt-2">
          <div className="p-col-12">
            <FormInput
              value={filters["logCode"]}
              required={false}
              field="logCode"
              type="INPUT"
              error={filtersError["logCode"]}
              fn={validateForm}
              loading={loading}
              placeholder="Log code"
            />
          </div>
          <div className="p-col-12">
            <FormDropdown
              required={false}
              label="code"
              field="disputeResolutionStatus"
              error={filtersError["disputeResolutionStatus"]}
              disabled={loading}
              value={filters["disputeResolutionStatus"]}
              fn={validateDropdown}
              options={disputeResolutionStatuses}
              placeholder="Dispute resolution Status"
            />
          </div>
          <div className="p-col-12">
            <FormDropdown
              required={false}
              label="code"
              field="creationStatus"
              error={filtersError["creationStatus"]}
              disabled={loading}
              value={filters["creationStatus"]}
              fn={validateDropdown}
              options={creationStatuses}
              placeholder="Dispute creation status"
            />
          </div>

          <div className="p-col-12">
            <div className="p-mt-5">
              <div className="p-grid">
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {cancelButton()}
                </div>
                <div className={loading ? "p-col-12" : "p-col-6"}>
                  {submitButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <Toast ref={toast} />
      </div>
      <div className="p-pb-2">{bulkDisputesFilterView()}</div>
    </div>
  );
}
