import { useContext, useEffect, useState } from "react";
import { MainContext } from "App";
import { ProgressBar } from "../progress-bar/progress-bar";
export function DownloadProgress(props) {
  const mainContext = useContext(MainContext);
  const [downloadProgress, setDownloadProgress] = useState(0);
  useEffect(() => {
    setDownloadProgress(mainContext?.mainState?.percentDownloaded);
  }, [mainContext?.mainState?.percentDownloaded]);
  return (
    <div>
      <ProgressBar
        value={props.percent ? props.percent : downloadProgress}
        width="100%"
        height="1rem"
        background="#0371AC"
      />
      {props.noText ? "" : <p className="text-small">Loading...</p>}
    </div>
  );
}
