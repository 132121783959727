import { useState } from "react";
import { API_SERVICE } from "api/service";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormDropdown } from "components/form/form-dropdown";
import { FormInput } from "components/form/form-input";
import { Optional } from "components/elements/optional/optional";
import { CUSTOM_VALIDATION } from "utils/validation";
import { saveAs } from "file-saver";
import { HELPER } from "utils/helper";
import { CustomToast } from "components/elements/alert/custom-toast";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function SuperAgentsFilter(props) {
  const [filters, setFilters] = useState({
    merchantName: null,
    active: null,
    cardAcceptorId: null,
    createStartDate: "",
    createEndDate: "",
  });
  const [filterErrors, setFilterErrors] = useState({
    merchantName: null,
    active: null,
    cardAcceptorId: null,
    createStartDate: null,
    createEndDate: null,
  });
  const [loading, setLoading] = useState(false);
  const statuses = [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ];
  const [downloadError, setDownloadError] = useState(null);

  function validateDropdown(e, name) {
    const value = e.target.value;
    setFilterErrors({ ...filterErrors, [name]: "" });
    setFilters({ ...filters, [name]: value });
  }

  function validateForm(e, name, type, refineName, required) {
    let value = e?.target?.value;

    const isEmpty = CUSTOM_VALIDATION.IS_EMPTY(value);
    const isValidInput = !isEmpty
      ? CUSTOM_VALIDATION.BASIC_VALIDATION(value, type)
      : false;
    if (isValidInput) {
      setFilters({ ...filters, [name]: value });
      setFilterErrors({ ...filterErrors, [name]: null });
    } else {
      let errorMessage =
        required && isEmpty ? `${refineName} is required` : null;
      if (!isValidInput) {
        errorMessage = `${refineName} is invalid`;
      }
      setFilterErrors({ ...filterErrors, [name]: errorMessage });
      setFilters({ ...filters, [name]: null });
    }
  }

  function getFilters() {
    let payload = {};
    for (const property in filters) {
      const value = filters[property];
      if (value || (property === "active" && value !== null)) {
        payload[property] = value;
      }
    }
    return payload;
  }

  async function downloadSuperAgents() {
    setLoading(true);
    const payload = getFilters();
    const url = BACK_OFFICE_API.SUPER_AGENT.DOWNLOAD_FILTERED_SUPER_AGENTS;
    const isBlob = true;
    API_SERVICE.MAKE_PUT_REQUEST(url, payload, isBlob)
      .then((data) => {
        saveAs(data, `${HELPER.TO_URL_STRING(payload)}.xlsx`);
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    setLoading(false);
  }

  return (
    <div>
      <div className="custom-modal-title p-text-left">Filter Super Agents</div>
      <div className="p-grid">
        <div className="p-col-12 p-mt-4">
          <FormInput
            value={filters["merchantName"]}
            required={false}
            field="merchantName"
            type="NAME"
            error={filterErrors["merchantName"]}
            fn={validateForm}
            placeholder="Super Agent Name"
          />
        </div>
        <div className="p-col-12 p-mt-2">
          <FormInput
            value={filters["cardAcceptorId"]}
            required={false}
            field="cardAcceptorId"
            type="CARD_ACCEPTANCE_ID"
            error={filterErrors["cardAcceptorId"]}
            fn={validateForm}
            placeholder="Card Acceptor Id"
          />
        </div>
        <div className="p-col-12 p-mt-2">
          <FormDropdown
            required={false}
            label="label"
            field="active"
            error={filterErrors["active"]}
            value={filters["active"]}
            fn={validateDropdown}
            options={statuses}
            placeholder="Status"
          />
        </div>
        <div className="p-col-6 p-mt-2">
          <FormInput
            inputType="date"
            value={filters["createStartDate"]}
            required={false}
            field="createStartDate"
            type="INPUT"
            error={filterErrors["createStartDate"]}
            fn={validateForm}
            placeholder="Start Date"
          />
          {/* <input
            type="date"
            value={filters["createStartDate"]}
            onChange={(e) =>
              setFilters({ ...filters, createStartDate: e?.target?.value })
            }
          /> */}
        </div>

        <div className="p-col-6 p-mt-2">
          <FormInput
            inputType="date"
            value={filters["createEndDate"]}
            required={false}
            field="createEndDate"
            type="INPUT"
            error={filterErrors["createEndDate"]}
            fn={validateForm}
            placeholder="End Date"
          />
          {/* <input
            type="date"
            value={filters["createEndDate"]}
            onChange={(e) =>
              setFilters({ ...filters, createEndDate: e?.target?.value })
            }
          /> */}
        </div>

        <div className="p-col-12 p-py-2">
          {downloadError && (
            <CustomToast
              title="Error"
              description={downloadError}
              type="error"
            />
          )}
        </div>

        <Optional show={!loading}>
          <div className="p-col-12 p-mt-4 p-mb-5">
            <div className="p-grid">
              <div className="p-col-4">
                <button onClick={props.closeModal} className="secondary-button">
                  Cancel
                </button>
              </div>
              <div className="p-col-4">
                <button
                  className="primary-button download-button"
                  onClick={downloadSuperAgents}
                >
                  Download
                </button>
              </div>
              <div className="p-col-4">
                <button
                  className="primary-button"
                  onClick={() => props.searchFunction(getFilters())}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </Optional>
        <Optional show={loading}>
          <div className="p-mt-2 p-text-center p-col-12">
            <CustomLoader loadingText="Loading..." />
          </div>
        </Optional>
      </div>
    </div>
  );
}
