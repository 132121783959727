export const MAXIMUM_RECEIPT_SIZE_IN_MB = 2;
export const MAXIMUM_BULK_DISPUTE_ZIP_SIZE_IN_MB = 800;
export const API_REQUEST_PAGE_SIZE = 10;
export const SYSTEM_ACCEPTED = "SYSTEM_ACCEPTED";
export const DECLINED = "DECLINED";
export const RESOLVED = "RESOLVED";
export const BACKOFFICE_CONFIGS = {
  DISPUTE_REASON_LIST: "dcir.dispute.reason.list",
  GL_ACCOUNT_NUMBER_SIZE: "dcir.gl.account.number.size",
  GL_NAME_ENQUIRY_OPTION: "dcir.gl.name.enquiry.applicable",
  BACKOFFICE_DISPUTE_RESPONSE_CODES: "dcir.dispute.backoffice.response-codes",
  FRONTOFFICE_DISPUTE_RESPONSE_CODES: "dcir.dispute.merchant.response-codes",
  TRANSACTIONS_DOWNLOAD_DATE_RANGE: "dcir.calendar.date-range-in-days",
  ACTIVE_DIRECTORY_STATUS: "dcir.active-directory-enabled",
  AUTO_DISPUTE_CREATION_STATUS: "dcir.recon.dispute.auto-create",
  VAT_RATE: "dcir.charge.vat.percentage",
  DIRECT_DEBIT_BACKOFFICE_DISPUTEABLE_STATUSES:
    "direct.debit.backoffice.disputeable.statuses",
  DIRECT_DEBIT_FRONTOFFICE_DISPUTEABLE_STATUSES:
    "direct.debit.frontoffice.disputeable.statuses",
  IS_NAME_ENQUIRY_ENABLED: "dcir.name-enquiry-enabled",
  IS_MANUAL_DISPUTE_REFUND_ENABLED: "dcir.enable.manual.dispute-refund",
  IS_MANUAL_SETTLEMENT_PAYMENT_ENABLED: "dcir.enable.manual.settlement-payment",
};
export const API_VERSION = "v1";
export const ACCOUNT_WEB_PARTICIPANT = "ACCOUNT_WEB_PARTICIPANT";
export const PARTICIPANT = "PARTICIPANT";
export const PASSWORD_VALIDATION_ERROR_MESSAGE = `Your password must contain at least 8 characters, an uppercase character, a lowercase character and a special character.`;
export const BACKOFFICE_DISPUTE_TYPE = {
  BACKOFFICE: "backoffice",
  BACKOFFICE_FAILED_RESPONSE_DISPUTES: "backoffice-failed-response-disputes",
  SUPERAGENT: "SUPERAGENT",
};
export const ACCOUNT_WEB = "account-web";
export const FRONT_OFFICE = "front-office";
export const BACK_OFFICE = "back-office";
export const SUPERAGENT_DISPUTES = "SUPERAGENT_DISPUTES";
export const BANK_DISPUTES = "BANK_DISPUTES";
// export const DisputeTypes = {
//   BackOffice: "BackOffice",
//   SuperAgent: "SuperAgent",
// };
export const TransactionChannels = {
  AccountWeb: "AccountWeb",
};
export const ACCOUNT_TYPES = ["GL", "NUBAN"];

export const DisputeTypes = {
  AgentDisputes: "agent-disputes",
  BankDisputes: "bank-disputes",
  BackofficeFailedResponseDisputes: "backoffice-failed-response-disputes",
  Backoffice: "backoffice",
  BackofficeSuperAgent: "backoffice-superagent",
};

export const ManualPaymentTypes = {
  Settlement: "Settlement",
  Dispute: "Dispute",
};
export const MAX_ANALYTICS_DATE_RANGE_IN_DAYS = 30;

export const UserTypes = {
  FrontOffice: "front-office",
  BackOffice: "back-office",
};

export const DOWNLOADED_FOR_PROCESSING = "DOWNLOADED_FOR_PROCESSING";
