import { useCallback, useEffect, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { Optional } from "components/elements/optional/optional";
import { HELPER } from "utils/helper";
import moment from "moment";
import { StatementUploadsFilter } from "../statement-upload/files-filter";
import { TransactionReconciliationBreakdown } from "./reconciliation-breakdown-by-day";
import { BACKOFFICE_SERVICE } from "../../../../api/services/backoffice-service";
// import { ReconTransactionsByDay } from "./daily-uploads";

export function CreditDebitReconciliation(props) {
  const [visible, setVisible] = useState(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  const [pageNo, setPageNo] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionDate, setTransactionDate] = useState(null);
  const [reconciliationsByDays, setReconciliationsByDays] = useState([]);
  const [emptyText, setEmptyText] = useState("No results!");
  const [searchParams, setSearchParams] = useState(null);
  const reconType =
    props.transactionIndicator === "CREDIT" ? "Credit" : "Debit";

  const tableHeaders = [
    { label: "Transaction Date", value: "transactionDate", type: "dateOnly" },
    { label: "Transaction Count", value: "totalAccountCount" },
    { label: "Actions", value: "actions" },
  ];

  const [fileDetails, setFileDetails] = useState(null);

  const openAction = useCallback((e) => {
    // setCurrentPageIndex(2);
    let transDate = moment(e?.transactionDate, "YYYY MM DD").format(
      "YYYY-MM-DD"
    );
    setFileDetails({
      totalAccountEntryUnmatched: e?.totalAccountEntryUnmatched,
      totalAccountEntryUnmatchedValue: e?.totalAccountEntryUnmatchedValue,
      totalAccountCount: e?.totalAccountCount,
      totalAccountValue: e?.totalAccountValue,
      totalMatchedAndSuccessful: e?.totalMatchedAndSuccessful,
      totalMatchedAndFailed: e?.totalMatchedAndFailed,
      totalDbCount: e?.totalDbCount,
      totalDbValue: e?.totalDbValue,
    });
    setTransactionDate(transDate);
    setCurrentPageIndex(2);
  }, []);

  const getReconciliationsByDays = useCallback(() => {
    setCurrentPageIndex(0);
    setVisible(false);
    let payload = {
      page: pageNo,
      size: 10,
      transactionIndicator: props.transactionIndicator,
    };
    if (searchParams) {
      setIsSearch(true);
      payload = {
        ...payload,
        ...searchParams,
      };
    }
    payload = HELPER.TO_URL_STRING(payload);
    BACKOFFICE_SERVICE.GET_DAILY_CREDIT_DEBIT_SUMMARIES(payload)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No results found!");
        } else {
          let arr = [];
          setTotalItems(data?.result.totalElements);
          setTotalPages(data?.result.totalPages);
          result.forEach((e) => {
            arr.push({
              ...e,
              actions: "CR",
              detailsFunction: openAction,
            });
          });
          setReconciliationsByDays(arr);
        }
        setError(null);
        setCurrentPageIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
      });
  }, [openAction, pageNo, searchParams, props.transactionIndicator]);

  useEffect(() => {
    getReconciliationsByDays();
  }, [getReconciliationsByDays]);

  function openModal() {
    setVisible(true);
  }

  function closeModal() {
    setVisible(false);
  }

  function reload() {}

  const modalContent = () => {
    return (
      <StatementUploadsFilter
        closeModal={closeModal}
        searchFunction={(params) => setSearchParams(params)}
      />
    );
  };
  const pageContent = () => {
    switch (currentPageIndex) {
      case 0:
        return <CustomLoader loadingText="Loading..." />;
      case 1:
        return (
          <CustomTable
            isReload={true}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={0}
            emptyText={emptyText}
            search={isSearch}
            reload={reload}
            error={error}
            items={reconciliationsByDays}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={(pageNo) => setPageNo(pageNo)}
          />
        );
      case 2:
        return (
          <TransactionReconciliationBreakdown
            goBack={() => setCurrentPageIndex(1)}
            transactionDate={transactionDate}
            transactionIndicator={props.transactionIndicator}
            day={transactionDate}
            fileDetails={fileDetails}
          />
        );
      default:
        break;
    }
  };
  return (
    <>
      <div>
        <CustomModal
          closeModal={closeModal}
          onHide={() => setVisible(false)}
          visible={visible}
          modalContent={modalContent}
        />
      </div>
      <div className="page-title p-text-left">{reconType} Reconciliation</div>
      <div className="p-mt-2">
        <CustomBreadcrumb page={`Manage ${reconType} Reconciliation`} />
      </div>
      <Optional show={currentPageIndex === 1}>
        <div className="p-grid">
          <div className="p-col-7"></div>
          <div className="p-col-5">
            <div className="p-grid">
              <div className="p-col-6 p-d-flex p-jc-end p-ai-center"></div>
              <div className="p-col-6">
                <Optional show={true}>
                  <button
                    disabled={loading}
                    onClick={() => openModal(0, false)}
                    className="primary-button hide-btn-text"
                  >
                    <i className="pi pi-filter" />
                    <span className="hide-btn-text">Filter</span>
                  </button>
                </Optional>
              </div>
            </div>
          </div>
        </div>
      </Optional>
      <div className="p-mt-4">{pageContent()}</div>
    </>
  );
}
