import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { DbConnectionClosures } from "./db-connection-closures";
import { LongRunningQueries } from "./long-running-queries";
import { ServicesHealthStatus } from "./services-health-status";

export function DcirServicesMonitoring() {
  return (
    <div className="dcir-services-monitoring p-text-left">
      <div className="page-title p-text-left"> Service Monitoring</div>
      <div className="p-mt-2">
        <CustomBreadcrumb page="Service Monitoring" />
      </div>
      <div className="p-grid p-mt-5">
        <div className="p-col-6">
          <ServicesHealthStatus />
        </div>
        <div className="p-col-6">
          <DbConnectionClosures />
        </div>
      </div>
      <div className="p-grid p-mt-5">
        <div className="p-col-12">
          <LongRunningQueries />
        </div>
      </div>
    </div>
  );
}
