import { useEffect, useState } from "react";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { FormDropdown } from "components/form/form-dropdown";
import { ScrollPanel } from "primereact/scrollpanel";
import { API_SERVICE } from "api/service";
import { HELPER } from "utils/helper";
import { Optional } from "components/elements/optional/optional";
import { CustomToast } from "components/elements/alert/custom-toast";
import { CustomMessage } from "components/elements/alert/custom-message";
import { COMMON_API } from "../../../api/routes/common/index";

export function ChangeUserRole(props) {
  const [loading, setLoading] = useState(false);
  const [showSuccess] = useState(false);
  const [roles] = useState(props.roles);
  const [role, setRole] = useState(props.userRole);
  const [selectedRoleAuthorities, setSelectedRoleAuthorities] = useState([]);
  const [updateResponse, setUpdateResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loadingPermissions, setLoadingPermissions] = useState(false);

  function updateUserRole() {
    setLoading(true);
    const payload = {
      role,
      username: props.username,
    };
    const url = COMMON_API.AUTH.UPDATE_USER_ROLE;
    API_SERVICE.MAKE_PUT_REQUEST(url, payload)
      .then((data) => {
        setUpdateResponse(data?.result?.message);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }

  function handleRoleSelection(e) {
    const value = e.target.value;
    setRole(value);
  }

  useEffect(() => {
    function getRoleDetails() {
      setLoadingPermissions(true);
      const url = COMMON_API.AUTH.GET_SINGLE_ROLE;
      API_SERVICE.MAKE_GET_REQUEST(`${url}/${role}`)
        .then((data) => {
          const result = data?.result;
          setSelectedRoleAuthorities(result?.authorities);
          setLoadingPermissions(false);
        })
        .catch((error) => {
          setLoadingPermissions(false);
          setError(HELPER.PROCESS_ERROR(error));
        });
    }
    getRoleDetails();
  }, [role]);

  const actions = () => {
    if (loading) {
      return (
        <div className="p-text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else if (!loading && !showSuccess) {
      return (
        <div className="p-mt-5 p-pb-3">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.cancel} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button onClick={updateUserRole} className="primary-button">
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const currentView = () => {
    if (updateResponse) {
      return (
        // <div>
        //   <p style={{ textAlign: "center", fontWeight: "normal" }}>
        //     Something don shele Something don shele
        //   </p>
        // </div>
        <div className="p-text-center">
          <div>
            <CustomMessage messageType="success" />
          </div>
          <div>
            <p className="success-message-text">{updateResponse}</p>
          </div>
          <div className="success-message-btn-container p-text-center p-my-4">
            <p
              onClick={() => {
                props.closeModal(true);
              }}
              className="close-modal add-cursor"
            >
              Close
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>
            <p className="p-my-0" style={{ fontSize: "1.1rem" }}>
              Update User Role
            </p>
            <p className="text-small p-mt-1" style={{ fontWeight: "normal" }}>
              Select new user role below
            </p>
          </div>
          {/* <div className="p-grid"> */}
          <div className="p-c">
            <p className="p-mb-0">Role:</p>
          </div>
          <div className="p-mt-2">
            <FormDropdown
              required={false}
              error={null}
              disabled={false}
              value={role}
              fn={handleRoleSelection}
              options={roles}
              placeholder="Select a role"
            />
          </div>
          {/* </div> */}
          <div>
            <div>
              <p className="p-mt-4">
                Permissions within {role?.split("_").join(" ")} role:
              </p>
            </div>

            <ScrollPanel style={{ width: "100%", height: "300px" }}>
              <div className="role-permissions">
                <Optional show={!loadingPermissions}>
                  {selectedRoleAuthorities.map((authority) => (
                    <p className="role-permission">{authority.friendlyName}</p>
                  ))}
                </Optional>
                <Optional show={loadingPermissions}>
                  <div className="permissions-loader">
                    <CustomLoader loadingText="" />
                  </div>
                </Optional>
              </div>
            </ScrollPanel>
          </div>
          <Optional show={error}>
            <div>
              <CustomToast title="Error" description={error} type="error" />
            </div>
          </Optional>
          <div className="">{actions()}</div>
        </div>
      );
    }
  };

  return (
    <div className="p-text-left change-role">
      <>{currentView()}</>
    </div>
  );
}
