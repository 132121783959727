import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import { useState } from "react";

export function ServerStatus({ server }) {
  const [isServerUp] = useState(server?.up);
  return (
    <CustomCard background={`${isServerUp ? "#7bd54f" : "#ea5455"}`}>
      <div
        className="p-text-center p-my-0"
        style={{
          background: `${isServerUp ? "#7bd54f" : "#ea5455"}`,
          color: "#ffffff",
          padding: " 0",
        }}
      >
        <p className="p-my-0 p-text-bold">
          {server?.name}
          <span
            className="blink"
            style={{
              display: "inline-block",
              height: "0.5rem",
              width: "0.5rem",
              borderRadius: "50%",
              background: `${isServerUp ? "#bfeacc" : "#ea5455"}`,
            }}
          ></span>
        </p>
      </div>
    </CustomCard>
  );
}
