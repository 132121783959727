import { useCallback, useEffect, useState } from "react";
import { MONITORING_SERVICE } from "../../../api/services/monitoring-service";
import { CustomCard } from "../../../components/elements/custom-card/custom-card";
import { CustomPagination } from "components/elements/custom-pagination/custom-pagination";
import { TableErrorView } from "../../../components/elements/custom-table/table-error-view";
import { HELPER } from "utils/helper";
import { Optional } from "components/elements/optional/optional";
import { Icon } from "components/elements/icons/icon";

export function DbConnectionClosures() {
  const [dbConnectionClosures, setDbConnectionClosures] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paginationProps, setPaginationProps] = useState({});
  const [pageNo, setPageNo] = useState(0);

  const savePaginationProps = useCallback(
    (pageData) => {
      const { totalPages, totalElements: totalItems } = pageData;
      const props = {
        totalPages,
        totalItems,
        currentPage: pageNo + 1,
        nextPage: () => setPageNo(pageNo + 1),
        prevPage: () => setPageNo(pageNo - 1),
        goToFirstPage: () => setPageNo(0),
        goToLastPage: () => setPageNo(totalPages - 1),
        goToPage: (page) => setPageNo(page),
      };
      setPaginationProps({ ...props });
    },
    [pageNo]
  );

  const getDatabaseConnectionClosures = useCallback(() => {
    setLoading(true);
    const params = HELPER.TO_URL_STRING({
      page: pageNo,
      size: 10,
    });
    const url = `/db-metrics/connection-closures?${params}`;
    MONITORING_SERVICE.MAKE_GET_REQUEST(url)
      .then((data) => {
        setDbConnectionClosures(data?.response?.content);
        savePaginationProps(data?.response);
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        setError(HELPER.PROCESS_ERROR(error));
        setLoading(false);
      });
  }, [savePaginationProps, pageNo]);

  useEffect(() => {
    getDatabaseConnectionClosures();
  }, [getDatabaseConnectionClosures]);

  function renderTableView() {
    return (
      <table className="p-mt-5 p-mb-4">
        <tr>
          <td>Service</td>
          <td>Times Of Occurence</td>
        </tr>
        {dbConnectionClosures.map((data) => (
          <tr>
            <td>{data?.service}</td>

            {/* <td>{HELPER.FORMAT_DATE(data?.date)}</td> */}
            <td>
              {!data?.dateTimeOfOccurrences?.length ? (
                <p>-------</p>
              ) : (
                data?.dateTimeOfOccurrences.map((occurence) => (
                  <p> {HELPER.FORMAT_DATE(occurence)} </p>
                ))
              )}
            </td>
          </tr>
        ))}
      </table>
    );
  }
  return (
    <CustomCard loading={loading}>
      <div>
        <p className="p-text-bold p-mt-0">Database Connection Closures</p>
        <Optional show={!dbConnectionClosures.length}>
          {/* <TableEmptyView
            emptyText="No results found"
            onReload={getDatabaseConnectionClosures}
          /> */}
          <div className="p-text-center">
            <Icon icon="no-item" />
            <div>
              <p className="empty-text-two p-text-center">No results found!</p>
            </div>
            <div className="p-mt-3">
              <button
                onClick={getDatabaseConnectionClosures}
                className="primary-button"
              >
                Reload
              </button>
            </div>
          </div>
        </Optional>
        <Optional show={error || dbConnectionClosures.length}>
          {error ? (
            <TableErrorView
              error={error}
              onReload={getDatabaseConnectionClosures}
            />
          ) : (
            renderTableView()
          )}
          <div className="custom-page-card p-text-center">
            <CustomPagination {...paginationProps} range="5" />
          </div>
        </Optional>
      </div>
    </CustomCard>
  );
}
