import React, { useContext, useState } from "react";
import { API_SERVICE } from "api/service";
import { saveAs } from "file-saver";
import { CustomToast } from "components/elements/alert/custom-toast";
import { HELPER } from "utils/helper";
import moment from "moment";
import { Optional } from "components/elements/optional/optional";
import { DownloadProgress } from "components/elements/download-progress/download-progress";
import { MainContext } from "App";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function DownloadConfirmation(props) {
  const mainContext = useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  function downloadTransactions() {
    const currentDate = Date.now();
    setLoading(true);
    const url = BACK_OFFICE_API.BULK_DISPUTE.DOWNLOAD_BULK_DISPUTE_TRANSACTIONS;
    const isBlobResponse = true;
    API_SERVICE.MAKE_PUT_REQUEST(
      `${url}/${props.batchId}`,
      {},
      isBlobResponse,
      mainContext
    )
      .then((data) => {
        saveAs(
          data,
          `${props.batchId}-${moment(currentDate).format("YYYY-MM-DD")}.xlsx`
        );
        props.closeModal();
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function handleError(error) {
    const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
    setDownloadError(errMessage);
    resetError();
    setLoading(false);
  }

  function resetError() {
    setTimeout(() => {
      setDownloadError(null);
    }, 5000);
  }

  return (
    <div>
      <div className="p-pb-1">
        {downloadError && (
          <CustomToast title="Error" description={downloadError} type="error" />
        )}
      </div>
      <h4 className="p-text-left p-mt-0">Confirm Download!</h4>
      <p className="p-text-left">
        Confirm that you want to download these transactions.
      </p>
      <Optional show={loading}>
        <DownloadProgress />
      </Optional>
      {/* {loading ? (
        <CustomLoader loadingText="Loading..." />
      ) : ( */}
      <Optional show={!loading}>
        <div className="p-mt-4 p-pb-2">
          <div className="p-grid">
            <div className="p-col-6">
              <button onClick={props.closeModal} className="secondary-button">
                Cancel
              </button>
            </div>
            <div className="p-col-6">
              <button onClick={downloadTransactions} className="primary-button">
                Download
              </button>
            </div>
          </div>
        </div>
      </Optional>
      {/* )} */}
    </div>
  );
}
