import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomBreadcrumb } from "components/elements/custom-breadcrumb/custom-breadcrumb";
import { CustomTable } from "components/elements/custom-table/custom-table";
import { API_SERVICE } from "api/service";
import "./participants.css";
import { CustomLoader } from "components/elements/custom-loader/custom-loader";
import { CustomModal } from "components/elements/custom-modal/custom-modal";
import { DetailsBreakDown } from "components/elements/details-break-down/details-break-down";
import { CustomConfirmDialog } from "../../../components/elements/custom-confirm-dialog/custom-confirm-dialog";
import { Toast } from "primereact/toast";
import { CustomSettlementParticipantModel } from "./custom-settlement-participant-model";
import { UserManualViewerButton } from "components/elements/user-manual-viewer/user-manual-viewer-button";

import { HELPER } from "utils/helper";
import ErrorBoundary from "components/elements/error-boundary/error-boundary";
import { BACK_OFFICE_API } from "../../../api/routes/back-office/index";

export function Participants() {
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState("");
  const [search, setSearch] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [itemIdForDelete, setItemIdForDelete] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [range] = useState(5);
  const [breakDownTitle, setBreakDownTitle] = useState("");
  const [participantForEdit, setParticipantForEdit] = useState(null);
  const [pageNo, setPageNo] = useState(0);

  const [emptyText, setEmptyText] = useState("");
  const [currentModalIndex, setCurrentModalIndex] = useState(0);

  const tableHeaders = [
    { label: "Name", value: "name" },
    { label: "Account number", value: "accountNumber" },
    { label: "Account name", value: "accountName" },
    { label: "Charge type", value: "chargeType" },
    { label: "Global", value: "global" },
    { label: "Actions", value: "actions" },
  ];

  const participantAuthorities = [
    { label: "UPDATE", value: "bo_dcir_update_participant" },
    { label: "DELETE", value: "bo_dcir_remove_participant" },
  ];

  const [details, setDetails] = useState([]);

  const [participants, setParticipants] = useState([]);

  const openModal = useCallback((index, isMobile) => {
    setCurrentModalIndex(index);
    if (isMobile) {
      setCurrentIndex(2);
    } else {
      setVisible(true);
    }
  }, []);

  const viewParticipant = useCallback(
    (id, isMobile) => {
      const url = BACK_OFFICE_API.PARTICIPANT.VIEW_PARTICIPANT;
      API_SERVICE.MAKE_GET_REQUEST(`${url}/${id}`)
        .then((data) => {
          const e = data?.result;
          let arr = [];
          setDetails([]);
          arr.push({
            label: "Participant id",
            value: e?.participantId,
          });
          arr.push({ label: "Name", value: e?.name });
          arr.push({ label: "Credit account name", value: e?.accountName });
          arr.push({
            label: "Credit account number",
            value: e?.accountNumber,
          });
          arr.push({
            label: "Fee sharing participant",
            value: e?.isFeeSharing ? "Yes" : "No",
          });
          if (!e?.isFeeSharing) {
            arr.push({
              label: "Debit account name",
              value: e?.feeDebitAccountName,
            });
            arr.push({
              label: "Debit account number",
              value: e?.feeDebitAccount,
            });
          }

          arr.push({ label: "Bank code", value: e?.bankCode });
          arr.push({ label: "Bank name", value: e?.bankName });
          arr.push({
            label: "Charge type code",
            value: e?.chargeType?.code,
          });
          arr.push({
            label: "Charge type",
            value: e?.chargeType?.chargeType?.code,
          });
          arr.push({
            label: "Flat",
            value: e?.chargeType?.flat,
            itemCase: "amount",
          });
          arr.push({
            label: "Minimum Cap",
            value: e?.chargeType?.minimumCap,
            itemCase: "amount",
          });
          arr.push({
            label: "Maximum Cap",
            value: e?.chargeType?.maximumCap,
            itemCase: "amount",
          });
          arr.push({
            label: "Percentage",
            value: e?.chargeType?.percentage,
          });
          arr.push({ label: "Description", value: e?.description });
          arr.push({
            label: "Global",
            value: e?.globalCharge ? "GLOBAL" : "NON GLOBAL",
          });
          setDetails(arr);
          setBreakDownTitle("Participant");
          openModal(2, isMobile);
        })
        .catch((error) => {
          toast.current.show({
            severity: "error",
            summary: "Error getting participant details!",
            detail: HELPER.PROCESS_ERROR(error, "TOAST"),
            life: 10000,
          });
        });
    },
    [openModal]
  );

  const openAction = useCallback(
    (e, action, isMobile) => {
      // eslint-disable-next-line default-case
      switch (action) {
        case "VIEW": {
          viewParticipant(e.participantId, isMobile);
          break;
        }
        case "DELETE":
          setItemIdForDelete(e?.participantId); //remember to change to chargeCode
          setConfirmText(`${e?.name?.toString()?.toLowerCase()}`);
          openModal(3, isMobile);
          break;
        case "UPDATE":
          const participantModel = {
            name: e?.name,
            accountName: e?.accountName,
            accountNumber: e?.accountNumber,
            chargeType: e?.chargeType,
            description: e?.description,
            global: e?.globalCharge,
            id: e?.participantId,
            reportEmail: e?.reportEmail,
            sendReport: e?.sendReport,
            feeDebitAccount: e?.feeDebitAccount,
            feeDebitAccountName: e?.feeDebitAccountName,
            feeSharing: e?.isFeeSharing,
            useMerchantChargeType: e?.useMerchantChargeType,
            remainderParticipant: e?.remainderParticipant,
          };
          setParticipantForEdit(participantModel);
          openModal(4, isMobile);
      }
    },
    [viewParticipant, openModal]
  );

  const getParticipants = useCallback(() => {
    setCurrentIndex(0);
    setParticipants([]);
    let params = {
      page: pageNo,
      size: 10,
    };
    params = HELPER.TO_URL_STRING(params);
    const url = BACK_OFFICE_API.PARTICIPANT.GET_SETTLEMENT_PARTICIPANTS;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data?.result?.content;
        if (!result?.length) {
          setEmptyText("No settlement participants found...");
        } else {
          let arr = [];
          setTotalItems(data?.result?.totalElements);
          setTotalPages(data?.result?.totalPages);
          result?.forEach((e) => {
            arr.push({
              ...e,
              global: e.globalCharge ? "GLOBAL" : "NON GLOBAL",
              chargeType: e.chargeType?.chargeTypeName,
              actions: "CRUD",
              detailsFunction: openAction,
            });
          });
          setParticipants(arr);
        }
        setError(null);
        setCurrentIndex(1);
        setLoading(false);
      })
      .catch((error) => {
        // setError("Unable to get request");
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }, [pageNo, openAction]);

  useEffect(() => {
    getParticipants();
  }, [getParticipants]);

  function deleteParticipant(id) {
    setModalLoading(true);
    const url = BACK_OFFICE_API.PARTICIPANT.DELETE_SETTLEMENT_PARTICIPANT;
    API_SERVICE.MAKE_PUT_REQUEST(`${url}/${id}`)
      .then((data) => {
        setVisible(false);
        setModalLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Participant deleted successfully",
          life: 3000,
        });
        reload();
      })
      .catch((error) => {
        toast.current.show({
          severity: "error",
          summary: "Error deleting participant!",
          detail: HELPER.PROCESS_ERROR(error, "TOAST"),
          life: 10000,
        });
        setModalLoading(false);
      });
  }

  function onHide() {}

  function reload() {
    setLoading(true);
    setSearch(false);
    setCurrentIndex(0);
    getParticipants();
  }

  function closeModal(isReload) {
    if (visible) {
      setVisible(false);
    }
    if (currentIndex === 2) {
      setCurrentIndex(1);
    }

    if (isReload === true) {
      reload();
    }
  }

  function goToPage(pageNo) {
    setPageNo(pageNo);
  }

  const mobileModal = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <CustomSettlementParticipantModel closeModal={closeModal} />;
      case 1:
        return (
          <CustomSettlementParticipantModel
            searchFunction={searchParticipant}
            isSearch={true}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting participant..."
            fn={deleteParticipant}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <CustomSettlementParticipantModel
            editParticipant={participantForEdit}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
    }
  };

  const settlementView = () => {
    // eslint-disable-next-line default-case
    switch (currentIndex) {
      case 0:
        return (
          <div className="loading-container">
            <CustomLoader loadingText="Loading participants..." />
          </div>
        );
      case 1:
        return (
          <CustomTable
            authorities={participantAuthorities}
            totalPages={totalPages}
            totalItems={totalItems}
            currentPage={pageNo + 1}
            range={range}
            emptyText={emptyText}
            search={search}
            reload={reload}
            error={error}
            items={participants}
            headers={tableHeaders}
            nextPage={() => setPageNo(pageNo + 1)}
            prevPage={() => setPageNo(pageNo - 1)}
            goToFirstPage={() => setPageNo(0)}
            goToLastPage={() => setPageNo(totalPages - 1)}
            goToPage={goToPage}
          />
        );
      case 2:
        return (
          <div className="mobile-modal-container">
            <div className="custom-card">
              <div className="mobile-card-position">{mobileModal()}</div>
            </div>
          </div>
        );
    }
  };

  function searchParticipant(e) {
    setVisible(false);
    setLoading(true);
    setParticipants([]);
    const params = HELPER.TO_URL_STRING(e);
    const url = BACK_OFFICE_API.PARTICIPANT.CREATE_SETTLEMENT_PARTICIPANT;
    API_SERVICE.MAKE_GET_REQUEST(`${url}?${params}`)
      .then((data) => {
        const result = data?.result?.content;
        if (!result.length) {
          setEmptyText("No settlement participants found ...");
          setSearch(true);
          setLoading(false);
        } else {
          let arr = [];
          setTotalItems(data.result?.totalElements);
          setTotalPages(data.result?.totalPages);
          result?.forEach((e) => {
            arr.push({
              ...e,
              global: e.global ? "GLOBAL" : "NON GLOBAL",
              chargeType: e.chargeType.code,
              actions: "CRUD",
              detailsFunction: openAction,
            });
          });
          setParticipants(arr);
          setError(null);
          setCurrentIndex(1);
          setLoading(false);
        }
      })
      .catch((error) => {
        // setError("Unable to get request");
        setError(HELPER.PROCESS_ERROR(error));
        setCurrentIndex(1);
        setLoading(false);
      });
  }

  const modalContent = () => {
    // eslint-disable-next-line default-case
    switch (currentModalIndex) {
      case 0:
        return <CustomSettlementParticipantModel closeModal={closeModal} />;
      case 1:
        return (
          <CustomSettlementParticipantModel
            searchFunction={searchParticipant}
            isSearch={true}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <DetailsBreakDown
            title={breakDownTitle}
            breakDown={details}
            closeModal={closeModal}
          />
        );
      case 3:
        return (
          <CustomConfirmDialog
            itemId={itemIdForDelete}
            confirmText={confirmText}
            loading={modalLoading}
            loadingText="Deleting participant..."
            fn={deleteParticipant}
            closeModal={closeModal}
          />
        );
      case 4:
        return (
          <CustomSettlementParticipantModel
            editParticipant={participantForEdit}
            isUpdate={true}
            closeModal={closeModal}
          />
        );
    }
  };

  return (
    <ErrorBoundary>
      <div>
        <Toast ref={toast} />
        <div>
          <CustomModal
            closeModal={closeModal}
            onHide={onHide}
            visible={visible}
            modalContent={modalContent}
          />
        </div>
        {/* <div className="page-title p-text-left">Settlement Participants</div> */}
        <div className="page-header">
          <div className="page-title p-text-left">Settlement Participants</div>
          <UserManualViewerButton fileName="participants" />
        </div>
        <div className="p-mt-2">
          <CustomBreadcrumb page="Manage Settlement Participants" />
        </div>
        <div className="floating-buttons desktop-scree">
          <div className="p-grid">
            <div className="p-col-7"></div>
            <div className="p-col-5">
              <div className="p-grid">
                <div className="p-col-6"></div>
                <div className="p-col-6">
                  <div
                    className={
                      HELPER.HAS_AUTHORITY("bo_dcir_create_participant")
                        ? "dcir-show"
                        : "dcir-hide"
                    }
                  >
                    <button
                      disabled={loading}
                      onClick={() => openModal(0, false)}
                      className="primary-button hide-btn-text"
                    >
                      <i className="pi pi-plus" />
                      <span className="hide-btn-text"> New participant</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{settlementView()}</div>
      </div>
    </ErrorBoundary>
  );
}
